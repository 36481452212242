import client from "../client";

export const getBusinessType = () => {
  const url = `business-types`;
  return new Promise((resolve, reject) => {
    client.get(url).then(
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      }
    );
  });
};

export const putUpdateBusinessType = (userType, userId, body) => {
  const url = `admin/users/${userType}/${userId}/business-type`;
  return new Promise((resolve, reject) => {
    client.put(url, body).then(
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      }
    );
  });
};
