import React from 'react';
import Box from '@material-ui/core/Box';
import wallet from '../../../assets/wallet.svg';
import logoAlt from '../../../assets/logoAlt.svg';
import mati from '../../../assets/mati.svg';
import Text from '../../../components/Text';
import useStyles from './CustomerValidationStatus.style';
import CreditStatusLabel from '../../Registers/CreditStatusLabel/CreditStatusLabel';

const CustomerValidationStatus = (props) => {
  const classes = useStyles();

  const { creditValidation, identityValidation, vendorValidation } = props;

  return (
    <Box className={classes.customerValidationStatus}>
      <Box className={classes.validationRow}>
        <Box>
          <img src={mati} alt="mati" />
          <Text>Validación MATI</Text>
        </Box>
        <CreditStatusLabel status={identityValidation} />
      </Box>
      <Box className={classes.validationRow}>
        <Box>
          <img src={logoAlt} alt="logo" />
          <Text>Validación YoFio</Text>
        </Box>
        <CreditStatusLabel status={vendorValidation} />
      </Box>
      <Box className={classes.validationRow}>
        <Box>
          <img src={wallet} alt="wallet" />
          <Text>Validación de crédito</Text>
        </Box>
        <CreditStatusLabel status={creditValidation.status} />
      </Box>

    </Box>
  );
};

export default CustomerValidationStatus;
