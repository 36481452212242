/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import PropTypes from 'prop-types';
import GridM from '@material-ui/core/Grid';
import useStyles from './Layout.styles';

const Grid = ({ children, ...others }) => {
  const classes = useStyles();
  return (
    <GridM classes={classes} {...others}>
      {children}
    </GridM>
  );
};


Grid.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]).isRequired,
};

export default Grid;
