import * as ACTIONS from './creditInformation.actionTypes';

const initialState = {
  isFetchingShopDetail: false,
  credit_line_shop_detail: {},
  error: null,

  loadingShop: {
    GET_SHOP_DETAIL: false,
  },
  errorShop: {
    GET_SHOP_DETAIL: null,
  },

  dataShopDetail: {},
};

const creditInformationReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.FETCH_SHOPBYID_INIT:
      return { ...state, isFetchingShopDetail: true };
    case ACTIONS.FETCH_SHOPBYID_SUCCESS:
      return {
        ...state, isFetchingShopDetail: false, error: null,
      };
    case ACTIONS.FETCH_SHOPBYID_ERROR:
      return { credit_line_shop_detail: {}, isFetchingShopDetail: false, error: action.error };
    case ACTIONS.SET_STATE:
      return {
        ...state, [action.payload.label]: action.payload.value, isFetchingShopDetail: false, error: null
      };

    case ACTIONS.INIT_SHOP_DETAIL:
      return { ...state, loadingShop: { ...state.loadingShop, GET_SHOP_DETAIL: true }, errorShop: { ...state.errorShop, GET_SHOP_DETAIL: null } };
    case ACTIONS.RESPONSE_SHOP_DETAIL:
      return {
        ...state, loadingShop: { ...state.loadingShop, GET_SHOP_DETAIL: false }, errorShop: { ...state.errorShop, GET_SHOP_DETAIL: null }, dataShopDetail: action.result,
      };
    case ACTIONS.ERROR_SHOP_DETAIL:
      return { ...state, loadingShop: { ...state.loadingShop, GET_SHOP_DETAIL: false }, errorShop: { ...state.errorShop, GET_SHOP_DETAIL: action.error } };
    case ACTIONS.CLEAR_SHOP_DETAIL:
      return { ...state, dataShopDetail: {} };
      
    default:
      return { ...state };
  }
};

export default creditInformationReducer;