import React from "react";

import { Button, MenuItem } from "@material-ui/core";

import Box from '../../components/Layout/Box';
import Text from '../../components/Text';
import TextInput from "../../components/TextInput";
import icons from "../../assets/icons";

function CardVouchers({
    classes,
    data,
    values,
    handleChangeInput,
    setZoomModal,
    dealerList,
    viewDetail,
    transformMetadata
}) {
    return (
        <>
            <Box className={classes.containerTicket}>
                <img
                    src={data?.img_url}
                    className={classes.imageContainer}
                />
                <img
                    src={icons.zoomIcon}
                    className={classes.zoomImage}
                    onClick={() => setZoomModal({
                        show: true,
                        image: data?.img_url
                    })}
                />
                {data.revision_status === 'PENDING' && <>
                    <TextInput
                        className={classes.formElement}
                        label="Distribuidor"
                        variant="standard"
                        select
                        value={values.dealer}
                        onChange={handleChangeInput('dealer')}
                    >
                        {dealerList.length !== 0 &&
                            dealerList.map(dealer => <MenuItem value={dealer.id}>{dealer.name}</MenuItem>)
                        }
                    </TextInput>
                    <Box className={classes.infoContainer}>
                        <Text className={classes.titleInfo}>{`Monto`}</Text>
                        <Text className={classes.textInfo}>{data?.metadata ? transformMetadata(data?.metadata, "total_amount") : "Sin datos"}</Text>
                    </Box>
                    <Box className={classes.infoContainer}>
                        <Text className={classes.titleInfo}>{`Fecha de compra`}</Text>
                        <Text className={classes.textInfo}>{data?.metadata ? transformMetadata(data?.metadata, "date") : "Sin datos"}</Text>
                    </Box>
                    <Button
                        className={classes.buttonViewDetail}
                        variant="contained"
                        color='secondary'
                        onClick={() => viewDetail(data?.id)}
                        disabled={
                            values.dealer !== ''
                            ? false : true
                        }
                    >
                        Ver detalle
                    </Button>
                </>
                }
                {data.revision_status === 'REJECTED' &&
                    <Box className={classes.buttonContainer}>
                        <img
                            src={icons.alertRed}
                            className={classes.statusImg}
                        />
                        <Text>Comprobante Rechazado</Text>
                    </Box>
                }
                {data.revision_status === 'APPROVED' &&
                    <Box className={classes.buttonContainer}>
                        <img
                            src={icons.alertGreen}
                            className={classes.statusImg}
                        />
                        <Text>{`Comprobante aprobado por $${data?.amount}`}</Text>
                    </Box>
                }
            </Box>
        </>
    )
}

export default CardVouchers;