import * as ACTION from './insurance.actionTypes';
import * as insuranceService from '../services/insurence.service';

export const getPolicyStatementClosing = (account_id, policy_id, payment_status, closing_date) => async (dispatch) => {
    dispatch({
        type: ACTION.INIT_GET_POLICY_STATEMENT_CLOSING,
    });

    try {
        const policyStatementData = await insuranceService.getPolicyStatementClosing(account_id, policy_id, payment_status, closing_date);
        if (policyStatementData) {
            dispatch({
            type: ACTION.RESPONSE_GET_POLICY_STATEMENT_CLOSING,
            result: policyStatementData,
            });
        }
    } catch (error) {
        dispatch({
            type: ACTION.ERROR_GET_POLICY_STATEMENT_CLOSING,
            error,
        });
    }
};

export const clearGetPolicyStatementClosing = () => async (dispatch) => {
    dispatch({
        type: ACTION.CLEAR_GET_POLICY_STATEMENT_CLOSING,
    });
};

export const getPolicy = (account_id, policy_type, policy_status) => async (dispatch) => {
    dispatch({
        type: ACTION.INIT_GET_POLICY,
    });

    try {
        const policyData = await insuranceService.getPolicy(account_id, policy_type, policy_status);
        if (policyData) {
            dispatch({
            type: ACTION.RESPONSE_GET_POLICY,
            result: policyData,
            });
        }
    } catch (error) {
        dispatch({
            type: ACTION.ERROR_GET_POLICY,
            error,
        });
    }
};

export const clearGetPolicy= () => async (dispatch) => {
    dispatch({
        type: ACTION.CLEAR_GET_POLICY,
    });
};