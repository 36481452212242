import React from 'react';
import Text from '../../../components/Text';
import Box from '../../../components/Layout/Box';
import useStyles from './CustomerLaboralInfo.style';
import MissingDataAlert from '../../../components/MissingDataAlert/MissingDataAlert';
import LabelValueTextList
  from '../../../components/LabelValueTextList/LabelValueTextList';

const CustomerLaboralInfo = (props) => {
  const {
    job_name,
    incomes,
    seniority,
    incomes_type,
  } = props;

  const classes = useStyles();

  let missingInfo;

  if (!job_name || !incomes || !seniority || !incomes_type) {
    missingInfo = true;
  }

  const rows = [
    {
    label: '¿A qué te dedicas?',
    value: job_name
    },
    {
      label: '¿Cuánto ganas?',
      value: incomes
    },
    {
      label: '¿Desde cuándo realizas esa actividad?',
      value: seniority
    },
    {
      label: '¿Cuál es tu tipo de ingresos?',
      value: incomes_type == 1 ? 'Fijo' : 'Variable'
    }
  ]

  return (
    <Box className={classes.customerLaboralInfoContainer}>
      {missingInfo && <MissingDataAlert text="El cliente no ha completado sus datos correctamente." />}
      <Text variant='h3'>
        MI TRABAJO
      </Text>
      <Box style={{display: 'flex'}}>
        <LabelValueTextList rows={rows}/>
      </Box>
    </Box>
  );
};

export default CustomerLaboralInfo;
