import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  tableCell: {
    textAlign: 'center',
    padding: theme.spacing(1),
  },
  statusCell: {
    position: 'relative',
    textAlign: 'center',
  },
  textStyle: {
    textAlign: 'center',
  },
  circle: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    left: '30px',
    width: '14px',
    height: '14px',
    borderRadius: '50%',
    marginRight: theme.spacing(1),
  },
  pendingCircle: {
    backgroundColor: '#fdcd18',
  },
  approvedCircle: {
    backgroundColor: '#45c441',
  },
  rejectedCircle: {
    backgroundColor: '#ea4b4b',
  },
  unknownCircle: {
    backgroundColor: '#d8d8d8',
  },
  tableTitle: {
    fontWeight: 'bold',
    marginTop: '40px',
    marginBottom: '20px',
  },
  tableContainer: {
    boxShadow: 'none',
    border: 'none',
  },
}));

export default useStyles;
