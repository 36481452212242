export const INIT_PUT_UPDATE_MARITAL_STATUS = 'USERS::INIT_PUT_UPDATE_MARITAL_STATUS';
export const RESPONSE_PUT_UPDATE_MARITAL_STATUS = 'USERS::RESPONSE_PUT_UPDATE_MARITAL_STATUS';
export const ERROR_PUT_UPDATE_MARITAL_STATUS = 'USERS::ERROR_PUT_UPDATE_MARITAL_STATUS';
export const CLEAR_PUT_UPDATE_MARITAL_STATUS = 'USERS::CLEAR_PUT_UPDATE_MARITAL_STATUS';

export const INIT_PUT_UPDATE_REQUIREMENTS = 'USERS::INIT_PUT_UPDATE_REQUIREMENTS';
export const RESPONSE_PUT_UPDATE_REQUIREMENTS = 'USERS::RESPONSE_PUT_UPDATE_REQUIREMENTS';
export const ERROR_PUT_UPDATE_REQUIREMENTS = 'USERS::ERROR_PUT_UPDATE_REQUIREMENTS';
export const CLEAR_PUT_UPDATE_REQUIREMENTS = 'USERS::CLEAR_PUT_UPDATE_REQUIREMENTS';

export const INIT_GET_BUREAUS = 'USERS::INIT_GET_BUREAUS';
export const RESPONSE_GET_BUREAUS = 'USERS::RESPONSE_GET_BUREAUS';
export const ERROR_GET_BUREAUS = 'USERS::ERROR_GET_BUREAUS';
export const CLEAR_GET_BUREAUS = 'USERS::CLEAR_GET_BUREAUS';

export const INIT_GET_COMPARISON_ALERTS = 'USERS::INIT_GET_COMPARISON_ALERTS';
export const RESPONSE_GET_COMPARISON_ALERTS = 'USERS::RESPONSE_GET_COMPARISON_ALERTS';
export const ERROR_GET_COMPARISON_ALERTS = 'USERS::ERROR_GET_COMPARISON_ALERTS';
export const CLEAR_GET_COMPARISON_ALERTS = 'USERS::CLEAR_GET_COMPARISON_ALERTS';

export const INIT_PUT_VEREDICT = 'USERS::INIT_PUT_VEREDICT';
export const RESPONSE_PUT_VEREDICT = 'USERS::RESPONSE_PUT_VEREDICT';
export const ERROR_PUT_VEREDICT = 'USERS::ERROR_PUT_VEREDICT';
export const CLEAR_PUT_VEREDICT = 'USERS::CLEAR_PUT_VEREDICT';

export const INIT_POST_RESUME_REGISTER = 'USERS::INIT_POST_RESUME_REGISTER';
export const RESPONSE_POST_RESUME_REGISTER = 'USERS::RESPONSE_POST_RESUME_REGISTER';
export const ERROR_POST_RESUME_REGISTER = 'USERS::ERROR_POST_RESUME_REGISTER';
export const CLEAR_POST_RESUME_REGISTER = 'USERS::CLEAR_POST_RESUME_REGISTER';

export const INIT_POST_REJECT_REGISTER = 'USERS::INIT_POST_REJECT_REGISTER';
export const RESPONSE_POST_REJECT_REGISTER = 'USERS::RESPONSE_POST_REJECT_REGISTER';
export const ERROR_POST_REJECT_REGISTER = 'USERS::ERROR_POST_REJECT_REGISTER';
export const CLEAR_POST_REJECT_REGISTER = 'USERS::CLEAR_POST_REJECT_REGISTER';

export const INIT_GET_USERS = 'USERS::INIT_GET_USERS';
export const RESPONSE_GET_USERS = 'USERS::RESPONSE_GET_USERS';
export const ERROR_GET_USERS = 'USERS::ERROR_GET_USERS';
export const CLEAR_GET_USERS = 'USERS::CLEAR_GET_USERS';

export const INIT_PUT_ASSIGN_PASSWORD = 'USERS::INIT_PUT_ASSIGN_PASSWORD';
export const RESPONSE_PUT_ASSIGN_PASSWORD = 'USERS::RESPONSE_PUT_ASSIGN_PASSWORD';
export const ERROR_PUT_ASSIGN_PASSWORD = 'USERS::ERROR_PUT_ASSIGN_PASSWORD';
export const CLEAR_PUT_ASSIGN_PASSWORD = 'USERS::CLEAR_PUT_ASSIGN_PASSWORD';

export const INIT_PUT_LIGHT_CREDIT_LINE = 'USERS::INIT_PUT_LIGHT_CREDIT_LINE';
export const RESPONSE_PUT_LIGHT_CREDIT_LINE = 'USERS::RESPONSE_PUT_LIGHT_CREDIT_LINE';
export const ERROR_PUT_LIGHT_CREDIT_LINE = 'USERS::ERROR_PUT_LIGHT_CREDIT_LINE';
export const CLEAR_PUT_LIGHT_CREDIT_LINE = 'USERS::CLEAR_PUT_LIGHT_CREDIT_LINE';

// Change bank account
export const INIT_PUT_CHANGE_BANK_ACCOUNT = 'USERS::INIT_PUT_CHANGE_BANK_ACCOUNT';
export const RESPONSE_PUT_CHANGE_BANK_ACCOUNT = 'USERS::RESPONSE_PUT_CHANGE_BANK_ACCOUNT';
export const ERROR_PUT_CHANGE_BANK_ACCOUNT = 'USERS::ERROR_PUT_CHANGE_BANK_ACCOUNT';
export const CLEAR_PUT_CHANGE_BANK_ACCOUNT = 'USERS::CLEAR_PUT_CHANGE_BANK_ACCOUNT';

// Credit line id's
export const INIT_GET_CREDIT_LINE_IDS = 'USERS::INIT_GET_CREDIT_LINE_IDS';
export const RESPONSE_GET_CREDIT_LINE_IDS = 'USERS::RESPONSE_GET_CREDIT_LINE_IDS';
export const ERROR_GET_CREDIT_LINE_IDS = 'USERS::ERROR_GET_CREDIT_LINE_IDS';
export const CLEAR_GET_CREDIT_LINE_IDS = 'USERS::CLEAR_GET_CREDIT_LINE_IDS';

export const CLEAR_ERRORS = 'USERS::CLEAR_ERRORS';
