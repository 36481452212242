import React, { useState, useEffect } from "react";

import TableMetadataReviewVoucherUI from "./TableMetadataReviewVoucher";
import useStyles from "./TableMetadataReviewVoucher.styles";
    
function TableMetadataReviewVoucher(props) {
    const { metadata, setMetadata, isEditable, setIsEditable, supplierName } = props;
    const classes = useStyles();
    const [editableMetadata, setEditableMetadata] = useState(null);

    useEffect(() => {
        if(metadata) {
            setEditableMetadata(metadata);
        }
    }, [metadata]);

    function formatCurrency(amount) {
        if (amount == null) {
            return '$0.00';
        }
        return `$${Number(amount).toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
    }

    const handleSave = () => {
        setMetadata(editableMetadata);
        setIsEditable(false);
    };

    const handleCancel = () => {
        setEditableMetadata(metadata);
        setIsEditable(false);
    };

    return <TableMetadataReviewVoucherUI
        classes={classes}
        metadata={editableMetadata}
        formatCurrency={formatCurrency}
        isEditable={isEditable}
        setIsEditable={setIsEditable}
        setEditableMetadata={setEditableMetadata}
        handleSave={handleSave}
        handleCancel={handleCancel}
        supplierName={supplierName}
        
    />
};

export default TableMetadataReviewVoucher;