import * as ACTION from '../../../../reducers/transactions.actionTypes';
import shopsService from '../../../../services/shops.service';

export const getTransactionsDetail = (creditLineId) => async (dispatch) => {
    dispatch({ type: ACTION.FETCH_TRANSACTIONS_INIT });

    try {
        const transactionsDetail = await shopsService.getShopMovementsById(creditLineId);
        if (transactionsDetail) {
            dispatch({
                type: ACTION.FETCH_TRANSACTIONS_SUCCESS
            });
            dispatch({
                type: ACTION.SET_STATE,
                payload: {
                    label: 'transactionsDetail',
                    value: transactionsDetail,
                },
            });
        } else {
            dispatch({
                type: ACTION.FETCH_TRANSACTIONS_ERROR,
            });
        }
    } catch (e) {
        dispatch({
            type: ACTION.FETCH_TRANSACTIONS_ERROR,
        });
    }
};