import { makeStyles } from "@material-ui/core/styles";

const useStyles =  makeStyles(() => ({
  rootContainer: {
    width: '100%',
  },
  controls: {
    display: 'flex',
    position: 'relative',
    top: 0,
    width: '100%',
    marginTop: '22px',
    color: '#858d9c',
    alignItems: 'center',
  },
  paper: {
    width: '100%',
    //marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 650,
  },
  root: {
    flexShrink: 0,
    margin: 20,
    //marginLeft: theme.spacing(2.5),
    borderColor: '#0253cc',
  },
  textStyle: {
    fontSize: 10,
    letterSpacing: -0.04,
    lineHeight: 1.44,
  },
  tableContainer: {
    width: '65%',
    borderRadius: 2.9,
    border: '1px solid #e8e8fb',
  },
  buttonContainer: {
    paddingTop: 5,
    paddingBottom: 5,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    width: '45%',
    padding: 10,
    margin: 10,
  },
  textBlue: {
    paddingTop: 10,
    paddingBottom: 10,
    color: '#0253cc'
  },
  mainPanel: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    justifyContent: "flex-start",
  },
  container: {
    flex: 1,
    backgroundColor: "#fafcfe",
    marginTop: 16,
    borderRadius: 6,
    borderColor: "#e8e8fc",
    paddingLeft: "2%",
  },
  upperPanel: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    alignContent: "center",
    alignItems: "center",
    borderColor: "#e8e8fc",
    "& h2": {
      fontSize: 20,
    },
     "& h5": {
          color: "#3a4760",
          fontSize: 11,
        },
    "& h6": {
      color: "#3a4760",
      fontSize: 9,
    },
    "& h3": {
      fontWeight: "bold",
    },
  },
  lowerPanel: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-around",
    alignContent: "center",
    alignItems: "center",
    height: 100,
    fontSize: 16,
    borderColor: "#e8e8fc",

    "& h3": {
      paddingTop: "10%",
      fontSize: 17,
    },
    "& h6": {
      paddingTop: "5%",
      fontSize: 9,
      color: "#3a4760",
    },
  },
  customerStatus: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    overflow: "hidden",
    padding: "0.5%",
    width: "25%",
    "& h3": {
      color: "#3a4760",
      fontSize:12,
    },
    "& h4": {
      color: "#3a4760",
      fontFamily: "Poppins",
      fontSize: 12,
    },
  },

}));

export default useStyles;
