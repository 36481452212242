import React from 'react';

import MenuItemLandingUI from './MenuItem';

const MenuItemLanding = (props) => {
    return(
        <MenuItemLandingUI {...props} />
    )
}

export default MenuItemLanding;