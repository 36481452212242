import * as ACTION from "./appointment.actionTypes";
import * as appointmentService from "../services/appointment.service";

export const getAppointmentAction = (phone_number) => async (dispatch) => {
  dispatch({
    type: ACTION.INIT_GET_APPOINTMENT,
  });

  try {
    const appointmentData = await appointmentService.getAppointment(
      phone_number
    );
    if (appointmentData) {
      dispatch({
        type: ACTION.RESPONSE_GET_APPOINTMENT,
        result: appointmentData,
      });
    }
  } catch (error) {
    dispatch({
      type: ACTION.ERROR_GET_APPOINTMENT,
      error,
    });
  }
};

export const putAppointmentAction =
  (appointment_id, body) => async (dispatch) => {
    dispatch({
      type: ACTION.INIT_PUT_APPOINTMENT,
    });

    try {
      const updateAppointment = await appointmentService.putAppoinment(
        appointment_id,
        body
      );
      if (updateAppointment) {
        dispatch({
          type: ACTION.RESPONSE_PUT_APPOINTMENT,
          result: updateAppointment,
        });
      }
    } catch (error) {
      dispatch({
        type: ACTION.ERROR_PUT_APPOINTMENT,
        error,
      });
    }
  };

export const cleanAppointment = () => async (dispatch) => {
  dispatch({
    type: ACTION.CLEAN_GET_APPOINTMENT,
  });
};
