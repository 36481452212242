import * as ACTION from './actionTypes';
import { fetchWeater } from '../../services/dashboardService';

export function loadingPage() {
  return {
    type: ACTION.LOADING_PAGE_DASHBOARD,
  };
}

export function getWeater(zipcode) {
  return {
    types: [
      ACTION.LOADING_WEATHER,
      ACTION.RECEIVE_WEATHER,
      ACTION.ERROR_WEATHER,
    ],
    promise: fetchWeater(zipcode)
  };
}
