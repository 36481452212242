import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import logo from '../../assets/logo.svg';
import { Box } from '../../components/Layout';
import Text from '../../components/Text';
import useStyles from './LoginPage.styles';
import GoogleLogin from '../../components/GoogleLogin/GoogleLogin';
import { login } from './LoginActions';

export const LoginPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  const target_path = params.previous_path || '/registers';
  const token = localStorage.getItem('firebaseToken');

  useEffect(() => {
    if(token !== null) {
      history.push(target_path);
    } else {
      handleGoogleLogin()
    }
  }, [token]);

  const handleGoogleLogin = () => {
    dispatch(login()).then(() => history.push(target_path));
  };

  return (
    <Box className={classes.page}>
      <Box className={classes.container}>
        <img className={classes.appLogo} src={logo} alt="logo" />
      </Box>
      <Box className={classes.loginContainer} bgcolor="white">
        <Box className={classes.login}>
          <Text variant="h1">
            Bienvenido(a) a yofio
          </Text>
          <Text variant="h2">Inicia sesión en tu cuenta</Text>
          <GoogleLogin onClick={handleGoogleLogin} />
        </Box>
        <Box>
          <Text>Build: {(process.env.REACT_APP_DEPLOYMENT_BUILD_ID || '').substring(0, 8)}</Text>
        </Box>
      </Box>
    </Box>
  );
};

export default LoginPage;
