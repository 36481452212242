import * as ACTION from './payment.actionTypes';

const paymentInfo = {
  loading: {
    POST_PAYMENT_AGREEMENTS: false,
    GET_PAYMENT_AGREEMENTS: false,
  },
  error: {
    POST_PAYMENT_AGREEMENTS: null,
    GET_PAYMENT_AGREEMENTS: null,
  },

  paymentAgreementsData: {},
  getPaymentAgreementsData: {},
};

const paymentReducer = (state = paymentInfo, action) => {
  switch (action.type) {
    case ACTION.INIT_POST_PAYMENT_AGREEMENTS:
      return {
        ...state,
        loading: { ...state.loading, POST_PAYMENT_AGREEMENTS: true },
        error: { ...state.error, POST_PAYMENT_AGREEMENTS: null },
      };
    case ACTION.RESPONSE_POST_PAYMENT_AGREEMENTS:
      return {
        ...state,
        loading: { ...state.loading, POST_PAYMENT_AGREEMENTS: false },
        error: { ...state.error, POST_PAYMENT_AGREEMENTS: null },
        presaleAdvisorTaskData: action.result,
      };
    case ACTION.ERROR_POST_PAYMENT_AGREEMENTS:
      return {
        ...state,
        loading: { ...state.loading, POST_PAYMENT_AGREEMENTS: false },
        error: { ...state.error, POST_PAYMENT_AGREEMENTS: action.error },
      };
    case ACTION.CLEAR_POST_PAYMENT_AGREEMENTS:
      return { ...state, paymentAgreementsData: {} };

    case ACTION.INIT_GET_PAYMENT_AGREEMENTS:
      return {
        ...state,
        loading: { ...state.loading, GET_PAYMENT_AGREEMENTS: true },
        error: { ...state.error, GET_PAYMENT_AGREEMENTS: null },
      };
    case ACTION.RESPONSE_GET_PAYMENT_AGREEMENTS:
      return {
        ...state,
        loading: { ...state.loading, GET_PAYMENT_AGREEMENTS: false },
        error: { ...state.error, GET_PAYMENT_AGREEMENTS: null },
        getPaymentAgreementsData: action.result,
      };
    case ACTION.ERROR_GET_PAYMENT_AGREEMENTS:
      return {
        ...state,
        loading: { ...state.loading, GET_PAYMENT_AGREEMENTS: false },
        error: { ...state.error, GET_PAYMENT_AGREEMENTS: action.error },
      };
    case ACTION.CLEAR_GET_PAYMENT_AGREEMENTS:
      return { ...state, getPaymentAgreementsData: {} };
    case ACTION.CLEAR_ERRORS:
      return { ...state, error: paymentInfo.error };
    default:
      return state;
  }
};

export default paymentReducer;
