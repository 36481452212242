export const INIT_GET_VEREDICT_REASONS_CATALOG = 'CATALOG::INIT_GET_VEREDICT_REASONS_CATALOG';
export const RESPONSE_GET_VEREDICT_REASONS_CATALOG = 'CATALOG::RESPONSE_GET_VEREDICT_REASONS_CATALOG';
export const ERROR_GET_VEREDICT_REASONS_CATALOG = 'CATALOG::ERROR_GET_VEREDICT_REASONS_CATALOG';
export const CLEAR_GET_VEREDICT_REASONS_CATALOG = 'CATALOG::CLEAR_GET_VEREDICT_REASONS_CATALOG';

export const INIT_GET_REVIEW_REQUEST_PROPERTIES_CATALOG = 'CATALOG::INIT_GET_REVIEW_REQUEST_PROPERTIES_CATALOG';
export const RESPONSE_GET_REVIEW_REQUEST_PROPERTIES_CATALOG = 'CATALOG::RESPONSE_GET_REVIEW_REQUEST_PROPERTIES_CATALOG';
export const ERROR_GET_REVIEW_REQUEST_PROPERTIES_CATALOG = 'CATALOG::ERROR_GET_REVIEW_REQUEST_PROPERTIES_CATALOG';
export const CLEAR_GET_REVIEW_REQUEST_PROPERTIES_CATALOG = 'CATALOG::CLEAR_GET_REVIEW_REQUEST_PROPERTIES_CATALOG';

export const INIT_GET_INVENTORY_REASONS_CATALOG = 'CATALOG::INIT_GET_INVENTORY_REASONS_CATALOG';
export const RESPONSE_GET_INVENTORY_REASONS_CATALOG = 'CATALOG::RESPONSE_GET_INVENTORY_REASONS_CATALOG';
export const ERROR_GET_INVENTORY_REASONS_CATALOG = 'CATALOG::ERROR_GET_INVENTORY_REASONS_CATALOG';
export const CLEAR_GET_INVENTORY_REASONS_CATALOG = 'CATALOG::CLEAR_GET_INVENTORY_REASONS_CATALOG';

export const INIT_GET_REJECT_REASONS_CATALOG = 'CATALOG::INIT_GET_REJECT_REASONS_CATALOG';
export const RESPONSE_GET_REJECT_REASONS_CATALOG = 'CATALOG::RESPONSE_GET_REJECT_REASONS_CATALOG';
export const ERROR_GET_REJECT_REASONS_CATALOG = 'CATALOG::ERROR_GET_REJECT_REASONS_CATALOG';
export const CLEAR_GET_REJECT_REASONS_CATALOG = 'CATALOG::CLEAR_GET_REJECT_REASONS_CATALOG';

export const CLEAR_ERRORS = 'CATALOG::CLEAR_ERRORS';
