import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    controls: {
        display: 'flex',
        position: 'relative',
        width: '100%',
        marginTop: '20px',
        color: '#858d9c',
        alignItems: 'center',
        cursor: 'pointer'
    },
    containerInfo: {
        marginTop: '20px',
        marginBottom: '20px',
    },
    titleDate: {
        fontSize: 15,
        fontWeight: 'bold',
    },
    containerTicket: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 10,
        border: '1px solid #e8e8fb',
        marginLeft: 15,
        maxWidth: '275px'
    },
    imageContainer: {
        width: '275px',
        height: '350px',
    },
    zoomImage: {
        position: 'absolute',
        width: 50,
        height: 50,
        marginTop: 130,
        marginLeft: 100,
        opacity: 0.8
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
        marginTop: 20,
        marginBottom: 20
    },
    containerStatus: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 20,
        marginBottom: 20,
        marginLeft: 10,
        marginRight: 10
    },
    containerInfoVoucher: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    statusImg: {
        width: 30,
        height: 30,
        marginRight: 10
    },
    textInfoStyle: {
        marginTop: 10,
        marginBottom: 10,
    },
    modalRejection: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalContainerZoom: {
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 15,
        backgroundColor: '#ffffff',
    },
    imageZoomStyle: {
        marginTop: 15,
        width: '500x',
        height: '600px',
        borderEndStartRadius: 15,
        borderEndEndRadius: 15,
    },
    buttonCloseZoom: {
        width: 30,
        height: 30,
        marginTop:  10,
        marginRight: 15,
        alignSelf: 'end'
    },
}));

export default useStyles;