import client from '../client';
import config from '../config';

class RegistersService {
  constructor() {
    this.session = {};
  }

  sendConfirmationSms(userId) {
    return client.put(`admin/users/account/${userId}/send-phone-number-verification-code`);
  }
}

export default new RegistersService();
