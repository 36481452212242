import React from "react";

import ScoreIndicatorUI from "./ScoreIndicator";    
import useStyles from "./ScoreIndicator.styles";

function ScoreIndicator(props) {
    const { score, textScore } = props;
    const classes = useStyles();

    const formateScore = Math.floor(score * 100);
    
    return <ScoreIndicatorUI
        classes={classes}
        score={formateScore}
        textScore={textScore}
    />
};

export default ScoreIndicator;