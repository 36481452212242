import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  CustomerStepContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    height: '90%',
  },
  buttonBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
}));

export default useStyles;
