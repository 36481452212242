import client from "../client";

export const getSupplier = (queries) => {
    const url = `admin/supplier-purchases${queries && Object.keys(queries).length > 0
        ? Object.keys(queries).reduce(
            (queryString, queryName, index) => `${queryString}${index !== 0 ? '&' : ''}${queryName}=${queries[queryName]}`, '?',
        )
        : ''}`;
    return new Promise((resolve, reject) => {
        client.get(url)
        .then((response) => {
            resolve(response);
        }, (error) => {
            reject(error);
        });
    });
};

export const getSupplierRejectReason = () => {
    const url = `admin/supplier-purchases/vouchers/reject-reasons`;
    return new Promise((resolve, reject) => {
        client.get(url)
        .then((response) => {
            resolve(response);
        }, (error) => {
            reject(error);
        });
    });
};

export const putSupplierAprove = (creditLineId, supplierPurchaseId, voucherId, body) => {
    const url = `admin/credit-line/${creditLineId}/supplier-purchases/${supplierPurchaseId}/voucher/${voucherId}/approve`;
    return new Promise((resolve, reject) => {
        client.put(url, body)
        .then((response) => {
            resolve(response);
        }, (error) => {
            reject(error);
        });
    });
};

export const putSupplierReject = (creditLineId, supplierPurchaseId, voucherId, body) => {
    const url = `admin/credit-line/${creditLineId}/supplier-purchases/${supplierPurchaseId}/voucher/${voucherId}/reject`;
    return new Promise((resolve, reject) => {
        client.put(url, body)
        .then((response) => {
            resolve(response);
        }, (error) => {
            reject(error);
        });
    });
};

export const getSuppliers = () => {
    const url = `admin/supplier-purchase/suppliers`;
    return new Promise((resolve, reject) => {
        client.get(url)
        .then((response) => {
            resolve(response);
        }, (error) => {
            reject(error);
        });
    });
};

export const putSupplierDisbursement = (id, body) => {
    const url = `admin/supplier-purchase/${id}/disbursement-confirmation`;
    return new Promise((resolve, reject) => {
        client.put(url, body)
        .then((response) => {
            resolve(response);
        }, (error) => {
            reject(error);
        });
    });
};

export const postSendTransfer = (creditLineId, body) => {
    const url = `/admin/credit-line/${creditLineId}/transfer`;
    return new Promise((resolve, reject) => {
        client.post(url, body)
        .then((response) => {
            resolve(response);
        }, (error) => {
            reject(error);
        });
    });
};