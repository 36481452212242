import React, { useState, useRef } from 'react';
import { CSVLink } from 'react-csv';
import client from '../../client';
import Button from '../Button';
import { Box } from '../Layout';
import {
  CircularProgress, FormHelperText
} from '@material-ui/core';

const Downloader = (props) => {
  const {
    url,
    filename,
    children
  } = props
  const [transactionData, setTransactionData] = useState([]);
  // setup the ref that we'll use for the hidden CsvLink click once we've updated the data
  const csvLink = useRef();
  const [showSpinner, setShowSpinner] = useState('none');
  const [errorMsg, setErrorMsg] = useState('');
  let downloadFile = false;

  const getTransactionData = async () => {
    setShowSpinner('block');
    setErrorMsg('');
    downloadFile = false;

    // 'api' just wraps axios with some setting specific to our app. the important thing here is that we use .then to capture the table response data, update the state, and then once we exit that operation we're going to click on the csv download link using the ref
    await client.get(url, { timeout: 600000 })
      .then((r) => {
        setTransactionData(r.data);
        setTimeout(() => setShowSpinner('none'), 100);
        downloadFile = true;
      })
      .catch((e) => {
        console.log(e.response.data.message || '');
        setTimeout(() => setShowSpinner('none'), 100);
        downloadFile = false;
        setErrorMsg(`${e.message} - ${e.response.data.detail || e.response.data.message || ''}`);
      });
    if (downloadFile) {
      csvLink.current.link.click();
    }
  };

  // more code here

  return (
    // a bunch of other code here...
    <Box style={{ display: 'flex', margin: '5px 10px' }}>
      <Box style={{ display: 'flex' }}>
        <Button onClick={getTransactionData}>Descargar</Button>
        <CSVLink
          data={transactionData}
          filename={filename}
          className="hidden"
          ref={csvLink}
          target="_blank"
        />
        <Box style={{ display: showSpinner }}>
          <CircularProgress />
        </Box>
      </Box>
      <FormHelperText>
        {errorMsg}
      </FormHelperText>
    </Box>
  );
};

export default Downloader;
