import React, { useEffect, useState } from "react";

import TableMetadataBuyDealerUI from "./TableMetadataBuyDealer";
import useStyles from "./TableMetadataBuyDealer.styles";
    
function TableMetadataBuyDealer(props) {
    const { metadata, setMetadata, isEditable, setIsEditable, supplierName } = props;
    const classes = useStyles();
    const [editableMetadata, setEditableMetadata] = useState(null);

    useEffect(() => {
        if(metadata) {
            setEditableMetadata(metadata);
        }
    }, [metadata]);

    function formatCurrency(amount) {
        if (amount == null) {
            return '$0.00';
        }
        return `$${Number(amount).toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
    }

    const handleSave = () => {
        setMetadata(editableMetadata);
        setIsEditable(false);
    };

    const handleCancel = () => {
        setEditableMetadata(metadata);
        setIsEditable(false);
    };

    return (
        <TableMetadataBuyDealerUI
            classes={classes}
            metadata={editableMetadata}
            formatCurrency={formatCurrency}
            isEditable={isEditable}
            setIsEditable={setIsEditable}
            setEditableMetadata={setEditableMetadata}
            handleSave={handleSave}
            handleCancel={handleCancel}
            supplierName={supplierName}
        />
    );
}

export default TableMetadataBuyDealer;
