import * as ACTION from './suppliers.actionTypes';

const initialState = {
    saveSuppliers: []
};

const saveSuppliersReducer = (state = initialState, action) => {
    switch(action.type) {
        case ACTION.SUPPLIERS_BUY_DEALER:
            return {
                ...state,
                saveSuppliers: action.result
            };
        default:
            return state;
    }
};

export default saveSuppliersReducer;