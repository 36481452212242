import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '400px',
    marginTop: '22px',
    alignItems: 'left',
    border: 'solid 1px #e8e8fb',
    borderRadius: '6px',
    marginLeft: '20px',
  },
  formElement: {
    marginTop: 20,
    marginBottom: 35,
    width: '100%',
  },
  formContent: {
    display: 'flex',
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingTop: '10px',
    paddingBottom: '10px',

  },
  formTitleContainer: {
    backgroundColor: 'rgba(2, 83, 204, 0.05)',
    alignItems: 'flex-start',
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingTop: '10px',
    paddingBottom: '10px',
    border: 'solid 1px #e8e8fc',
  },
  formHeader: {
    fontSize: '14px',
    fontWeight: 'bolder',
    lineHeight: '1',
    letterSpacing: '-0.16px',
    textAlign: 'left',
    color: 'rgba(0, 0, 0, 0.93)',
  }
}));

export default useStyles;
