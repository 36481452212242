import client from '../client';

class LoanService {
    // Funcion que nos permite obtener el listado de la tesorería
    getResourceTreasuryList(queries = {}) {
        const url = `admin/shop-loan/disbursement${queries && Object.keys(queries).length > 0
            ? Object.keys(queries).reduce(
                (queryString, queryName, index) => `${queryString}${index !== 0 ? '&' : ''}${queryName}=${queries[queryName]}`, '?',
            )
            : ''}`;

        return client.get(url).then(({ data }) => data);
    }

    // Funcion que nos permite obtener datos de la tesorería buscando por nombre del tendero, id, etc ...
    getSearchTreasuryList(queries = {}) {

    }

    postSendTransfer = (creditLineId, body) => {
        const url = `/admin/credit-line/${creditLineId}/transfer`;
        return new Promise((resolve, reject) => {
            client.post(url, body)
                .then((response) => {
                    resolve(response);
                }, (error) => {
                    reject(error);
                });
        });
    }
}

export default new LoanService();

