
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  customerLaboralInfoContainer: {
    margin: '3%',
    display: 'flex',
    flexDirection: 'column',
  },
}));

export default useStyles;
