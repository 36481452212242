import React, { useEffect } from 'react';
import Alert from '@material-ui/lab/Alert';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';
import SearchIcon from '@material-ui/icons/Search';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import HeadquarterBlueIcon from '../../assets/icons/headquarter-blue-icon.svg';
import HeadquarterWhiteIcon from '../../assets/icons/headquarter-white-icon.svg';
import WholesalerBlueIcon from '../../assets/icons/wholesaler-blue-icon.svg';
import WholesalerWhiteIcon from '../../assets/icons/wholesaler-white-icon.svg';

import { useDispatch, useSelector } from 'react-redux';
import FormControl from '@material-ui/core/FormControl';
import {
  Select, FormHelperText, Input, CircularProgress, Snackbar,
} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';

import useStyles from './CreateWholesaler.style';
import SectionTitle from '../../components/SectionTitle';
import Button from '../../components/Button';
import PageContainer from '../../components/PageContainer';
import Text from '../../components/Text';
import { Box } from '../../components/Layout';
import { banks } from '../../constants/constants';
import { createHeadquarter, getZipCodeDetails, findHeadquarters, createWholesaler, clearState } from './wholesalerCreateActions';

const WholesalerCreatePage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [neighborhoodList, setNeighborhoodList] = React.useState('');
  const [wholesalerTypeSelection, setWholesalerTypeSelection] = React.useState('');
  const [headquarterSearchParam, setHeadquarterSearchParam] = React.useState('');
  const [hqIcon, setHqIcon] = React.useState(HeadquarterBlueIcon);
  const [wsIcon, setWsIcon] = React.useState(WholesalerBlueIcon);
  const [selectedHq, setSelectedHq] = React.useState('');
  const [isSelectedHq, setIsSelectedHq] = React.useState(false);

  const {
    isFetchingZcd,
    zcdList,
    isFetchingHq,
    hqList,
    isCreatingWholesaler,
    wasCreatedWholesaler,
    isCreatingHeadquarter,
    wasCreatedHeadquarter,
    error,
  } = useSelector((state) => state.wholesalerReducer);
  const [alert, setAlert] = React.useState({
    open: false,
    message: '',
    severity: '',
  });

  const [headquarterValues, setHeadquarterValues] = React.useState({
    business_name: '',
    trade_name: '',
    lada: '+52',
    phone_number: '',
    name: '',
    lastname: '',
    maternal_lastname: '',
    lr_name: '',
    lr_lastname: '',
    lr_maternal_lastname: '',
    email: '',
    curp: '',
    rfc: '',
    is_legal_representative_same_owner: true,
    business_rfc: '',
    zip_code: '',
    street_name: '',
    street_number: '',
    apartment_number: '',
    neighborhood: '',
    state: '',
    city: '',
  });
  const [wholesalerValues, setWholesalerValues] = React.useState({
    trade_name: '',
    email: '',
    additional_phone_numbers_text: '',
    bank_id: '',
    bank_account_number: '',
    zip_code: '',
    street_name: '',
    street_number: '',
    apartment_number: '',
    neighborhood: '',
    state: '',
    city: '',
    additional_phone_numbers: [],
  });

  const handleChange = (prop) => (event) => {
    event.preventDefault();
    setHeadquarterValues({ ...headquarterValues, [prop]: event.target.value });
  };

  const handleChangeWs = (prop) => (event) => {
    event.preventDefault();
    setWholesalerValues({ ...wholesalerValues, [prop]: event.target.value });
  }

  const handleChangeCase = (prop) => (event) => {
    event.preventDefault();
    setHeadquarterValues({ ...headquarterValues, [prop]: event.target.value.toUpperCase() });
  }

  const handleChangeZipCode = (event) => {
    handleChange('zip_code')(event);
    if (event.target.value.length !== 5) {
      return;
    }
    dispatch(getZipCodeDetails(event.target.value));
  };

  const handleChangeZipCodeWs = (event) => {
    handleChangeWs('zip_code')(event);
    if (event.target.value.length !== 5) {
      return;
    }
    dispatch(getZipCodeDetails(event.target.value));
  };

  const handleChangeAdditionalPhoneNumbers = (event) => {
    const apn = event.target.value.split(',');
    setWholesalerValues({ ...wholesalerValues, additional_phone_numbers: apn });
  };

  const handleChangeNeighborhood = (event) => {
    const nh = event.target.value;
    let city = '';
    let state = '';
    zcdList.map((zcd) => {
      if (zcd.neighborhood === nh) {
        city = zcd.city;
        state = zcd.state;
        return '';
      }
      return '';
    });
    setHeadquarterValues(
      {
        ...headquarterValues, neighborhood: nh, city, state,
      },
    );
  };

  const handleChangeNeighborhoodWs = (event) => {
    const nh = event.target.value;
    let city = '';
    let state = '';
    zcdList.map((zcd) => {
      if (zcd.neighborhood === nh) {
        city = zcd.city;
        state = zcd.state;
        return '';
      }
      return '';
    });
    setWholesalerValues(
      {
        ...wholesalerValues, neighborhood: nh, city, state,
      },
    );
  };

  const handleChangeIsLrSameOwner = () => {
    setHeadquarterValues({ ...headquarterValues, is_legal_representative_same_owner: !headquarterValues.is_legal_representative_same_owner });
  }

  const handleFindHeadquarter = (event) => {
    dispatch(findHeadquarters(headquarterSearchParam));
  };

  const [errorMessages, setErrorMessages] = React.useState({
    business_name: '',
    trade_name: '',
    phone_number: '',
    name: '',
    lastname: '',
    curp: '',
    business_rfc: '',
    zip_code: '',
    street_name: '',
    street_number: '',
    apartment_number: '',
    neighborhood: '',
    state: '',
    city: '',
    ws_trade_name: '',
    ws_bank_id: '',
    ws_bank_account_number: '',
    ws_zip_code: '',
    ws_street_name: '',
    ws_street_number: '',
    ws_apartment_number: '',
    ws_neighborhood: '',
    ws_additional_phone_numbers_text: '',
  });

  const errorMsgs = {
    business_name: 'Ingrese el nombre de la bodega',
    trade_name: 'Ingrese el nombre comercial de la bodega',
    phone_number: 'Ingrese el número de teléfono de la bodega',
    name: 'Ingrese el nombre del dueño de la bodega',
    lastname: 'Ingrese el apellido paterno del dueño de la bodega',
    curp: 'Ingrese el CURP del dueño de la bodega',
    business_rfc: 'Ingrese el RFC de la bodega',
    zip_code: 'Ingrese el código postal de la bodega',
    street_name: 'Ingrese el nombre de la calle de la bodega',
    street_number: 'Ingrese el número exterior de la calle de la bodega',
    apartment_number: 'Ingrese el número interior de la calle de la bodega',
    neighborhood: 'Ingrese la colonia de la calle de la bodega',
    ws_trade_name: 'Ingrese el nombre comercial de la sucursal',
    ws_bank_id: 'Ingrese el banco de la sucursal',
    ws_bank_account_number: 'Ingrese el número de cuenta bancaria de la sucursal',
    ws_zip_code: 'Ingrese el código postal de la sucursal',
    ws_street_name: 'Ingrese el nombre de la calle de la sucursal',
    ws_street_number: 'Ingrese el número exterior de la calle de la sucursal',
    ws_apartment_number: 'Ingrese el número interior de la calle de la sucursal',
    ws_neighborhood: 'Ingrese la colonia de la calle de la sucursal',
    ws_additional_phone_numbers_text: 'Ingrese los números telefónicos para notificación',
  };

  const handleErrorMessages = (errors) => {
    setErrorMessages({ ...errorMessages, ...errors });
  };

  const handleHeadquarterSubmit = () => {
    let hasError = false;
    const errors = {};
    Object.entries(headquarterValues).forEach(([k, v]) => {
      if (errorMsgs[k] && (v === '' || v === 0)) {
        hasError = true;
        errors[k] = errorMsgs[k];
      } else {
        errors[k] = '';
      }
    });
    if (!headquarterValues.is_legal_representative_same_owner) {
      if (headquarterValues.lr_name === '') {
        errors['lr_name'] = 'Ingrese el nombre del representate legal de la bodega';
        hasError = true;
      }
      if (headquarterValues.lr_lastname === '') {
        errors['lr_lastname'] = 'Ingrese el apellido del representate legal de la bodega';
        hasError = true;
      }
    }
    handleErrorMessages(errors);
    if (hasError) {
      return;
    }
    dispatch(createHeadquarter(headquarterValues));
  };

  const handleWholesalerSubmit = () => {
    let hasError = false;
    const errors = {};
    Object.entries(wholesalerValues).forEach(([k, v]) => {
      if (errorMsgs[`ws_${k}`] && (v === '' || v === 0)) {
        hasError = true;
        errors[`ws_${k}`] = errorMsgs[`ws_${k}`];
      } else {
        errors[`ws_${k}`] = '';
      }
    });
    if (!wholesalerValues.additional_phone_numbers_text.match(/^\+[1-9][0-9]{0,2}[1-9][0-9]{9}(,\+[1-9][0-9]{0,2}[1-9][0-9]{9})*$/)) {
      errors['ws_additional_phone_numbers_text'] = 'Los numeros para notificación requieren un formato lada (+52) y separados por coma (,)';
      hasError = true;
    }
    handleErrorMessages(errors);
    if (hasError) {
      return;
    }
    dispatch(createWholesaler(selectedHq, wholesalerValues));
  };

  const closeAlert = () => {
    setAlert({ ...alert, open: false });
  };

  useEffect(() => {
    dispatch(clearState());
  }, []);

  useEffect(() => {
    if (zcdList && zcdList.length > 0) {
      setNeighborhoodList(
        zcdList.map((zcd) => (<MenuItem value={zcd.neighborhood}>{zcd.neighborhood}</MenuItem>)),
      );
    } else {
      setNeighborhoodList('');
    }
  }, [zcdList]);

  useEffect(() => {
    if (error) {
      setAlert({
        ...alert,
        open: true,
        message: error,
        severity: 'error',
      });
    } else {
      setAlert({
        ...alert,
        open: false,
        message: '',
      });
    }
  }, [error]);

  useEffect(() => {
    if (wasCreatedHeadquarter) {
      setAlert({
        ...alert,
        open: true,
        message: 'La bodega fue creada exitosamente',
        severity: 'success',
      });
      setHeadquarterValues({
        ...headquarterValues,
        business_name: '',
        trade_name: '',
        lada: '+52',
        phone_number: '',
        name: '',
        lastname: '',
        maternal_lastname: '',
        lr_name: '',
        lr_lastname: '',
        lr_maternal_lastname: '',
        email: '',
        curp: '',
        rfc: '',
        is_legal_representative_same_owner: true,
        business_rfc: '',
        zip_code: '',
        street_name: '',
        street_number: '',
        apartment_number: '',
        neighborhood: '',
        state: '',
        city: '',
      });
    }
  }, [wasCreatedHeadquarter]);

  useEffect(() => {
    if (wasCreatedWholesaler) {
      setAlert({
        ...alert,
        open: true,
        message: 'La sucursal fue creada exitosamente',
        severity: 'success',
      });
      setWholesalerValues({
        ...wholesalerValues,
        trade_name: '',
        email: '',
        additional_phone_numbers_text: '',
        bank_id: '',
        bank_account_number: '',
        zip_code: '',
        street_name: '',
        street_number: '',
        apartment_number: '',
        neighborhood: '',
        state: '',
        city: '',
        additional_phone_numbers: [],
      });
    }
  }, [wasCreatedWholesaler]);

  const headquarterList = hqList && hqList.length > 0 ?
    hqList.map((hq) => {
      return (<FormControlLabel value={hq.id} control={<Radio />} label={hq.trade_name} className={classes.headquarterOption} />);
    }) : '';

  return (
    <PageContainer>
      <Snackbar open={alert.open} onClose={closeAlert} autoHideDuration={10000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert severity={alert.severity} onClose={closeAlert}>
          {alert.message}
        </Alert>
      </Snackbar>

      {!wholesalerTypeSelection && (
        <>
          <Box className={classes.mainPanel}>
            <SectionTitle>Registro de Bodega</SectionTitle>
          </Box>
          <Box className={classes.cardColoredContainer}>
            <div
              id='headquarter-selection'
              className={classes.cardColored}
              onClick={() => {
                setWholesalerTypeSelection('hq');
                setHqIcon(HeadquarterBlueIcon);
              }}
              onMouseOver={() => setHqIcon(HeadquarterWhiteIcon)}
              onMouseOut={() => setHqIcon(HeadquarterBlueIcon)}>
              <Text className={classes.selectionText}>
                <img className={classes.hqImg} src={hqIcon} alt="Bodega" />
                Bodega
              </Text>
            </div>
            <div
              id='wholesaler-selection'
              className={classes.cardColored}
              onClick={() => {
                setWholesalerTypeSelection('ws');
                setWsIcon(WholesalerBlueIcon);
              }}
              onMouseOver={() => setWsIcon(WholesalerWhiteIcon)}
              onMouseOut={() => setWsIcon(WholesalerBlueIcon)}>
              <Text className={classes.selectionText}>
                <img src={wsIcon} alt="Sucursal" />
                Sucursal
              </Text>
            </div>
          </Box>
        </>
      )}

      {wholesalerTypeSelection === 'hq' && (
        <>
          <Link className={classes.controls} onClick={() => setWholesalerTypeSelection('')}>
            <ArrowBackIcon />
            <Text>Regresar</Text>
          </Link>
          <Box className={classes.mainPanel}>
            <SectionTitle>Creaci&oacute;n de Bodega</SectionTitle>
          </Box>

          <Box style={{ width: '80%', paddingTop: 15, paddingBottom: 15 }}>
            <Text variant="h4" className={classes.questionText}>
              Tel&eacute;fono de la bodega
            </Text>
            <Box>
              <Select
                variant="standard"
                select
                style={{ display: 'inline-flex' }}
                value={headquarterValues.lada}
                onChange={handleChange('lada')}
              >
                <MenuItem value="+52">+52</MenuItem>
              </Select>
              <Input
                id="phone-number"
                style={{ display: 'inline-flex', width: '85%' }}
                className={classes.input2}
                value={headquarterValues.phone_number}
                type="text"
                onChange={handleChange('phone_number')}
                onBlur={handleChange('phone_number')}
                placeholder="N&uacute;mero de tel&eacute;fono de la bodega"
                inputProps={{ maxLength: 10 }}
              />
            </Box>
            <FormHelperText>
              {errorMessages.phone_number}
            </FormHelperText>
          </Box>

          <Box style={{ width: '80%', paddingTop: 15, paddingBottom: 15 }}>
            <Text variant="h4" className={classes.questionText}>
              Raz&oacute;n social de la bodega
            </Text>
            <Input
              id="business-name"
              style={{ display: 'flex' }}
              className={classes.input2}
              value={headquarterValues.business_name}
              type="text"
              onChange={handleChange('business_name')}
              placeholder="Escribe la razón social de la bodega"
              inputProps={{ maxLength: 100 }}
            />
            <FormHelperText>
              {errorMessages.business_name}
            </FormHelperText>
          </Box>

          <Box style={{ width: '80%', paddingTop: 15, paddingBottom: 15 }}>
            <Text variant="h4" className={classes.questionText}>
              Nombre comercial de la bodega
            </Text>
            <Input
              id="trade-name"
              style={{ display: 'flex' }}
              className={classes.input2}
              value={headquarterValues.trade_name}
              type="text"
              onChange={handleChange('trade_name')}
              placeholder="Escribe el nombre comercial de la bodega"
              inputProps={{ maxLength: 100 }}
            />
            <FormHelperText>
              {errorMessages.trade_name}
            </FormHelperText>
          </Box>

          <Box style={{ width: '80%', paddingTop: 15, paddingBottom: 15 }}>
            <Text variant="h4" className={classes.questionText}>
              RFC de la bodega
            </Text>
            <Input
              id="business-rfc"
              style={{ display: 'flex' }}
              className={classes.input2}
              value={headquarterValues.business_rfc}
              type="text"
              onChange={handleChange('business_rfc')}
              onKeyUp={handleChangeCase('business_rfc')}
              placeholder="Escribe el RFC de la bodega"
              inputProps={{ maxLength: 13 }}
            />
            <FormHelperText>
              {errorMessages.business_rfc}
            </FormHelperText>
          </Box>

          <Box style={{ width: '80%', paddingTop: 15, paddingBottom: 15, display: 'flex', flexDirection: 'column' }}>
            <Text className={classes.sectionText}>Datos del due&ntilde;o de la Bodega</Text>
          </Box>

          <Box style={{ width: '80%', paddingTop: 15, paddingBottom: 15 }}>
            <Text variant="h4" className={classes.questionText}>
              Nombre del dueño
            </Text>
            <Input
              id="name"
              style={{ display: 'flex' }}
              className={classes.input2}
              value={headquarterValues.name}
              type="text"
              onChange={handleChange('name')}
              placeholder="Escribe el nombre del dueño"
              inputProps={{ maxLength: 150 }}
            />
            <FormHelperText>
              {errorMessages.name}
            </FormHelperText>
          </Box>

          <Box style={{ width: '80%', paddingTop: 15, paddingBottom: 15 }}>
            <Text variant="h4" className={classes.questionText}>
              Apellido paterno del dueño
            </Text>
            <Input
              id="lastname"
              style={{ display: 'flex' }}
              className={classes.input2}
              value={headquarterValues.lastname}
              type="text"
              onChange={handleChange('lastname')}
              placeholder="Escribe el apellido paterno del dueño"
              inputProps={{ maxLength: 150 }}
            />
            <FormHelperText>
              {errorMessages.lastname}
            </FormHelperText>
          </Box>

          <Box style={{ width: '80%', paddingTop: 15, paddingBottom: 15 }}>
            <Text variant="h4" className={classes.questionText}>
              Apellido materno del dueño <i>(Opcional)</i>
            </Text>
            <Input
              id="maternal-lastname"
              style={{ display: 'flex' }}
              className={classes.input2}
              value={headquarterValues.maternal_lastname}
              type="text"
              onChange={handleChange('maternal_lastname')}
              placeholder="Escribe el apellido materno del dueño"
              inputProps={{ maxLength: 150 }}
            />
            <FormHelperText>
              {errorMessages.maternal_lastname}
            </FormHelperText>
          </Box>

          <Box style={{ width: '80%', paddingTop: 15, paddingBottom: 15 }}>
            <Text variant="h4" className={classes.questionText}>
              CURP del dueño
            </Text>
            <Input
              id="curp"
              style={{ display: 'flex' }}
              className={classes.input2}
              value={headquarterValues.curp}
              type="text"
              onChange={handleChange('curp')}
              onKeyUp={handleChangeCase('curp')}
              placeholder="Escribe el CURP del dueño"
              inputProps={{ maxLength: 18 }}
            />
            <FormHelperText>
              {errorMessages.curp}
            </FormHelperText>
          </Box>

          <Box style={{ width: '80%', paddingTop: 15, paddingBottom: 15 }}>
            <Text variant="h4" className={classes.questionText}>
              RFC del dueño <i>(Opcional)</i>
            </Text>
            <Input
              id="rfc"
              style={{ display: 'flex' }}
              className={classes.input2}
              value={headquarterValues.rfc}
              type="text"
              onChange={handleChange('rfc')}
              onKeyUp={handleChangeCase('rfc')}
              placeholder="Escribe el RFC del dueño"
              inputProps={{ maxLength: 13 }}
            />
            <FormHelperText>
              {errorMessages.rfc}
            </FormHelperText>
          </Box>

          <Box style={{ width: '80%', paddingTop: 15, paddingBottom: 15 }}>
            <Text variant="h4" className={classes.questionText}>
              Correo electr&oacute;nico del dueño <i>(Opcional)</i>
            </Text>
            <Input
              id="email"
              style={{ display: 'flex' }}
              className={classes.input2}
              value={headquarterValues.email}
              type="text"
              onChange={handleChange('email')}
              placeholder="Escribe el correo electrónico del dueño"
              inputProps={{ maxLength: 50 }}
            />
            <FormHelperText>
              {errorMessages.email}
            </FormHelperText>
          </Box>

          <Box style={{ width: '80%', paddingTop: 15, paddingBottom: 15 }} className={classes.checkElement}>
            <Checkbox
              onChange={handleChangeIsLrSameOwner}
              checked={headquarterValues.is_legal_representative_same_owner}
              name="IS_LR_SAME_OWNER" />
            <Text className={classes.questionText} variant='body1' align='justify'>
              El representante legal es el mismo dueño
            </Text>
          </Box>

          {(!headquarterValues.is_legal_representative_same_owner &&
            <>
              <Box style={{ width: '80%', paddingTop: 15, paddingBottom: 15, display: 'flex', flexDirection: 'column' }}>
                <Text className={classes.sectionText}>Datos del representante legal de la Bodega</Text>
              </Box>

              <Box style={{ width: '80%', paddingTop: 15, paddingBottom: 15 }}>
                <Text variant="h4" className={classes.questionText}>
                  Nombre del representante legal
                </Text>
                <Input
                  id="lr_name"
                  style={{ display: 'flex' }}
                  className={classes.input2}
                  value={headquarterValues.lr_name}
                  type="text"
                  onChange={handleChange('lr_name')}
                  placeholder="Escribe el nombre del representante legal"
                  inputProps={{ maxLength: 150 }}
                />
                <FormHelperText>
                  {errorMessages.lr_name}
                </FormHelperText>
              </Box>

              <Box style={{ width: '80%', paddingTop: 15, paddingBottom: 15 }}>
                <Text variant="h4" className={classes.questionText}>
                  Apellido paterno del representante legal
                </Text>
                <Input
                  id="lr_lastname"
                  style={{ display: 'flex' }}
                  className={classes.input2}
                  value={headquarterValues.lr_lastname}
                  type="text"
                  onChange={handleChange('lr_lastname')}
                  placeholder="Escribe el apellido paterno del representante legal"
                  inputProps={{ maxLength: 150 }}
                />
                <FormHelperText>
                  {errorMessages.lr_lastname}
                </FormHelperText>
              </Box>

              <Box style={{ width: '80%', paddingTop: 15, paddingBottom: 15 }}>
                <Text variant="h4" className={classes.questionText}>
                  Apellido materno del representante legal <i>(Opcional)</i>
                </Text>
                <Input
                  id="lr_maternal-lastname"
                  style={{ display: 'flex' }}
                  className={classes.input2}
                  value={headquarterValues.lr_maternal_lastname}
                  type="text"
                  onChange={handleChange('lr_maternal_lastname')}
                  placeholder="Escribe el apellido materno del representante legal"
                  inputProps={{ maxLength: 150 }}
                />
                <FormHelperText>
                  {errorMessages.lr_maternal_lastname}
                </FormHelperText>
              </Box>
            </>
          )}

          <Box style={{ width: '80%', paddingTop: 15, paddingBottom: 15, display: 'flex', flexDirection: 'column' }}>
            <Text className={classes.sectionText}>Direcci&oacute;n de la Bodega</Text>
          </Box>

          <Box style={{ width: '80%', paddingTop: 15, paddingBottom: 15 }}>
            <Text variant="h4" className={classes.questionText}>
              C&oacute;digo postal de la bodega
            </Text>
            <Input
              id="zip-code"
              style={{ display: 'flex' }}
              className={classes.input2}
              value={headquarterValues.zip_code}
              type="tel"
              onChange={handleChangeZipCode}
              placeholder="Escribe el código postal de la bodega"
              inputProps={{ maxLength: 5 }}
            />
            <FormHelperText>
              {errorMessages.zip_code}
            </FormHelperText>
          </Box>

          <Box style={{ width: '80%', paddingTop: 15, paddingBottom: 15 }}>
            <Text variant="h4" className={classes.questionText}>
              Colonia de la bodega
              {' '}
              <span style={{ display: isFetchingZcd ? 'block' : 'none' }}>cargando ...</span>
            </Text>
            <Select
              displayEmpty
              style={{ display: 'flex' }}
              className={classes.input2}
              labelId="neighborhood-label"
              id="neighborhood"
              value={headquarterValues.neighborhood}
              onChange={handleChangeNeighborhood}
            >
              <MenuItem value="" disabled>Selecciona una colonia</MenuItem>
              {neighborhoodList}
            </Select>
            <FormHelperText>
              {errorMessages.neighborhood}
            </FormHelperText>
          </Box>

          <Box style={{ width: '80%', paddingTop: 15, paddingBottom: 15 }}>
            <Text variant="h4" className={classes.questionText}>
              Estado de la bodega
            </Text>
            <Input
              disabled
              id="state"
              style={{ display: 'flex' }}
              className={classes.input2}
              value={headquarterValues.state}
              type="text"
              inputProps={{ maxLength: 18 }}
            />
          </Box>

          <Box style={{ width: '80%', paddingTop: 15, paddingBottom: 15 }}>
            <Text variant="h4" className={classes.questionText}>
              Ciudad de la bodega
            </Text>
            <Input
              disabled
              id="city"
              style={{ display: 'flex' }}
              className={classes.input2}
              value={headquarterValues.city}
              type="text"
              inputProps={{ maxLength: 18 }}
            />
          </Box>

          <Box style={{ width: '80%', paddingTop: 15, paddingBottom: 15 }}>
            <Text variant="h4" className={classes.questionText}>
              Direcci&oacute;n - Nombre de la calle
            </Text>
            <Input
              id="street-name"
              style={{ display: 'flex' }}
              className={classes.input2}
              value={headquarterValues.street_name}
              type="text"
              onChange={handleChange('street_name')}
              placeholder="Escribe la calle de la bodega"
              inputProps={{ maxLength: 50 }}
            />
            <FormHelperText>
              {errorMessages.street_name}
            </FormHelperText>
          </Box>

          <Box style={{ width: '80%', paddingTop: 15, paddingBottom: 15 }}>
            <Text variant="h4" className={classes.questionText}>
              Direcci&oacute;n - N&uacute;mero exterior
            </Text>
            <Input
              id="street-number"
              style={{ display: 'flex' }}
              className={classes.input2}
              value={headquarterValues.street_number}
              type="text"
              onChange={handleChange('street_number')}
              placeholder="Escribe el número exterior de la bodega"
              inputProps={{ maxLength: 20 }}
            />
            <FormHelperText>
              {errorMessages.street_number}
            </FormHelperText>
          </Box>

          <Box style={{ width: '80%', paddingTop: 15, paddingBottom: 15 }}>
            <Text variant="h4" className={classes.questionText}>
              Direcci&oacute;n - N&uacute;mero interior
            </Text>
            <Input
              id="apartment-number"
              style={{ display: 'flex' }}
              className={classes.input2}
              value={headquarterValues.apartment_number}
              type="text"
              onChange={handleChange('apartment_number')}
              placeholder="Escribe el número interior de la bodega"
              inputProps={{ maxLength: 20 }}
            />
            <FormHelperText>
              {errorMessages.apartment_number}
            </FormHelperText>
          </Box>

          <Box style={{ width: '80%' }}>
            <FormControl className={classes.formStyles}>
              <Box style={{ display: isCreatingHeadquarter ? 'block' : 'none' }}>
                <CircularProgress />
              </Box>
              <Box className={classes.buttonContainer}>
                <Button
                  variant="outlined"
                  className={classes.button}
                  style={{ backgroundColor: '#0253cc', color: 'white' }}
                  onClick={() => {
                    handleHeadquarterSubmit();
                  }}
                  disabled={isCreatingHeadquarter}
                >
                  Crear la Bodega
                </Button>
              </Box>
            </FormControl>
          </Box>
        </>
      )}

      {wholesalerTypeSelection === 'ws' && (
        <>
          <Link className={classes.controls} onClick={() => setWholesalerTypeSelection('')}>
            <ArrowBackIcon />
            <Text>Regresar</Text>
          </Link>
          <Box className={classes.mainPanel}>
            <SectionTitle>Creaci&oacute;n de Sucursal</SectionTitle>
          </Box>
          {(!isSelectedHq && <>
            <Box style={{ width: '80%', paddingTop: '15px', paddingBottom: '15px' }}>
              <Box style={{ paddingBottom: '10px' }}>
                <Text className={classes.questionText}>
                  &iquest;A qu&eacute; bodega pertenece la sucursal&#63;
                </Text>
              </Box>
              <Box className={classes.searchContainer}>
                <InputBase
                  value={headquarterSearchParam}
                  onChange={(event) => {
                    setHeadquarterSearchParam(event.target.value);
                  }}
                  className={classes.input}
                  placeholder="Buscar bodega (número de teléfono, razón social, nombre comercial, dueño, o ID)"
                  inputProps={{ 'aria-label': 'search user' }}
                  onKeyUp={(event) => {
                    if (event.key === 'Enter') {
                      handleFindHeadquarter(event);
                    }
                  }}
                />
                <Divider className={classes.divider} orientation="vertical" />
                <IconButton
                  onClick={handleFindHeadquarter}
                  className={classes.iconButton}
                  aria-label="search"
                >
                  <SearchIcon />
                </IconButton>
              </Box>
              <FormHelperText>
                {errorMessages.shopSelected}
              </FormHelperText>
            </Box>
            <span style={{ display: isFetchingHq ? 'block' : 'none' }}>cargando ...</span>

            {
              hqList && hqList.length > 0 &&
              (<>
                <Box style={{ marginTop: '20px' }}>
                  <Text className={classes.questionText}>
                    Elige una bodega a la cual vicular la sucursal
                  </Text>
                </Box>
                <RadioGroup
                  name="headquarter-radio-group"
                  style={{ width: '80%', marginTop: '20px' }}
                  value={selectedHq}
                  onChange={(event) => setSelectedHq(event.target.value)}>
                  {headquarterList}
                </RadioGroup>

                <Box style={{ width: '79%', marginLeft: '10px' }}>
                  <Box className={classes.buttonContainer}>
                    <Button
                      variant="outlined"
                      className={classes.button}
                      style={{ backgroundColor: '#0253cc', color: 'white' }}
                      onClick={() => {
                        setIsSelectedHq(true);
                      }}
                      disabled={isCreatingWholesaler}
                    >
                      Continuar
                    </Button>
                  </Box>
                </Box>
              </>)
            }
          </>)}

          {(isSelectedHq && <>
            <Box style={{ width: '80%', paddingTop: 15, paddingBottom: 15, display: 'flex', flexDirection: 'column' }}>
              <Text className={classes.sectionText}>Datos generales de la Sucursal</Text>
            </Box>

            <Box style={{ width: '80%', paddingTop: 15, paddingBottom: 15 }}>
              <Text variant="h4" className={classes.questionText}>
                Nombre comercial de la sucursal
              </Text>
              <Input
                id="trade-name"
                style={{ display: 'flex' }}
                className={classes.input2}
                value={wholesalerValues.trade_name}
                type="text"
                onChange={handleChangeWs('trade_name')}
                placeholder="Escribe el nombre comercial de la sucursal"
                inputProps={{ maxLength: 100 }}
              />
              <FormHelperText>
                {errorMessages.ws_trade_name}
              </FormHelperText>
            </Box>

            <Box style={{ width: '80%', paddingTop: 15, paddingBottom: 15 }}>
              <Text variant="h4" className={classes.questionText}>
                Correo electr&oacute;nico de la sucursal
              </Text>
              <Input
                id="email"
                style={{ display: 'flex' }}
                className={classes.input2}
                value={wholesalerValues.email}
                type="text"
                onChange={handleChangeWs('email')}
                placeholder="Escribe el correo electrónico de la sucursal"
                inputProps={{ maxLength: 50 }}
              />
              <FormHelperText>
                {errorMessages.ws_email}
              </FormHelperText>
            </Box>

            <Box style={{ width: '80%', paddingTop: 15, paddingBottom: 15 }}>
              <Text variant="h4" className={classes.questionText}>
                N&uacute;meros de tel&eacute;fono para notificaci&oacute;n
              </Text>
              <Input
                id="additional-phone-numbers-text"
                style={{ display: 'flex' }}
                className={classes.input2}
                value={wholesalerValues.additional_phone_numbers_text}
                type="tel"
                onChange={handleChangeWs('additional_phone_numbers_text')}
                onBlur={handleChangeAdditionalPhoneNumbers}
                placeholder="Ingresa los números de teléfono con lada (+52) y separados por coma (,)"
                inputProps={{ maxLength: 500 }}
              />
              <FormHelperText>
                {errorMessages.ws_additional_phone_numbers_text}
              </FormHelperText>
            </Box>

            <Box style={{ width: '80%', paddingTop: 15, paddingBottom: 15, display: 'flex', flexDirection: 'column' }}>
              <Text className={classes.sectionText}>Datos bancarios de la Sucursal</Text>
            </Box>

            <Box style={{ width: '80%', paddingTop: 15, paddingBottom: 15 }}>
              <Text variant="h4" className={classes.questionText}>
                Banco
              </Text>
              <Select
                displayEmpty
                style={{ display: 'flex' }}
                className={classes.input2}
                labelId="bank-id-label"
                id="bank-id"
                value={wholesalerValues.bank_id}
                onChange={handleChangeWs('bank_id')}
              >
                <MenuItem value="" disabled>Selecciona el banco</MenuItem>
                {
                  banks.map((bank) => {
                    if (!bank.isActive) {
                      return;
                    }
                    // eslint-disable-next-line consistent-return
                    return <MenuItem value={bank.value}>{bank.title}</MenuItem>;
                  })
                }
              </Select>
              <FormHelperText>
                {errorMessages.ws_bank_id}
              </FormHelperText>
            </Box>

            <Box style={{ width: '80%', paddingTop: 15, paddingBottom: 15 }}>
              <Text variant="h4" className={classes.questionText}>
                N&uacute;mero de cuenta bancaria
              </Text>
              <Input
                id="bank-account-number"
                style={{ display: 'flex' }}
                className={classes.input2}
                value={wholesalerValues.bank_account_number}
                type="tel"
                onChange={handleChangeWs('bank_account_number')}
                placeholder="Escribe el número de cuenta bancaria"
                inputProps={{ maxLength: 18, minLength: 18 }}
              />
              <FormHelperText>
                {errorMessages.ws_bank_account_number}
              </FormHelperText>
            </Box>

            <Box style={{ width: '80%', paddingTop: 15, paddingBottom: 15, display: 'flex', flexDirection: 'column' }}>
              <Text className={classes.sectionText}>Direcci&oacute;n de la Sucursal</Text>
            </Box>

            <Box style={{ width: '80%', paddingTop: 15, paddingBottom: 15 }}>
              <Text variant="h4" className={classes.questionText}>
                C&oacute;digo postal de la sucursal
              </Text>
              <Input
                id="zip-code"
                style={{ display: 'flex' }}
                className={classes.input2}
                value={wholesalerValues.zip_code}
                type="tel"
                onChange={handleChangeZipCodeWs}
                placeholder="Escribe el código postal de la sucursal"
                inputProps={{ maxLength: 5 }}
              />
              <FormHelperText>
                {errorMessages.ws_zip_code}
              </FormHelperText>
            </Box>

            <Box style={{ width: '80%', paddingTop: 15, paddingBottom: 15 }}>
              <Text variant="h4" className={classes.questionText}>
                Colonia de la sucursal
                {' '}
                <span style={{ display: isFetchingZcd ? 'block' : 'none' }}>cargando ...</span>
              </Text>
              <Select
                displayEmpty
                style={{ display: 'flex' }}
                className={classes.input2}
                labelId="neighborhood-label"
                id="neighborhood"
                value={wholesalerValues.neighborhood}
                onChange={handleChangeNeighborhoodWs}
              >
                <MenuItem value="" disabled>Selecciona una colonia</MenuItem>
                {neighborhoodList}
              </Select>
              <FormHelperText>
                {errorMessages.ws_neighborhood}
              </FormHelperText>
            </Box>

            <Box style={{ width: '80%', paddingTop: 15, paddingBottom: 15 }}>
              <Text variant="h4" className={classes.questionText}>
                Estado de la sucursal
              </Text>
              <Input
                disabled
                id="state"
                style={{ display: 'flex' }}
                className={classes.input2}
                value={wholesalerValues.state}
                type="text"
                inputProps={{ maxLength: 18 }}
              />
            </Box>

            <Box style={{ width: '80%', paddingTop: 15, paddingBottom: 15 }}>
              <Text variant="h4" className={classes.questionText}>
                Ciudad de la sucursal
              </Text>
              <Input
                disabled
                id="city"
                style={{ display: 'flex' }}
                className={classes.input2}
                value={wholesalerValues.city}
                type="text"
                inputProps={{ maxLength: 18 }}
              />
            </Box>

            <Box style={{ width: '80%', paddingTop: 15, paddingBottom: 15 }}>
              <Text variant="h4" className={classes.questionText}>
                Direcci&oacute;n - Nombre de la calle
              </Text>
              <Input
                id="street-name"
                style={{ display: 'flex' }}
                className={classes.input2}
                value={wholesalerValues.street_name}
                type="text"
                onChange={handleChangeWs('street_name')}
                placeholder="Escribe la calle de la sucursal"
                inputProps={{ maxLength: 50 }}
              />
              <FormHelperText>
                {errorMessages.ws_street_name}
              </FormHelperText>
            </Box>

            <Box style={{ width: '80%', paddingTop: 15, paddingBottom: 15 }}>
              <Text variant="h4" className={classes.questionText}>
                Direcci&oacute;n - N&uacute;mero exterior
              </Text>
              <Input
                id="street-number"
                style={{ display: 'flex' }}
                className={classes.input2}
                value={wholesalerValues.street_number}
                type="text"
                onChange={handleChangeWs('street_number')}
                placeholder="Escribe el número exterior de la sucursal"
                inputProps={{ maxLength: 20 }}
              />
              <FormHelperText>
                {errorMessages.ws_street_number}
              </FormHelperText>
            </Box>

            <Box style={{ width: '80%', paddingTop: 15, paddingBottom: 15 }}>
              <Text variant="h4" className={classes.questionText}>
                Direcci&oacute;n - N&uacute;mero interior
              </Text>
              <Input
                id="apartment-number"
                style={{ display: 'flex' }}
                className={classes.input2}
                value={wholesalerValues.apartment_number}
                type="text"
                onChange={handleChangeWs('apartment_number')}
                placeholder="Escribe el número interior de la sucursal"
                inputProps={{ maxLength: 20 }}
              />
              <FormHelperText>
                {errorMessages.ws_apartment_number}
              </FormHelperText>
            </Box>

            <Box style={{ width: '80%' }}>
              <FormControl className={classes.formStyles}>
                <Box style={{ display: isCreatingWholesaler ? 'block' : 'none' }}>
                  <CircularProgress />
                </Box>
                <Box className={classes.buttonContainer}>
                  <Button
                    variant="outlined"
                    className={classes.button}
                    style={{ backgroundColor: '#0253cc', color: 'white' }}
                    onClick={() => {
                      handleWholesalerSubmit();
                    }}
                    disabled={isCreatingWholesaler}
                  >
                    Crear la Sucursal
                  </Button>
                </Box>
              </FormControl>
            </Box>
          </>)}
        </>
      )}

    </PageContainer>
  );
};

export default WholesalerCreatePage;
