import React, { useEffect } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
} from '@material-ui/core';
import { Box } from '../../components/Layout';
import Text from '../../components/Text';
import useStyles from './PaymentAgreements.styles';
import { useDispatch, useSelector } from 'react-redux';
import { getPaymentAgreements } from '../../reducers/payment.action';
import moment from 'moment';

const PaymentAgreements = ({ creditLineId }) => {
    const { getPaymentAgreementsData } = useSelector((state) => state.paymentReducer);
  const classes = useStyles();
  const dispatch = useDispatch();
  const headCells = [
    { id: 'amount', label: 'Monto para aplicar' },
    { id: 'cancellation_amount', label: 'Monto para condonar' },
    { id: 'due_date', label: 'Fecha de vencimiento' },
    { id: 'status', label: 'Estatus' },
  ];

  const displayStatus = (status) => {
    switch (status) {
      case 'PENDING':
        return 'Pendiente';
      case 'DONE':
        return 'Realizado'; 
      case 'EXPIRED':
        return 'Expirado';
      case 'CREATED':
        return 'Creado';
      default:
        return 'No aplica';
    }
  };

  const getStatusCircleColor = (status) => {
    switch (status) {
      case 'PENDING':
        return classes.pendingCircle;
      case 'DONE':
        return classes.approvedCircle;
      case 'EXPIRED':
        return classes.rejectedCircle;
      case 'CREATED':
        return classes.unknownCircle;
      default:
        return classes.unknownCircle;
    }
  };

  useEffect(() => {
    dispatch(getPaymentAgreements(creditLineId));
  }, [creditLineId]);
  return (
    <Box className={classes.root}>
    <Text className={classes.tableTitle}>Acuerdos de pago</Text>
     {getPaymentAgreementsData?.data?.length !== 0 ? (
      <TableContainer className={classes.tableContainer}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {headCells.map((headCell) => (
              <TableCell key={headCell.id} align="center">
                <Text>{headCell.label}</Text>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {getPaymentAgreementsData?.data?.map((row) => (
            <TableRow key={row.id}>
              <TableCell align="center" className={classes.tableCell}>
                <Text>${row.amount.toFixed(2)}</Text>
              </TableCell>
              <TableCell align="center" className={classes.tableCell}>
                <Text>${row.cancellation_amount.toFixed(2)}</Text>
              </TableCell>
              <TableCell align="center" className={classes.tableCell}>
                <Text>{moment(row.due_date).format('DD/MM/YYYY')}</Text>
              </TableCell>
              <TableCell align="center" className={classes.statusCell}>
              <div className={`${classes.circle} ${getStatusCircleColor(row.status)}`} />
                <Text>{displayStatus(row.status)}</Text>
              </TableCell>
            </TableRow>
          ))} 
      </TableBody>
      </Table>
    </TableContainer>
      ) : (
       <Box>
       <Text>No hay acuerdos de pago</Text>
       </Box>
      )
     }
    </Box>
  );
};

export default PaymentAgreements;
