import React from 'react';
import PropTypes from 'prop-types';
import Box from '../Layout/Box';
import Text from '../Text';

const LabelValueTextList = (props) => {

  const { rows } = props;
  return (
    <Box>
      {rows.map((row) => (
        <Box style={{
          display: 'flex',
          flexDirection: 'row',
          lineHeight: 500,
          marginTop: '3%',
        }}
        >
          <Text style={{ color: '#3a4760' }} variant="h3">{row.label}:</Text>
          <Text style={{ paddingRight: 5 }}>&nbsp;</Text>
          <Text variant="h3">{row.value || ' - '}</Text> 
        </Box>
      ))}
    </Box>
  );
};

LabelValueTextList.propTypes = {
  rows: PropTypes.array.isRequired,
};

export default LabelValueTextList;