import React, { useEffect, useState } from 'react';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import { useDispatch, useSelector } from 'react-redux';
import Box from '../../components/Layout/Box';
import PageContainer from '../../components/PageContainer';
import SectionTitle from '../../components/SectionTitle';
import PaginationTable from '../../components/Table/PaginationTable';
import useStyles from './RegistersPage.styles';
import { getRegisters } from './registersActions';
import * as ACTIONS from './actionTypes';
import MissingDataAlert
  from '../../components/MissingDataAlert/MissingDataAlert';

export const RegistersPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { error } = useSelector((state) => state.registersState);
  const [inputValue, setInputVale] = useState('');
  const [nothingFoundNoticeShown, showNothingFoundNotice] = useState(false);

  useEffect(() => {
    if (error) {
      showNothingFoundNotice(true);
      setInputVale('');
    }
  }, [error]);

  useEffect(() => {
    setTimeout(() => showNothingFoundNotice(false), 3000);
  }, [nothingFoundNoticeShown]);

  /*
  useEffect(() => {
    if (!('length' in customers && customers.length < 0)) dispatch(getRegisters());
  }, [dispatch]);
   */

  useEffect(() => () => dispatch({
    type: ACTIONS.SET_STATE,
    label: 'searchString',
    value: '',
  },), []);

  return (
    <PageContainer>
      <Box className={classes.rootContainer}>
        <Box className={classes.topContainer} onSubmit={(e) => { e.preventDefault(); }}>
          <SectionTitle>Registro de nuevos usuarios</SectionTitle>
          {nothingFoundNoticeShown && <MissingDataAlert status="BAD" text="No se encontraron clientes" />}
          <Box className={classes.searchContainer}>
            <InputBase
              value={inputValue}
              onChange={(event) => {
                setInputVale(event.target.value);
                dispatch({
                  type: ACTIONS.SET_STATE,
                  label: 'searchString',
                  value: event.target.value,
                });
              }}
              className={classes.input}
              placeholder="Buscar por nombre de tienda, ID, nombre de cliente…"
              inputProps={{ 'aria-label': 'search user' }}
              onKeyUp={(event) => {
                if (event.key === 'Enter') {
                  dispatch(getRegisters());
                }
              }}
            />
            <Divider className={classes.divider} orientation="vertical" />
            <IconButton onClick={() => dispatch(getRegisters())} className={classes.iconButton} aria-label="search">
              <SearchIcon />
            </IconButton>
          </Box>
        </Box>
        <Box className={classes.tableContainer}>
          <PaginationTable />
        </Box>
      </Box>
    </PageContainer>
  );
};
