import React from "react";
import PropTypes from "prop-types";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const CustomSnackBar = (props) => {
  const { open, handleClose, msg, msgType, style } = props;

  return (
    <>
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose} style={style} >
        <Alert onClose={handleClose} severity={msgType}>
          {msg}
        </Alert>
      </Snackbar>
    </>
  );
};

CustomSnackBar.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  msg: PropTypes.string.isRequired,
  msgType: PropTypes.string.isRequired,
};
export default CustomSnackBar;
