import React, { useEffect, useState } from 'react';
import {
  TableContainer, Table, TableBody, TableRow, TableCell, TableFooter, TablePagination,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from '../Shops/PaginationTable.style';
import Text from '../../components/Text';
import ArrowedText from '../../components/ArrowedText';
import Button from '../../components/Button';
import { Box } from '../../components/Layout';
import { OrderedTableHead } from './ShopTableHead';
import {utcSimpleDateFunction} from '../Shops/dateFunction';
import { statusType } from '../../constants/constants';

export const ShopTable = (props) => {
  const { resources } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  let count = resources.length

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    setPage(0);
  }, [resources]);

  return (
    <Box>
      <TableContainer className={classes.tableContainer}>
        <Table aria-label="custom pagination table">
          <OrderedTableHead />
          <TableBody>
            {(rowsPerPage > 0
                ? resources.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : resources
              ).map((shop, index) => (
                    <TableRow key={index} style={{ maxHeight: 43 }}>
                        <TableCell>
                            <Text className={classes.textStyle} style={{ textAlign: 'center' }}>
                                {shop.personhood_name}
                            </Text>
                        </TableCell>
                        <TableCell>
                            <Text className={classes.textStyle}>{shop.account_shopkeeper_id.substr(shop.account_shopkeeper_id.length - 6)}</Text>
                        </TableCell>
                        <TableCell>
                            <Text className={classes.textStyle} style={{ textAlign: 'center' }}>
                                {shop.shopkeeper_name}
                            </Text>
                        </TableCell>
                        <TableCell>
                            <Text className={classes.textStyle} style={{ textAlign: 'center' }}>
                                {
                                    shop.shopkeeper_phone_number !== "" ? shop.shopkeeper_phone_number : '-'
                                }
                            </Text>
                        </TableCell>
                        <TableCell>
                            <Text className={classes.textStyle} style={{ textAlign: 'center' }}>
                            {shop?.closing_date ? utcSimpleDateFunction(shop.closing_date) : '-'}
                            </Text>
                        </TableCell>
                        <TableCell>
                            <Text className={classes.textStyle} style={{ textAlign: 'center' }}>
                                {shop?.deadline_date ? utcSimpleDateFunction(shop.deadline_date) : '-'}
                            </Text>
                        </TableCell>
                        <TableCell>
                            <Text className={classes.textStyle}>${shop?.ending_balance ? shop.ending_balance : 0.00}</Text>
                        </TableCell>
                        <TableCell>
                            <Text className={classes.textStyle} style={{ textAlign: 'center' }}>
                                {shop?.credit_line_status ? statusType[shop.credit_line_status] : '-'}
                            </Text>
                        </TableCell>
                        <TableCell>
                            <Link to={`/shop/submenu/${shop.credit_line_id}/${shop.account_shopkeeper_id}`}>
                            <Button>
                                <ArrowedText>
                                    Ver detalle
                                </ArrowedText>
                            </Button>
                            </Link>
                        </TableCell>
                    </TableRow>
                ))
            }
          </TableBody>
          <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              colSpan={8}
              count={resources.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
                native: true,
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </TableRow>
        </TableFooter>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ShopTable;
