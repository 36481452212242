import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  phoneValidationTableContainer: {
    marginTop: '5%',
    marginBottom: '5%',
    width: '70%',
    justifyItems: 'space-between',
  },
  tableRow: {
    display: 'flex',
    flexDirection: 'row',
    borderColor: '#858d9c',
    paddingTop: 20,
    paddingBottom: 20,
  },
  tableRowItem: {
    flex: 0.25,
    marginLeft: '5%',
    marginRight: '5%',
  },
  tableHeader: {
    color: '#858d9c',
    display: 'flex',
    borderColor: '#858d9c',
    paddingTop: 10,
    paddingBottom: 10,
  },
}));

export default useStyles;
