import * as ACTIONS from '../../reducers/shop.actionTypes';
import shopsService from '../../services/shops.service';

export const getCreditLineShop = (queries) => async (dispatch, getState) => {
  dispatch({
    type: ACTIONS.FETCH_SHOPS_INIT,
  });

  try {
    const shops = await shopsService.getCreditLineShop(queries);
    if (shops) {
      dispatch({
        type: ACTIONS.FETCH_SHOPS_SUCCESS,
      });
      dispatch({
        type: ACTIONS.SET_STATE,
        payload: {
          label: 'credit_line_shops',
          value: shops,
        },
      });
    } else {
      dispatch({
        type: ACTIONS.FETCH_SHOPS_ERROR,
      });
    }
  } catch (e) {
    dispatch({
      type: ACTIONS.FETCH_SHOPS_ERROR,
    });
  }
};

export const addSelectedShop = (selectedShop) => async (dispatch, getState) => {
  dispatch({
    type: ACTIONS.ADD_SELECTED_SHOP,
    payload: selectedShop,
  });
};

export const deleteSelectedShop = (selectedShop) => async (dispatch, getState) => {
  dispatch({
    type: ACTIONS.DELETE_SELECTED_SHOP,
    payload: selectedShop,
  });
};

export const clearSelectedShopsSet = () => async (dispatch, getState) => {
  dispatch({
    type: ACTIONS.CLEAR_SELECTED_SHOPS,
  });
};

export const toggleSelectedShop = (shop, newState) => async (dispatch, getState) => {
  if (newState) {
    dispatch(addSelectedShop(shop));
  } else {
    dispatch(deleteSelectedShop(shop));
  }
};

export const getSearchShops = (settlementType) => async (dispatch, getState) => {
  dispatch({
    type: ACTIONS.FETCH_SHOPS_INIT,
  });

  try {
    const shops = await shopsService.getSearchShopsList();

    if (shops) {
      dispatch({
        type: ACTIONS.FETCH_SHOPS_SUCCESS,
      });
      dispatch({
        type: ACTIONS.SET_STATE,
        payload: {
          label: 'credit_line_shops',
          value: shops,
        },
      });
    } else {
      dispatch({
        type: ACTIONS.FETCH_SHOPS_ERROR,
      });
    }
  } catch (e) {
    dispatch({
      type: ACTIONS.FETCH_SHOPS_ERROR,
    });
  }
};

export const getAllShops = (queries) => async (dispatch, getState) => {
  dispatch({
    type: ACTIONS.FETCH_SHOPS_INIT,
  });

  try {
    const shops = await shopsService.getAllShopsDetail(queries);
    if (shops) {
      dispatch({
        type: ACTIONS.FETCH_ALL_SHOPS_SUCCESS,
      });
      dispatch({
        type: ACTIONS.SET_STATE_ALL_SHOPS,
        payload: {
          label: 'credit_line_all_shops',
          value: shops,
        },
      });
    } else {
      dispatch({
        type: ACTIONS.FETCH_ALL_SHOPS_ERROR,
      });
    }
  } catch (e) {
    dispatch({
      type: ACTIONS.FETCH_ALL_SHOPS_ERROR,
    });
  }
};

export const getShokeeperDetailById = (shopkeeper_id) => async (dispatch, getState) => {
  dispatch({
    type: ACTIONS.FETCH_SHOKEEPER_BY_ID_INIT,
  });

  try {
    const shokeeper = await shopsService.getShokeeperById(shopkeeper_id);
    if (shokeeper) {
      dispatch({
        type: ACTIONS.FETCH_SHOKEEPER_BY_ID_SUCCESS,
      });
      dispatch({
        type: ACTIONS.SET_STATE_SHOKEEPER_BY_ID,
        payload: {
          label: 'shokeeper_detail',
          value: shokeeper,
        },
      });
    } else {
      dispatch({
        type: ACTIONS.FETCH_SHOKEEPER_BY_ID_ERROR,
      });
    }
  } catch (e) {
    dispatch({
      type: ACTIONS.FETCH_SHOKEEPER_BY_ID_ERROR,
    });
  }
};

export const getReferencesById = (shopkeeper_id) => async (dispatch, getState) => {
  dispatch({
    type: ACTIONS.FETCH_REFERENCES_BY_ID_INIT,
  });

  try {
    const references = await shopsService.getReferencesById(shopkeeper_id);
    if (references) {
      dispatch({
        type: ACTIONS.FETCH_REFERENCES_BY_ID_SUCCESS,
      });
      dispatch({
        type: ACTIONS.SET_STATE_REFERENCES_BY_ID,
        payload: {
          label: 'references_detail',
          value: references,
        },
      });
    } else {
      dispatch({
        type: ACTIONS.FETCH_REFERENCES_BY_ID_ERROR,
      });
    }
  } catch (e) {
    dispatch({
      type: ACTIONS.FETCH_REFERENCES_BY_ID_ERROR,
    });
  }
};