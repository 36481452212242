import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { 
    getVouchers,
} from "../../reducers/reviewVouchers.action";

import ReviewVoucherUI from "./ReviewVoucher";
import useStyles from './ReviewVouchers.styles';

function ReviewVoucher() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [inputValue, setInputVale] = useState('');
    const [status, setStatus] = useState('PENDING');

    useEffect(() => {
        if(inputValue === '' || inputValue === undefined || inputValue === null) {
            dispatch(getVouchers({status: status}))
        }
    }, [inputValue]);

    const searchAction = () => {
        dispatch(getVouchers({status: status, search: inputValue}))
    };

    return <ReviewVoucherUI
        classes={classes}
        dispatch={dispatch}
        inputValue={inputValue}
        setInputVale={setInputVale}
        status={status}
        setStatus={setStatus}
        searchAction={searchAction}
    />
}

export default ReviewVoucher;