import * as ACTION from './amounts.actionTypes';

const initialState = {
    saveAmounts: {}
};

const saveAmountsReducer = (state = initialState, action) => {
    switch(action.type) {
        case ACTION.AMOUNTS_BUY_DEALER:
            return {
                ...state,
                saveAmounts: action.result
            };
        default:
            return state;
    }
};

export default saveAmountsReducer;