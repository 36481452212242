import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getAppointmentAction } from "../../reducers/appointment.action";

import TableDatingHistoryUI from "./TableDatingHistory";
import useStyles from "./TableDatingHistory.styles";

function TableDatingHistory(props) {
  const { phone_number } = props;
  const dispatch = useDispatch();
  const classes = useStyles();

  const { appointmentData } = useSelector((state) => state.appointmentReducer);
  console.log(appointmentData);
  useEffect(() => {
    if (phone_number !== "") {
      dispatch(getAppointmentAction(`%2B52${phone_number.slice(3)}`));
    }
  }, []);

  return (
    <TableDatingHistoryUI
      classes={classes}
      appointmentData={
        Object.keys(appointmentData).length !== 0 ? appointmentData?.data : []
      }
    />
  );
}

export default TableDatingHistory;
