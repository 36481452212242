import * as ACTIONS from './shops.actionTypes';

const initialState = {
  isFetchingShops: false,
  settlementsPositive: [],
  settlementsNegative: [],
  error: null
};

const collectionReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.FETCH_SHOPS_COLLECTION_INIT:
      return { ...state, isFetchingShops: true };
    case ACTIONS.FETCH_SHOPS_COLLECTION_SUCCESS:
      return {
        ...state, isFetchingShops: false, error: null,
      };
    case ACTIONS.FETCH_SHOPS_ERROR:
      return { ...state, loadingCustomers: false, error: action.error };
    case ACTIONS.SET_STATE_COLLECTION:
      return {
        ...state, [action.payload.label]: action.payload.value,
      };
    default:
      return { ...state };
  }
};

export default collectionReducer;
