import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  rootContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '95%',
    paddingTop: 15,
    paddingLeft: 30,
    paddingRight: 30,
  },
  topContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  emptySpace: {
    flexGrow: 1,
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    width: 330,
    background: '#E8E8FC33',
    borderRadius: 2,
    marginTop: 8,
    marginRight: 10
  },
  input: {
    marginLeft: 9,
    marginTop: 7,
    marginBottom: 8,
    width: 260,
    flex: 1,
    fontSize: 10,
    fontWeight: 300,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    marginTop: 4,
    marginBottom: 4,
  },
  tableContainer: {
    marginTop: 22,
  },
  formControl: {
    minWidth: 160,
    display: 'flex',

  },
  datesContainer: {
    display: 'flex',
    '& Button': {
      marginTop: 10,
      marginLeft: 10,
    },
  },
  modalContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '40%',
    bgcolor: 'background.paper',
    border: '1px solid #FFF',
    borderRadius: '10px',
    boxShadow: 24,
    backgroundColor: '#FFF',
    padding: 20
  },
  modalTextContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  textTitleModalStyle: {
    fontWeight: 'bold'
  },
  textModalStyle: {
    fontSize: '13px'
  },
  subMenuImages: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  imagesSubMenuContainer: {
    paddingRight: 3,
    paddingLeft: 3,
    paddingTop: 3,
    borderRadius: '20px',
    backgroundColor: 'blue',
    marginLeft: 10
  }
}));

export default useStyles;
