import React from 'react';
import { Box } from '@material-ui/core';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import useStyles from './ArrowedText.style';
import Text from '../Text';

export const ArrowedText = ({ children }) => {
  const classes = useStyles();
  return (
    <Box className={classes.horizontalContent}>
      <Text className={classes.textStyle}>{children}</Text>
      <KeyboardArrowRight
        className={classes.arrowIcon}
      />
    </Box>
  );
};

export default ArrowedText;
