import { useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';

// TODO: Should we clean up the listener?
function useFirebaseAuth() {
  const [isAuthorized, setIsAuthorized] = useState(true);
  const [user, setUser] = useState(null);

  firebase.auth().onAuthStateChanged(
    (authUser) => {
      if (authUser) {
        setIsAuthorized(true);
        setUser(authUser);
      } else {
        setIsAuthorized(false);
      }
    },
  );

  return { isAuthorized, user: JSON.parse(JSON.stringify(user)) };
}

export default useFirebaseAuth;
