import * as ACTION from '../containers/Home/actionTypes';

const initialState = {
  loading: false,
};

const homeReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION.LOADING_PAGE_HOME:
      return { ...state, loading: true };
    default:
      return { ...state };
  }
};

export default homeReducer;
