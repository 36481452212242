import React from 'react';
import useStyles from './CreditStatusLabel.style';

const CreditStatusLabel = (props) => {
  const classes = useStyles();
  const { status, newStatus } = props;

  let statusClass;
  let statusText;

  switch (status) {
    case 'REVIEWNEEDED':
      statusClass = classes.pending;
      statusText = 'PENDIENTE';
      break;
    case 'PENDING':
      statusClass = classes.pending;
      statusText = 'PENDIENTE';
      break;
    case 'BLOCKED':
      statusClass = classes.blocked;
      statusText = 'BLOQUEADA';
      break;
    case 'ACTIVED':
      statusClass = classes.approved;
      statusText = 'APROBADO';
      break;
    case 'REJECTED':
      statusClass = classes.rejected;
      statusText = 'RECHAZADO';
      break;
    case 'NO_MATCH':
      statusClass = classes.rejected;
      statusText = 'NO_MATCH';
      break;
    default:
      statusClass = classes.pending;
      statusText = 'PENDIENTE';
      break;
  }

  switch (newStatus) {
    case 'IN_PROGRESS':
      statusClass = classes.pending;
      statusText = 'PENDIENTE';
      break;
    case 'REJECTED':
      statusClass = classes.rejected;
      statusText = 'RECHAZADO';
      break;
    case 'SUCCESS':
      statusClass = classes.approved;
      statusText = 'APROBADO';
      break;
    case 'ERROR':
      statusClass = classes.rejected;
      statusText = 'ERROR';
      break;
    default:
      break;
  }

  return (
    <div className={`${classes.creditStatusText} ${statusClass}`}>
      {statusText}
    </div>
  );
};

export default CreditStatusLabel;
