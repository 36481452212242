import * as ACTIONS from './shop.actionTypes';

const initialState = {
  isFetchingShops: false,
  credit_line_shops: [],
  selectedShops: [],
  isFetchingAllShops: false,
  credit_line_all_shops: [],
  isFetchinShokeeperDetail: false,
  shokeeper_detail: {},
  error: null,
};

const shopsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.FETCH_SHOPS_INIT:
      return { ...state, isFetchingShops: true };
    case ACTIONS.FETCH_SHOPS_SUCCESS:
      return {
        ...state, isFetchingShops: false, error: null,
      };
    case ACTIONS.FETCH_SHOPS_ERROR:
      return { ...state, isFetchingShops: false, error: action.error };
    case ACTIONS.SET_STATE:
      return {
        ...state, [action.payload.label]: action.payload.value, isFetchingShops: false, error: null
      };
    case ACTIONS.ADD_SELECTED_SHOP: {
      const { selectedShops } = state;

      const containsSelectedShop = selectedShops.find((selectedShop) => selectedShop.shop_id === action.payload.shop_id);

      if (!containsSelectedShop) {
        return {
          ...state,
          selectedShops: [...state.selectedShops, action.payload],
        };
      }
      return state;
    }
    case ACTIONS.DELETE_SELECTED_SHOP: {
      const { selectedShops } = state;

      const filteredSelectedShops = selectedShops.filter((selectedShop) => selectedShop.shop_id !== action.payload.shop_id);

      return {
        ...state,
        selectedShops: [...filteredSelectedShops],
      };
    }
    case ACTIONS.CLEAR_SELECTED_SHOPS:
      return {
        ...state,
        selectedShops: [],
      };
    case ACTIONS.FETCH_ALL_SHOPS_INIT:
      return { ...state, isFetchingAllShops: true };
    case ACTIONS.FETCH_ALL_SHOPS_SUCCESS:
      return {
        ...state, isFetchingAllShops: false, error: null,
      };
    case ACTIONS.FETCH_ALL_SHOPS_ERROR:
      return { ...state, isFetchingAllShops: false, error: action.error };
    case ACTIONS.SET_STATE_ALL_SHOPS:
      return {
        ...state, [action.payload.label]: action.payload.value, isFetchingAllShops: false, error: null
      };
    case ACTIONS.FETCH_SHOKEEPER_BY_ID_INIT:
      return { ...state, isFetchinShokeeperDetail: true };
    case ACTIONS.FETCH_SHOKEEPER_BY_ID_SUCCESS:
      return {
        ...state, isFetchinShokeeperDetail: false, error: null,
      };
    case ACTIONS.FETCH_SHOKEEPER_BY_ID_ERROR:
      return { ...state, isFetchinShokeeperDetail: false, error: action.error };
    case ACTIONS.SET_STATE_SHOKEEPER_BY_ID:
      return {
        ...state, [action.payload.label]: action.payload.value, isFetchinShokeeperDetail: false, error: null
      };
    case ACTIONS.SET_STATE_REFERENCES_BY_ID:
      return {
        ...state, [action.payload.label]: action.payload.value,
      }
    default:
      return { ...state };
  }
};

export default shopsReducer;
