import * as ACTION from '../../../../reducers/transactions.actionTypes';
import shopsService from '../../../../services/shops.service';

export const getLegacyTransactionsDetail = (account_id) => async (dispatch, getState) => {
    dispatch({ type: ACTION.FETCH_TRANSACTIONS_INIT });

    try {
        const transactionsDetail = await shopsService.getLegacyTransactions(account_id);
        if (transactionsDetail) {
            dispatch({
                type: ACTION.FETCH_TRANSACTIONS_SUCCESS
            });
            dispatch({
                type: ACTION.SET_STATE,
                payload: {
                    label: 'legacyTransactionsDetail',
                    value: transactionsDetail,
                },
            });
            dispatch({
                type: ACTION.FETCH_TRANSACTIONS_ERROR,
            });
        }
    } catch (e) {
        dispatch({
            type: ACTION.FETCH_TRANSACTIONS_ERROR,
        });
    }
};
