import React from 'react';
import PropTypes from 'prop-types';
import Text from '../Text';
import useStyles from './GoogleLogin.style';
import Button from '../Button';
import googleLogo from '../../assets/googleLogo.svg';

const GoogleLogin = (props) => {
  const classes = useStyles();
  const { onClick } = props;

  return (
    <Button onClick={onClick} variant="outlined" className={classes.loginButton}>
      <img width={44} src={googleLogo} className={classes.logo} alt="" />
      <Text>
        Inicia sesión con Google
      </Text>
    </Button>
  );
};

GoogleLogin.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default GoogleLogin;
