import * as ACTION from './actionTypes';
import client from '../../../client';
import { appService } from '../../../services/app.service';

export const getFindShop = () => async (dispatch, getState) => {
  const { searchString } = getState().registersState;
  const url = `${appService.config.paths.shop.route}?${searchString ? `name=${searchString}` : ''}`;
  dispatch({ type: ACTION.FETCH_CUSTOMERS_INIT });
  const response = await client.get(url);
  const { data } = response;

  if (data && data.length > 0) {
    dispatch({ type: ACTION.FETCH_CUSTOMERS_SUCCESS });
    dispatch({ type: ACTION.SET_STATE, label: 'shops', value: data });
  } else {
    dispatch({ type: ACTION.FETCH_CUSTOMERS_ERROR, error: 'No shops returned from service' });
    dispatch({ type: ACTION.FETCH_CUSTOMERS_SUCCESS, error: null });
  }
};

export const getFindCustomer = () => async (dispatch, getState) =>  {
  const { searchCustomerString } = getState().registersState;
  const url = `${appService.config.paths.customers.route}?${searchCustomerString ? `search=${searchCustomerString}&order_by=name&sort=desc&` : ''}`;
  dispatch({ type: ACTION.FETCH_FIND_CUSTOMERS_INIT });
  const response = await client.get(url);
  const { data } = response;

  if (data && data.data && data.data.length > 0) {
    dispatch({ type: ACTION.FETCH_FIND_CUSTOMERS_SUCCESS });
    dispatch({ type: ACTION.SET_STATE, label: 'customersFound', value: data.data });
  } else {
    dispatch({ type: ACTION.FETCH_FIND_CUSTOMERS_ERROR, error: 'No customers returned from service' });
    dispatch({ type: ACTION.FETCH_FIND_CUSTOMERS_SUCCESS, error: null });
  }
}

export const getAdvisor = () => async (dispatch) => {
  const url = appService.config.paths.advisor.route;
  dispatch({ type: ACTION.FETCH_CUSTOMERS_INIT });
  const response = await client.get(url);
  const { data } = response;
  if (data && data.length > 0) {
    dispatch({ type: ACTION.FETCH_CUSTOMERS_SUCCESS });
    dispatch({ type: ACTION.SET_STATE, label: 'advisors', value: data });
  } else {
    dispatch({ type: ACTION.FETCH_CUSTOMERS_ERROR, error: 'No shops returned from service' });
    dispatch({ type: ACTION.FETCH_CUSTOMERS_SUCCESS, error: null });
  }
};

// eslint-disable-next-line max-len
export const createCredit = (account_id, account_type, sales_advisor_id, amt, inst, payment_day = 'MONDAY', bank_name, bank_account, term_type, monthPaymentDay, interestRate, loan_portfolio_status, lead_source) => async (dispatch) => {
  const url = appService.config.paths.loan.route;

  try {
    const amount = parseFloat(amt);
    const installment = parseFloat(inst);
    const month_payment_day = parseInt(monthPaymentDay);
    const interest_rate = parseFloat(interestRate);

    await client.post(url, {
      account_id,
      account_type,
      sales_advisor_id,
      amount,
      installment,
      term_type,
      month_payment_day,
      payment_day,
      bank_name,
      bank_account,
      interest_rate,
      loan_portfolio_status,
      lead_source
    }).then(
      (response) => {
        window.location = `/credits/${response.data.credit_line_id}/detail`;
      },
    ).catch((error) => {
      dispatch({ type: ACTION.UPDATE_ALERT_ERROR, value: error.response.data.readable_message });
    });
  } catch (e) {
    console.log(e);
  }
};
