import React from 'react';
import {
  TableHead,
  TableRow,
  TableCell,
} from '@material-ui/core';
import useStyles from './CreditExtensions.styles';
import Text from '../../components/Text';

export const CreditTableHeader = (props) => {
  const classes = useStyles();

  const headCells = [
    {
      id: 'shop_name', numeric: false, disablePadding: false, label: 'Nombre de tienda',
    },
    {
      id: 'shopkeeper_name', numeric: false, disablePadding: false, label: 'Tendero',
    },
    {
      id: 'id_shop', numeric: false, disablePadding: false, label: 'ID Tienda',
    },
    {
      id: 'phone_shop', numeric: true, disablePadding: false, label: 'Teléfono',
    },
    {
      id: 'credit_ammount', numeric: false, disablePadding: false, label: 'Monto crédito',
    },
    {
      id: 'extension_status', numeric: false, disablePadding: false, label: 'Ampliación',
    },
    {
      id: 'detail', numeric: true, disablePadding: false, label: 'Ver detalle',
    },
  ];

  return (
    <TableHead className={classes.tableHead}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            align="center"
            scope="col"
          >
            <Text className={classes.titleLabel}>{headCell.label}</Text>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default CreditTableHeader;
