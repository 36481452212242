import * as ACTION from '../containers/Login/actionTypes';

const initialState = {
  user: undefined,
  token: undefined,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION.LOGIN_INIT:
      return state;
    case ACTION.LOGIN_FAILURE:
      return state;
    case ACTION.LOGIN_SUCCESS:
      return { ...state, user: action.content.user, token: action.content.token };
    default:
      return { ...state };
  }
};

export default authReducer;
