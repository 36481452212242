import * as ACTION from './vouchers.actionTypes';

const initialState = {
    saveVouchers: []
};

const saveVouchersReducer = (state = initialState, action) => {
    switch(action.type) {
        case ACTION.VOUCHERS_BUY_DEALER:
            return {
                ...state,
                saveVouchers: action.result
            };
        default:
            return state;
    }
};

export default saveVouchersReducer;