import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
    putMatiVerification,
    clearPutMatiVerification
} from "../../reducers/kyc.action";
import { putTaxPayerId, clearTaxPayerId } from "../../reducers/modifications.action";
import validarRFC from "../../constants/validateRFC";

import TableMatiVerificationUI from "./TableMatiVerification";
import useStyles from "./TableMatiVerification.styles";

function TableMatiVerification(props) {
    const { data } = props;
    const dispatch = useDispatch();
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [isModalAction, setIsModalAction] = useState({
        isVisible: false,
        titleModal: '',
        contentModal: '',
        typeStatus: '',
        personType: '',
        accountID: '',
        contractID: '',
        personPhoneNumber: '',
        action: '',
    });
    const [newRFC, setNewRFC] = useState('');
    const [isSnackBar, setIsSnackBar] = useState(false);
    const [isSnackBarError, setIsSnackBarError] = useState(false);
    const [isPutError, setIsPutError] = useState(false);

    let validatedRFC = validarRFC(newRFC);
    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    const visibleData = data.slice(startIndex, endIndex);

    const messagesMATI = {
        "The face did not match the document": "El rostro no coincide con el documento",
        "The date of birth could not be obtained": "No se pudo obtener la fecha de nacimiento",
        "This user is currently underage": "Este usuario es menor de edad actualmente",
        "Cannot meet dependent input": "No se puede cumplir con la entrada dependiente",
        "Document is considered as negligence": "El documento se considera como negligencia",
        "Document is considered as fraud attempt": "El documento se considera como intento de fraude",
        "Document is considered tampered": "El documento se considera como alterado",
        "User has not provided any document": "El usuario no ha proporcionado ningún documento",
        "Full name mismatch": "El nombre completo no coincide",
        "CURP number is invalid": "El número de CURP es inválido",
        "No curp data found": "No se encontraron datos de CURP",
        "Not enough params to get ine data": "No hay suficientes parámetros para obtener datos de INE",
        "Invalid parameters": "Parámetros inválidos",
        "No ine data found": "No se encontraron datos de INE",
    };

    const {
        dataPutMatiVerification,
        error: errorMati
    } = useSelector((state) => state.kycReducer);

    const {
        taxPayerIdData,
        error
    } = useSelector((state) => state.modificationsReducer);

    useEffect(() => {
        if(Object.keys(dataPutMatiVerification).length !== 0) {
            setIsModalAction({
                isVisible: false,
                titleModal: '',
                contentModal: '',
                typeStatus: '',
                personType: '',
                accountID: '',
                contractID: '',
                personPhoneNumber: '',
                action: '',
            });

            dispatch(clearPutMatiVerification());
            setTimeout(() => {
                window.location.reload();
            }, 3000);
        }
    }, [dataPutMatiVerification]);

    useEffect(() => {
        if(errorMati?.PUT_MATI_VERIFICATION && errorMati.PUT_MATI_VERIFICATION) {
            if(errorMati.PUT_MATI_VERIFICATION !== null) {
                setIsModalAction({
                    isVisible: false,
                    titleModal: '',
                    contentModal: '',
                    typeStatus: '',
                    personType: '',
                    accountID: '',
                    contractID: '',
                    personPhoneNumber: '',
                    action: '',
                });
                setIsPutError(true);
                setIsSnackBarError(true);
                dispatch(clearPutMatiVerification());

                setTimeout(() => {
                    setIsPutError(false);
                    setIsSnackBarError(false);
                }, 3000);
            }
        }
    }, [errorMati.PUT_MATI_VERIFICATION]);

    useEffect(() => {
        if(taxPayerIdData?.status) {
            if(taxPayerIdData.status >= 200 && taxPayerIdData.status < 300) {
                setIsModalAction({
                    isVisible: false,
                    titleModal: '',
                    contentModal: '',
                    typeStatus: '',
                    personType: '',
                    accountID: '',
                    contractID: '',
                    personPhoneNumber: '',
                    action: '',
                });
                setNewRFC('');
                setIsSnackBar(true);
                dispatch(clearTaxPayerId());

                setTimeout(() => {
                    setIsSnackBar(false);
                }, 3000);
            }
        }
    }, [taxPayerIdData]);

    useEffect(() => {
        if(error?.PUT_TAXPAYER_ID && error.PUT_TAXPAYER_ID) {
            if(error.PUT_TAXPAYER_ID !== null) {
                setIsModalAction({
                    isVisible: false,
                    titleModal: '',
                    contentModal: '',
                    typeStatus: '',
                    personType: '',
                    accountID: '',
                    contractID: '',
                    personPhoneNumber: '',
                    action: '',
                });
                setNewRFC('');
                setIsSnackBarError(true);
                dispatch(clearTaxPayerId());

                setTimeout(() => {
                    setIsSnackBarError(false);
                }, 3000);
            }
        }
    }, [error.PUT_TAXPAYER_ID]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleInputChange = (event) => {
        setNewRFC(event.target.value);
    };

    const handleVerificationAction = (typeStatus, personType, accountID, contractID) =>  {
        let body = {
            internal_document_review: typeStatus,
            person_type: personType,
            account_id: accountID,
        };

        dispatch(putMatiVerification(contractID, body));
    };

    const handleChanceRFC = (personType, accountID, personPhoneNumber) => {
        let body = {
            aval_phone_number: personType !== 'ACCOUNT' ? personPhoneNumber : undefined,
            new_taxpayer_id: newRFC,
            account_id: accountID,
            account_type_id: "SK"
        }

        dispatch(putTaxPayerId(body));
    };

    return <TableMatiVerificationUI
        classes={classes}
        page={page}
        rowsPerPage={rowsPerPage}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        dataMati={visibleData}
        allData={data}
        isModalAction={isModalAction}
        setIsModalAction={setIsModalAction}
        handleVerificationAction={handleVerificationAction}
        newRFC={newRFC}
        handleInputChange={handleInputChange}
        validatedRFC={validatedRFC}
        handleChanceRFC={handleChanceRFC}
        messagesMATI={messagesMATI}
        isSnackBar={isSnackBar}
        isSnackBarError={isSnackBarError}
        isPutError={isPutError}
    />
};

export default TableMatiVerification;
