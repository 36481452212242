import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  IdentityValidationInfoContainer: {
    '& p': {
      marginTop: 20,
      marginBottom: 20,
    },
  },
  matiButton: {
    backgroundColor: '#0253cc',
    color: 'white',
    height: 38,
    paddingLeft: 30,
    paddingRight: 30,
  },
  buttonLabel: {
    fontSize: 'x-small',
    marginLeft: 5,
    marginRight: 5,
  },
}));

export default useStyles;
