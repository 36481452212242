import * as ACTION from '../containers/Dashboard/actionTypes';

const initialState = {
  loadingPage: false,
  loadingWeather: false,
  errorWeather: null,
  weather: {}
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION.LOADING_PAGE_DASHBOARD:
      return { ...state, loadingPage: true };
    case ACTION.LOADING_WEATHER:
      return { ...state, loadingWeather: true };
    case ACTION.RECEIVE_WEATHER:
      return {
        ...state, loadingWeather: false, errorWeather: null, weather: action.result.data
      };
    case ACTION.ERROR_WEATHER:
      return { ...state, loadingWeather: false, errorWeather: action.error };
    default:
      return { ...state };
  }
};

export default dashboardReducer;
