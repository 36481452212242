import React, { useState, useEffect } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useDispatch } from 'react-redux';
import {
  Button, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, MenuItem,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { putCreditLineExtensionRequest } from '../../reducers/creditline.action';
import PageContainer from '../../components/PageContainer/PageContainer';
import Text from '../../components/Text';
import TopBarDetail from '../Shop/SubMenu/TopBarDetail';
import useStyles from './ExtensionDetails.styles';
import TextInput from '../../components/TextInput';
import { Box } from '../../components/Layout';

const ExtensionDetails = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { shop } = location.state || {};
  const classes = useStyles();

  const formatValue = (value) => {
    if (!value) return '';
    return `$${Number(value).toLocaleString('en-US')}`;
  };

  const formatInterestValue = (value) => {
    if (!value) return '';
    return `${value}% ($)`;
  };

  const creditDetails = [
    { label: 'Monto Actual:', value: formatValue(shop.credit_line_amount) },
    { label: 'Monto Propuesto:', value: formatValue(shop.proposed_amount) },
    { label: 'Tipo de evaluación:', value: shop.evaluation_type === 'MANUAL' ? 'Manual' : 'Automática' },
    { label: 'Cuota propuesta:', value: formatValue(shop.proposed_installment || '0') },
    { label: 'Cuota mínima:', value: formatValue(shop.min_installment) },
    { label: 'Cuota máxima:', value: formatValue(shop.max_installment) },
    { label: 'Número de ampliación:', value: shop.extension_number },
  ];

  const initialShopValues = {
    proposedAmount: formatValue(shop.proposed_amount),
    proposedInterest: formatInterestValue(shop.proposed_interest),
    proposedInstallment: formatValue(shop.proposed_installment || '0'),
    minInstallment: formatValue(shop.min_installment),
    maxInstallment: formatValue(shop.max_installment),
    evaluationType: shop.evaluation_type,
  };

  const [inputValues, setInputValues] = useState(initialShopValues);
  const [inputErrors, setInputErrors] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [evaluationType, setEvaluationType] = useState(initialShopValues.evaluationType);

  useEffect(() => {
    setInputValues({
      ...initialShopValues,
    });
  }, [shop]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Verificar si el nombre es 'proposedInterest'
    if (name === 'proposedInterest') {
      // Eliminar cualquier contenido no numérico
      const formattedValue = value.replace(/[^0-9.]/g, '');

      // Agregar el formato "% ($)" al final si hay contenido numérico
      if (formattedValue !== '') {
        setInputValues((prevInputValues) => ({
          ...prevInputValues,
          [name]: `${formattedValue}% ($)`,
        }));
      } else {
        setInputValues((prevInputValues) => ({
          ...prevInputValues,
          [name]: formattedValue,
        }));
      }
    } else {
      // Otras operaciones para campos diferentes a 'proposedInterest'
      const formattedValue = formatValue(value.replace(/\D/g, ''));

      setInputValues((prevInputValues) => ({
        ...prevInputValues,
        [name]: formattedValue,
      }));
    }

    setInputErrors((prevInputErrors) => ({
      ...prevInputErrors,
      [name]: value.trim() === '' ? 'Campo Obligatorio' : '',
    }));
  };

  const handleClearEdit = () => {
    setInputValues(initialShopValues);
    setInputErrors({});
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setInputValues(initialShopValues);
    setInputErrors({});
  };

  const handleUpdateExtensionValues = () => {
    handleCloseModal();
    history.push('/extensions/', { shopName: shop.shop_name });
    const body = {
      proposed_amount: inputValues.proposedAmount ? parseFloat(inputValues.proposedAmount.replace(/\D/g, '')) : 0,
      proposed_interest: inputValues.proposedInterest ? parseFloat(inputValues.proposedInterest.replace(/[^0-9.]/g, '')) : 0,
      evaluation_type: evaluationType,
      proposed_installment: inputValues.proposedInstallment ? parseFloat(inputValues.proposedInstallment.replace(/\D/g, '')) : 0,
      min_installment: inputValues.minInstallment ? parseFloat(inputValues.minInstallment.replace(/\D/g, '')) : 0,
      max_installment: inputValues.maxInstallment ? parseFloat(inputValues.maxInstallment.replace(/\D/g, '')) : 0,
    };

    dispatch(putCreditLineExtensionRequest(shop.credit_line_extension_request_id, body));
  };

  const labelDictionary = {
    proposedAmount: 'Monto Propuesto',
    proposedInterest: 'Interés Propuesto Mensual',
    proposedInstallment: 'Cuota Propuesta',
    minInstallment: 'Cuota Mínima',
    maxInstallment: 'Cuota Máxima',
    evaluationType: 'Tipo de Evaluación',
  };

  return (
    <PageContainer>
      <Link to="/extensions/" className={classes.controls}>
        <ArrowBackIcon />
        <Text>Regresar</Text>
      </Link>
      <TopBarDetail
        shokeeper_detail={{}}
        credit_line_shop_detail={{}}
        isExtension
        shopData={shop}
      />
      <div className={classes.wrapper}>
        <div className={classes.container}>
          <Text style={{ fontWeight: 'bold', marginBottom: '10px', marginTop: '10px' }}>Datos del crédito</Text>
          {creditDetails.map((detail, index) => (
            <div className={classes.rowInfo} key={index}>
              <Text>{detail.label}</Text>
              <Text>{detail.value}</Text>
            </div>
          ))}
        </div>
        <div className={classes.formContainer}>
          <Text style={{ fontWeight: 'bold', marginBottom: '10px', marginTop: '10px' }}>Personaliza la ampliación del crédito</Text>
          {Object.entries(inputValues).map(([name, value], index) => (
            name === 'evaluationType' ? (
              <TextInput
                key={index}
                className={classes.formElement}
                label={labelDictionary[name] || name}
                variant="standard"
                select
                name={name}
                value={evaluationType} // Usar el estado independiente
                onChange={(e) => setEvaluationType(e.target.value)} // Actualizar el estado independiente
              >
                <MenuItem value="">
                  <em>Seleccionar</em>
                </MenuItem>
                <MenuItem value="MANUAL">Manual</MenuItem>
                <MenuItem value="AUTOMATIC">Automática</MenuItem>
              </TextInput>
            ) : (
              <TextInput
                key={index}
                className={classes.formElement}
                label={labelDictionary[name] || name}
                variant="standard"
                name={name}
                value={value}
                onChange={handleInputChange}
                helperText={inputErrors[name]}
                error={inputErrors[name] === 'Campo Obligatorio'}
              />
            )
            // ...
          ))}
          <Box className={classes.buttonContainer}>
            <Button
              variant="text"
              color="secondary"
              onClick={handleClearEdit}
            >
              Limpiar Edición
            </Button>
            <Button
              style={{ borderRadius: 30 }}
              variant="contained"
              color="secondary"
              onClick={handleOpenModal}
            >
              Guardar personalización
            </Button>
          </Box>
        </div>
      </div>
      <Dialog
        open={isModalOpen}
        onClose={handleCloseModal}
        PaperProps={{
          style: {
            width: '412px',
            height: 'auto',
            display: 'flex',
            flexDirection: 'column',
          },
        }}
      >
        <DialogTitle style={{
          textAlign: 'left', fontWeight: 'bold', marginTop: '10px', marginBottom: '10px',
        }}
        >
          ¡Atención!
          <IconButton
            aria-label="close"
            style={{ position: 'absolute', right: '8px', top: '8px' }}
            onClick={handleCloseModal}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ textAlign: 'center' }}>
          <Text>
            Vas a modificar una ampliación para el crédito de
            {' '}
            {shop.shopkeeper_name}
            {' '}
            de
            {' '}
            {shop.shop_name}
            .
          </Text>
        </DialogContent>
        <DialogActions style={{ flexDirection: 'column' }}>
          <Button
            style={{ borderRadius: '30px', marginBottom: '8px', width: '95%' }}
            variant="contained"
            color="secondary"
            onClick={() => {
              handleUpdateExtensionValues();
            }}
          >
            Confirmar Modificación
          </Button>
          <Button
            variant="text"
            color="secondary"
            onClick={() => {
              handleCloseModal();
            }}
            style={{ background: 'none', width: '95%', marginBottom: '20px' }}
          >
            Borrar Modificación
          </Button>
        </DialogActions>
      </Dialog>
    </PageContainer>
  );
};

export default ExtensionDetails;
