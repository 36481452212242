import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import CustomSnackBar from '../CustomSnackBar/CustomSnackBar';
import CustomModal from '../CustomModal/CustomModal';
import { Box } from '../Layout';
import Button from '../Button';

const AdminAction = ({
  splashBtnText,
  isLoading,
  onClickSplashBtn,
  isOpenSnackbar,
  handleCloseSnackbar,
  setOpenModal,
  isOpenModal,
  modalTitle,
  onClickModalBtn,
  children,
  msgType,
  snackBarMsg,
  isDisabledModalBtn,
  isEdit,
  isLostLead,
  isSecondError,
  isOpenSecondSnackbar,
  secondMsgType,
  secondSnackbarMsg,
  style,
  customStyle,
}) => (
  <div style={{ marginTop: isEdit ? 0 : 12 }}>
    {isEdit ? (
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={onClickSplashBtn}
        disabled={isLoading}
      >
        <EditIcon fontSize="small" />
      </IconButton>
    ) : (
      <Button
        variant="contained"
        color={isLostLead ? 'primary' : 'secondary'}
        onClick={onClickSplashBtn}
        className={style}
        customStyle={customStyle}
        children={splashBtnText}
        isDisabled={isLoading}
      />
    ) }

    <CustomSnackBar
      open={isOpenSnackbar}
      onClose={handleCloseSnackbar}
      msgType={msgType}
      msg={snackBarMsg}
      style={{ marginBottom: isOpenSecondSnackbar ? 80 : 0 }}
    />
    {isSecondError && (
   <CustomSnackBar
   open={isOpenSecondSnackbar}
   onClose={handleCloseSnackbar}
   msgType={secondMsgType}
   msg={secondSnackbarMsg}
 />
    ) }
    <CustomModal
      isMessage={isEdit}
      title={modalTitle}
      open={isOpenModal}
      setOpen={setOpenModal}
      closeButton
    >
      <FormControl style={{ marginLeft: 50 }}>
        {children}
        <Box style={{ marginTop: 12, display: 'flex', height: 30 }}>
          <Button
            variant="contained"
            color="secondary"
            children="Aceptar"
            onClick={onClickModalBtn}
            isDisabled={isDisabledModalBtn}
          />
        </Box>
      </FormControl>
    </CustomModal>
  </div>
);

export default AdminAction;

AdminAction.propTypes = {
  children: PropTypes.element.isRequired,
  splashBtnText: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  onClickSplashBtn: PropTypes.func.isRequired,
  isOpenSnackbar: PropTypes.bool.isRequired,
  handleCloseSnackbar: PropTypes.func.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  isOpenModal: PropTypes.bool.isRequired,
  modalTitle: PropTypes.string,
  onClickModalBtn: PropTypes.func.isRequired,
  snackBarMsg: PropTypes.string.isRequired,
  msgType: PropTypes.string.isRequired,
  isDisabledModalBtn: PropTypes.bool,
  isEdit: PropTypes.bool,
};
