import React from "react";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Modal, IconButton, Grid } from "@material-ui/core";
import Close from "@material-ui/icons/Close";

import PageContainer from "../../components/PageContainer";
import Box from "../../components/Layout/Box";
import Text from "../../components/Text";
import TopBarBuyDealer from "../../components/TopBarBuyDealer/TopBarBuyDealer";
import CardReviewVoucher from "../../components/CardReviewVoucher";

function DetailReviewVouchers({
  classes,
  toReturn,
  shopkeeper_detail,
  credit_line_shop_detail,
  dataVouchersClient,
  zoomModal,
  setZoomModal,
  creditLineId,
  accountId,
}) {

  function reviewVouchers(vouchers) {
    return (
      vouchers &&
      vouchers?.map((item) => {
        return (
          <CardReviewVoucher
            data={item}
            setZoomModal={setZoomModal}
            creditLineId={creditLineId}
            accountId={accountId}
          />
        )
      })
    );
  }  

  return (
    <PageContainer>
      <div onClick={toReturn} className={classes.controls}>
        <ArrowBackIcon />
        <Text>Regresar</Text>
      </div>
      <TopBarBuyDealer
        shokeeper_detail={shopkeeper_detail?.data || shopkeeper_detail}
        credit_line_shop_detail={credit_line_shop_detail}
      />
      {Object.keys(dataVouchersClient).length !== 0 && dataVouchersClient?.map((data, index) => (
        <div className={classes.containerInfo} key={index}>
          <Text className={classes.titleDate}>{data?.week_name}</Text>
          <Grid container direction="row" alignItems="flex-start">
            { reviewVouchers(data?.vouchers) }
          </Grid>
        </div>
      ))}
      <Modal
        open={zoomModal.show}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={classes.modalRejection}
      >
        <Box className={classes.modalContainerZoom}>
          <IconButton
            className={classes.buttonCloseZoom}
            onClick={() =>
              setZoomModal({
                show: false,
                image: "",
              })
            }
          >
            <Close />
          </IconButton>
          <img src={zoomModal.image} className={classes.imageZoomStyle} />
        </Box>
      </Modal>
    </PageContainer>
  );
}

export default DetailReviewVouchers;