import * as ACTION from './kyc.actionTypes';

const dataKYC = {
    loading: {
        GET_MATI_VERIFICATION: false,
        PUT_MATI_VERIFICATION: false,
    },
    error: {
        GET_MATI_VERIFICATION: null,
        PUT_MATI_VERIFICATION: null,
    },

    dataGetMatiVerification: {},
    dataPutMatiVerification: {},
};

const kycReducer = (state= dataKYC, action) => {
    switch (action.type) {
        case ACTION.INIT_GET_MATI_VERIFICATION:
            return { ...state, loading: { ...state.loading, GET_MATI_VERIFICATION: true }, error: { ...state.error, GET_MATI_VERIFICATION: null} };
        case ACTION.RESPONSE_GET_MATI_VERIFICATION:
            return { ...state, loading: { ...state.loading, GET_MATI_VERIFICATION: false }, error: { ...state.error, GET_MATI_VERIFICATION: null}, dataGetMatiVerification: action.result };
        case ACTION.ERROR_GET_MATI_VERIFICATION:
            return { ...state, loading: { ...state.loading, GET_MATI_VERIFICATION: false }, error: { ...state.error, GET_MATI_VERIFICATION: action.error} };
        case ACTION.CLEAR_GET_MATI_VERIFICATION:
            return { ...state, dataGetMatiVerification: {} };

        case ACTION.INIT_PUT_MATI_VERIFICATION:
            return { ...state, loading: { ...state.loading, PUT_MATI_VERIFICATION: true }, error: { ...state.error, PUT_MATI_VERIFICATION: null} };
        case ACTION.RESPONSE_PUT_MATI_VERIFICATION:
            return { ...state, loading: { ...state.loading, PUT_MATI_VERIFICATION: false }, error: { ...state.error, PUT_MATI_VERIFICATION: null}, dataPutMatiVerification: action.result };
        case ACTION.ERROR_PUT_MATI_VERIFICATION:
            return { ...state, loading: { ...state.loading, PUT_MATI_VERIFICATION: false }, error: { ...state.error, PUT_MATI_VERIFICATION: action.error} };
        case ACTION.CLEAR_PUT_MATI_VERIFICATION:
            return { ...state, dataPutMatiVerification: {} };

        default:
            return state;
    }
};

export default kycReducer;