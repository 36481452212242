import React, { useEffect } from 'react';
import {
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import useStyles from './OrderedTableHead.style';
import Text from '../../../components/Text';

const headCells = [
  {
    id: 'transaction_id', numeric: false, disablePadding: false, label: 'ID de Transacción',
  },
  {
    id: 'transaction_type', numeric: false, disablePadding: false, label: 'Tipo de Transacción',
  },
  {
    id: 'amount', numeric: true, disablePadding: false, label: 'Monto',
  }
];

export const OrderedTableHead = (props) => {
  const classes = useStyles();
  const { page, orderBy, sort } = useSelector((state) => state.customersState);

  useEffect(() => {
  }, [orderBy, sort]);

  return (
    <TableHead className={classes.tableHead}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            align="center"
            scope="col"
            sort={orderBy === headCell.id ? sort : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? sort : 'asc'}
            >
              <Text className={classes.titleLabel}>{headCell.label}</Text>
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default OrderedTableHead;
