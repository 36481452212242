import React from 'react';
import {
  Table, TableHead, TableRow, TableSortLabel, TableCell, TableBody,
} from '@material-ui/core';
import Text from '../../../components/Text';
import { dateFunction } from '../dateFunction';

const TransactionTable = (props) => {
  const reversedPropKeys = [...Object.keys(props)].reverse();


  if (Object.keys(props).length === 0) { return <Text variant="h2" style={{ padding: '20%', textAlign: 'center' }}>No hay transacciones en este corte</Text>; }
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>
            <TableSortLabel />
            <TableSortLabel>
              Concepto
            </TableSortLabel>
          </TableCell>
          <TableCell>
            <TableSortLabel>
              Fecha de transacción
            </TableSortLabel>
          </TableCell>
          <TableCell>
            <TableSortLabel>
              Monto
            </TableSortLabel>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {reversedPropKeys.map((keyName, i) => (
          <TableRow key={i}>
            <TableCell component="th">
              <img src={props[keyName].product_icon} style={{ width: 25 }} />
              {props[keyName].product_name}
            </TableCell>
            <TableCell component="th" scope="row" style={{ textAlign: 'center' }}>
              { dateFunction(props[keyName].date)}
            </TableCell>
            <TableCell component="th" scope="row">
              {`$ ${props[keyName].amount}`}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default TransactionTable;
