import React from 'react';
import {
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    TableHead,
    TableFooter,
    TablePagination,
    CircularProgress,
    Paper
} from "@material-ui/core";
import moment from 'moment';
import { headCellExtension } from '../../../../constants/constants';
import { formatCurrency } from '../../../../utils/utils';

import { Box } from '../../../../components/Layout';
import Text from '../../../../components/Text';

const Extension = ({
    classes,
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    dataAllExtension,
    loading
}) => {
    const informationTable = () => {
        return (
            <TableBody>
                {dataAllExtension?.map((data, index) => (
                    <TableRow key={index} style={{ maxHeight: 43 }}>
                        <TableCell>
                            <Text className={classes.textCellTable}>{moment(data.executed_at).format('LL') || "-"}</Text>
                        </TableCell>
                        <TableCell>
                            <Text className={classes.textCellTable}>{formatCurrency(data.prev_amount) || '-'}</Text>
                        </TableCell>
                        <TableCell>
                            <Text className={classes.textCellTable}>{formatCurrency(data.new_amount) || '-'}</Text>
                        </TableCell>
                        <TableCell>
                            <Text className={classes.textCellTable}>{`${data.prev_interest_rate} %` || '-'}</Text>
                        </TableCell>
                        <TableCell>
                            <Text className={classes.textCellTable}>{`${data.new_interest_rate} %` || '-'}</Text>
                        </TableCell>
                        <TableCell>
                            <Text className={classes.textCellTable}>{formatCurrency(data.prev_installment) || '-'}</Text>
                        </TableCell>
                        <TableCell>
                            <Text className={classes.textCellTable}>{formatCurrency(data.new_installment) || '-'}</Text>
                        </TableCell>
                        <TableCell>
                            <Text className={classes.textCellTable}>{data.extension_type === "ADJUSTMENT" ? "Ajuste" : "Ampliación"}</Text>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        )
    }

    return (
        <Box className={classes.container}>
            <Box className={classes.content}>
                <Text className={classes.title}>Tabla de Amortización</Text>
                {loading && (
                    <Box className={classes.centerContent}>
                        <CircularProgress size={55} style={{marginRight: 10}}/>
                        <Text>Cargando...</Text>
                    </Box>
                )}
                {dataAllExtension.length === 0 && !loading && (
                    <Box className={classes.centerContent}>
                        <Text>No se encontraron datos</Text>
                    </Box>
                )}
                {dataAllExtension.length > 0 && (
                    <Paper className={classes.tableWrapper}>
                        <TableContainer>
                            <Table aria-label="a dense table">
                                <TableHead className={classes.tableHead}>
                                    <TableRow>
                                        {headCellExtension.map((headCell) => (
                                            <TableCell
                                                key={headCell.id}
                                                align="center"
                                                scope="col"
                                            >
                                                <Text className={classes.titleTable}>{headCell.label}</Text>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                {informationTable()}
                                <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            rowsPerPageOptions={[20, 50, 100]}
                                            colSpan={15}
                                            count={dataAllExtension.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            SelectProps={{
                                                inputProps: {
                                                    'aria-label': 'rows per page',
                                                },
                                                native: true,
                                            }}
                                            onChangePage={handleChangePage}
                                            onChangeRowsPerPage={handleChangeRowsPerPage}
                                        />
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                    </Paper>
                )}
            </Box>
        </Box>
    );
};

export default Extension;