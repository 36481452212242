export const INIT_GET_AVAL_STATUS = "AVALS::INIT_GET_AVAL_STATUS";
export const RESPONSE_GET_AVAL_STATUS = "AVALS::RESPONSE_GET_AVAL_STATUS";
export const ERROR_GET_AVAL_STATUS = "AVALS::ERROR_GET_AVAL_STATUS";
export const CLEAR_GET_AVAL_STATUS = "AVALS::CLEAR_GET_AVAL_STATUS";

export const INIT_POST_AVAL_DATA = "AVALS::INIT_POST_AVAL_DATA";
export const RESPONSE_POST_AVAL_DATA = "AVALS::RESPONSE_POST_AVAL_DATA";
export const ERROR_POST_AVAL_DATA = "AVALS::ERROR_POST_AVAL_DATA";
export const CLEAR_POST_AVAL_DATA = "AVALS::CLEAR_POST_AVAL_DATA";

export const INIT_POST_WISH_NEW_AVAL = "AVALS::INIT_POST_WISH_NEW_AVAL";
export const RESPONSE_POST_WISH_NEW_AVAL = "AVALS::RESPONSE_POST_WISH_NEW_AVAL";
export const ERROR_POST_WISH_NEW_AVAL = "AVALS::ERROR_POST_WISH_NEW_AVAL";
export const CLEAR_POST_WISH_NEW_AVAL = "AVALS::CLEAR_POST_WISH_NEW_AVAL";

export const INIT_GET_ZIPCODE = "AVALS::INIT_GET_ZIPCODE";
export const RESPONSE_GET_ZIPCODE = "AVALS::RESPONSE_GET_ZIPCODE";
export const ERROR_GET_ZIPCODE = "AVALS::ERROR_GET_ZIPCODE";
export const CLEAR_GET_ZIPCODE = "AVALS::CLEAR_GET_ZIPCODE";

export const INIT_PUT_BUREAU_DECISION = "AVALS::INIT_PUT_BUREAU_DECISION";
export const RESPONSE_PUT_BUREAU_DECISION = "AVALS::RESPONSE_PUT_BUREAU_DECISION";
export const ERROR_PUT_BUREAU_DECISION = "AVALS::ERROR_PUT_BUREAU_DECISION";
export const CLEAR_PUT_BUREAU_DECISION = "AVALS::CLEAR_PUT_BUREAU_DECISION";

export const CLEAR_ERRORS = "AVALS::CLEAR_ERRORS";
