import * as ACTION from './presaleadvisors.actionTypes';
import * as presaleAdvisorsService from '../services/presaleadvisors.service';

export const getPresaleAdvisors = () => async (dispatch) => {
  dispatch({
    type: ACTION.INIT_GET_PRESALE_ADVISORS,
  });

  try {
    const presaleAdvisorsData = await presaleAdvisorsService.getPresaleAdvisors();
    if (presaleAdvisorsData) {
      dispatch({
        type: ACTION.RESPONSE_GET_PRESALE_ADVISORS,
        result: presaleAdvisorsData,
      });
    }
  } catch (error) {
    dispatch({
      type: ACTION.ERROR_GET_PRESALE_ADVISORS,
      error,
    });
  }
};

export const clearPresaleAdvisors = () => async (dispatch) => {
  dispatch({
    type: ACTION.CLEAR_GET_PRESALE_ADVISORS,
  });
};

export const putPresaleAdvisor = (userType, userId, body) => async (dispatch) => {
  dispatch({
    type: ACTION.INIT_PUT_PRESALE_ADVISORS,
  });

  try {
    const updatePresaleAdvisorData = await presaleAdvisorsService.putPresaleAdvisor(userType, userId, body);
    if (updatePresaleAdvisorData) {
      dispatch({
        type: ACTION.RESPONSE_PUT_PRESALE_ADVISORS,
        result: updatePresaleAdvisorData,
      });
    }
  } catch (error) {
    dispatch({
      type: ACTION.ERROR_PUT_PRESALE_ADVISORS,
      error,
    });
  }
};

export const postPresaleAdvisorTask = (body) => async (dispatch) => {
  dispatch({
    type: ACTION.INIT_POST_PRESALE_ADVISORS_TASK,
  });

  try {
    const postPresaleAdvisorTaskData = await presaleAdvisorsService.postPresaleAdvisorTask(body);
    if (postPresaleAdvisorTaskData) {
      dispatch({
        type: ACTION.RESPONSE_POST_PRESALE_ADVISORS_TASK,
        result: postPresaleAdvisorTaskData,
      });
    }
  } catch (error) {
    dispatch({
      type: ACTION.ERROR_POST_PRESALE_ADVISORS_TASK,
      error,
    });
  }
};

export const clearErrors = () => async (dispatch) => {
  dispatch({
    type: ACTION.CLEAR_ERRORS,
  });
};
