/* eslint-disable import/prefer-default-export */
import client from '../client';

export const postShortUrl = (body) => {
  const url = 'admin/utils/short_url';
  return new Promise((resolve, reject) => {
    client.post(url, body)
      .then((response) => {
        resolve(response);
      }, (error) => {
        reject(error);
      });
  });
};
