import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getMatiVerification } from "../../reducers/kyc.action";

import MatiVerificationUI from "./MatiVerification";
import useStyles from './MatiVerification.styles';

function MatiVerification() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [inputValue, setInputVale] = useState('');
    const [status, setStatus] = useState('REJECTED');
    const [dataMati, setDataMati] = useState([]);

    const {
        dataGetMatiVerification
    } = useSelector((state) => state.kycReducer);

    useEffect(() => {
        dispatch(getMatiVerification());
    }, []);
    
    useEffect(() => {
        if(Object.keys(dataGetMatiVerification).length !== 0 && inputValue === "") {
            if(dataGetMatiVerification?.data && dataGetMatiVerification.data && status !== "ALL") {
                const filteredClients = filterClients(dataGetMatiVerification.data, status);
                setDataMati(filteredClients);
            } else {
                setDataMati(dataGetMatiVerification.data);
            }
        }
    }, [dataGetMatiVerification, status, inputValue]);

    const searchAction = () => {
        if(Object.keys(dataGetMatiVerification).length !== 0) {
            if(dataGetMatiVerification?.data && dataGetMatiVerification.data && inputValue !== "" && inputValue !== undefined && inputValue !== null) {
                const filteredClients = searchClients(dataGetMatiVerification.data, inputValue);
                setDataMati(filteredClients);
            }
        }
    };
    
    function filterClients(clients, status) {
        if(status === 'REJECTED') {
            return clients.filter(client => client.internal_document_review === 'NO_MATCH' || client.internal_document_review === 'DUPLICATED_DOCUMENT');
        } else {
            return clients.filter(client => client.internal_document_review === status);
        }
    };

    function searchClients(clients, inputValue) {
        return clients.filter(client => {
          return (
            client?.store_name?.includes(inputValue) ||
            client?.store_phone?.includes(inputValue) 
          );
        });
      }

    return <MatiVerificationUI
        classes={classes}
        dispatch={dispatch}
        inputValue={inputValue}
        setInputVale={setInputVale}
        status={status}
        setStatus={setStatus}
        searchAction={searchAction}
        dataMati={dataMati}
    />
}

export default MatiVerification;