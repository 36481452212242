export const FETCH_SHOPBYID_INIT = 'SHOP::FETCH_SHOPBYID_INIT';
export const FETCH_SHOPBYID_SUCCESS = 'SHOP::FETCH_SHOPBYID_SUCCESS';
export const FETCH_SHOPBYID_ERROR = 'SHOP::FETCH_SHOPBYID_ERROR';
export const SET_STATE = 'SHOP::SET_STATE_SHOPBYID';
export const FETCH_BLOCKED_BYID_INIT = 'SHOP::FETCH_BLOCKED_BYID_INIT';
export const FETCH_BLOCKED_BYID_SUCCESS = 'SHOP::FETCH_BLOCKED_BYID_SUCCESS';
export const FETCH_BLOCKED_BYID_ERROR = 'SHOP::FETCH_BLOCKED_BYID_ERROR';

export const INIT_SHOP_DETAIL = 'SHOP::INIT_SHOP_DETAIL';
export const RESPONSE_SHOP_DETAIL = 'SHOP::RESPONSE_SHOP_DETAIL';
export const ERROR_SHOP_DETAIL = 'SHOP::ERROR_SHOP_DETAIL';
export const CLEAR_SHOP_DETAIL = 'SHOP::CLEAR_SHOP_DETAIL';
