import React from 'react';
import { MenuItem, Select } from '@material-ui/core';
import moment from 'moment';
import { Box } from '../Layout';
import Text from '../Text/Text';
import useStyles from './BureauDatePicker.style';

const BureauDatePicker = ({
  dateData, onSelectedItemChange, selectedValue, setSelectedValue,
}) => {
  const classes = useStyles();
  const bureauData = dateData || [];
  bureauData.sort((a, b) => moment(a.created_at).unix() - moment(b.created_at).unix());
  const handleSelectChange = (event) => {
    const selectedId = event.target.value;
    const selectedItem = bureauData.find((item) => item.consultation_id === selectedId);
    setSelectedValue(selectedItem);
    onSelectedItemChange(selectedItem);
  };

  return (
    <Box className={classes.generalContainer}>
      <Text className={classes.text}>Selecciona una Fecha</Text>
      <Select
        className={classes.select}
        value={selectedValue ? selectedValue.consultation_id : ''}
        onChange={handleSelectChange}
      >
        {bureauData.map((item) => (
          <MenuItem key={item.consultation_id} value={item.consultation_id}>
            {moment(item.created_at).format('D [de] MMMM YYYY')}
            {' '}
            -
            {' '}
            {item.person_type === 'ACCOUNT' ? 'Titular' : item.person_type === 'PARTNER' ? 'Obligado Solidario' : 'Aval' }
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

export default BureauDatePicker;
