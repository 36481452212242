import client from "../client";

export const getAvalStatus = (user_type, user_id) => {
  const url = `admin/shop-loan/credit-line-request/${user_type}/${user_id}/requirements`;
  return new Promise((resolve, reject) => {
    client.get(url).then(
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      }
    );
  });
};

export const postAvalData = (userId, body) => {
  const url = `admin/users/account/${userId}/aval`;
  return new Promise((resolve, reject) => {
    client.post(url, body).then(
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      }
    );
  });
};

export const postWishNewAval = (userId, body) => {
  const url = `admin/users/account/${userId}/wish-new-aval`;
  return new Promise((resolve, reject) => {
    client.post(url, body).then(
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      }
    );
  });
};

export const getZipCode = (zipCode) => {
  const url = `zip-codes/${zipCode}`;
  return new Promise((resolve, reject) => {
    client.get(url).then(
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      }
    );
  });
};

export const putBureauDecision =(user_id, body) => {
  const url = `admin/users/account/${user_id}/aval/bureau-decision`;
  return new Promise((resolve, reject) => {
    client.put(url, body).then(
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      }
    );
  });
};