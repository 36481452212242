import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { useHistory } from 'react-router';
import useStyles from './App.styles';
import { Box } from '../components/Layout';
import { darkTheme, lightTheme } from './Theme';
import useFirebaseAuth from '../hooks/useFirebaseAuth';

const App = (props) => {
  const { isAuthorized } = useFirebaseAuth();

  const history = useHistory();

  useEffect(() => {
    if (!isAuthorized) {
      history.push('/?previous_path='+encodeURIComponent(window.location.pathname));
    }
  }, [isAuthorized]);

  const { children } = props;
  const { isLightThemeSelected } = useState(true);
  const classes = useStyles();

  return (
    <MuiThemeProvider theme={isLightThemeSelected ? lightTheme : darkTheme}>
      <Box className={classes.container}>
        {children}
      </Box>
    </MuiThemeProvider>
  );
};

App.propTypes = {
  children: PropTypes.element.isRequired,
  classes: PropTypes.shape({}),
};

App.defaultProps = {
  classes: {},
};

export default App;
