import React, { useState } from 'react';
import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  CircularProgress,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import useStyles from './PaginationTable.style';
import Text from '../../Text';
import ArrowedText from '../../ArrowedText';
import OrderedTableHead from '../OrderedTableHead/OrderedCreditTableHead';
import Button from '../../Button';
import { Box } from '../../Layout';

export const PaginationCreditTable = (props) => {
  const classes = useStyles();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [showModal, setShowModal] = useState(false);
  const { shopCredits } = useSelector(
    (state) => state.registersState,
  );
  const formatter = new Intl.NumberFormat('es-MX', {
    style: 'currency',
    currency: 'MXN',
  });

  const shopList = shopCredits && shopCredits.length > 0 ?
    shopCredits.map((shopCredit, index) => (
      <TableRow key={index} style={{ maxHeight: 43 }}>
        <TableCell>
          <Text className={classes.textStyle}>{shopCredit.account_type_id === 'CU' ? 'Cliente' : 'Tienda'}</Text>
        </TableCell>
        <TableCell>
          <Text className={classes.textStyle}>{shopCredit.account_id}</Text>
        </TableCell>
        <TableCell>
          <Text className={classes.textStyle}>{shopCredit.shop_name || '-'}</Text>
        </TableCell>
        <TableCell>
          <Text className={classes.textStyle}>{shopCredit.shopkeeper_name || shopCredit.name}</Text>
        </TableCell>
        <TableCell>
          <Text className={classes.textStyle}>{shopCredit.phone_number}</Text>
        </TableCell>
        <TableCell>
          <Text className={classes.textStyle}>
            {formatter.format(shopCredit.credit_line_amount)}
          </Text>
        </TableCell>
        <TableCell>
          <Text className={classes.textStyle}>
            {formatter.format(shopCredit.installment)}
          </Text>
        </TableCell>
        <TableCell>
          <Link to={`/credits/${shopCredit.credit_line_id}/detail`}>
            <Button>
              <ArrowedText>
                Ver detalle
              </ArrowedText>
            </Button>
          </Link>
        </TableCell>
      </TableRow>
    )) : '';

  const [selectedCustomer, setSelectedCustomer] = useState({
    name: '',
    profile: 1,
    step: undefined,
  });

  return (
    <Box>
      {shopCredits === undefined && (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
          <CircularProgress  size={55} style={{marginRight: 10}}/>
          <Text>Cargando...</Text>
        </Box>
      )}
      <TableContainer className={classes.tableContainer}>
        <Table aria-label="custom pagination table">
          <OrderedTableHead />
          <TableBody>
            {shopList}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

PaginationCreditTable.propTypes = {};

export default PaginationCreditTable;
