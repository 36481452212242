import client from '../client';

export const putTaxPayerId = (body) => {
    const url = `modifications/taxpayer-id`;
    return new Promise((resolve, reject) => {
      client.put(url, body).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  };

export const patchInfoProperties = (user_type, user_id,payload) => {
  const url = `modifications/users/${user_type}/${user_id}`;
  return new Promise((resolve, reject) => {
    client.patch(url, payload).then(
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      }
    );
  });
}