import React, { useEffect } from 'react';
import {
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from '../../../Shops/OrderedTableHead.style';
import Text from '../../../../components/Text';

export const CreditInformtionTableHead = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { page, orderBy, sort } = useSelector((state) => state.customersState);

  useEffect(() => {
  }, [orderBy, sort]);
  
  const headCells = [
    {
      id: 'next_payment_date', numeric: false, disablePadding: false, label: 'Próximo pago',
    },
    {
      id: 'share', numeric: true, disablePadding: false, label: 'Cuota',
    },
    {
      id: 'capital', numeric: true, disablePadding: false, label: 'Capital',
    },
    {
      id: 'interest', numeric: true, disablePadding: false, label: 'Interes',
    },
    {
      id: 'late_interest', numeric: true, disablePadding: false, label: 'Interes moratorio',
    },
    {
      id: 'iva', numeric: true, disablePadding: false, label: 'IVA',
    },
    {
      id: 'late_iva', numeric: true, disablePadding: false, label: 'IVA moratorio',
    },
    {
      id: 'status', label: 'Estatus',
    },
    {
      id: 'payment_date', numeric: false, disablePadding: false, label: 'Fecha de pago',
    },
    {
      id: 'remainig_capital', numeric: true, disablePadding: false, label: 'Capital restante',
    },
    {
      id: 'remainig_interest', numeric: true, disablePadding: false, label: 'Interes restante',
    },
    {
      id: 'remainig_late_interest', numeric: true, disablePadding: false, label: 'Interes moratorio restante',
    },
    {
      id: 'insurance', numeric: true, disablePadding: false, label: 'Seguro',
    },
    {
      id: 'remaining_insurance', numeric: true, disablePadding: false, label: 'Seguro restante',
    },
    {
      id: 'remainig_iva', numeric: true, disablePadding: false, label: 'IVA restante',
    },
    {
      id: 'remainig_late_iva', numeric: true, disablePadding: false, label: 'IVA moratorio restante',
    },
  ];

  return (
    <TableHead className={classes.tableHead}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            align="center"
            scope="col"
            sort={orderBy === headCell.id ? sort : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? sort : 'asc'}
            >
              <Text className={classes.titleLabel}>{headCell.label}</Text>
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default CreditInformtionTableHead;
