import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    imageHolder: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        cursor: 'grab',
        '&:active': {
            cursor: 'grabbing',
        },
    },
    image: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },
    zoomButtons: {
        position: 'absolute',
        top: 0,
        right: 0,
        zIndex: 1,
    },
    zoomButton: {
        backgroundColor: 'white',
        border: '1px solid gray',
        borderRadius: 5,
        padding: 5,
        fontSize: 16,
        margin: 5,
        cursor: 'pointer',
        width: '22px',
        '&:hover': {
            backgroundColor: '#f2f2f2',
        },
    },
});

export default useStyles;
