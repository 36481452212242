import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  contentBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    margin: '10px 0px',
  },
  contentLabelTextBox:  {
    display: 'flex',
    flexDirection: 'row',
    width: '90%',
    justifyContent: 'space-around',
    margin: '10px',
  },

  labelText:  {
    width: '35%',
    textAlign: 'left'
  },

  valueText:  {
    width: '65%',
    textAlign: 'left'
  },

  buttonBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginTop: '10px',
  },
}));

export default useStyles;
