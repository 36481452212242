import React from "react";

import PageContainer from '../../components/PageContainer';

function LandingPage({
    classes
}) {
    return (
        <PageContainer>
            <iframe width={'100%'} height={'100%'} src={`${process.env.REACT_APP_LANDING}`}/>
        </PageContainer>
    )
}

export default LandingPage;