import React from 'react';
import useStyles from './ConfirmationAlertModal.styles';
import Text from '../Text';
import Modal from '../CustomModal/CustomModal';
import { Box } from '../Layout';
import Button from '../Button';

const ConfirmationAlertModal = ({
  open, setOpen,handleConfirmation,title,
}) => {
  const classes = useStyles();

  return (
    <Modal
      title={title}
      closeButton
      open={open}
      setOpen={(value) => { setOpen(value); }}
    >
      <Box className={classes.contentBox}>
        <Text className={classes.labelText}>¿Estás seguro que quieres cancelar este desembolso?</Text>
        <Box className={classes.buttonBox}>
          <Button variant="contained" color="secondary" onClick={handleConfirmation}>Confirmar</Button>
          <Button variant="contained" onClick={() => setOpen(false)}>Cancelar</Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ConfirmationAlertModal;
