import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import { Select } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { createAction, getActions } from '../../Customers/customersActions';

import { monthNamesFull } from '../../../constants/constants';
import useStyles from './Collections.styles';
import Text from '../../../components/Text';
import Button from '../../../components/Button';
import Box from '../../../components/Layout/Box';
import { dateFunction } from '../dateFunction';

const Collections = (props) => {
  const { customerID } = props;
  const classes = useStyles();
  const date = new Date();
  const month = monthNamesFull[date.getMonth()];
  const day = date.getDate();
  const dispatch = useDispatch();
  const [action, SetAction] = useState(' ');
  const [promise, setSelectedDate] = useState();
  const [comment, setComment] = useState(' ');
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleAction = (event) => {
    SetAction(event.target.value);
  };
  const handleComment = (event) => {
    setComment(event.target.value);
  };

  useEffect(() => {
    dispatch(getActions(customerID));
  }, []);

  const promiseDateTrue = () => {
    if (action == 'PROMISE_OF_PAYMENT') {
      return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="MM/dd/yyyy"
            margin="normal"
            id="date-picker-inline"
            label="Fecha de promesa de pago"
            value={promise}
            onChange={handleDateChange}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </MuiPickersUtilsProvider>
      );
    }
  };
  const displayAction = (e) => {
    switch (e) {
      case 'PROMISE_OF_PAYMENT':
        return 'Promesa de pago';
      case 'PARTIAL_PAYMENT':
        return 'Pago parcial';
      case 'BROKEN_PROMISE_OF_PAYMENT':
        return 'Promesa de pago rota';
      case 'SIM_NOT_LONGER_BELONG_TO_CLIENT':
        return 'Chip ya no pertenece al Usuario';
      case 'CLIENT_NOT_FOUND':
        return 'No Localizable';
      case 'CALL_LIKELY_BLOCKED':
        return 'Posible bloqueo de número telefónico';
      case 'REFUSAL_OF_PAYMENT':
        return 'Negativa de Pago';
      case 'AFFECTED_BY_HEALTH_CONTINGENCY':
        return ' Afectado por contingencia sanitaria';
      case 'SICKNESS':
        return 'Enfermedad';
      case 'INCOME_DELAYED':
        return 'Retraso de Ingresos';
      case 'UNEMPLOYED':
        return 'Desempleado';
      default:
        return 'No Localizable';
    }
  };

  const { histories } = useSelector((state) => state.customersState);

  const reversedHistories = [...histories].reverse();

  return (
    <Box className={classes.CollectionsStyles}>
      <Box>
        <Text variant="h2">Historial</Text>
        {reversedHistories.map((history, i) => (
          <List key={i} className={classes.listStyles}>
            <ListItem>
              <FormHelperText>{dateFunction(history.date)}</FormHelperText>
              {' '}
              <br />
            </ListItem>
            <ListItem>
              <FormHelperText>
                Agente:
                {history.agent}
              </FormHelperText>
            </ListItem>
            <ListItem>
              <ListItemText>
                {' '}
                {displayAction(history.action)}
                {' '}
              </ListItemText>
            </ListItem>
            {history.promise_of_payment_date ? (
              <ListItem>
                <ListItemText>
                  {dateFunction(history.promise_of_payment_date)}
                </ListItemText>
              </ListItem>
            ) : (
              ''
            )}
            <ListItem>
              <ListItemText>
                Comentarios:
                {history.comments}
              </ListItemText>
            </ListItem>
            <Divider style={{ minWidth: 400 }} />
          </List>
        ))}
      </Box>
      <Divider
        orientation="vertical"
        style={{ minHeight: 1000, margin: '1.3%' }}
      />
      <Box style={{ width: '100%' }}>
        <FormControl className={classes.formStyles}>
          <Text variant="h2">Gestión del día</Text>
          <FormHelperText>
            {`${day} de ${month}`}
          </FormHelperText>
          <FormControl variant="outlined" color="secondary">
            <InputLabel
              id="demo-simple-select"
              color="secondary"
            >
              Status
            </InputLabel>
            <Select
              labelId="demo-simple-select"
              id="demo-simple-select"
              autoWidth
              value={action}
              onChange={handleAction}
              label="Status"
            >
              <MenuItem value="PROMISE_OF_PAYMENT">
                Realizó promesa de pago
              </MenuItem>
              <MenuItem value="PARTIAL_PAYMENT">Generó Pago Parcial</MenuItem>
              <MenuItem value="BROKEN_PROMISE_OF_PAYMENT">
                Promesa de Pago Rota
              </MenuItem>
              <MenuItem value="SIM_NOT_LONGER_BELONG_TO_CLIENT">
                Chip ya no pertenece al Usuario
              </MenuItem>
              <MenuItem value="CLIENT_NOT_FOUND">No Localizable</MenuItem>
              <MenuItem value="CALL_LIKELY_BLOCKED">
                Posible bloqueo de número telefónico
              </MenuItem>
              <MenuItem value="REFUSAL_OF_PAYMENT">Negativa de Pago</MenuItem>
              <MenuItem value="AFFECTED_BY_HEALTH_CONTINGENCY">
                Afectado por contingencia sanitaria
              </MenuItem>
              <MenuItem value="SICKNESS">Enfermedad</MenuItem>
              <MenuItem value="INCOME_DELAYED">Retraso de Ingresos</MenuItem>
              <MenuItem value="UNEMPLOYED">Desempleado</MenuItem>
            </Select>
          </FormControl>
          {promiseDateTrue()}
          <TextField
            id="outlined-multiline-flexible"
            label="Comentarios"
            multiline
            rowsMax="3"
            margin="normal"
            variant="outlined"
            value={comment}
            onChange={handleComment}
            color="secondary"
          />
          <Button
            color="secondary"
            variant="contained"
            onClick={() => {
              dispatch(
                createAction(customerID, Number(date), action, promise, comment),
              );
            }}
          >
            Guardar
          </Button>
        </FormControl>
      </Box>
    </Box>
  );
};

export default Collections;
