import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useStyles from './Toggle.styles';
import toggle from '../../assets/toggle.svg';
import { Box } from '../Layout';

const Toggle = ({ onToggle, ...others }) => {
  const classes = useStyles();
  const [toggled, setToggled] = useState(true);

  return (
    <Box
      {...others}
      onClick={() => {
        setToggled(!toggled);
        if (onToggle) onToggle(toggled);
      }}
    >
      <img
        className={`${classes.dropdown} ${toggled ? classes.open : classes.closed}`}
        src={toggle}
      />
    </Box>

  );
};

Toggle.propTypes = {
  onToggle: PropTypes.func.isRequired,
};

export default Toggle;
