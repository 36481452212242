import * as ACTION from './inventory.actionTypes';
import * as inventoryService from '../services/inventory.service';

export const getInventory = (phone_number) => async (dispatch) => {
  dispatch({
    type: ACTION.INIT_GET_INVENTORY,
  });

  try {
    const InventoryData = await inventoryService.getInventory(phone_number);
    if (InventoryData) {
      dispatch({
        type: ACTION.RESPONSE_GET_INVENTORY,
        result: InventoryData,
      });
    }
  } catch (error) {
    dispatch({
      type: ACTION.ERROR_GET_INVENTORY,
      error,
    });
  }
};

export const clearInventory = () => async (dispatch) => {
  dispatch({
    type: ACTION.CLEAR_GET_INVENTORY,
  });
};

export const getInventoryComparison = (phone_number) => async (dispatch) => {
  dispatch({
    type: ACTION.INIT_GET_INVENTORY_COMPARISON,
  });

  try {
    const InventoryComparisonData = await inventoryService.getInventoryComparison(phone_number);
    if (InventoryComparisonData) {
      dispatch({
        type: ACTION.RESPONSE_GET_INVENTORY_COMPARISON,
        result: InventoryComparisonData,
      });
    }
  } catch (error) {
    dispatch({
      type: ACTION.ERROR_GET_INVENTORY_COMPARISON,
      error,
    });
  }
};

export const postReevaluateInventory = (userId, body) => async (dispatch) => {
  dispatch({
    type: ACTION.INIT_POST_REEVALUATE_INVENTORY,
  });

  try {
    const reevaluateInventoryData = await inventoryService.postReevaluateInventory(userId, body);
    if (reevaluateInventoryData) {
      dispatch({
        type: ACTION.RESPONSE_POST_REEVALUATE_INVENTORY,
      });
    }
  } catch (error) {
    dispatch({
      type: ACTION.ERROR_POST_REEVALUATE_INVENTORY,
      error,
    });
  }
};

export const clearInventoryComparison = () => async (dispatch) => {
  dispatch({
    type: ACTION.CLEAR_GET_INVENTORY_COMPARISON,
  });
};

export const getInventoryVersion = (account_type_id, account_id) => async (dispatch) => {
  dispatch({
    type: ACTION.INIT_GET_INVENTORY_VERSION,
  });

  try {
    const InventoryVersionData = await inventoryService.getInventoryVersion(account_type_id, account_id);
    if (InventoryVersionData) {
      dispatch({
        type: ACTION.RESPONSE_GET_INVENTORY_VERSION,
        result: InventoryVersionData,
      });
    }
  } catch (error) {
    dispatch({
      type: ACTION.ERROR_GET_INVENTORY_VERSION,
      error,
    });
  }
};

export const clearGetInventoryVersion = () => async (dispatch) => {
  dispatch({
    type: ACTION.CLEAR_GET_INVENTORY_VERSION,
  });
};

export const getInventoryDocuments = (account_id, account_type_id, pdf_type, business_flow, version, phone_number) => async (dispatch) => {
  dispatch({
    type: ACTION.INIT_GET_INVENTORY_DOCUMENTS,
  });

  try {
    const InventoryDocumentsData = await inventoryService.getInventoryDocuments(account_id, account_type_id, pdf_type, business_flow, version, phone_number);
    if (InventoryDocumentsData) {
      dispatch({
        type: ACTION.RESPONSE_GET_INVENTORY_DOCUMENTS,
        result: InventoryDocumentsData,
      });
    }
  } catch (error) {
    dispatch({
      type: ACTION.ERROR_GET_INVENTORY_DOCUMENTS,
      error,
    });
  }
};

export const clearGetInventoryDocuments = () => async (dispatch) => {
  dispatch({
    type: ACTION.CLEAR_GET_INVENTORY_DOCUMENTS,
  });
};

export const postInventoryVersionClosing = (account_id, account_type_id) => async (dispatch) => {
  dispatch({
    type: ACTION.INIT_POST_INVENTORY_VERSION_CLOSING,
  });

  try {
    const inventoryVersionClosingData = await inventoryService.postInventoryVersionClosing(account_id, account_type_id);
    if (inventoryVersionClosingData) {
      dispatch({
        type: ACTION.RESPONSE_POST_INVENTORY_VERSION_CLOSING,
      });
    }
  } catch (error) {
    dispatch({
      type: ACTION.ERROR_POST_INVENTORY_VERSION_CLOSING,
      error,
    });
  }
};

export const putIsValidInventoryPhotos = (photo_id, is_valid, account_id, account_type_id) => async (dispatch) => {
  dispatch({
    type: ACTION.INIT_PUT_IS_VALID_INVENTORY_PHOTOS,
  });

  try {
    const inventoryPhotosData = await inventoryService.putIsValidInventoryPhotos(photo_id, is_valid, account_id, account_type_id);
    if (inventoryPhotosData) {
      dispatch({
        type: ACTION.RESPONSE_PUT_IS_VALID_INVENTORY_PHOTOS,
      });
    }
  } catch (error) {
    dispatch({
      type: ACTION.ERROR_PUT_IS_VALID_INVENTORY_PHOTOS,
      error,
    });
  }
};

export const clearPutIsValidInventoryPhotos = () => async (dispatch) => {
  dispatch({
    type: ACTION.CLEAR_PUT_IS_VALID_INVENTORY_PHOTOS,
  });
};

export const putInventoryPhotosCategory = (account_id, account_type_id, photo_id, category, requester) => async (dispatch) => {
  dispatch({
    type: ACTION.INIT_PUT_INVENTORY_PHOTOS_CATEGORY,
  });

  try {
    const inventoryPhotosCategoryData = await inventoryService.putInventoryPhotosCategory(account_id, account_type_id, photo_id, category, requester);
    if (inventoryPhotosCategoryData) {
      dispatch({
        type: ACTION.RESPONSE_PUT_INVENTORY_PHOTOS_CATEGORY,
      });
    }
  } catch (error) {
    dispatch({
      type: ACTION.ERROR_PUT_INVENTORY_PHOTOS_CATEGORY,
      error,
    });
  }
};

export const clearPutInventoryPhotosCategory = () => async (dispatch) => {
  dispatch({
    type: ACTION.CLEAR_PUT_INVENTORY_PHOTOS_CATEGORY,
  });
};

export const deleteInventoryPhotosCategory = (account_id, account_type_id, photo_id, requester) => async (dispatch) => {
  dispatch({
    type: ACTION.INIT_DELETE_INVENTORY_PHOTOS_CATEGORY,
  });

  try {
    const deleteInventoryPhotosCategoryData = await inventoryService.deleteInventoryPhotosCategory(account_id, account_type_id, photo_id, requester);
    if (deleteInventoryPhotosCategoryData) {
      dispatch({
        type: ACTION.RESPONSE_DELETE_INVENTORY_PHOTOS_CATEGORY,
      });
    }
  } catch (error) {
    dispatch({
      type: ACTION.ERROR_DELETE_INVENTORY_PHOTOS_CATEGORY,
      error,
    });
  }
};

export const clearDeleteInventoryPhotosCategory = () => async (dispatch) => {
  dispatch({
    type: ACTION.CLEAR_DELETE_INVENTORY_PHOTOS_CATEGORY,
  });
};

// Task
export const postTaskAssignment = (body) => async (dispatch) => {
  dispatch({
    type: ACTION.INIT_POST_TASK_ASSIGNMENT,
  });

  try {
    const postTaskAssignmentData = await inventoryService.postTaskAssignment(body);
    if (postTaskAssignmentData) {
      dispatch({
        type: ACTION.RESPONSE_POST_TASK_ASSIGNMENT,
        result: postTaskAssignmentData,
      });
    }
  } catch (error) {
    dispatch({
      type: ACTION.ERROR_POST_TASK_ASSIGNMENT,
      error,
    });
  }
};

export const clearPostTaskAssignment = () => async (dispatch) => {
  dispatch({
    type: ACTION.CLEAR_POST_TASK_ASSIGNMENT,
  });
};

// Closing fill form
export const postInventoryClosingFillForm = (phone_number, body) => async (dispatch) => {
  dispatch({
    type: ACTION.INIT_POST_INVENTORY_CLOSING_FILL_FORM,
  });

  try {
    const postInventoryClosingFillFormData = await inventoryService.postInventoryClosingFillForm(phone_number, body);
    if (postInventoryClosingFillFormData) {
      dispatch({
        type: ACTION.RESPONSE_POST_INVENTORY_CLOSING_FILL_FORM,
        result: postInventoryClosingFillFormData,
      });
    }
  } catch (error) {
    dispatch({
      type: ACTION.ERROR_POST_INVENTORY_CLOSING_FILL_FORM,
      error,
    });
  }
};

export const clearPostInventoryClosingFillForm = () => async (dispatch) => {
  dispatch({
    type: ACTION.CLEAR_POST_INVENTORY_CLOSING_FILL_FORM,
  });
};

// Category photos
export const getInventoryPhotoCategory = (account_type_id, account_id) => async (dispatch) => {
  dispatch({
    type: ACTION.INIT_GET_INVENTORY_PHOTOS_CATEGORY,
  });

  try {
    const InventoryPhotoCategoryData = await inventoryService.getInventoryPhotoCategory(account_type_id, account_id);
    if (InventoryPhotoCategoryData) {
      dispatch({
        type: ACTION.RESPONSE_GET_INVENTORY_PHOTOS_CATEGORY,
        result: InventoryPhotoCategoryData,
      });
    }
  } catch (error) {
    dispatch({
      type: ACTION.ERROR_GET_INVENTORY_PHOTOS_CATEGORY,
      error,
    });
  }
};

export const clearGetInventoryPhotoCategory = () => async (dispatch) => {
  dispatch({
    type: ACTION.CLEAR_GET_INVENTORY_PHOTOS_CATEGORY,
  });
};

export const clearErrors = () => async (dispatch) => {
  dispatch({
    type: ACTION.CLEAR_ERRORS,
  });
};
