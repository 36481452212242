import React from "react";

import PageContainer from '../../components/PageContainer';
import Box from '../../components/Layout/Box';
import { MenuItem } from "@material-ui/core";
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import SectionTitle from '../../components/SectionTitle';
import TextInput from "../../components/TextInput";
import TableReviewVoucher from "../../components/TableReviewVoucher";

function ReviewVoucher({
    classes,
    inputValue,
    setInputVale,
    status,
    setStatus,
    searchAction
}) {
    return (
        <PageContainer>
            <Box className={classes.rootContainer}>
                <Box className={classes.topContainer}>
                    <SectionTitle>Revisión de tickets</SectionTitle>
                    <Box className={classes.searchContainer}>
                        <InputBase
                            value={inputValue}
                            onChange={(event) => {
                                setInputVale(event.target.value);
                            }}
                            className={classes.input}
                            placeholder="Buscar por nombre de tienda, ID, nombre de cliente…"
                            inputProps={{ 'aria-label': 'search user' }}
                        />
                        <Divider className={classes.divider} orientation="vertical" />
                        <IconButton onClick={() => searchAction()} className={classes.iconButton} aria-label="search">
                            <SearchIcon />
                        </IconButton>
                    </Box>
                </Box>
                <Box className={classes.filterStatus}>
                    <TextInput
                        className={classes.formElement}
                        label="Filtrar por estado"
                        variant="standard"
                        select
                        value={status}
                        onChange={(value) => setStatus(value.target.value)}
                    >
                        <MenuItem value={'PENDING'}>Por verificar</MenuItem>
                        <MenuItem value={'APPROVED'}>Aprobado</MenuItem>
                        <MenuItem value={'REJECTED'}>Rechazado</MenuItem>
                        <MenuItem value={'OUTDATED'}>Caducada</MenuItem>
                    </TextInput>
                </Box>
                <Box className={classes.tableContainer}>
                    <TableReviewVoucher statusFilter={status}/>
                </Box>
            </Box>
        </PageContainer>
    )
}

export default ReviewVoucher;