import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    generalContainer: {
        display: 'flex',
        marginTop: '20px',
    },
    buttonStyle: {
        color: '#3f51b5', 
        backgroundColor: '#fff', 
        padding: '5px 10px', 
        fontSize: '14px', 
        fontWeight: 'bold', 
        borderRadius: '5px', 
        minWidth: '100px', 
        height: '40px', 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center', 
        cursor: 'pointer', 
        border: '1px solid #3f51b5',
        marginRight: '10px' 
    },
    modalContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalStyleContainer: {
        display: 'flex',
        flexDirection: 'column',
        padding: 15,
        borderRadius: 15,
        backgroundColor: '#ffffff',
    },
    rowContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 20
    },
    titleModal: {
        fontSize: 16,
        fontWeight: 'bold',
        color: "#000"
    },
    buttonClose: {
        width: 30,
        height: 30,
    },
    formElementModal: {
        marginTop: 10,
        marginBottom: 10,
        marginRight: 10,
        marginLeft: 10
    },
    tabStyle: {
        color: '#3f51b5',
        fontWeight: 'bold',
    },
    dateRangeContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: 20,
        marginBottom: 20,
    },
    datePicker: {
        width: '45%',
        '& .MuiInputLabel-root': {
            color: '#3f51b5',
        },
        '& .MuiInputLabel-root.Mui-focused': {
            color: '#3f51b5',
        },
        '& .MuiPickersDay-daySelected': {
            backgroundColor: '#3f51b5',
            color: '#ffffff',
            '&:hover': {
                backgroundColor: '#3f51b5',
            },
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#3f51b5',
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#3f51b5',
        },
        '& .MuiPickersDay-current': {
            color: '#3f51b5',
        },
        '& .MuiIconButton-root': {
            color: '#3f51b5',
        },
        '& .MuiInputBase-input': {
            color: '#3f51b5',
        },
    },
    buttonModalContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 20,
    },
    buttonDownload: {
        backgroundColor: "#3f51b5",
        fontSize: '12px',
        fontWeight: '600',
    },
}));

export default useStyles;
