import * as ACTION from './actionTypes';
import client from '../../../client';
import { appService } from '../../../services/app.service';

export const getFindShop = () => async (dispatch, getState) => {
  const { searchString } = getState().registersState;
  const url = `${appService.config.paths.shop.route}?${searchString ? `name=${searchString}` : ''}`;
  dispatch({ type: ACTION.FETCH_CUSTOMERS_INIT });
  const response = await client.get(url);
  const { data } = response;
  const d = data.filter((shop) => shop.credit_line_id !== undefined);

  if (d && d.length > 0) {
    dispatch({ type: ACTION.FETCH_CUSTOMERS_SUCCESS });
    dispatch({ type: ACTION.SET_STATE, label: 'shops', value: d });
  } else {
    dispatch({ type: ACTION.FETCH_CUSTOMERS_ERROR, error: 'No shops returned from service' });
    dispatch({ type: ACTION.FETCH_CUSTOMERS_SUCCESS, error: null });
  }
};

export const getCreditLine = (creditLineID) => async (dispatch) => {
  const url = `${appService.config.paths.loan.route}/${creditLineID}`;
  try {
    dispatch({ type: ACTION.FETCH_CUSTOMERS_INIT });
    const response = await client.get(url);
    const { data } = response;
    dispatch({ type: ACTION.SET_STATE, label: 'displayedCreditLine', value: data });
  } catch (e) {
    dispatch({ type: ACTION.FETCH_CUSTOMERS_ERROR, error: e });
  }
};

export const applyDelete = (creditLineID, delete_reason) => async (dispatch) => {
  const url = `${appService.config.paths.loan.route}/${creditLineID}`;
  const data = {
    delete_reason,
  };

  try {
    await client.delete(url, {
      params: data,
    }).then(
      () => {
        dispatch({ type: ACTION.UPDATE_ALERT_SUCCESS, value: 'La línea de crédito fue dada de baja exitosamente !' });
        dispatch(getCreditLine(creditLineID));
      },
    ).catch((error) => {
      dispatch({ type: ACTION.UPDATE_ALERT_ERROR, value: error.response.data.message });
    });
  } catch (e) {
    console.log(e);
  }
};
