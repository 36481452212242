import * as ACTION from '../containers/Registers/actionTypes';

const initialState = {
  isFetchingCustomers: false,
  isFetchingPhotos: false,
  customers: [],
  displayedCustomer: undefined,
  photoCategories: [],
  inventoryPhotos: [],
  links: [],
  next: undefined,
  page: 1,
  pages: undefined,
  total_elements: undefined,
  searchString: '',
  sort: 'desc',
  orderBy: 'name',
  alert: {
    open: false,
    severity: 'error',
    message: '',
  },
};

const registersReducer = (state = initialState, action, step) => {
  switch (action.type) { 
    case ACTION.FETCH_CUSTOMERS_INIT:
      return { ...state, isFetchingCustomers: true };
    case ACTION.FETCH_CUSTOMERS_SUCCESS:
      return {
        ...state, loadingCustomers: false, error: null, isFetchingCustomers: false,
      };
    case ACTION.FETCH_CUSTOMERS_ERROR:
      return { ...state, loadingCustomers: false, error: action.error, isFetchingCustomers: false };
    case ACTION.UPDATE_CUSTOMERS_INIT:
      return { step, updatingCustomer: true };
    case ACTION.UPDATE_CUSTOMERS_SUCCESS:
      return { step, updatingCustomer: false, error: null };
    case ACTION.UPDATE_CUSTOMERS_ERROR:
      return { step, updatingCustomer: false, error: action.error };
    case ACTION.SET_STATE:
      return {
        ...state, [action.label]: action.value,
      };
    case ACTION.UPDATE_ALERT_SUCCESS:
      return {
        ...state,
        alert: {
          open: true,
          severity: 'success',
          message: action.value,
        },
      };
    case ACTION.UPDATE_ALERT_ERROR:
      return {
        ...state,
        alert: {
          open: true,
          severity: 'error',
          message: action.value,
        },
      };
    case ACTION.UPDATE_CLOSE_ALERT:
      return {
        ...state,
        alert: {
          open: false,
          severity: 'error',
          message: '',
        },
      };
    default:
      return { ...state };
  }

};

export default registersReducer;
