import React from 'react'
import Modal from '@material-ui/core/Modal';

const ModalComponent = ({ children, ...otherProps }) => {
    return(
        <Modal
            { ...otherProps }
        >
            { children }
        </Modal> 
    )
}

export default ModalComponent;

