import * as ACTION from "./avals.actionTypes";
import * as avalsService from "../services/avals.service";

export const getAvalStatus = (user_type, user_id) => async (dispatch) => {
  dispatch({
    type: ACTION.INIT_GET_AVAL_STATUS,
  });

  try {
    const avalStatus = await avalsService.getAvalStatus(user_type, user_id);
    if (avalStatus) {
      dispatch({
        type: ACTION.RESPONSE_GET_AVAL_STATUS,
        result: avalStatus,
      });
    }
  } catch (error) {
    dispatch({
      type: ACTION.ERROR_GET_AVAL_STATUS,
      error,
    });
  }
};

export const clearAvalStatus = () => async (dispatch) => {
  dispatch({
    type: ACTION.CLEAR_GET_AVAL_STATUS,
  });
};

export const postAvalData = (userId, body) => async (dispatch) => {
  dispatch({
    type: ACTION.INIT_POST_AVAL_DATA,
  });

  try {
    const avalData = await avalsService.postAvalData(userId, body);
    if (avalData) {
      dispatch({
        type: ACTION.RESPONSE_POST_AVAL_DATA,
        result: avalData,
      });
    }
  } catch (error) {
    dispatch({
      type: ACTION.ERROR_POST_AVAL_DATA,
      error,
    });
  }
};

export const postWishNewAval = (userId, body) => async (dispatch) => {
  dispatch({
    type: ACTION.INIT_POST_WISH_NEW_AVAL,
  });

  try {
    const avalData = await avalsService.postWishNewAval(userId, body);
    if (avalData) {
      dispatch({
        type: ACTION.RESPONSE_POST_WISH_NEW_AVAL,
        result: avalData,
      });
    }
  } catch (error) {
    dispatch({
      type: ACTION.ERROR_POST_WISH_NEW_AVAL,
      error,
    });
  }
};

export const clearAvalData = () => async (dispatch) => {
  dispatch({
    type: ACTION.CLEAR_POST_AVAL_DATA,
  });
};

export const getZipCode = (zipCode) => async (dispatch) => {
  dispatch({
    type: ACTION.INIT_GET_ZIPCODE,
  });

  try {
    const zipCodeInformation = await avalsService.getZipCode(zipCode);
    if (zipCodeInformation) {
      dispatch({
        type: ACTION.RESPONSE_GET_ZIPCODE,
        result: zipCodeInformation,
      });
    }
  } catch (error) {
    dispatch({
      type: ACTION.ERROR_GET_ZIPCODE,
      error,
    });
  }
};

export const clearZipCode = () => async (dispatch) => {
  dispatch({
    type: ACTION.CLEAR_GET_ZIPCODE,
  });
};

export const putBureauDecisionAction = (user_id, body) => async (dispatch) => {
  dispatch({
    type: ACTION.INIT_PUT_BUREAU_DECISION,
  });

  try {
    const bureauDecision= await avalsService.putBureauDecision(user_id, body);
    if (bureauDecision) {
      dispatch({
        type: ACTION.RESPONSE_PUT_BUREAU_DECISION,
        result: bureauDecision,
      });
    }
  } catch (error) {
    dispatch({
      type: ACTION.ERROR_PUT_BUREAU_DECISION,
      error,
    });
  }
};

export const clearPutBureauDecisionAction = () => async (dispatch) => {
  dispatch({
    type: ACTION.CLEAR_PUT_BUREAU_DECISION,
  });
};

export const clearErrors = () => async (dispatch) => {
  dispatch({
    type: ACTION.CLEAR_ERRORS,
  });
};
