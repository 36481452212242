import React, { useEffect } from 'react';
import {
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from './OrderedTableHead.style';
import Text from '../../../components/Text';

const headCells = [
  {
    id: 'shop_id', numeric: false, disablePadding: false, label: 'ID de tienda',
  },
  {
    id: 'shop_name', numeric: false, disablePadding: false, label: 'Nombre de tienda',
  },
  {
    id: 'bank', numeric: true, disablePadding: false, label: 'Banco',
  },
  {
    id: 'total_sales', numeric: true, disablePadding: false, label: 'Total de ventas',
  },
  {
    id: 'total_charges', numeric: true, disablePadding: false, label: 'Total de cobros',
  },
  {
    id: 'balance', numeric: true, disablePadding: false, label: 'Balance',
  },
  {
    id: 'action', label: 'Acción',
  },
  {
    id: 'status', label: 'Estatus',
  },
  {
    id: 'show_shop', label: 'Ver Transacciones',
  },
];

export const OrderedTableHead = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { page, orderBy, sort } = useSelector((state) => state.customersState);

  useEffect(() => {
  }, [orderBy, sort]);

  return (
    <TableHead className={classes.tableHead}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            align="center"
            scope="col"
            sort={orderBy === headCell.id ? sort : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? sort : 'asc'}
            >
              <Text className={classes.titleLabel}>{headCell.label}</Text>
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default OrderedTableHead;
