import * as ACTION from "./avals.actionTypes";

const dataAvals = {
  loading: {
    GET_AVAL_STATUS: false,
    POST_AVAL_DATA: false,
    POST_WISH_NEW_AVAL: false,
    GET_ZIPCODE: false,
    PUT_BUREAU_DECISION: false,
  },
  error: {
    GET_AVAL_STATUS: null,
    POST_AVAL_DATA: null,
    POST_WISH_NEW_AVAL: null,
    GET_ZIPCODE: null,
    PUT_BUREAU_DECISION: null,
  },
  avalStatus: {},
  avalData: {},
  wishNewAval: {},
  zipCode: {},
  bureauDecisionData: {},
};

const avals = (state = dataAvals, action) => {
  switch (action.type) {
    case ACTION.INIT_GET_AVAL_STATUS:
      return {
        ...state,
        loading: { ...state.loading, GET_AVAL_STATUS: true },
        error: { ...state.error, GET_AVAL_STATUS: null },
      };
    case ACTION.RESPONSE_GET_AVAL_STATUS:
      return {
        ...state,
        loading: { ...state.loading, GET_AVAL_STATUS: false },
        error: { ...state.error, GET_AVAL_STATUS: null },
        avalStatus: action.result,
      };
    case ACTION.ERROR_GET_AVAL_STATUS:
      return {
        ...state,
        loading: { ...state.loading, GET_AVAL_STATUS: false },
        error: { ...state.error, GET_AVAL_STATUS: action.error },
      };
    case ACTION.CLEAR_GET_AVAL_STATUS:
      return { ...state, avalStatus: {} };

    case ACTION.INIT_POST_AVAL_DATA:
      return {
        ...state,
        loading: { ...state.loading, POST_AVAL_DATA: true },
        error: { ...state.error, POST_AVAL_DATA: null },
      };
    case ACTION.RESPONSE_POST_AVAL_DATA:
      return {
        ...state,
        loading: { ...state.loading, POST_AVAL_DATA: false },
        error: { ...state.error, POST_AVAL_DATA: null },
        avalData: action.result,
      };
    case ACTION.ERROR_POST_AVAL_DATA:
      return {
        ...state,
        loading: { ...state.loading, POST_AVAL_DATA: false },
        error: { ...state.error, POST_AVAL_DATA: action.error },
      };
    case ACTION.CLEAR_POST_AVAL_DATA:
      return { ...state, avalData: {} };

    case ACTION.INIT_POST_WISH_NEW_AVAL:
      return {
        ...state,
        loading: { ...state.loading, POST_WISH_NEW_AVAL: true },
        error: { ...state.error, POST_WISH_NEW_AVAL: null },
      };
    case ACTION.RESPONSE_POST_WISH_NEW_AVAL:
      return {
        ...state,
        loading: { ...state.loading, POST_WISH_NEW_AVAL: false },
        error: { ...state.error, POST_WISH_NEW_AVAL: null },
        wishNewAval: action.result,
      };
    case ACTION.ERROR_POST_WISH_NEW_AVAL:
      return {
        ...state,
        loading: { ...state.loading, POST_WISH_NEW_AVAL: false },
        error: { ...state.error, POST_WISH_NEW_AVAL: action.error },
      };
    case ACTION.CLEAR_POST_WISH_NEW_AVAL:
      return { ...state, wishNewAval: {} };

    case ACTION.INIT_GET_ZIPCODE:
      return {
        ...state,
        loading: { ...state.loading, GET_ZIPCODE: true },
        error: { ...state.error, GET_ZIPCODE: null },
      };
    case ACTION.RESPONSE_GET_ZIPCODE:
      return {
        ...state,
        loading: { ...state.loading, GET_ZIPCODE: false },
        error: { ...state.error, GET_ZIPCODE: null },
        zipCode: action.result,
      };
    case ACTION.ERROR_GET_ZIPCODE:
      return {
        ...state,
        loading: { ...state.loading, GET_ZIPCODE: false },
        error: { ...state.error, GET_ZIPCODE: action.error },
      };
    case ACTION.CLEAR_GET_ZIPCODE:
      return { ...state, zipCode: {} };

    case ACTION.INIT_PUT_BUREAU_DECISION:
      return {
        ...state,
        loading: { ...state.loading, PUT_BUREAU_DECISION: true },
        error: { ...state.error, PUT_BUREAU_DECISION: null },
      };
    case ACTION.RESPONSE_PUT_BUREAU_DECISION:
      return {
        ...state,
        loading: { ...state.loading, PUT_BUREAU_DECISION: false },
        error: { ...state.error, PUT_BUREAU_DECISION: null },
        bureauDecisionData: action.result,
      };
    case ACTION.ERROR_PUT_BUREAU_DECISION:
      return {
        ...state,
        loading: { ...state.loading, PUT_BUREAU_DECISION: false },
        error: { ...state.error, PUT_BUREAU_DECISION: action.error },
      };
    case ACTION.CLEAR_PUT_BUREAU_DECISION:
      return { ...state, bureauDecisionData: {} };
      
    case ACTION.CLEAR_ERRORS:
      return { ...state, error: dataAvals.error };
    default:
      return state;
  }
};

export default avals;
