import React from "react";

import { 
    TableContainer,
    Table,
    TableBody,
    TableRow, 
    TableCell,
    TableHead,
    TableFooter,
    TablePagination,
    CircularProgress
} from "@material-ui/core";
import { Link } from 'react-router-dom';
import moment from "moment";
import Text from '../Text';
import Button from '../Button';
import ArrowedText from '../ArrowedText';
import { Box } from "../Layout";
import { headCellBuyDealer } from "../../constants/constants";
import icons from "../../assets/icons";

function TableBuyDealer({
    classes,
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    supplierData,
    saveVouchersClick,
    getPendingVouchers
}) {   
    const revisionStatus = (data) => {
        if(data?.revision_status === 'PENDING') {
            return (
                <div className={classes.statusContainer}>
                    <div style={{background: '#3050ff', width: 15, height: 15, borderRadius: 50}}></div>
                    <Text className={classes.textCellTable}>{`Por verificar (${getPendingVouchers(data).length})`}</Text>
                </div>
            )
        } else if(data?.revision_status === 'APPROVED') {
            return (
                <div className={classes.statusContainer}>
                    <div style={{background: '#45c441', width: 15, height: 15, borderRadius: 50}}></div>
                    <Text className={classes.textCellTable}>Verificación completa</Text>
                </div>
            )
        } else if(data?.revision_status === 'OUTDATED') {
            return(
                <div className={classes.statusContainer}>
                    <div style={{background: '#e5534b', width: 15, height: 15, borderRadius: 50}}></div>
                    <Text className={classes.textCellTable}>Caducada</Text>
                </div>
            )
        } else {
            return 
        }
    };

    const informationTable = () => {
        return (
            <TableBody>
                {( rowsPerPage > 0 
                    ? supplierData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : supplierData
                ). map((data, index) => (
                    <TableRow key={index} style={{ maxHeight: 43 }}>
                        <TableCell>
                            <Text className={classes.textCellTable}>{data?.shop_name}</Text>
                        </TableCell>
                        <TableCell>
                            <Text className={classes.textCellTable}>{data?.credit_line_id.substring(30)}</Text>
                        </TableCell>
                        <TableCell>
                            <Text className={classes.textCellTable}>{data?.shopkeeper_name}</Text>
                        </TableCell>
                        <TableCell>
                            <Text className={classes.textCellTable}>{data?.shop_number.substring(3)}</Text>
                        </TableCell>
                        <TableCell>
                            <Text className={classes.textCellTable}>{`$${data?.amount}`}</Text>
                        </TableCell>
                        <TableCell>
                            <Text className={classes.textCellTable}>{moment(data?.created_at).format('L LT')}</Text>
                        </TableCell>
                        <TableCell>
                            <Text className={classes.textCellTable}>{data?.expired_at !== null ? moment(data?.expired_at).format('L LT') : 'No se ha desembolsado'}</Text>
                        </TableCell>
                        <TableCell>
                            { revisionStatus(data) }
                        </TableCell>
                        <TableCell>
                            <Button onClick={() => saveVouchersClick(
                                data?.vouchers, 
                                data?.credit_line_id, 
                                data?.account_id, 
                                data?.purchase_id, 
                                data?.suppliers, 
                                data?.amount, 
                                data?.acepted_amount
                                )}
                            >
                                <ArrowedText>
                                    Ver detalle
                                </ArrowedText>
                            </Button>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        )
    }

    return (
        <Box>
            {Object.keys(supplierData).length === 0 && (
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                    <CircularProgress  size={55} style={{marginRight: 10}}/>
                    <Text>Cargando...</Text>
                </Box>
            )}
            <TableContainer>
                <Table aria-label="a dense table">
                    <TableHead className={classes.tableHead}>
                        <TableRow>
                            {headCellBuyDealer.map((headCell) => (
                                <TableCell
                                    align="center"
                                    scope="col"
                                >
                                    <Text className={classes.titleTable}>{headCell.label}</Text>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    { informationTable() }
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[10, 15, 20]}
                                colSpan={8}
                                count={supplierData.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                inputProps: {
                                    'aria-label': 'rows per page',
                                },
                                native: true,
                                }}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </Box>
    )
};

export default TableBuyDealer;