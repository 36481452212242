import React from 'react';
import PropTypes from 'prop-types';
import { Divider } from '@material-ui/core';
import { Box } from '../../../../components/Layout';
import Text from '../../../../components/Text';
import LabelValueTextList
  from '../../../../components/LabelValueTextList/LabelValueTextList';
import personalInfo from '../../../../assets/personalInfo.svg';
import location from '../../../../assets/location.svg';


const CustomerInfoCard = (props) => {
  const { type } = props;

  let cardTitle = '';
  let cardIcon;

  switch (type) {
    case 'personal_data':
      cardTitle = 'Datos Personales';
      cardIcon = personalInfo;
      break;
    case 'address':
      cardTitle = 'Domicilio';
      cardIcon = location;
      break;
    case 'job_information':
      cardTitle = 'Trabajo';
      cardIcon = location;
      break;
    case 'customer_references':
      cardTitle = 'Referencias personales';
      cardIcon = personalInfo;
      break;
    default: break;
  }

  return (
    <Box style={{
      height: '100%',
      padding: '3%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
    }}
    >
      <Box style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
      }}
      >
        <Box style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          alignSelf: 'flex',
          justifyContent: 'center',
        }}
        >
          <img style={{ padding: '5%' }} src={cardIcon} alt="oh no" />
          <Text variant="h3">
            {cardTitle}
          </Text>
        </Box>
        <LabelValueTextList
          rows={props.rows}
        />
      </Box>
      <Divider orientation="vertical" style={{ height: 250, marginLeft: 15 }} />
    </Box>
  );
};

CustomerInfoCard.propTypes = {
  type: PropTypes.string.isRequired,
  rows: PropTypes.array.isRequired,
};

export default CustomerInfoCard;
