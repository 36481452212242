import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '../../../components/Layout';
import useStyles from './VendorValidationInfo.style';
import CustomerInfoCard from './CustomerInfoCard';
import Text from '../../../components/Text';
import MissingDataAlert from '../../../components/MissingDataAlert/MissingDataAlert';

const VendorValidationInfo = (props) => {
  const classes = useStyles();

  const { yofio_validation_info } = props;

  const { displayedCustomer: customer } = useSelector((state) => state.registersState);

  const { user_incident: duplicateCustomer, status } = yofio_validation_info;

  return (
    <Box>
      {status === 'No match' && <MissingDataAlert text="Error NO MATCH" status="BAD" />}
      {status === 'approved' && <MissingDataAlert text="Todos los documentos del cliente han sido validados correctamente." status="GOOD" />}
      { status === 'DUPLICATED_DOCUMENT' && (
        <Box className={classes.VendorValidationInfoContainer}>
          <MissingDataAlert status="BAD" text="Documento CURP duplicado en el sistema." />
          <Text>
            CURP duplicado:
            {customer.personal_data.curp}
          </Text>
          {
          <Box className={classes.CardContainer}>
            <CustomerInfoCard duplicateCustomer={duplicateCustomer} customer={customer.personal_data} customerId={customer.personal_data.user_auth_id} type="original" />
            <CustomerInfoCard customerId={duplicateCustomer.account_id} customer={duplicateCustomer} block type="duplicate" duplicateKey="CURP" />
          </Box>
          }
        </Box>
      )}
    </Box>
  );
};

export default VendorValidationInfo;
