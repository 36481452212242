import client from '../client';

export const getInventory = (phone_number) => {
  const url = `admin/inventory/${phone_number}`;
  return new Promise((resolve, reject) => {
    client.get(url).then(
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      },
    );
  });
};

export const getInventoryComparison = (phone_number) => {
  const url = `admin/inventory/comparison_form_model?phone_number=${phone_number}`;
  return new Promise((resolve, reject) => {
    client.get(url).then(
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      },
    );
  });
};

export const postReevaluateInventory = (userId, body) => {
  const url = `admin/user/${userId}/reevaluate-inventory`;
  return new Promise((resolve, reject) => {
    client.post(url, body).then(
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      },
    );
  });
};

export const getInventoryVersion = (account_type_id, account_id) => {
  const url = `admin/reports/inventory-summary/${account_type_id}/${account_id}/versions`;
  return new Promise((resolve, reject) => {
    client.get(url).then(
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      },
    );
  });
};

export const getInventoryDocuments = (account_id, account_type_id, pdf_type, business_flow, version, phone_number) => {
  const url = `admin/shop-loan/credit-line-request/documents?account_id=${account_id}?account_type_id=${account_type_id}?pdf_type=${pdf_type}?business_flow=${business_flow}?version=${version}?phone_number=${phone_number}`;
  return new Promise((resolve, reject) => {
    client.get(url).then(
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      },
    );
  });
};

export const postInventoryVersionClosing = (account_id, account_type_id) => {
  const url = `admin/inventory/versions/closing?account_id=${account_id}&account_type_id=${account_type_id}`;
  return new Promise((resolve, reject) => {
    client.post(url).then(
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      },
    );
  });
};

export const putIsValidInventoryPhotos = (photo_id, is_valid, account_id, account_type_id) => {
  const url = `admin/inventory/user/${account_id}/${account_type_id}/photos/${photo_id}/valid?is_valid=${is_valid}`;
  return new Promise((resolve, reject) => {
    client.put(url).then(
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      },
    );
  });
};

export const putInventoryPhotosCategory = (account_id, account_type_id, photo_id, category, requester) => {
  const url = `admin/inventory/user/${account_id}/${account_type_id}/photos/${photo_id}/category?category=${category}&requester=${requester}`;
  return new Promise((resolve, reject) => {
    client.put(url).then(
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      },
    );
  });
};

export const deleteInventoryPhotosCategory = (account_id, account_type_id, photo_id, requester) => {
  const url = `admin/inventory/user/${account_id}/${account_type_id}/photos/${photo_id}?requester=${requester}`;
  return new Promise((resolve, reject) => {
    client.delete(url).then(
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      },
    );
  });
};

// Task
export const postTaskAssignment = (body) => {
  const url = 'admin/task/assignment';
  return new Promise((resolve, reject) => {
    client.post(url, body).then(
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      },
    );
  });
};

// Closing fill form
export const postInventoryClosingFillForm = (phone_number, body) => {
  const url = `admin/inventory/closing-fill-form?phone_number=${phone_number}`;
  return new Promise((resolve, reject) => {
    client.post(url, body).then(
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      },
    );
  });
};

// Category photos
export const getInventoryPhotoCategory = (account_type_id, account_id) => {
  const url = `admin/inventory/photo-category/${account_type_id}/${account_id}`;
  return new Promise((resolve, reject) => {
    client.get(url).then(
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      },
    );
  });
};