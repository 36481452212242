import React, { useState } from "react";
import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  TablePagination,
} from "@material-ui/core";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import useStyles from "../SubMenuPage.styles";
import Text from "../../../../components/Text";
import ArrowedText from "../../../../components/ArrowedText";
import TablePaginationActions from "../../../../components/Table/TablePaginationActions";
import CollectionTableHead from "./CollectionTableHead";
import Button from "../../../../components/Button";
import { Box } from "../../../../components/Layout";
import { utcSimpleDateFunction } from '../../../Shops/dateFunction';
import { statusType, colorStatus } from '../../../../constants/constants';

export const CollectionTable = (props) => {
  const classes = useStyles();
  const { resourceType, shokeeper_detail, settlements, shopId }= props;
  let dataShokeeper = shokeeper_detail?.data ? shokeeper_detail.data : {};
  return (
    <Box>
      <TableContainer className={classes.doubleTableContainerPaginator}>
        <Table aria-label="custom pagination table" stickyHeader>
          <CollectionTableHead resourceType={ resourceType } />
          <TableBody>
            {
              settlements.map((settlement, index) => (
                <TableRow key={index} style={{ maxHeight: 43 }}>
                  <TableCell>
                    <Text className={classes.textStyle}>
                      { dataShokeeper.owner_data?.AccountShopkeeperID ? dataShokeeper.owner_data.AccountShopkeeperID.substr(dataShokeeper.owner_data.AccountShopkeeperID.length - 6) : '-' }
                    </Text>
                  </TableCell>
                  <TableCell>
                    <Text className={classes.textStyle}>
                      { dataShokeeper.shop?.PersonhoodName || '-' }
                    </Text>
                  </TableCell>
                  <TableCell>
                    <Text className={classes.textStyle}>
                      { dataShokeeper.owner_data?.Email || '-' }
                    </Text>
                  </TableCell>
                  <TableCell>
                    <Text className={classes.textStyle}>
                      { dataShokeeper.bank?.AccountManager || '-' }
                    </Text>
                  </TableCell>
                  <TableCell>
                    <Text className={classes.textStyle}>
                      { settlement?.total_payments || '$0.00' }
                    </Text>
                  </TableCell>
                  <TableCell>
                    <Text className={classes.textStyle}>
                      { settlement?.total_collections || '$0.00' }
                    </Text>
                  </TableCell>
                  <TableCell>
                    <Text className={classes.textStyle}>
                      { settlement?.balance || '$0.00' }
                    </Text>
                  </TableCell>
                  <TableCell>
                    <Text className={classes.textStyle} style={{ textAlign: 'center', color: colorStatus[settlement.status] }}>
                        {statusType[settlement.status]}
                    </Text>
                  </TableCell>
                  <TableCell>
                    <Link to={`/shop/${resourceType}/${settlement.settlement_id}/transactions/${shopId}`}>
                      <Button>
                          <ArrowedText>
                              Ver detalle
                          </ArrowedText>
                      </Button>
                      </Link>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

CollectionTable.propTypes = {};

export default CollectionTable;