import * as ACTION from '../../../../reducers/transactions.actionTypes';
import shopsService from '../../../../services/shops.service';

export const getConektaReference = (accountId, accountTypeId) => async (dispatch) => {
    dispatch({ type: ACTION.FETCH_TRANSACTIONS_INIT });

    try {
        const references = await shopsService.getConektaReference(accountId, accountTypeId);
        if (references) {
            dispatch({
                type: ACTION.FETCH_TRANSACTIONS_SUCCESS
            });
            dispatch({
                type: ACTION.SET_STATE,
                payload: {
                    label: 'conektaReference',
                    value: references,
                },
            });
        } else {
            dispatch({
                type: ACTION.FETCH_TRANSACTIONS_ERROR,
            });
        }
    } catch (e) {
        dispatch({
            type: ACTION.FETCH_TRANSACTIONS_ERROR,
        });
    }
};

export const getArcusReference = (accountId, accountTypeId) => async (dispatch) => {
    dispatch({ type: ACTION.FETCH_TRANSACTIONS_INIT });

    try {
        const references = await shopsService.getArcusReference(accountId, accountTypeId);
        if (references) {
            dispatch({
                type: ACTION.FETCH_TRANSACTIONS_SUCCESS
            });
            dispatch({
                type: ACTION.SET_STATE,
                payload: {
                    label: 'arcusReference',
                    value: references,
                },
            });
        } else {
            dispatch({
                type: ACTION.FETCH_TRANSACTIONS_ERROR,
            });
        }
    } catch (e) {
        dispatch({
            type: ACTION.FETCH_TRANSACTIONS_ERROR,
        });
    }
};