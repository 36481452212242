import { getCustomers } from "./customersActions";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import InputBase from "@material-ui/core/InputBase";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import Box from "../../components/Layout/Box";
import PageContainer from "../../components/PageContainer";
import SectionTitle from "../../components/SectionTitle";
import CustomersTable from "./Table/CustomerTable";
import useStyles from "./CustomersPage.styles";
import * as ACTIONS from "./actionTypes";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";
import MissingDataAlert from "../../components/MissingDataAlert/MissingDataAlert";
import { Select } from "@material-ui/core";
import { monthNamesFull, daysOfMonth } from "../../constants/constants";
import EventOutlinedIcon from "@material-ui/icons/EventOutlined";
import Button from '../../components/Button'

export const CustomersPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [nothingFoundNoticeShown, showNothingFoundNotice] = useState(false);
  const [inputValue, setInputVale] = useState("");
  const { error } = useSelector((state) => state.customersState);
  const [dates, setDates] = useState([]);
  let date = new Date();

  useEffect(() => {
    if (error) {
      showNothingFoundNotice(true);
      setInputVale("");
    }
  }, [error]);

  useEffect(() => {
    setTimeout(() => showNothingFoundNotice(false), 3000);
  }, [nothingFoundNoticeShown]);

  useEffect(
    () => () =>
      dispatch({
        type: ACTIONS.SET_STATE,
        label: "searchString",
        value: "",
        label: "filter",
        value: "",
      },),
    []);

    const handleChecks = (event) => {

        setDates(event.target.value)
        dispatch({
          type: ACTIONS.SET_STATE,
          label: "filter",
          value: event.target.value,
        });
    }

  return (
    <PageContainer>
      <Box className={classes.rootContainer}>
        <Box
          className={classes.topContainer}
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <SectionTitle>Clientes</SectionTitle>
          <Box className={classes.datesContainer}>
            <FormControl
              color="secondary"
              component="list"
             
              className={classes.formControl}
            >
              <InputLabel
                style={{ display: "flex", padding: 0, margin: 0, }}
                id="demo-simple-select-outlined-label"
                variant="outlined"
                labelId="demo-simple-select-outlined-label"
              >
                <EventOutlinedIcon
                  color="secondary"
                  style={{ display: "flex", marginTop: -5, marginRight: 10 }}
                />
                Calendario
              </InputLabel>
              <Select
                multiple
                value={dates}
                onChange={handleChecks}
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                variant='outlined'
                autoWidth
                renderValue={(selected) => selected.join(', ')}
                label="XXXXX Calendario"
              >
                <FormHelperText>
                  {monthNamesFull[date.getMonth()]}
                </FormHelperText>
                {daysOfMonth.map((day, i) => {
                  return (
                    <MenuItem key={i} value={day}>
                      <FormControlLabel
                        control={
                          <Checkbox
                              name={day}
                          />
                        }
                        label={day}
                      />
                    </MenuItem>
                  );
                })}
                <FormHelperText>
                  {monthNamesFull[date.getMonth()]}
                </FormHelperText>
              </Select>
            </FormControl>
                <Button disabled={dates.length == 0} onClick={() => dispatch(getCustomers())}>BUSCAR</Button>
          </Box>
          {nothingFoundNoticeShown && (
            <MissingDataAlert status="BAD" text="No se encontraron clientes" />
          )}
          <Box component="form" className={classes.searchContainer}>
            <InputBase
              value={inputValue}
              onChange={(event) => {
                setInputVale(event.target.value);
                dispatch({
                  type: ACTIONS.SET_STATE,
                  label: "searchString",
                  value: event.target.value,
                });
              }}
              className={classes.input}
              placeholder="Buscar por nombre de cliente, email, ID..."
              inputProps={{ "aria-label": "search user" }}
              onKeyUp={(event) => {
                if (event.key === "Enter") {
                  dispatch(getCustomers());
                }
              }}
            />
            <Divider className={classes.divider} orientation="vertical" />
            <IconButton
              onClick={() => dispatch(getCustomers())}
              className={classes.iconButton}
              aria-label="search"
            >
              <SearchIcon />
            </IconButton>
          </Box>
        </Box>
        <Box className={classes.tableContainer}>
          <CustomersTable />
        </Box>
      </Box>
    </PageContainer>
  );
};
