import React from "react";

import { 
    TableContainer,
    Table,
    TableBody,
    TableRow, 
    TableCell,
    TableHead,
    TableFooter,
    TablePagination,
    CircularProgress
} from "@material-ui/core";
import Text from '../Text';
import Button from '../Button';
import ArrowedText from '../ArrowedText';
import { Box } from "../Layout";

import { headCellReviewVoucher } from "../../constants/constants";

function TableReviewVoucher({
    classes,
    page,
    rowsPerPage,
    dataVouchers,
    handleChangePage,
    handleChangeRowsPerPage,
    detailVouchersClient
}) {
    const informationTable = () => {
        return (
            <TableBody>
                {dataVouchers?.clients?.map((data, index) => (
                    <TableRow key={index} style={{ maxHeight: 43 }}>
                        <TableCell>
                            <Text className={classes.textCellTable}>{data?.business_name}</Text>
                        </TableCell>
                        <TableCell>
                            <Text className={classes.textCellTable}>{data?.credit_line_id.slice(-6)}</Text>
                        </TableCell>
                        <TableCell>
                            <Text className={classes.textCellTable}>{data?.business_owner}</Text>
                        </TableCell>
                        <TableCell>
                            <Text className={classes.textCellTable}>{data?.business_phone_number.substring(3)}</Text>
                        </TableCell>
                        <TableCell>
                            <Text className={classes.textCellTable}>{`Por verificar (${data?.VouchersPending})`}</Text>
                        </TableCell>
                        <TableCell className={classes.centerContentStyle}>
                            <Button onClick={() => detailVouchersClient(
                                    data?.credit_line_id, 
                                    data?.account_id
                                )}
                            >
                                <ArrowedText>
                                    Ver detalle
                                </ArrowedText>
                            </Button>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        )
    }

    return (
        <Box>
            {Object.keys(dataVouchers).length === 0 && (
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                    <CircularProgress  size={55} style={{marginRight: 10}}/>
                    <Text>Cargando...</Text>
                </Box>
            )}
            <TableContainer>
                <Table aria-label="a dense table">
                    <TableHead className={classes.tableHead}>
                        <TableRow>
                            {headCellReviewVoucher.map((headCell) => (
                                <TableCell
                                    align="center"
                                    scope="col"
                                >
                                    <Text className={classes.titleTable}>{headCell.label}</Text>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    { informationTable() }
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[10, 15, 20]}
                                colSpan={8}
                                count={dataVouchers?.quantity_of_clients}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: {
                                        'aria-label': 'rows per page',
                                    },
                                    native: true,
                                }}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </Box>
    )
};

export default TableReviewVoucher;