import React, {useState, useEffect} from "react";
import { useDispatch } from "react-redux";

import StatementPDFUI from "./Statement";
import useStyles from "./Statement.styles";
import { generateLastFiveMonths } from "../../utils/utils";

function StatementPDF({
    label,
    creditLineId
}) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [selectedDate, setSelectedDate] = useState('');
    const [monthOptions, setMonthOptions] = useState([]);
    const [dateRange, setDateRange] = useState({ start: null, end: null });
    const [selectedTab, setSelectedTab] = useState('month');

    useEffect(() => {
        setMonthOptions(generateLastFiveMonths());
    }, []);

    const handleChange = (event) => {
        setSelectedDate(event.target.value);
    };
    
    const handleDateRangeChange = (type) => (date) => {
        setDateRange(prev => ({ ...prev, [type]: date }));
    };

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    return <StatementPDFUI
        classes={classes}
        label={label}
        isOpenModal={isOpenModal}
        setIsOpenModal={setIsOpenModal}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        handleChange={handleChange}
        monthOptions={monthOptions}
        creditLineId={creditLineId}
        dateRange={dateRange}
        setDateRange={setDateRange}
        handleDateRangeChange={handleDateRangeChange}
        selectedTab={selectedTab}
        handleTabChange={handleTabChange}
    />
}

export default StatementPDF;