import React from 'react';
import { useDispatch } from 'react-redux';

import Moment from 'moment';
import Modal from '../../../components/CustomModal/CustomModal';
import { Box } from '../../../components/Layout';
import Text from '../../../components/Text';
import Button from '../../../components/Button';
import { dayNamesMap } from '../../../constants/constants';
import { moneyFunction } from '../../../constants/moneyFunction';

import useStyles from './CreditModal.style';
import { approveCredit } from '../creditActions';

const CreditModal = (props) => {
  const { open, setOpen, loan } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  let credit_line_id;
  let amount;
  let term;
  let installment;
  let payment_day;
  let start_loan_date;

  const handleAccept = () => {
    dispatch(approveCredit(credit_line_id));
    setOpen(false);
  };

  if (loan) {
    credit_line_id = loan.credit_line_id;
    amount = moneyFunction(loan.credit_line_amount);
    term = loan.installment_plan_list.length;
    installment = moneyFunction(loan.installment);
    payment_day = dayNamesMap[loan.payment_day];
    start_loan_date = loan.credit_line_start_date;
  }

  return (
    <Modal
      title="Enviar a Tesorer&iacute;a"
      closeButton
      open={open}
      setOpen={(value) => {
        setOpen(value);
      }}
    >
      <Box className={classes.CustomerStepContent}>
        <Text className={classes.text}>
            Enviar a revisi&oacute;n  el cr&eacute;dito por <strong className={classes.textBlue}>{amount} </strong>
            a un plazo de <strong className={classes.textBlue}>{term} semanas </strong>
            con un pago semanal de <strong className={classes.textBlue}>{installment}</strong>
            ; la fecha de desembolso es el d&iacute;a <strong className={classes.textBlue}>{payment_day} {Moment(start_loan_date).format('DD/MMMM/yyyy')}</strong>
        </Text>
        <Box className={classes.buttonBox}>
          <Button variant="contained" color="secondary" onClick={handleAccept}>
            <Text> Enviar </Text>
          </Button>
        </Box>
      </Box>

    </Modal>
  );
};

export default CreditModal;
