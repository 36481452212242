import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    rootContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '95%',
        paddingTop: 15,
        paddingLeft: 30,
        paddingRight: 30,
    },
    rowContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    topContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    searchContainer: {
        display: 'flex',
        alignItems: 'center',
        width: 330,
        background: '#f0f3fa',
        borderRadius: 10,
    },
    input: {
        marginLeft: 10,
        marginRight: 10,
        flex: 1,
        fontSize: 11,
        fontWeight: 300,
        
    },
    iconButton: {
        padding: 10,
        color: '#0253cc',
    },
    divider: {
        height: 28,
        marginTop: 4,
        marginBottom: 4,
        color: 'rgba(26, 0, 172, 0.1)',
    },
    tableContainer: {
        marginTop: 22,
    },
    
    formElement: {
        width: '150px',
        marginRight: '20px',
    }
}));

export default useStyles;