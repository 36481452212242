import client from '../client';

export const postPaymentAgreements = (body, id) => {
  const url = `admin/credit-line/${id}/payment-agreements`;
  return new Promise((resolve, reject) => {
    client.post(url, body).then(
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      },
    );
  });
};

export const getPaymentAgreements = (credit_line_id) => {
  const url = `admin/credit-line/${credit_line_id}/payment-agreements`;
  return new Promise((resolve, reject) => {
    client.get(url).then(
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      },
    );
  });
};


