import * as ACTION from './payment.actionTypes';
import * as paymentService from '../services/payment.service';

export const postPaymentAgreements = (body, id) => async (dispatch) => {
  dispatch({
    type: ACTION.INIT_POST_PAYMENT_AGREEMENTS,
  });

  try {
    const postPaymentAgreementsData = await paymentService.postPaymentAgreements(body, id);
    if (postPaymentAgreementsData) {
      dispatch({
        type: ACTION.RESPONSE_POST_PAYMENT_AGREEMENTS,
        result: postPaymentAgreementsData,
      });
    }
  } catch (error) {
    dispatch({
      type: ACTION.ERROR_POST_PAYMENT_AGREEMENTS,
      error,
    });
  }
};

export const clearPaymentAgreements = () => async (dispatch) => {
  dispatch({
    type: ACTION.CLEAR_POST_PAYMENT_AGREEMENTS,
  });
};

export const getPaymentAgreements = (credit_line_id) => async (dispatch) => {
  dispatch({
    type: ACTION.INIT_GET_PAYMENT_AGREEMENTS,
  });

  try {
    const getPaymentAgreementsData = await paymentService.getPaymentAgreements(credit_line_id);
    if (getPaymentAgreementsData) {
      dispatch({
        type: ACTION.RESPONSE_GET_PAYMENT_AGREEMENTS,
        result: getPaymentAgreementsData,
      });
    }
  } catch (error) {
    dispatch({
      type: ACTION.ERROR_GET_PAYMENT_AGREEMENTS,
      error,
    });
  }
};

export const clearGetPaymentAgreements = () => async (dispatch) => {
  dispatch({
    type: ACTION.CLEAR_GET_PAYMENT_AGREEMENTS,
  });
};

export const clearErrors = () => async (dispatch) => {
  dispatch({
    type: ACTION.CLEAR_ERRORS,
  });
};
