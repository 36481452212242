import React, { useEffect, useState } from 'react';
import {
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from './OrderedTableHead.style';
import Text from '../../Text';
import { getRegisters } from '../../../containers/Registers/registersActions';
import * as ACTIONS from '../../../containers/Registers/actionTypes';

// TODO: Remove implementation to generalize component

const headCells = [
  {
    id: 'register_date', numeric: false, disablePadding: true, label: 'Fecha y hora\nde registro',
  },
  {
    id: 'profile', numeric: true, disablePadding: false, label: 'Perfil',
  },
  {
    id: 'name', numeric: true, disablePadding: false, label: 'Nombre de cliente /\nNombre de Tendero',
  },
  {
    id: 'phone', numeric: true, disablePadding: false, label: 'Teléfono',
  },
  {
    id: 'last_step_done', numeric: true, disablePadding: false, label: 'Fecha y hora de\núltimo paso',
  },
  {
    id: 'step', numeric: true, disablePadding: false, label: 'Paso de registro',
  },
  {
    id: 'credit_validation', numeric: true, disablePadding: false, label: 'Status\nCrédito',
  },
  {
    id: 'detail', numeric: true, disablePadding: false, label: 'Ver detalle',
  },
  {
    id: 'actions', numeric: true, disablePadding: false, label: 'Acciones',
  },
];

export const OrderedTableHead = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { page, orderBy, sort } = useSelector((state) => state.registersState);

  useEffect(() => {
    dispatch(getRegisters(page));
  }, [orderBy, sort]);

  return (
    <TableHead className={classes.tableHead}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            align="center"
            scope="col"
            sort={orderBy === headCell.id ? sort : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? sort : 'asc'}

              onClick={() => {
                if (orderBy === headCell.id) {
                  dispatch({
                    type: ACTIONS.SET_STATE,
                    label: 'sort',
                    value: (sort === 'asc' ? 'desc' : 'asc'),
                  });
                  dispatch({
                    type: ACTIONS.SET_STATE,
                    label: 'page',
                    value: 0,
                  });
                } else {
                  dispatch({
                    type: ACTIONS.SET_STATE,
                    label: 'orderBy',
                    value: headCell.id,
                  });
                  dispatch({
                    type: ACTIONS.SET_STATE,
                    label: 'page',
                    value: 0,
                  });
                }
              }}
            >
              <Text className={classes.titleLabel}>{headCell.label}</Text>
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default OrderedTableHead;
