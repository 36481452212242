import React from 'react';
import PhoneIcon from '@material-ui/icons/Phone';
import LocationOnSharpIcon from '@material-ui/icons/LocationOnSharp';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';

import Text from '../../../components/Text';
import TopMenuItem from './TopMenuItem';
import useStyles from './SubMenuPage.styles';
import defaultUser from '../../../assets/user_profile.png';
import { statusType } from '../../../constants/constants';

const TopBarDetail = ({ shokeeper_detail, credit_line_shop_detail, isExtension, shopData }) => {
    const classes = useStyles();
    const img = shokeeper_detail.shop?.BuildingPhotoURL || defaultUser;
    
    const colorStatus = shokeeper_detail.status !== 'CURRENT' ? '#ee4e52' : '#009805';
    const colorAllowDisbursment = shokeeper_detail.allow_disbursements ? '#009805' : '#ee4e52';
    
    const mapLoanPortfolioStatus = (s) => {
        const statusMap = {
            'LIGHT': 'Light',
            'NORMAL': 'Normal',
            'RESTRUCTURING': 'Restructura',
            'CHANGE_OF_CONDITIONS': 'Cambio de condiciones',
            'WRITTEN_OFF': 'Condonada',
        };
        return statusMap[s] || 'No identificado';
    };

    const renderLocationItem = () => {
        if (!isExtension) {
            return (
                <TopMenuItem
                    mainText={shokeeper_detail.address?.Delegation || '-'}
                    icon={<LocationOnSharpIcon fontSize='small' style={{ marginRight: '4px' }} />}
                    secondText={'Municipio'}
                    styleContainer={{ marginLeft: '20px' }}
                />
            );
        }
        return null;
    };

    return (
        <div className={classes.topBarDetail}>
            <div className={classes.containerLogo}>
                <img className={classes.userLogo} src={img} alt="shop_logo" />
                <TopMenuItem
                    mainText={!isExtension ? shokeeper_detail.shop?.PersonhoodName || '-' : shopData.shopkeeper_name}
                    icon={!isExtension && <PhoneIcon fontSize='small' style={{ marginRight: '4px' }} />}
                    secondText={!isExtension ? shokeeper_detail.owner_data?.PhoneNumber || '-' : null}
                />
            </div>
            {renderLocationItem()}
            <TopMenuItem
                mainText={!isExtension ? shokeeper_detail.owner_data?.FullName || '-' : shopData.shop_name}
                icon={<PhoneAndroidIcon fontSize='small' style={{ marginRight: '4px' }} />}
                secondText={!isExtension ? shokeeper_detail.shop?.BusinessPhoneNumber || '-' : shopData.shop_phone}
                styleContainer={{ marginLeft: '20px' }}
            />
            {!isExtension && (
                <TopMenuItem
                    mainText={credit_line_shop_detail.sales_advisor?.advisor_name || '-'}
                    rigthIcon={<PhoneAndroidIcon fontSize='small' style={{ marginRight: '4px', color: 'transparent' }} />}
                    secondText={'Asesor'}
                    styleContainer={{ marginLeft: '20px' }}
                />
            )}
            {!isExtension && (
                <TopMenuItem
                    mainText={credit_line_shop_detail.clabe ? credit_line_shop_detail.clabe : '-'}
                    rigthIcon={<PhoneAndroidIcon fontSize='small' style={{ marginRight: '4px', color: 'transparent' }} />}
                    secondText={'CLABE Pago a YoFio por STP'}
                    styleContainer={{ marginLeft: '20px' }}
                />
            )}
            {!isExtension && (
                <TopMenuItem
                    mainText={mapLoanPortfolioStatus(credit_line_shop_detail?.loan_portfolio_status)}
                    rigthIcon={<PhoneAndroidIcon fontSize='small' style={{ marginRight: '4px', color: 'transparent' }} />}
                    secondText={'Tipo cuenta'}
                    styleContainer={{ marginLeft: '20px' }}
                />
            )}
            {!isExtension && (
                <TopMenuItem
                    rigthIcon={<PhoneAndroidIcon fontSize='small' style={{ marginRight: '4px', color: 'transparent' }} />}
                    secondText={'Estatus'}
                    mainTextComponent={
                        <Text
                            className={classes.mainTextBar}
                            style={{ color: colorStatus }}
                        >
                            {statusType[credit_line_shop_detail.status]}
                        </Text>
                    }
                    styleContainer={{ marginLeft: '20px' }}
                />
            )}
            {!isExtension && (
                <TopMenuItem
                    rigthIcon={<PhoneAndroidIcon fontSize='small' style={{ marginRight: '4px', color: 'transparent' }} />}
                    secondText={'Bloqueo'}
                    mainTextComponent={
                        <Text
                            className={classes.mainTextBar}
                            style={{ color: colorAllowDisbursment }}
                        >
                            {shokeeper_detail.allow_disbursements ? 'Sin bloqueo' : 'Bloqueado'}
                        </Text>
                    }
                    styleContainer={{ marginLeft: '20px' }}
                />
            )}
        </div>
    );
};

export default TopBarDetail;
