import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import { Link } from 'react-router-dom';
import Icon from '@material-ui/core/Icon';
import usesStyles from './MenuItem.styles';
import Text from '../Text';
import StoreIcon from '../Icons/StoreIcon';

import userIconB from '../../assets/Menu/account-box-black-24-dp-b.png';
import tiendaIconA from '../../assets/Menu/storefront-black-24-dp-a.png';
import tiendaIconB from '../../assets/Menu/storefront-black-24-dp-b.png';
import registerIconA from '../../assets/Menu/how-to-reg-black-24-dp-a.png';
import registerIconB from '../../assets/Menu/how-to-reg-black-24-dp-b.png';
import clientIconA from '../../assets/Menu/face-black-24-dp-a.png';
import clientIconB from '../../assets/Menu/face-black-24-dp-b.png';
import liquidacionesIconA from '../../assets/Menu/monetization-on-black-24-dp-a.png';
import liquidacionesIconB from '../../assets/Menu/monetization-on-black-24-dp-b.png';
import cobrosIconA from '../../assets/Menu/price-check-black-24-dp-a.png';
import cobrosIconB from '../../assets/Menu/price-check-black-24-dp-b.png';
import creditoIconA from '../../assets/Menu/credit-score-black-24-dp-a.png';
import creditoIconB from '../../assets/Menu/credit-score-black-24-dp-b.png';
import tesoreriaIconA from '../../assets/Menu/request-quote-black-24-dp-a.png';
import tesoreriaIconB from '../../assets/Menu/request-quote-black-24-dp-b.png';
import deleteIconA from '../../assets/Menu/delete.svg';
import deleteIconB from '../../assets/Menu/delete_outline.svg';
import disbursementIconA from '../../assets/Menu/disbursement-24-dp-a.png';
import disbursementIconB from '../../assets/Menu/disbursement-24-dp-b.png';
import reportIconA from '../../assets/Menu/report-24-dp-a.png';
import reportIconB from '../../assets/Menu/report-24-dp-b.png';
import wholesalerIconA from '../../assets/Menu/wholesaler-24-dp-a.png';
import wholesalerIconB from '../../assets/Menu/wholesaler-24-dp-b.png';
import bureauIconA from '../../assets/Menu/bureau-24-dp-a.png';
import bureauIconB from '../../assets/Menu/bureau-24-dp-b.png';

export const MenuItem = (props) => {
  const classes = usesStyles();

  const iconComponent = () => {
    let srcIcon = '';
    switch (props.textItem) {
      case 'USUARIOS':
        srcIcon = userIconB;
        break;
      case 'TIENDAS':
        srcIcon = props.selected ? tiendaIconA : tiendaIconB;
        break;
      case 'BODEGAS':
        srcIcon = props.selected ? wholesalerIconA : wholesalerIconB;
        break;
      case 'Registro_Landing':
        srcIcon = props.selected ? registerIconA : registerIconB;
        break;
      case 'Registros':
        srcIcon = props.selected ? registerIconA : registerIconB;
        break;
      case 'Verificación_de_MATI':
        srcIcon = props.selected ? registerIconA : registerIconB;
        break;
      case 'Clientes':
        srcIcon = props.selected ? clientIconA : clientIconB;
        break;
      case 'Liquidaciones':
        srcIcon = props.selected ? liquidacionesIconA : liquidacionesIconB;
        break;
      case 'Cobros':
        srcIcon = props.selected ? cobrosIconA : cobrosIconB;
        break;
      case 'Crédito_a_Tienda':
        srcIcon = props.selected ? creditoIconA : creditoIconB;
        break;
      case 'Ampliación_Express':
        srcIcon = props.selected ? creditoIconA : creditoIconB;
        break;
      case 'Tesorería':
        srcIcon = props.selected ? tesoreriaIconA : tesoreriaIconB;
        break;
      case 'Pagos_a_Tienda':
        srcIcon = props.selected ? tesoreriaIconA : tesoreriaIconB;
        break;
      case 'Dar_de_baja':
        srcIcon = props.selected ? deleteIconA : deleteIconB;
        break;
      case 'Dispersion_manual':
        srcIcon = props.selected ? disbursementIconA : disbursementIconB;
        break;
      case 'Reportes':
        srcIcon = props.selected ? reportIconA : reportIconB;
        break;
      case 'Buro_crediticio':
        srcIcon = props.selected ? bureauIconA : bureauIconB;
        break;
      case 'Compra_distribuidor':
        srcIcon = props.selected ? tesoreriaIconA : tesoreriaIconB;
        break;
      case 'Tesorería_distribuidor':
        srcIcon = props.selected ? tesoreriaIconA : tesoreriaIconB;
        break;
        case 'Ampliaciones':
          srcIcon = props.selected ? tesoreriaIconA : tesoreriaIconB;
          break;
      case 'Registro':
        srcIcon = props.selected ? registerIconA : registerIconB;
        break;
      case 'Revisión_tickets': 
        srcIcon = props.selected ? tesoreriaIconA : tesoreriaIconB;
        break;
      default:
        srcIcon = '';
        break;
    }

    if (srcIcon === '') {
      return <StoreIcon className={props.selected ? classes.selectedIcon : classes.unselectedIcon} />
    } else {
      return <img width={25} height={25} src={srcIcon} alt="" />
    }
  }

  function _useLinkComponentInName() {
    if (props.children) {
      return (
        <Text className={`${classes.textStyled} ${props.selected ? classes.selectedText : classes.unselectedText}`}>{props.textItem}</Text>
      );
    }
    return (
      <Link
        className={classes.link}
        to={{
          pathname: props.route,
          query: { route: props.textItem },
        }}
      >
        <Text className={`${classes.textStyled} ${props.selected ? classes.selectedText : classes.unselectedText}`} primary="Drafts">{props.textItem}</Text>
      </Link>
    );
  }
  return (
    <Box onClick={props.onClick} className={classes.container}>
      <Box className={classes.iconConateiner}>
        {iconComponent()}
      </Box>
      <Box className={classes.textContainer} flexGrow={1}>
        {_useLinkComponentInName()}
      </Box>
      {props.children && (
        <Box className={classes.iconConateiner}>
          <Icon className={props.selected ? classes.selectedIcon : classes.unselectedIcon}>keyboard_arrow_down</Icon>
        </Box>
      )}
    </Box>
  );
};

MenuItem.defaultProps = {
  textItem: 'Dashboard',
};

export default MenuItem;
