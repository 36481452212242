import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  rootContainer: {},
  controls: {
    display: 'flex',
    position: 'relative',
    top: 0,
    width: '100%',
    marginTop: '22px',
    color: '#858d9c',
    alignItems: 'center',
  },
  customerInfo: {
    backgroundColor: '#f4f8ff',
  },
  mainPanel: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-start',
  },
  StepsInfoContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    width: '100%',
    margin: '2%',
  },
  stepArrowContainer: {
    marginLeft: -15,
  },
  stepArrowLabel: {
    color: 'white',
    display: 'flex',
    width: 150,
    marginLeft: 20,
    '& svg': {
      paddingLeft: 5,
      paddingRight: 5,
    },
    '& p': {
      fontWeight: 'bold',
      fontSize: 12,
      textAlign: 'center',
    },
  },
  stepArrowChild: {},
  validationContainer: {
    fontFamily: 'Poppins',
    display: 'block',
    fontSize: '.7rem',
    '& p': {
      marginTop: 5,
      marginBottom: 5,
    },
  },
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '95%',
    background: '#E8E8FC33',
    borderRadius: 2,
    //marginTop: 8,
  },
  inputSearch: {
    marginLeft: 9,
    marginTop: 7,
    marginBottom: 8,
    width: 460,
    flex: 1,
    fontSize: 10,
    fontWeight: 300,
  },
  input: {
    marginLeft: 9,
    marginTop: 7,
    marginBottom: 8,
    width: 260,
    flex: 1,
    fontSize: 14,
    fontWeight: 300,
  },
  input2: {
    marginLeft: 9,
    marginTop: 7,
    marginBottom: 8,
    width: '95%',
    flex: 1,
    fontSize: 14,
    fontWeight: 300,
  },
  iconButton: {
    padding: 10,
    color: '#0253cc',
  },
  divider: {
    height: 28,
    marginTop: 4,
    marginBottom: 4,
  },
  textBlue: {
    paddingTop: 10,
    paddingBottom: 10,
    color: '#0253cc'
  },
  buttonContainer: {
    paddingTop: 5,
    paddingBottom: 5,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    width: '100%',
    padding: 10,
  },
  formStyles: {
    display: 'flex',
    minWidth: 120,
    //marginLeft: '5%',
    //marginRight: '10%',
  },
  cardColoredContainer: {
    width: '500px',
    height: '150px',
    display: '-webkit-flex',
    webkitFlexDirection: 'row',
    display: 'flex',
    flexDirection: 'row',
  },
  cardColored: {
    width: '200px',
    height: '100px',
    margin: '5px 20px 1px 24px',
    padding: '25px 98px 20px',
    cursor: 'pointer',
    borderRadius: '10px',
    backgroundColor: '#f6f9fc',
    color: 'rgba(48, 80, 255, 0.93)',
    '&:hover': {
      backgroundColor: '#3050ff',
      color: 'rgba(255, 255, 255, 0.93)',
    }
  },
  selectionText: {
    width: '94px',
    height: '70px',
    flexGrow: '0',
    fontFamily: 'Poppins',
    fontSize: '18px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
  },
  questionText: {
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.3px',
    textAlign: 'left',
    color: '#202122',
    paddingLeft: '10px',
  },
  sectionText: {
    fontFamily: 'Poppins',
    fontSize: '17px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.3px',
    textAlign: 'left',
    color: '#202122',
    paddingBottom: '10px',
    paddingLeft: '10px',
    textDecoration: 'underline',
  },
  checkElement: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
  },
  headquarterOption: {
    height: '60px',
    flexGrow: '0',
    margin: '0px 0px 0 10px',
    padding: '0 22px 0 20px',
    border: 'solid 1px #d6dde3',
    backgroundColor: '#fafcfe',
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
  }
}));

export default useStyles;
