import * as ACTION from './business.actionTypes';
import * as businessService from '../services/business.service';

export const getBusinessType = () => async (dispatch) => {
    dispatch({
        type: ACTION.INIT_GET_BUSINESS_TYPE,
    });

    try {
        const businessTypeInfo = await businessService.getBusinessType();
        if (businessTypeInfo) {
            dispatch({
                type: ACTION.RESPONSE_GET_BUSINESS_TYPE,
                result: businessTypeInfo,
            });
        }
    } catch (error) {
        dispatch({
            type: ACTION.ERROR_GET_BUSINESS_TYPE,
            error,
        });
    }
};

export const clearBusinessType = () => async (dispatch) => {
    dispatch({
        type: ACTION.CLEAR_GET_BUSINESS_TYPE,
    });
};

export const putUpdateBusinessType = (userType,userId, body) => async (dispatch) => {
    dispatch({
        type: ACTION.INIT_PUT_UPDATE_BUSINESS_TYPE,
    });

    try {
        const updateBusinessData = await businessService.putUpdateBusinessType(userType, userId, body);
        if (updateBusinessData) {
            dispatch({
                type: ACTION.RESPONSE_PUT_UPDATE_BUSINESS_TYPE,
                result: updateBusinessData,
            });
        }
    } catch (error) {
        dispatch({
            type: ACTION.ERROR_PUT_UPDATE_BUSINESS_TYPE,
            error,
        });
    }
};

export const clearUpdateBusinessType = () => async (dispatch) => {
    dispatch({
        type: ACTION.CLEAR_PUT_UPDATE_BUSINESS_TYPE,
    });
};

export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: ACTION.CLEAR_ERRORS,
    });
};