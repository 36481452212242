import { firebaseService } from '../../services/firebase.service';
import * as ACTION from './actionTypes';

export const login = () => (dispatch, getState) => new Promise(((resolve, reject) => {

  dispatch({ type: ACTION.LOGIN_INIT });
  firebaseService.getAuthToken().then(({ user, token }) => {
    localStorage.setItem('firebaseToken', token);
    dispatch({ type: ACTION.LOGIN_SUCCESS, content: { user, token } });
    resolve();
  }).catch((error) => {
    dispatch({ type: ACTION.LOGIN_FAILURE });
    console.log(error);
    reject(error);
  });
}));

export const logout = () => (dispatch, getState) => {
};
