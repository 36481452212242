import React, {useState, useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';

import useStyles from "./CardVouchers.styles";
import CardVouchersUI from "./CardVouchers";
import { getSuppliers } from "../../reducers/dealer.action";

function CardVouchers({
    data, 
    setZoomModal, 
    purchaseId,
    creditLineId,
    accountId
}) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const [ values, setValues ] = useState({
        dealer: '',
    });
    const [ dealerList, setDealerList ] = useState([]);

    const {
        suppliersData
    } = useSelector((state) => state.supplierReducer);
    
    useEffect(() => {
        dispatch(getSuppliers())
    }, []);

    useEffect(() => {
        if(Object.keys(suppliersData).length !== 0 && suppliersData?.data) {
            let provitionalList = [];
            suppliersData.data.categories.map((categorie) => {
                if(categorie?.suppliers) {
                    categorie.suppliers.map((supplier) => {
                        provitionalList.push(supplier)
                    })
                }
            })
            setDealerList(provitionalList);
        }
    }, [suppliersData]);
    
    function formatCurrency(amount) {
        if (amount == null) {
            return '$0.00';
        }
        return `$${Number(amount).toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
    }

    const transformMetadata = (metadata, type) => {
        try {
            const transformMetadata = metadata ? JSON.parse(metadata) : null;
            if (transformMetadata && type === "total_amount") {
                return transformMetadata?.Total_Amount === ("undefined" || "null") ? "Sin definir" : formatCurrency(transformMetadata.Total_Amount);
            } else {
                return transformMetadata?.Date === ("undefined" || "null") ? "Sin definir" : transformMetadata.Date;
            }
        } catch (error) {
            console.error('Failed to parse metadata', error);
        }
    };

    const handleChangeInput = (prop) => (event) => {
        let { value } = event.target;
        setValues({ ...values, [prop]: value });
    };

    const viewDetail = (voucherId) => {
        history.replace(`/buy-dealer/detail/${creditLineId}/${accountId}/${purchaseId}/${voucherId}/${values.dealer}/metadata-dealer`);
    };

    return <CardVouchersUI
        classes={classes}
        data={data}
        values={values}
        handleChangeInput={handleChangeInput}
        setZoomModal={setZoomModal}
        dealerList={dealerList}
        viewDetail={viewDetail}
        transformMetadata={transformMetadata}
    />
}

export default CardVouchers;