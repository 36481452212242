import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Box
} from '@material-ui/core';

import useStyles from '../SubMenuPage.styles';
import Text from '../../../../components/Text';
import { getSettlementsForCollections } from '../../../Shops/ShopsPage.actions';
import CollectionTable from './CollectionTable';

const CollectionPage = ({ shopId }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [nothingFoundNoticeShown, showNothingFoundNotice] = useState(false);


    const {
        isFetchingShops,
        settlementsPositive,
        settlementsNegative,
        error
    } = useSelector((state) => state.collectionReducer);
    const {
        isFetchinShokeeperDetail,
        shokeeper_detail,
    } = useSelector((state) => state.shopState);

    useEffect(() => {
        dispatch(getSettlementsForCollections(shopId))
    }, [])

    return (
        <>
            <Text className={classes.titleInformationCreditTable} style={{ marginTop: 0 }}>Cortes y liquidaciones</Text>
            <Box className={classes.tableContainer} style={{ height: '50%' }}>
                <CollectionTable
                    resourceType={'settlements'}
                    shokeeper_detail={ shokeeper_detail }
                    settlements={ settlementsPositive }
                    shopId={ shopId }
                />
            </Box>
            <Text className={classes.titleInformationCreditTable}>Recaudaciones</Text>
            <Box className={classes.tableContainer} style={{ height: '50%' }}>
                <CollectionTable
                    resourceType={'collections'}
                    shokeeper_detail={ shokeeper_detail }
                    settlements={ settlementsNegative }
                    shopId={ shopId }
                />
            </Box>
        </>
    )
}

export default CollectionPage;