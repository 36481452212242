export const INIT_GET_SUPPLIER = 'SUPPLIER::INIT_GET_SUPPLIER';
export const RESPONSE_GET_SUPPLIER = 'SUPPLIER::RESPONSE_GET_SUPPLIER';
export const ERROR_GET_SUPPLIER = 'SUPPLIER::ERROR_GET_SUPPLIER';
export const CLEAR_GET_SUPPLIER = 'SUPPLIER::CLEAR_GET_SUPPLIER';

export const INIT_GET_SUPPLIER_REJECT_REASON = 'SUPPLIER::INIT_GET_SUPPLIER_REJECT_REASON';
export const RESPONSE_GET_SUPPLIER_REJECT_REASON = 'SUPPLIER::RESPONSE_GET_SUPPLIER_REJECT_REASON';
export const ERROR_GET_SUPPLIER_REJECT_REASON = 'SUPPLIER::ERROR_GET_SUPPLIER_REJECT_REASON';
export const CLEAR_GET_SUPPLIER_REJECT_REASON = 'SUPPLIER::CLEAR_GET_SUPPLIER_REJECT_REASON';

export const INIT_PUT_SUPPLIER_APROVE = 'SUPPLIER::INIT_PUT_SUPPLIER_APROVE';
export const RESPONSE_PUT_SUPPLIER_APROVE = 'SUPPLIER::RESPONSE_PUT_SUPPLIER_APROVE';
export const ERROR_PUT_SUPPLIER_APROVE = 'SUPPLIER::ERROR_PUT_SUPPLIER_APROVE';
export const CLEAR_PUT_SUPPLIER_APROVE = 'SUPPLIER::CLEAR_PUT_SUPPLIER_APROVE';

export const INIT_PUT_SUPPLIER_REJECT = 'SUPPLIER::INIT_PUT_SUPPLIER_REJECT';
export const RESPONSE_PUT_SUPPLIER_REJECT = 'SUPPLIER::RESPONSE_PUT_SUPPLIER_REJECT';
export const ERROR_PUT_SUPPLIER_REJECT = 'SUPPLIER::ERROR_PUT_SUPPLIER_REJECT';
export const CLEAR_PUT_SUPPLIER_REJECT = 'SUPPLIER::CLEAR_PUT_SUPPLIER_REJECT';

export const INIT_GET_SUPPLIERS = 'SUPPLIER::INIT_GET_SUPPLIERS';
export const RESPONSE_GET_SUPPLIERS = 'SUPPLIER::RESPONSE_GET_SUPPLIERS';
export const ERROR_GET_SUPPLIERS = 'SUPPLIER::ERROR_GET_SUPPLIERS';
export const CLEAR_GET_SUPPLIERS = 'SUPPLIER::CLEAR_GET_SUPPLIERS';

export const INIT_PUT_SUPPLIERS_DISBURSEMENT = 'SUPPLIER::INIT_PUT_SUPPLIERS_DISBURSEMENT';
export const RESPONSE_PUT_SUPPLIERS_DISBURSEMENT = 'SUPPLIER::RESPONSE_PUT_SUPPLIERS_DISBURSEMENT';
export const ERROR_PUT_SUPPLIERS_DISBURSEMENT = 'SUPPLIER::ERROR_PUT_SUPPLIERS_DISBURSEMENT';
export const CLEAR_PUT_SUPPLIERS_DISBURSEMENT = 'SUPPLIER::CLEAR_PUT_SUPPLIERS_DISBURSEMENT';

export const INIT_POST_SEND_TRANSFER = 'SUPPLIER::INIT_POST_SEND_TRANSFER';
export const RESPONSE_POST_SEND_TRANSFER = 'SUPPLIER::RESPONSE_POST_SEND_TRANSFER';
export const ERROR_POST_SEND_TRANSFER = 'SUPPLIER::ERROR_POST_SEND_TRANSFER';
export const CLEAR_POST_SEND_TRANSFER = 'SUPPLIER::CLEAR_POST_SEND_TRANSFER';

export const CLEAR_ERRORS = 'SUPPLIER::CLEAR_ERRORS';