import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getExtension } from "../../../../reducers/creditline.action";

import ExtensionUI from "./CreditExtension";
import useStyles from "./styles";

function Extension({creditLineId, dataIds}) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [dataAllExtension, setDataAllExtension] = useState([]);
    const [loading, setLoading] = useState(true);

    const { dataGetExtension } = useSelector((state) => state.creditLineReducer);

    useEffect(() => {
        setLoading(true);
        setDataAllExtension([]);
        
        if(Object.keys(dataIds).length !== 0) {
            dataIds.forEach((dataId) => {
                dispatch(getExtension(dataId?.credit_line_id));
            })
        } else {
            setLoading(false);
        }
    }, [dataIds, dispatch]);

    useEffect(() => {
        if (Object.keys(dataGetExtension).length !== 0 && dataGetExtension?.data) {
            setDataAllExtension(prevExtensions => {
                const newExtensions = dataGetExtension.data.filter(
                    newExt => !prevExtensions.some(prevExt => prevExt.id === newExt.id)
                );
                
                const updatedExtension = [...prevExtensions, ...newExtensions];
    
                return updatedExtension.sort((a, b) => 
                    new Date(b.executed_at) - new Date(a.executed_at)
                );
            });
            setLoading(false);
        }
    }, [dataGetExtension]);
    
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return <ExtensionUI
        classes={classes}
        page={page}
        rowsPerPage={rowsPerPage}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        dataAllExtension={dataAllExtension}
        loading={loading}
    />
};

export default Extension;