
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  customerReferenceInfoContainer: {
    margin: '3%',
    marginTop:'3%',
    display: 'flex',
    flexDirection: 'column',
  },
}));

export default useStyles;
