export const copyTextToClipboard = (textToCopy) => {
    // Crear un elemento de texto en el DOM
    const tempInput = document.createElement("input");
    tempInput.style = "position: absolute; left: -1000px; top: -1000px";
    tempInput.value = textToCopy;
    document.body.appendChild(tempInput);

    // Seleccionar y copiar el texto
    tempInput.select();
    document.execCommand("copy");

    // Eliminar el elemento temporal y cambiar el estado de copied a true
    document.body.removeChild(tempInput);
};