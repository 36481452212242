import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  CustomerStepContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    height: '90%',
  },
  buttonBox: {
    padding: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    height: '80%',
  },
  textBlue: {
    color: '#0253cc'
  },
  text: {
    paddingTop: 10,
    paddingBottom: 20,
    fontSize: 'medium',
    lineHeight: 1.5,
  },
}));

export default useStyles;
