import * as ACTION from "./notifications.actionTypes";

const whatsappMessageData = {
  loading: {
    POST_WHATSAPP_MESSAGE: false,
  },
  error: {
    POST_WHATSAPP_MESSAGE: null,
  },
  whatsappMessage: {},
};

const notificationsReducer = (state = whatsappMessageData, action) => {
  switch (action.type) {
    case ACTION.INIT_POST_WHATSAPP_MESSAGE:
      return {
        ...state,
        loading: { ...state.loading, POST_WHATSAPP_MESSAGE: true },
        error: { ...state.error, POST_WHATSAPP_MESSAGE: null },
      };
    case ACTION.RESPONSE_POST_WHATSAPP_MESSAGE:
      return {
        ...state,
        loading: { ...state.loading, POST_WHATSAPP_MESSAGE: false },
        error: { ...state.error, POST_WHATSAPP_MESSAGE: null },
        whatsappMessage: action.result,
      };
    case ACTION.ERROR_POST_WHATSAPP_MESSAGE:
      return {
        ...state,
        loading: { ...state.loading, POST_WHATSAPP_MESSAGE: false },
        error: { ...state.error, POST_WHATSAPP_MESSAGE: action.error },
      };
    case ACTION.CLEAR_POST_WHATSAPP_MESSAGE:
      return { ...state, whatsappMessage: {} };
    case ACTION.CLEAR_ERRORS:
      return { ...state, error: whatsappMessageData.error };
    default:
      return state;
  }
};

export default notificationsReducer;
