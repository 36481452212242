import * as ACTION from './catalog.actionTypes';

const dataCatalog = {
  loading: {
    GET_VEREDICT_REASONS_CATALOG: false,
    GET_REVIEW_REQUEST_PROPERTIES_CATALOG: false,
    GET_INVENTORY_REASONS_CATALOG: false,
    GET_REJECT_REASONS_CATALOG: false,
  },
  error: {
    GET_VEREDICT_REASONS_CATALOG: null,
    GET_REVIEW_REQUEST_PROPERTIES_CATALOG: null,
    GET_INVENTORY_REASONS_CATALOG: null,
    GET_REJECT_REASONS_CATALOG: null,
  },
  veredictReasonsCatalog: {},
  reviewRequestPropertiesCatalog: {},
  inventoryReasonsCatalog: {},
  rejectReasonsCatalog: {},
};

const catalogReducer = (state = dataCatalog, action) => {
  switch (action.type) {
    case ACTION.INIT_GET_VEREDICT_REASONS_CATALOG:
      return { ...state, loading: { ...state.loading, GET_VEREDICT_REASONS_CATALOG: true }, error: { ...state.error, GET_VEREDICT_REASONS_CATALOG: null } };
    case ACTION.RESPONSE_GET_VEREDICT_REASONS_CATALOG:
      return {
        ...state, loading: { ...state.loading, GET_VEREDICT_REASONS_CATALOG: false }, error: { ...state.error, GET_VEREDICT_REASONS_CATALOG: null }, veredictReasonsCatalog: action.result,
      };
    case ACTION.ERROR_GET_VEREDICT_REASONS_CATALOG:
      return { ...state, loading: { ...state.loading, GET_VEREDICT_REASONS_CATALOG: false }, error: { ...state.error, GET_VEREDICT_REASONS_CATALOG: action.error } };
    case ACTION.CLEAR_GET_VEREDICT_REASONS_CATALOG:
      return { ...state, veredictReasonsCatalog: {} };

    case ACTION.INIT_GET_REVIEW_REQUEST_PROPERTIES_CATALOG:
      return { ...state, loading: { ...state.loading, GET_REVIEW_REQUEST_PROPERTIES_CATALOG: true }, error: { ...state.error, GET_REVIEW_REQUEST_PROPERTIES_CATALOG: null } };
    case ACTION.RESPONSE_GET_REVIEW_REQUEST_PROPERTIES_CATALOG:
      return {
        ...state, loading: { ...state.loading, GET_REVIEW_REQUEST_PROPERTIES_CATALOG: false }, error: { ...state.error, GET_REVIEW_REQUEST_PROPERTIES_CATALOG: null }, reviewRequestPropertiesCatalog: action.result,
      };
    case ACTION.ERROR_GET_REVIEW_REQUEST_PROPERTIES_CATALOG:
      return { ...state, loading: { ...state.loading, GET_REVIEW_REQUEST_PROPERTIES_CATALOG: false }, error: { ...state.error, GET_REVIEW_REQUEST_PROPERTIES_CATALOG: action.error } };
    case ACTION.CLEAR_GET_REVIEW_REQUEST_PROPERTIES_CATALOG:
      return { ...state, reviewRequestPropertiesCatalog: {} };

    case ACTION.INIT_GET_INVENTORY_REASONS_CATALOG:
      return { ...state, loading: { ...state.loading, GET_INVENTORY_REASONS_CATALOG: true }, error: { ...state.error, GET_INVENTORY_REASONS_CATALOG: null } };
    case ACTION.RESPONSE_GET_INVENTORY_REASONS_CATALOG:
      return {
        ...state, loading: { ...state.loading, GET_INVENTORY_REASONS_CATALOG: false }, error: { ...state.error, GET_INVENTORY_REASONS_CATALOG: null }, inventoryReasonsCatalog: action.result,
      };
    case ACTION.ERROR_GET_INVENTORY_REASONS_CATALOG:
      return { ...state, loading: { ...state.loading, GET_INVENTORY_REASONS_CATALOG: false }, error: { ...state.error, GET_INVENTORY_REASONS_CATALOG: action.error } };
    case ACTION.CLEAR_GET_INVENTORY_REASONS_CATALOG:
      return { ...state, inventoryReasonsCatalog: {} };

    case ACTION.INIT_GET_REJECT_REASONS_CATALOG:
      return { ...state, loading: { ...state.loading, GET_REJECT_REASONS_CATALOG: true }, error: { ...state.error, GET_REJECT_REASONS_CATALOG: null } };
    case ACTION.RESPONSE_GET_REJECT_REASONS_CATALOG:
      return {
        ...state, loading: { ...state.loading, GET_INVENTORY_REASONS_CATALOG: false }, error: { ...state.error, GET_REJECT_REASONS_CATALOG: null }, rejectReasonsCatalog: action.result,
      };
    case ACTION.ERROR_GET_REJECT_REASONS_CATALOG:
      return { ...state, loading: { ...state.loading, GET_REJECT_REASONS_CATALOG: false }, error: { ...state.error, GET_REJECT_REASONS_CATALOG: action.error } };
    case ACTION.CLEAR_GET_REJECT_REASONS_CATALOG:
      return { ...state, rejectReasonsCatalog: {} };
    case ACTION.CLEAR_ERRORS:
      return { ...state, error: dataCatalog.error };
    default:
      return state;
  }
};

export default catalogReducer;
