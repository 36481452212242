import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  rootContainer: {},
  controls: {
    display: 'flex',
    position: 'relative',
    top: 0,
    width: '100%',
    marginTop: '22px',
    color: '#858d9c',
    alignItems: 'center',
  },
  customerInfo: {
    backgroundColor: '#f4f8ff',
  },
  mainPanel: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-start',
  },
  StepsInfoContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    width: '100%',
    margin: '2%',
  },
  stepArrowContainer: {
    marginLeft: -15,
  },
  stepArrowLabel: {
    color: 'white',
    display: 'flex',
    width: 150,
    marginLeft: 20,
    '& svg': {
      paddingLeft: 5,
      paddingRight: 5,
    },
    '& p': {
      fontWeight: 'bold',
      fontSize: 12,
      textAlign: 'center',
    },
  },
  stepArrowChild: {},
  validationContainer: {
    fontFamily: 'Poppins',
    display: 'block',
    fontSize: '.7rem',
    '& p': {
      marginTop: 5,
      marginBottom: 5,
    },
  },
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '35%',
    background: '#E8E8FC33',
    borderRadius: 2,
    //marginTop: 8,
  },
 inputSearch: {
    marginLeft: 9,
    marginTop: 7,
    marginBottom: 8,
    width: 460,
    flex: 1,
    fontSize: 10,
    fontWeight: 300,
  },
 input: {
    marginLeft: 9,
    marginTop: 7,
    marginBottom: 8,
    width: 260,
    flex: 1,
    fontSize: 10,
    fontWeight: 300,
  },
  iconButton: {
    padding: 10,
    color: '#0253cc',
  },
  divider: {
    height: 28,
    marginTop: 4,
    marginBottom: 4,
  },
  textBlue: {
    paddingTop: 10,
    paddingBottom: 10,
    color: '#0253cc'
  },
  buttonContainer: {
    paddingTop: 5,
    paddingBottom: 5,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    width: '100%',
    padding: 10,
  },
  formStyles: {
    display: 'flex',
    minWidth: 120,
    //marginLeft: '5%',
    //marginRight: '10%',
  },
}));

export default useStyles;
