import React, { useState } from "react";
import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  TablePagination,
} from "@material-ui/core";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import useStyles from "../SubMenuPage.styles";
import Text from "../../../../components/Text";
import ArrowedText from "../../../../components/ArrowedText";
import TablePaginationActions from "../../../../components/Table/TablePaginationActions";
import CreditInformtionTableHead from "./CreditInformtionTableHead";
import Button from "../../../../components/Button";
import { Box } from "../../../../components/Layout";
import { utcSimpleDateFunction, simpleDateFunction } from '../../../Shops/dateFunction';
import { statusType, colorStatus } from '../../../../constants/constants';

export const CreditInformationTable = (props) => {
  const classes = useStyles();
  const credit_line_shop_detail = props.credit_line_shop_detail;

  return (
    <Box>
      <TableContainer className={classes.tableContainerPaginator}>
        <Table aria-label="custom pagination table">
          <CreditInformtionTableHead />
          <TableBody>
            { credit_line_shop_detail &&
              <>
                {
                  credit_line_shop_detail.map((installment, index) => (
                    <TableRow key={index} style={{ maxHeight: 43 }}>
                      <TableCell>
                        <Text className={classes.textStyle}>
                          { installment?.deadline_date ? utcSimpleDateFunction(installment.deadline_date) : '-' }
                        </Text>
                      </TableCell>
                      <TableCell>
                        <Text className={classes.textStyle}>
                          ${ installment?.installment || '0.00' }
                        </Text>
                      </TableCell>
                      <TableCell>
                        <Text className={classes.textStyle}>
                          ${ installment?.principal || '0.00' }
                        </Text>
                      </TableCell>
                      <TableCell>
                        <Text className={classes.textStyle}>
                          ${ installment?.interest || '0.00' }
                        </Text>
                      </TableCell>
                      <TableCell>
                        <Text className={classes.textStyle}>
                          ${ installment?.late_interest || '0.00' }
                        </Text>
                      </TableCell>
                      <TableCell>
                        <Text className={classes.textStyle}>
                          ${ installment?.vat || '0.00' }
                        </Text>
                      </TableCell>
                      <TableCell>
                        <Text className={classes.textStyle}>
                          ${ installment?.late_vat || '0.00' }
                        </Text>
                      </TableCell>
                      <TableCell>
                        <Text className={classes.textStyle} style={{ textAlign: 'center', color: colorStatus[installment.status] }}>
                            {statusType[installment.status]}
                        </Text>
                      </TableCell>
                      <TableCell>
                        <Text className={classes.textStyle}>
                          { installment?.paid_at ? simpleDateFunction(installment.paid_at) : '-' }
                        </Text>
                      </TableCell>
                      <TableCell>
                        <Text className={classes.textStyle}>
                          ${ installment?.remaining_principal || '0.00' }
                        </Text>
                      </TableCell>
                      <TableCell>
                        <Text className={classes.textStyle}>
                          ${ installment?.remaining_interest || '0.00' }
                        </Text>
                      </TableCell>
                      <TableCell>
                        <Text className={classes.textStyle}>
                          ${ installment?.remaining_late_interest || '0.00' }
                        </Text>
                      </TableCell>
                      <TableCell>
                        <Text className={classes.textStyle}>
                          ${ installment?.insurance_amount || '0.00' }
                        </Text>
                      </TableCell>
                      <TableCell>
                        <Text className={classes.textStyle}>
                          ${ installment?.remaining_insurance_amount || '0.00' }
                        </Text>
                      </TableCell>
                      <TableCell>
                        <Text className={classes.textStyle}>
                          ${ installment?.remaining_vat || '0.00' }
                        </Text>
                      </TableCell>
                      <TableCell>
                        <Text className={classes.textStyle}>
                          ${ installment?.remaining_late_vat || '0.00' }
                        </Text>
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              }
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

CreditInformationTable.propTypes = {};

export default CreditInformationTable;