import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  container: {
    marginTop: 25,
    display: 'flex',
    alignItems: 'center',
    justify: 'center',
  },
  unselectedIcon: {
    color: '#858d9c'
  },
  selectedIcon: {
    color: '#0253cc'
  },
  textStyled: {
    fontSize: 16,
    letterSpacing: -0.2,
  },
  unselectedText: {
    color: '#858d9c',
  },
  selectedText: {
    color: '#0253cc'
  },
  iconConateiner: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: 20,
    minWidth: 20,
    maxHeight: 20,
    minHeight: 20,
  },
  textContainer: {
    marginLeft: 20
  },
  link: {
    textDecoration: 'none',
  }
}));

export default useStyles;
