import * as ACTION from './dealer.actionTypes';

const dataSupplier = {
    loading: {
        GET_SUPPLIER: false,
        GET_SUPPLIER_REJECT_REASON: false,
        PUT_SUPPLIER_APROVE: false,
        PUT_SUPPLIER_REJECT: false,
        GET_SUPPLIERS: false,
        PUT_SUPPLIER_DISBURSEMENT: false,
    },
    error: {
        GET_SUPPLIER: null,
        GET_SUPPLIER_REJECT_REASON: null,
        PUT_SUPPLIER_APROVE: null,
        PUT_SUPPLIER_REJECT: null,
        GET_SUPPLIERS: null,
        PUT_SUPPLIER_DISBURSEMENT: null,
    },

    supplierData: {},
    supplierRejectReasonData: {},
    supplierAproveData: {},
    supplierRejectData: {},
    suppliersData: {},
    supplierDisbursementData: {}
};

const supplierReducer = (state= dataSupplier, action) => {
    switch (action.type) {
        case ACTION.INIT_GET_SUPPLIER:
            return { ...state, loading: { ...state.loading, GET_SUPPLIER: true }, error: { ...state.error, GET_SUPPLIER: null} };
        case ACTION.RESPONSE_GET_SUPPLIER:
            return { ...state, loading: { ...state.loading, GET_SUPPLIER: false }, error: { ...state.error, GET_SUPPLIER: null}, supplierData: action.result };
        case ACTION.ERROR_GET_SUPPLIER:
            return { ...state, loading: { ...state.loading, GET_SUPPLIER: false }, error: { ...state.error, GET_SUPPLIER: action.error} };
        case ACTION.CLEAR_GET_SUPPLIER:
            return { ...state, supplierData: {} };

        case ACTION.INIT_GET_SUPPLIER_REJECT_REASON:
            return { ...state, loading: { ...state.loading, GET_SUPPLIER_REJECT_REASON: true }, error: { ...state.error, GET_SUPPLIER_REJECT_REASON: null} };
        case ACTION.RESPONSE_GET_SUPPLIER_REJECT_REASON:
            return { ...state, loading: { ...state.loading, GET_SUPPLIER_REJECT_REASON: false }, error: { ...state.error, GET_SUPPLIER_REJECT_REASON: null}, supplierRejectReasonData: action.result };
        case ACTION.ERROR_GET_SUPPLIER_REJECT_REASON:
            return { ...state, loading: { ...state.loading, GET_SUPPLIER_REJECT_REASON: false }, error: { ...state.error, GET_SUPPLIER_REJECT_REASON: action.error} };
        case ACTION.CLEAR_GET_SUPPLIER_REJECT_REASON:
            return { ...state, supplierRejectReasonData: {} };

        case ACTION.INIT_PUT_SUPPLIER_APROVE:
            return { ...state, loading: { ...state.loading, PUT_SUPPLIER_APROVE: true }, error: { ...state.error, PUT_SUPPLIER_APROVE: null} };
        case ACTION.RESPONSE_PUT_SUPPLIER_APROVE:
            return { ...state, loading: { ...state.loading, PUT_SUPPLIER_APROVE: false }, error: { ...state.error, PUT_SUPPLIER_APROVE: null}, supplierAproveData: action.result };
        case ACTION.ERROR_PUT_SUPPLIER_APROVE:
            return { ...state, loading: { ...state.loading, PUT_SUPPLIER_APROVE: false }, error: { ...state.error, PUT_SUPPLIER_APROVE: action.error} };
        case ACTION.CLEAR_PUT_SUPPLIER_APROVE:
            return { ...state, supplierAproveData: {} };
        
        case ACTION.INIT_PUT_SUPPLIER_REJECT:
            return { ...state, loading: { ...state.loading, PUT_SUPPLIER_REJECT: true }, error: { ...state.error, PUT_SUPPLIER_REJECT: null} };
        case ACTION.RESPONSE_PUT_SUPPLIER_REJECT:
            return { ...state, loading: { ...state.loading, PUT_SUPPLIER_REJECT: false }, error: { ...state.error, PUT_SUPPLIER_REJECT: null}, supplierRejectData: action.result };
        case ACTION.ERROR_PUT_SUPPLIER_REJECT:
            return { ...state, loading: { ...state.loading, PUT_SUPPLIER_REJECT: false }, error: { ...state.error, PUT_SUPPLIER_REJECT: action.error} };
        case ACTION.CLEAR_PUT_SUPPLIER_REJECT:
            return { ...state, supplierRejectData: {} };

        case ACTION.INIT_GET_SUPPLIERS:
            return { ...state, loading: { ...state.loading, GET_SUPPLIERS: true }, error: { ...state.error, GET_SUPPLIERS: null} };
        case ACTION.RESPONSE_GET_SUPPLIERS:
            return { ...state, loading: { ...state.loading, GET_SUPPLIERS: false }, error: { ...state.error, GET_SUPPLIERS: null}, suppliersData: action.result };
        case ACTION.ERROR_GET_SUPPLIERS:
            return { ...state, loading: { ...state.loading, GET_SUPPLIERS: false }, error: { ...state.error, GET_SUPPLIERS: action.error} };
        case ACTION.CLEAR_GET_SUPPLIERS:
            return { ...state, suppliersData: {} };

        case ACTION.INIT_PUT_SUPPLIERS_DISBURSEMENT:
            return { ...state, loading: { ...state.loading, PUT_SUPPLIER_DISBURSEMENT: true }, error: { ...state.error, PUT_SUPPLIER_DISBURSEMENT: null} };
        case ACTION.RESPONSE_PUT_SUPPLIERS_DISBURSEMENT:
            return { ...state, loading: { ...state.loading, PUT_SUPPLIER_DISBURSEMENT: false }, error: { ...state.error, PUT_SUPPLIER_DISBURSEMENT: null}, supplierDisbursementData: action.result };
        case ACTION.ERROR_PUT_SUPPLIERS_DISBURSEMENT:
            return { ...state, loading: { ...state.loading, PUT_SUPPLIER_DISBURSEMENT: false }, error: { ...state.error, PUT_SUPPLIER_DISBURSEMENT: action.error} };
        case ACTION.CLEAR_PUT_SUPPLIERS_DISBURSEMENT:
            return { ...state, supplierDisbursementData: {} };

        case ACTION.CLEAR_ERRORS:
            return { ...state, error: dataSupplier.error };
        default:
            return state;
    }
};

export default supplierReducer;