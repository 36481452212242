import React, { useState } from "react";
import PropTypes from "prop-types";
import Box from "../Layout/Box";
import { monthNames } from "../../constants/constants";
import Text from "../Text";
import CutoffList from "../../containers/CustomerTransactions/CutoffList/CutoffList";

const CutoffCard = (props) => {
  const {
    deadline_date,
    name,
    date,
    amount,
    status,
    selected,
    balance,
    list,
  } = props;

  const deadlineDateMX = new Date(deadline_date.toLocaleString('en-US', { timeZone: 'America/Mexico_City' }));
  const oneWeek = 604800000;
  const deadlineDay = deadlineDateMX.getDate();
  const deadlineMonthAbb = monthNames[deadlineDateMX.getMonth()];
  const today = new Date();
  const pendingPay = status === 1 && deadline_date - today < oneWeek;

  let headerTextColor = "#001027";
  let headerBackground = "#6a6a6a";
  const balanceTextColor = balance === 0 ? "#6a6a6a" : "black";
  let deadlineDateColor = "black";
  let backgroundColor = "#f0f0fc";

if(selected == list){
  backgroundColor = "#c8daf4"
  headerTextColor = "#0253cc";
}
  if (status === 0) {
    headerBackground = "#cdcdcd";
    headerTextColor = "#6a6a6a";
  }
  if (status === 1) {
    if (pendingPay) {
      deadlineDateColor = "#e23b3b";
    }

    if (today.getTime() > deadline_date) {
      headerBackground = "#e23b3b";
    }
  }
  if (status === 2) {
    headerBackground = "#009804";
  }

  return (
    <Box
      style={{
        width: 350,
        height: 50,
        borderRadius: 8,
        overflow: "hidden",
        display: "flex",
        flexDirection: "row",
        backgroundColor: backgroundColor,
      }}
    >
      <Box
        style={{
          backgroundColor: headerBackground,
          width: "100%",
          alignItems: "center",
          paddingRight: 2,
          paddingBottom: 5,
          paddingTop: 5,
          flex: 0.02,
        }}
      />
      <Box
        style={{
          paddingTop: 5,
          paddingBottom: 5,
          color: headerTextColor,
          flex: 0.998,
          display: "flex",
          flexDirection: "row",

        }}
      >
        <Box
          style={{
            paddingTop: 5,
            paddingBottom: 5,
            paddingLeft: 15,
            paddingRight: 15,
            flex: 0.75,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
        >
          <Text>
            <b>{name}</b>
          </Text>
          <Text>{`Vence el ${deadlineDay} de ${deadlineMonthAbb}`}</Text>
        </Box>
        <Box
          style={{
            paddingTop: 5,
            paddingBottom: 5,
            paddingLeft: 15,
            paddingRight: 15,
            flex: 0.25,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Text style={{ fontSize: 16, color: headerTextColor }}>{`$${amount}`}</Text>
        </Box>
      </Box>
    </Box>
  );
};

CutoffCard.propTypes = {
  cutoff_id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  deadline_date: PropTypes.string.isRequired,
  status: PropTypes.number.isRequired,
  balance: PropTypes.string.isRequired,
};

export default CutoffCard;
