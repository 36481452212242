import React, { useState } from 'react';
import Button from '../Button';
import { Box } from '../Layout';
import {
  CircularProgress, FormHelperText
} from '@material-ui/core';
import { appService } from '../../services/app.service';

const PdfDownloader = (props) => {
  const {
    url,
    filename,
    label,
    isStatement,
    disabled
  } = props
  // setup the ref that we'll use for the hidden CsvLink click once we've updated the data
  const [showSpinner, setShowSpinner] = useState('none');
  const [errorMsg, setErrorMsg] = useState('');

  const downloadFile = async () => {
    setShowSpinner('block');
    setErrorMsg('');

    const basePath = appService.config.paths.api.basePath;
    const token = localStorage.getItem('firebaseToken');
    fetch(basePath + url, {
      method: "GET",
      headers: {
        "Authorization": token
      },
      timeout: 600000,
    })
      .then(response => {
        response.arrayBuffer().then(buffer => {
          setTimeout(() => setShowSpinner('none'), 100);
          const blob = new Blob([buffer], { type: 'application/pdf' });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = filename;
          link.click();
        });
      })
      .catch(e => {
        console.error(e);
        setTimeout(() => setShowSpinner('none'), 100);
        setErrorMsg(`${e.message} - ${e.response.data.detail || e.response.data.message || ''}`);
      });
  };

  // more code here

  return (
    // a bunch of other code here...
    <Box style={{ display: 'flex'}}>
      <Box style={{ display: 'flex' }}>
        <Button
          variant="contained"
          color='secondary'
          onClick={downloadFile}
          isDisabled={isStatement && disabled}
          style={isStatement ? { 
            backgroundColor: "#3f51b5",
            fontSize: '12px',
            fontWeight: '600',
            marginRight: '10px',
            cursor: 'pointer',
          }: {
            color: '#3f51b5', 
            backgroundColor: '#fff', 
            padding: '5px 10px', 
            fontSize: '14px', 
            fontWeight: 'bold', 
            borderRadius: '5px', 
            minWidth: '100px', 
            height: '40px', 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center', 
            cursor: 'pointer', 
            border: '1px solid #3f51b5',
            marginRight: '10px' 
          }}
        >
          {label}
        </Button>
        <Box style={{ display: showSpinner}}>
          <CircularProgress />
        </Box>
      </Box>
      <FormHelperText>
        {errorMsg}
      </FormHelperText>
    </Box>
  );
};

export default PdfDownloader;
