import * as ACTION from './users.actionTypes';

const usersData = {
  loading: {
    PUT_UPDATE_MARITAL_STATUS: false,
    PUT_UPDATE_REQUIREMENTS: false,
    GET_BUREAUS: false,
    GET_COMPARISON_ALERTS: false,
    PUT_VEREDICT: false,
    POST_RESUME_REGISTER: false,
    POST_REJECT_REGISTER: false,
    GET_USERS: false,
    PUT_ASSIGN_PASSWORD: false,
    PUT_LIGHT_CREDIT_LINE: false,
    PUT_CHANGE_BANK_ACCOUNT: false,
    GET_CREDIT_LINE_IDS: false,
  },
  error: {
    PUT_UPDATE_MARITAL_STATUS: null,
    PUT_UPDATE_REQUIREMENTS: null,
    GET_BUREAUS: null,
    GET_COMPARISON_ALERTS: null,
    PUT_VEREDICT: null,
    POST_RESUME_REGISTER: null,
    POST_REJECT_REGISTER: null,
    GET_USERS: null,
    PUT_ASSIGN_PASSWORD: null,
    PUT_LIGHT_CREDIT_LINE: null,
    PUT_CHANGE_BANK_ACCOUNT: null,
    GET_CREDIT_LINE_IDS: null,
  },
  maritalStatusData: {},
  requirementsData: {},
  bureausData: {},
  comparsionAlertsData: {},
  veredictData: {},
  resumeRegisterData: {},
  rejectRegisterData: {},
  usersResponseData: {},
  assignPasswordData: {},
  lightCreditLineData: {},
  putChangeBankAccountData: {},
  getCreditLineIdsData: {},
};

const usersReducer = (state = usersData, action) => {
  switch (action.type) {
    case ACTION.INIT_PUT_UPDATE_MARITAL_STATUS:
      return { ...state, loading: { ...state.loading, PUT_UPDATE_MARITAL_STATUS: true }, error: { ...state.error, PUT_UPDATE_MARITAL_STATUS: null } };
    case ACTION.RESPONSE_PUT_UPDATE_MARITAL_STATUS:
      return {
        ...state, loading: { ...state.loading, PUT_UPDATE_MARITAL_STATUS: false }, error: { ...state.error, PUT_UPDATE_MARITAL_STATUS: null }, maritalStatusData: action.result,
      };
    case ACTION.ERROR_PUT_UPDATE_MARITAL_STATUS:
      return { ...state, loading: { ...state.loading, PUT_UPDATE_MARITAL_STATUS: false }, error: { ...state.error, PUT_UPDATE_MARITAL_STATUS: action.error } };
    case ACTION.CLEAR_PUT_UPDATE_MARITAL_STATUS:
      return { ...state, maritalStatusData: {} };

    case ACTION.INIT_PUT_UPDATE_REQUIREMENTS:
      return { ...state, loading: { ...state.loading, PUT_UPDATE_REQUIREMENTS: true }, error: { ...state.error, PUT_UPDATE_REQUIREMENTS: null } };
    case ACTION.RESPONSE_PUT_UPDATE_REQUIREMENTS:
      return {
        ...state, loading: { ...state.loading, PUT_UPDATE_REQUIREMENTS: false }, error: { ...state.error, PUT_UPDATE_REQUIREMENTS: null }, requirementsData: action.result,
      };
    case ACTION.ERROR_PUT_UPDATE_REQUIREMENTS:
      return { ...state, loading: { ...state.loading, PUT_UPDATE_REQUIREMENTS: false }, error: { ...state.error, PUT_UPDATE_REQUIREMENTS: action.error } };
    case ACTION.CLEAR_PUT_UPDATE_REQUIREMENTS:
      return { ...state, requirementsData: {} };

    case ACTION.INIT_GET_BUREAUS:
      return { ...state, loading: { ...state.loading, GET_BUREAUS: true }, error: { ...state.error, GET_BUREAUS: null } };
    case ACTION.RESPONSE_GET_BUREAUS:
      return {
        ...state, loading: { ...state.loading, GET_BUREAUS: false }, error: { ...state.error, GET_BUREAUS: null }, bureausData: action.result,
      };
    case ACTION.ERROR_GET_BUREAUS:
      return { ...state, loading: { ...state.loading, GET_BUREAUS: false }, error: { ...state.error, GET_BUREAUS: action.error } };
    case ACTION.CLEAR_GET_BUREAUS:
      return { ...state, bureausData: {} };

    case ACTION.INIT_GET_COMPARISON_ALERTS:
      return { ...state, loading: { ...state.loading, GET_COMPARISON_ALERTS: true }, error: { ...state.error, GET_COMPARISON_ALERTS: null } };
    case ACTION.RESPONSE_GET_COMPARISON_ALERTS:
      return {
        ...state, loading: { ...state.loading, GET_COMPARISON_ALERTS: false }, error: { ...state.error, GET_COMPARISON_ALERTS: null }, comparsionAlertsData: action.result,
      };
    case ACTION.ERROR_GET_COMPARISON_ALERTS:
      return { ...state, loading: { ...state.loading, GET_COMPARISON_ALERTS: false }, error: { ...state.error, GET_COMPARISON_ALERTS: action.error } };
    case ACTION.CLEAR_GET_COMPARISON_ALERTS:
      return { ...state, comparsionAlertsData: {} };

    case ACTION.INIT_PUT_VEREDICT:
      return { ...state, loading: { ...state.loading, PUT_VEREDICT: true }, error: { ...state.error, PUT_VEREDICT: null } };
    case ACTION.RESPONSE_PUT_VEREDICT:
      return {
        ...state, loading: { ...state.loading, PUT_VEREDICT: false }, error: { ...state.error, PUT_VEREDICT: null }, veredictData: action.result,
      };
    case ACTION.ERROR_PUT_VEREDICT:
      return { ...state, loading: { ...state.loading, PUT_VEREDICT: false }, error: { ...state.error, PUT_VEREDICT: action.error } };
    case ACTION.CLEAR_PUT_VEREDICT:
      return { ...state, veredictData: {} };

    case ACTION.INIT_POST_RESUME_REGISTER:
      return { ...state, loading: { ...state.loading, POST_RESUME_REGISTER: true }, error: { ...state.error, POST_RESUME_REGISTER: null } };
    case ACTION.RESPONSE_POST_RESUME_REGISTER:
      return {
        ...state, loading: { ...state.loading, POST_RESUME_REGISTER: false }, error: { ...state.error, POST_RESUME_REGISTER: null }, resumeRegisterData: action.result,
      };
    case ACTION.ERROR_POST_RESUME_REGISTER:
      return { ...state, loading: { ...state.loading, POST_RESUME_REGISTER: false }, error: { ...state.error, POST_RESUME_REGISTER: action.error } };
    case ACTION.CLEAR_POST_RESUME_REGISTER:
      return { ...state, resumeRegisterData: {} };

    case ACTION.INIT_POST_REJECT_REGISTER:
      return { ...state, loading: { ...state.loading, POST_REJECT_REGISTER: true }, error: { ...state.error, POST_REJECT_REGISTER: null } };
    case ACTION.RESPONSE_POST_REJECT_REGISTER:
      return {
        ...state, loading: { ...state.loading, POST_REJECT_REGISTER: false }, error: { ...state.error, POST_REJECT_REGISTER: null }, rejectRegisterData: action.result,
      };
    case ACTION.ERROR_POST_REJECT_REGISTER:
      return { ...state, loading: { ...state.loading, POST_REJECT_REGISTER: false }, error: { ...state.error, POST_REJECT_REGISTER: action.error } };
    case ACTION.CLEAR_POST_REJECT_REGISTER:
      return { ...state, rejectRegisterData: {} };

    case ACTION.INIT_GET_USERS:
      return { ...state, loading: { ...state.loading, GET_USERS: true }, error: { ...state.error, GET_USERS: null } };
    case ACTION.RESPONSE_GET_USERS:
      return {
        ...state, loading: { ...state.loading, GET_USERS: false }, error: { ...state.error, GET_USERS: null }, usersResponseData: action.result,
      };
    case ACTION.ERROR_GET_USERS:
      return { ...state, loading: { ...state.loading, GET_USERS: false }, error: { ...state.error, GET_USERS: action.error } };
    case ACTION.CLEAR_GET_USERS:
      return { ...state, usersResponseData: {} };


      case ACTION.INIT_PUT_ASSIGN_PASSWORD:
        return { ...state, loading: { ...state.loading, PUT_ASIGN_PASSWORD: true }, error: { ...state.error, PUT_ASIGN_PASSWORD: null } };
      case ACTION.RESPONSE_PUT_ASSIGN_PASSWORD:
        return {
          ...state, loading: { ...state.loading, PUT_ASIGN_PASSWORD: false }, error: { ...state.error, PUT_ASIGN_PASSWORD: null }, assignPasswordData: action.result,
        };
      case ACTION.ERROR_PUT_ASSIGN_PASSWORD:
        return { ...state, loading: { ...state.loading, PUT_ASIGN_PASSWORD: false }, error: { ...state.error, PUT_ASIGN_PASSWORD: action.error } };
      case ACTION.CLEAR_PUT_ASSIGN_PASSWORD:
        return { ...state, assignPasswordData: {} };
    
      case ACTION.INIT_PUT_LIGHT_CREDIT_LINE:
        return { ...state, loading: { ...state.loading, PUT_LIGHT_CREDIT_LINE: true }, error: { ...state.error, PUT_LIGHT_CREDIT_LINE: null } };
      case ACTION.RESPONSE_PUT_LIGHT_CREDIT_LINE:
        return {
          ...state, loading: { ...state.loading, PUT_LIGHT_CREDIT_LINE: false }, error: { ...state.error, PUT_LIGHT_CREDIT_LINE: null }, lightCreditLineData: action.result,
        };
      case ACTION.ERROR_PUT_LIGHT_CREDIT_LINE:
        return { ...state, loading: { ...state.loading, PUT_LIGHT_CREDIT_LINE: false }, error: { ...state.error, PUT_LIGHT_CREDIT_LINE: action.error } };
      case ACTION.CLEAR_PUT_LIGHT_CREDIT_LINE:
        return { ...state, lightCreditLineData: {} };
        
    // Change bank account
      case ACTION.INIT_PUT_CHANGE_BANK_ACCOUNT:
        return { ...state, loading: { ...state.loading, PUT_CHANGE_BANK_ACCOUNT: true }, error: { ...state.error, PUT_CHANGE_BANK_ACCOUNT: null } };
      case ACTION.RESPONSE_PUT_CHANGE_BANK_ACCOUNT:
        return {
          ...state, loading: { ...state.loading, PUT_CHANGE_BANK_ACCOUNT: false }, error: { ...state.error, PUT_CHANGE_BANK_ACCOUNT: null }, putChangeBankAccountData: action.result,
        };
      case ACTION.ERROR_PUT_CHANGE_BANK_ACCOUNT:
        return { ...state, loading: { ...state.loading, PUT_CHANGE_BANK_ACCOUNT: false }, error: { ...state.error, PUT_CHANGE_BANK_ACCOUNT: action.error } };
      case ACTION.CLEAR_PUT_CHANGE_BANK_ACCOUNT:
        return { ...state, putChangeBankAccountData: {} };
    
    // Credit line id's
    case ACTION.INIT_GET_CREDIT_LINE_IDS:
      return { ...state, loading: { ...state.loading, GET_CREDIT_LINE_IDS: true }, error: { ...state.error, GET_CREDIT_LINE_IDS: null } };
    case ACTION.RESPONSE_GET_CREDIT_LINE_IDS:
      return {
        ...state, loading: { ...state.loading, GET_CREDIT_LINE_IDS: false }, error: { ...state.error, GET_CREDIT_LINE_IDS: null }, getCreditLineIdsData: action.result,
      };
    case ACTION.ERROR_GET_CREDIT_LINE_IDS:
      return { ...state, loading: { ...state.loading, GET_CREDIT_LINE_IDS: false }, error: { ...state.error, GET_CREDIT_LINE_IDS: action.error } };
    case ACTION.CLEAR_GET_CREDIT_LINE_IDS:
      return { ...state, getCreditLineIdsData: {} };

    case ACTION.CLEAR_ERRORS:
      return { ...state, error: usersData.error };
    default:
      return state;
  }
};

export default usersReducer;
