import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  Box, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, IconButton, MenuItem, Snackbar, TextField, withStyles,
} from '@material-ui/core';
import Text from '../Text';
import useStyles from './ApprovalTableForm.styles';
import Button from '../Button';
import { useDispatch, useSelector } from 'react-redux';
import { getInventoryReasonsCatalog, getVeredictReasonsCatalog, getVeredictReviewRequestPropertiesCatalog } from '../../reducers/catalog.action';
import { putVeredict } from '../../reducers/users.action';
import CloseIcon from '@material-ui/icons/Close';
import { Alert } from '@material-ui/lab';
import { postReevaluateInventory } from '../../reducers/inventory.action';

const GreenCheckbox = withStyles({
  root: {
    color: '#d3dee7',
    '&$checked': {
      color: '#4db050',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);


const initialInputs = [
  {
    labelText: '¿Quién realizó la evaluación del negocio?',
    name: 'input1',
    value: '',
    error: false,
  },
  {
    labelText: '¿Quién realizó la aprobación del crédito?',
    name: 'input2',
    value: '',
    error: false,
  },
  {
    labelText: '¿Cuál es el veredicto de la mesa de aprobación?',
    name: 'input3',
    value: '',
    options: [
      { value: '', label: 'Seleccionar' },
      { value: 'APPROVED', label: 'Aprobado' },
      { value: 'REJECT', label: 'Rechazado' },
      { value: 'RETURN', label: 'Devuelto' },
    ],
  },
];

const ApprovalTableForm = ({
  handleCheckboxChange, checkboxValues, handleInputChange, inputValues, shopkeeperInfo, inputErrors,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [checkboxInfo, setCheckboxInfo] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOpenVeredictSnackbar, setIsOpenVeredictSnackbar] = useState(false);
  const [isError, setIsError] = useState(false);

  const updateCheckboxInfo = (id, checked) => {
    const updatedInfo = checkboxInfo.map((item) => (
      item.id === id ? { ...item, reviewed: checked } : item
    ));
    setCheckboxInfo(updatedInfo);
  };

  const { veredictReasonsCatalog, reviewRequestPropertiesCatalog} = useSelector((state) => state.catalogReducer);
  const { error } = useSelector((state) => state.usersReducer);
  const { inventoryReasonsCatalog} = useSelector(
    (state) => state.catalogReducer
  );

  useEffect(() => {
    const desiredOrder = [
      'Revisión de Inventario',
      'Revisión de Fotos de Inventario',
      'Revisión de Fotos de Fachada',
      'Revisión de Titularidad',
      'Revisión de Domicilio',
      'Revisión Dirección con GPS',
      'Revisión Google Maps',
      'Revisión de Ingresos',
      'Revisión de Gastos',
      'Revisión de Propuesta de Crédito',
    ];

    const sortedData = (reviewRequestPropertiesCatalog?.data || []).sort((a, b) => (
      desiredOrder.indexOf(a.name) - desiredOrder.indexOf(b.name)
    ));

    const updatedInfo = sortedData.map((item) => ({
      id: item.id,
      name: item.name,
      reviewed: checkboxValues[item.id] || false,
    }));
    setCheckboxInfo(updatedInfo);
  }, [checkboxValues, reviewRequestPropertiesCatalog?.data]);

  useEffect(() => {
    if (inputValues?.input3 === "REJECT" || inputValues?.input3 === "RETURN") {
      dispatch(getVeredictReasonsCatalog(inputValues?.input3));
      
      if (!inputValues?.additionalInput || inputValues?.additionalInput === veredictReasonsCatalog?.data?.reasons[0]?.name) {
        const defaultValue = veredictReasonsCatalog?.data?.reasons[0]?.name;
        handleInputChange('additionalInput', defaultValue);
      }
    }
  }, [inputValues?.input3]);

  useEffect(() => {
    dispatch(getVeredictReviewRequestPropertiesCatalog());
    dispatch(getInventoryReasonsCatalog());
  }, []);

  useEffect(() => {
    if (error?.PUT_VEREDICT) {
      if (error?.PUT_VEREDICT != null) {
        setIsError(true);
      }
    }
  }, [error?.PUT_VEREDICT]);

  const returnVeredictInventoryOption = inventoryReasonsCatalog?.data
  ?.find((reason) => reason.name === "Veredicto devuelto");


  const sendVeredict = () => {
    const uniqueId = uuidv4();
    const body = {
      id: uniqueId.toString(),
      reviewer_email: inputValues?.input2,
      evaluator_email: inputValues?.input1,
      reviewed_properties: checkboxInfo,
      status: inputValues?.input3 === "REJECT" ? "REJECTED" : inputValues?.input3 === "RETURN" ? 'RETURNED' : 'APPROVED',
      reason: inputValues?.additionalInput,
    };

    const bodyReevaluate = {
      reason_id: returnVeredictInventoryOption?.id
    }

    if(inputValues?.input3 === 'RETURN'){
      dispatch(postReevaluateInventory(shopkeeperInfo?.id,bodyReevaluate))
    }

    dispatch(putVeredict('SK', shopkeeperInfo?.id, body)).then(handleOpenVeredictSnackbar());
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleOpenVeredictSnackbar = () => {
    setIsOpenVeredictSnackbar(true);
    setTimeout(() => {
    window.location.reload();
    }, 3500);
  };

  const handleCloseVeredictSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsOpenVeredictSnackbar(false);
  };

  return (
    <Box className={classes.formContainer}>
      <Box className={classes.formTitleContainer}>
        <Text className={classes.formHeader}>Mesa de aprobación</Text>
      </Box>
      <Grid container direction="column" className={classes.formContent}>
        {reviewRequestPropertiesCatalog?.data?.map((item) => (
          <FormControlLabel
            key={item.id}
            control={(
              <GreenCheckbox
                checked={checkboxValues[item.id] || false}
                onChange={(e) => {
                  handleCheckboxChange(item.id, e.target.checked);
                  updateCheckboxInfo(item.id, e.target.checked);
                }}
                name={item.name}
                color="primary"
              />
            )}
            label={item.name}
          />
        ))}
        {initialInputs?.map((input, index) => (
          <div key={index}>
            <TextField
              label={input?.labelText}
              variant="outlined"
              className={classes.formElement}
              name={input?.name}
              value={inputValues?.[input?.name] || ''}
              onChange={(e) => handleInputChange(input?.name, e?.target?.value)}
              select={input?.options ? true : false}
              error={input?.name === 'input1' || input?.name === 'input2' ? inputErrors[input?.name] : false}
              helperText={input?.name === 'input1' || input?.name === 'input2' ? (inputErrors[input?.name] ? "Debe ser un correo válido de YoFio" : '') : ''}
              type={input?.options ? 'email' : 'general'}
            >
              {input?.options?.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </div>
        ))}
        {(inputValues?.input3 === 'REJECT' || inputValues?.input3 === 'RETURN') && (
          <div>
            <TextField
              label="Motivo de rechazo"
              variant="outlined"
              className={classes.formElement}
              select
              name="additionalInput"
              value={inputValues?.additionalInput || (veredictReasonsCatalog?.data?.reasons[0]?.name || '')}
              onChange={(e) => handleInputChange('additionalInput', e.target.value)}
            >
              {veredictReasonsCatalog?.data?.reasons?.map((reason) => (
                <MenuItem key={reason.id} value={reason.name}>
                  {reason.name}
                </MenuItem>
              ))}
            </TextField>
          </div>
        )}
        <Button
          style={{ borderRadius: 30, height: 44 }}
          variant="contained"
          color="secondary"
          onClick={handleOpenModal}
          disabled={
            inputValues?.input1 === "" ||
            inputValues?.input2 === "" ||
            inputValues?.input3 === "" ||
            inputErrors?.input1 || 
            inputErrors?.input2 || 
            ((inputValues?.input3 === 'RETURN' || inputValues?.input3 === 'REJECT') && (inputValues?.additionalInput === "" || inputErrors?.additionalInput))
          }
        >
          Enviar veredicto
        </Button>
        <Dialog
          open={isModalOpen}
          onClose={handleCloseModal}
          PaperProps={{
            style: {
              width: '412px',
              height: 'auto',
              display: 'flex',
              flexDirection: 'column',
            },
          }}
        >
          <DialogTitle style={{
            textAlign: 'left', fontWeight: 'bold', marginTop: '10px', marginBottom: '10px',
          }}
          >
            ¡Atención!
            <IconButton
              aria-label="close"
              style={{ position: 'absolute', right: '8px', top: '8px' }}
              onClick={handleCloseModal}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent style={{ textAlign: 'center' }}>
            <Text>
              Vas a {inputValues?.input3 === 'REJECT' ? 'rechazar' : inputValues?.input3 === 'APPROVED' ? 'aprobar' : 'devolver'}
              {' '}
              el crédito de
              {' '}
              {shopkeeperInfo?.name + ' ' + shopkeeperInfo?.last_name + ' ' + shopkeeperInfo?.maternal_lastname}
              {' '}
              de
              {' '}
              {shopkeeperInfo?.shop?.name}
              .
            </Text>
          </DialogContent>
          <DialogActions style={{ flexDirection: 'column' }}>
            <Button
              style={{ borderRadius: '30px', marginBottom: '8px', width: '95%', height: '44px' }}
              variant="contained"
              color="secondary"
              onClick={() => {
                sendVeredict();
                handleCloseModal();
              }}
            >
              Enviar Veredicto
            </Button>
            <Button
              variant="text"
              color="secondary"
              onClick={handleCloseModal}
              style={{ background: 'none', width: '95%', marginBottom: '20px', height: '44px' }}
            >
              Regresar
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
      <Snackbar
      open={isOpenVeredictSnackbar}
      autoHideDuration={2500}
      onClose={handleCloseVeredictSnackbar}
    >
      <Alert
        onClose={handleCloseVeredictSnackbar}
        severity={isError ? "error" : "success"}
      >
        {isError ? error?.PUT_VEREDICT?.response?.data
          ?.readable_message || 'Ocurrió un error' : "Veredicto enviado correctamente"}
      </Alert>
    </Snackbar>
    </Box>
  );
};

export default ApprovalTableForm;
