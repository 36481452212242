import React, { useState } from 'react';
import { Input, Select, MenuItem } from '@material-ui/core';
import { Box } from '../../components/Layout';
import PageContainer from '../../components/PageContainer';
import SectionTitle from '../../components/SectionTitle';
import Downloader from '../../components/Downloader';
import Text from '../../components/Text';
import useStyles from './Reports.style';

export const ReportsPage = () => {
  const classes = useStyles();
  const [url, setUrl] = useState('/admin/reports/customer/helper');
  const [urlRegistration, setUrlRegistration] = useState('/admin/reports/registration');
  const [customerID, setCustomerID] = useState('');
  const [lada, setLada] = useState('+52');
  const [phoneNumber, setPhoneNumber] = useState('');

  const handleChange = (event) => {
    setCustomerID(event.target.value);
    setUrl('/admin/reports/customer/helper');
    if (event.target.value !== '') {
      setUrl(`/admin/reports/customer/helper?customer-id=${event.target.value}`);
    }
  };

  const handleChangeRegistration = (event) => {
    setPhoneNumber(event.target.value);
    setUrlRegistration('/admin/reports/registration');
    if (event.target.value !== '') {
      setUrlRegistration(`/admin/reports/registration?phone-number=${encodeURIComponent(lada)}${event.target.value}`);
    }
  };

  const handleChangeRegistrationLada = (event) => {
    setLada(event.target.value);
    setUrlRegistration('/admin/reports/registration');
    if (event.target.value !== '') {
      setUrlRegistration(`/admin/reports/registration?phone-number=${encodeURIComponent(lada)}${event.target.value}`);
    }
  };

  return (
    <PageContainer>
      <Box className={classes.mainPanel}>
        <SectionTitle>Reportes disponibles</SectionTitle>
      </Box>

      <Box style={{ width: '40%' }}>
        <Text variant="h3" className={classes.textBlue}>
          Reporte Helper
        </Text>

        <Input
          id="credit-amount"
          style={{ display: 'flex' }}
          value={customerID}
          type="text"
          onChange={handleChange}
          onBlur={handleChange}
          placeholder="ID del cliente (opcional)"
        />

        <Downloader url={url} filename="helper.csv" />
      </Box>

      <Box style={{ width: '40%' }}>
        <Text variant="h3" className={classes.textBlue}>
          Reporte Registro
        </Text>

        <Box>
          <Select
            variant="standard"
            select
            value={lada}
            onChange={handleChangeRegistrationLada}
          >
            <MenuItem value="+52">+52</MenuItem>
          </Select>
          <Input
            id="phone-number"
            style={{ width: '80%' }}
            value={phoneNumber}
            type="text"
            onChange={handleChangeRegistration}
            onBlur={handleChangeRegistration}
            placeholder="N&uacute;mero de tel&eacute;fono del tendero (opcional)"
          />
        </Box>

        <Downloader url={urlRegistration} filename="registro.csv" />
      </Box>
    </PageContainer>
  );
};

export default ReportsPage;
