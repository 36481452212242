import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  page: {
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'row',
    background: '#ea4b4b',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 0.5,
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loginContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 0.5,
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  appLogo: {
    maxWidth: 400,
    width: '80%',
  },
  login: {
    display: 'flex',
    flexDirection: 'column',
    height: '40%',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
}));

export default useStyles;
