import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  table: {
    border: `1px solid ${theme.palette.divider}`,
    width: '100%',
    borderCollapse: 'collapse',
  },
  tableCell: {
    textAlign: 'center',
    padding: theme.spacing(1),
  },
  textStyle: {
    textAlign: 'center',
  },
  pendingText: {
    color: 'yellow',
  },
  approvedText: {
    color: 'green',
  },
  rejectedText: {
    color: 'red',
  },
  unknownText: {
    color: 'black',
  },
  tableTitle: {
    fontWeight: 'bold',
    marginTop: '40px',
    marginBottom: '20px',
  },
}));

export default useStyles;
