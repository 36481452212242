export const INIT_GET_EXTENSION_REQUEST = 'CREDITLINE::INIT_GET_EXTENSION_REQUEST';
export const RESPONSE_GET_EXTENSION_REQUEST = 'CREDITLINE::RESPONSE_GET_EXTENSION_REQUEST';
export const ERROR_GET_EXTENSION_REQUEST = 'CREDITLINE::ERROR_GET_EXTENSION_REQUEST';
export const CLEAR_GET_EXTENSION_REQUEST = 'CREDITLINE::CLEAR_GET_EXTENSION_REQUEST';

export const INIT_PUT_EXTENSION_REQUEST = 'CREDITLINE::INIT_PUT_EXTENSION_REQUEST';
export const RESPONSE_PUT_EXTENSION_REQUEST = 'CREDITLINE::RESPONSE_PUT_EXTENSION_REQUEST';
export const ERROR_PUT_EXTENSION_REQUEST = 'CREDITLINE::ERROR_PUT_EXTENSION_REQUEST';
export const CLEAR_PUT_EXTENSION_REQUEST = 'CREDITLINE::CLEAR_PUT_EXTENSION_REQUEST';

export const INIT_POST_PARTICULAR_EXTENSION_REQUEST = 'CREDITLINE::INIT_POST_PARTICULAR_EXTENSION_REQUEST';
export const RESPONSE_POST_PARTICULAR_EXTENSION_REQUEST = 'CREDITLINE::RESPONSE_POST_PARTICULAR_EXTENSION_REQUEST';
export const ERROR_POST_PARTICULAR_EXTENSION_REQUEST = 'CREDITLINE::ERROR_POST_PARTICULAR_EXTENSION_REQUEST';
export const CLEAR_POST_PARTICULAR_EXTENSION_REQUEST = 'CREDITLINE::CLEAR_POST_PARTICULAR_EXTENSION_REQUEST';

export const INIT_GET_LATE_FEES = 'CREDITLINE::INIT_GET_LATE_FEES';
export const RESPONSE_GET_LATE_FEES = 'CREDITLINE::RESPONSE_GET_LATE_FEES';
export const ERROR_GET_LATE_FEES = 'CREDITLINE::ERROR_GET_LATE_FEES';
export const CLEAR_GET_LATE_FEES = 'CREDITLINE::CLEAR_GET_LATE_FEES';

export const INIT_DELETE_DISBURSEMENT = 'CREDITLINE::INIT_DELETE_DISBURSEMENT';
export const RESPONSE_DELETE_DISBURSEMENT = 'CREDITLINE::RESPONSE_DELETE_DISBURSEMENT';
export const ERROR_DELETE_DISBURSEMENT = 'CREDITLINE::ERROR_DELETE_DISBURSEMENT';
export const CLEAR_DELETE_DISBURSEMENT = 'CREDITLINE::CLEAR_DELETE_DISBURSEMENT';

export const INIT_DELETE_INVENTORY_PURCHASE = 'CREDITLINE::INIT_DELETE_INVENTORY_PURCHASE';
export const RESPONSE_DELETE_INVENTORY_PURCHASE = 'CREDITLINE::RESPONSE_DELETE_INVENTORY_PURCHASE';
export const ERROR_DELETE_INVENTORY_PURCHASE = 'CREDITLINE::ERROR_DELETE_INVENTORY_PURCHASE';
export const CLEAR_DELETE_INVENTORY_PURCHASE = 'CREDITLINE::CLEAR_DELETE_INVENTORY_PURCHASE';

export const INIT_GET_EXTENSION = 'CREDITLINE::INIT_GET_EXTENSION';
export const RESPONSE_GET_EXTENSION = 'CREDITLINE::RESPONSE_GET_EXTENSION';
export const ERROR_GET_EXTENSION = 'CREDITLINE::ERROR_GET_EXTENSION';
export const CLEAR_GET_EXTENSION = 'CREDITLINE::CLEAR_GET_EXTENSION';

export const CLEAR_ERRORS = 'CREDITLINE::CLEAR_ERRORS';
