import client from "../client";

export const postWhatsappMessage = (body) => {
  const url = `notifications/whatsapp`;
  return new Promise((resolve, reject) => {
    client.post(url, body).then(
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      }
    );
  });
};
