import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';

import { getVouchers } from "../../reducers/reviewVouchers.action";

import TableReviewVoucherUI from "./TableReviewVoucher";
import useStyles from "./TableReviewVoucher.styles";
    
function TableReviewVoucher(props) {
    const { statusFilter } = props;
    const dispatch = useDispatch();
    const classes = useStyles();
    const history = useHistory();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [dataVouchers, setDataVouchers] = useState({});

    const {
        getVouchersData
    } = useSelector((state) => state.reviewVoucherReducer);

    useEffect(() => {
        let offSet = page !== 0 ? rowsPerPage*page-1 : 0
        dispatch(getVouchers({status: statusFilter, limit: rowsPerPage, offset: offSet}));
    }, [statusFilter, page, rowsPerPage]);

    useEffect(() => {
        if(Object.keys(getVouchersData).length !== 0) {
            if(getVouchersData?.data && getVouchersData.data) {
                setDataVouchers(getVouchersData.data)
            }
        }
    }, [getVouchersData]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const detailVouchersClient = (credit_line_id, account_id) => {
        history.replace(`/review-voucher/detail/${credit_line_id}/${account_id}`);
    }

    return <TableReviewVoucherUI
        classes={classes}
        page={page}
        rowsPerPage={rowsPerPage}
        dataVouchers={dataVouchers}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        detailVouchersClient={detailVouchersClient}
    />
};

export default TableReviewVoucher;