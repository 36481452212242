import React from 'react';

import {
  Table,
  TableHead,
  TableRow,
  TableSortLabel,
  TableCell,
  TableBody,
} from '@material-ui/core';
import Text from '../../components/Text';
import { dateFunction } from './dateFunction';

const CurrentTransactions = (props) => {
  if (Object.keys(props).length === 0) {
    return (
      <Text variant="h2" style={{ padding: '20%', textAlign: 'center' }}>
        Por el momento no hay transacciones corrientes
      </Text>
    );
  }

  const reversedPropKeys = [...Object.keys(props)].reverse();

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>
            <TableSortLabel />
            <TableSortLabel>Concepto</TableSortLabel>
          </TableCell>
          <TableCell>
            <TableSortLabel>Fecha de transacción</TableSortLabel>
          </TableCell>
          <TableCell>
            <TableSortLabel>Monto</TableSortLabel>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {reversedPropKeys.map((keyName, i) => (
          <TableRow key={i}>
            <TableCell component="th">
              <img src={props[keyName].product_icon} style={{ width: 20 }} />
              {props[keyName].product_name}
            </TableCell>
            <TableCell
              component="th"
              scope="row"
              style={{ textAlign: 'justify' }}
            >
              {dateFunction(props[keyName].date)}
            </TableCell>
            <TableCell component="th" scope="row">
              {`$ ${props[keyName].amount}`}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
export default CurrentTransactions;
