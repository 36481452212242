import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    tableHead: {
        width: '100%',
        backgroundColor: '#F8F9F9',
    },
    titleTable: {
        color: '#858d9c',
        fontSize: 12,
        textAlign: 'center'
    },
    textCellTable: {
        color: '#3a3b3f',
        fontSize: 11,
        textAlign: 'center'
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalContainer: {
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 15,
        backgroundColor: '#ffffff',
        padding: 20
    },
    titleModal: {
        textAlign: 'center',
        fontSize: 22,
        marginBottom: 30,
        marginTop: 10
    },
    containerInfoModal: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 10
    },
    titleInfoModal: {
        fontSize: 15,
        fontWeight: 'bold',
        marginRight: 10
    },
    textInfoModal: {
        fontSize: 15,
    },
    containerButtonModal: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        marginBottom: 10,
        marginTop: 20
    },
    input: {
        width: 70
    },
    snackbar: {
        pointerEvents: 'auto',
      },
}));

export default useStyles;
