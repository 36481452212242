import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getSupplier } from "../../reducers/dealer.action";

import BuyDealerUI from './BuyDealer';
import useStyles from './BuyDealer.styles';
import * as ACTIONS from './actionTypes';

function BuyDealer() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [inputValue, setInputVale] = useState('');
    const [nothingFoundNoticeShown, showNothingFoundNotice] = useState(false);
    const [status, setStatus] = useState('PENDING');

    const { error } = useSelector((state) => state.registersState);

    useEffect(() => {
        if (error) {
          showNothingFoundNotice(true);
          setInputVale('');
        }
    }, [error]);

    useEffect(() => {
        setTimeout(() => showNothingFoundNotice(false), 3000);
    }, [nothingFoundNoticeShown]);

    useEffect(() => () => dispatch({
        type: ACTIONS.SET_STATE,
        label: 'searchString',
        value: '',
    },), []);

    useEffect(() => {
        if(inputValue === '' || inputValue === undefined || inputValue === null) {
            dispatch(getSupplier({status: status}))
        }
    }, [inputValue]);

    const searchAction = () => {
        dispatch(getSupplier({status: status, search:inputValue}))
    };

    return <BuyDealerUI
        classes={classes}
        dispatch={dispatch}
        inputValue={inputValue}
        setInputVale={setInputVale}
        nothingFoundNoticeShown={nothingFoundNoticeShown}
        status={status}
        setStatus={setStatus}
        searchAction={searchAction}
    />
}

export default BuyDealer;