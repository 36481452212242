import * as ACTION from './business.actionTypes';

const dataBusiness = {
    loading: {
        GET_BUSINESS_TYPE: false,
        PUT_UPDATE_BUSINESS_TYPE: false,
    },
    error: {
      GET_BUSINESS_TYPE: null,
      PUT_UPDATE_BUSINESS_TYPE: null,
    },
    businessType: {},
    updateBusinessTypeData: {},
};

const businessReducer = (state= dataBusiness, action) => {
    switch (action.type) {
        case ACTION.INIT_GET_BUSINESS_TYPE:
            return { ...state, loading: { ...state.loading, GET_BUSINESS_TYPE: true }, error: { ...state.error, GET_BUSINESS_TYPE: null} };
        case ACTION.RESPONSE_GET_BUSINESS_TYPE:
            return { ...state, loading: { ...state.loading, GET_BUSINESS_TYPE: false }, error: { ...state.error, GET_BUSINESS_TYPE: null}, businessType: action.result };
        case ACTION.ERROR_GET_BUSINESS_TYPE:
            return { ...state, loading: { ...state.loading, GET_BUSINESS_TYPE: false }, error: { ...state.error, GET_BUSINESS_TYPE: action.error} };
        case ACTION.CLEAR_GET_BUSINESS_TYPE:
            return { ...state, businessType: {} };
       
            
        case ACTION.INIT_PUT_UPDATE_BUSINESS_TYPE:
            return { ...state, loading: { ...state.loading, PUT_UPDATE_BUSINESS_TYPE: true }, error: { ...state.error, PUT_UPDATE_BUSINESS_TYPE: null} };
        case ACTION.RESPONSE_PUT_UPDATE_BUSINESS_TYPE:
            return { ...state, loading: { ...state.loading, PUT_UPDATE_BUSINESS_TYPE: false }, error: { ...state.error, PUT_UPDATE_BUSINESS_TYPE: null}, updateBusinessTypeData: action.result };
        case ACTION.ERROR_PUT_UPDATE_BUSINESS_TYPE:
            return { ...state, loading: { ...state.loading, PUT_UPDATE_BUSINESS_TYPE: false }, error: { ...state.error, PUT_UPDATE_BUSINESS_TYPE: action.error} };
        case ACTION.CLEAR_PUT_UPDATE_BUSINESS_TYPE:
            return { ...state, updateBusinessTypeData: {} };    

        case ACTION.CLEAR_ERRORS:
            return { ...state, error: dataBusiness.error };
        default:
            return state;
    }
};

export default businessReducer;