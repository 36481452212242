import * as ACTIONS from './shops.actionTypes';
import * as ACTIONS_EXPRESS from '../containers/ExpressExtension/actionTypes';

const initialState = {
  isFetchingShops: false,
  settlements: [],
  shops: [],
  selectedShops: [],
  searchShops: [],
  transactionTypes: [],
  extensionLigth: {}
};

const shopsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.FETCH_SHOPS_INIT:
      return { ...state, isFetchingCustomers: true };
    case ACTIONS.FETCH_SHOPS_SUCCESS:
      return {
        ...state, loadingCustomers: false, error: null,
      };
    case ACTIONS.FETCH_SHOPS_ERROR:
      return { ...state, loadingCustomers: false, error: action.error };
    case ACTIONS.SET_STATE:
      return {
        ...state, [action.payload.label]: action.payload.value,
      };
    case ACTIONS.ADD_SELECTED_SHOP: {
      const { selectedShops } = state;

      const containsSelectedShop = selectedShops.find((selectedShop) => selectedShop.key === action.payload.key);

      if (!containsSelectedShop) {
        return {
          ...state,
          selectedShops: [...state.selectedShops, action.payload],
        };
      }
      return state;
    }
    case ACTIONS.DELETE_SELECTED_SHOP: {
      const { selectedShops } = state;

      const filteredSelectedShops = selectedShops.filter((selectedShop) => selectedShop.key !== action.payload.key);

      return {
        ...state,
        selectedShops: [...filteredSelectedShops],
      };
    }
    case ACTIONS.CLEAR_SELECTED_SHOPS:
      return {
        ...state,
        selectedShops: [],
      };
    case ACTIONS_EXPRESS.FETCH_RESPONSE_EXTENSION_LIGTH:
      return { ...state, extensionLigth: action.response };
    default:
      return { ...state };
  }
};

export default shopsReducer;
