import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    containerTicket: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 10,
        border: '1px solid #e8e8fb',
        marginLeft: 15,
        maxWidth: '275px'
    },
    imageContainer: {
        width: '275px',
        height: '350px',
    },
    zoomImage: {
        position: 'absolute',
        width: 50,
        height: 50,
        marginTop: 130,
        marginLeft: 100,
        opacity: 0.8
    },
    formElement: {
        marginTop: 20,
        marginBottom: 20,
        marginRight: 10,
        marginLeft: 10
    },
    infoContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        margin: '0px 20px 20px 20px',
    },
    titleInfo: {
        fontFamily: 'Poppins',
        fontSize: 14,
        fontWeight: 300,
        width: "50%",
        color: '#37465A'
    },
    textInfo: {
        fontFamily: 'Poppins',
        fontSize: 14,
        fontWeight: 500,
        width: "50%",
        color: '#37465A'
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
        marginTop: 20,
        marginBottom: 20
    },
    buttonViewDetail: {
        backgroundColor: '#3050ff',
        margin: '0px 20px 20px 20px',
    },
    containerStatus: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 20,
        marginBottom: 20,
        marginLeft: 10,
        marginRight: 10
    },
    containerInfoVoucher: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    statusImg: {
        width: 30,
        height: 30,
        marginRight: 10
    },
    textInfoStyle: {
        marginTop: 10,
        marginBottom: 10,
    },
}));

export default useStyles;