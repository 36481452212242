import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  generalContainer: {
    height: 60,
    backgroundColor: '#0253CC0D',
    borderColor: '#E8E8FC',
    borderWidth: 1,
    borderStyle: 'solid',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    width: '50%',
    marginRight: 5,
  },
  text: { color: '#4A4B4B', fontWeight: 500, fontSize: 12 },
  select: {
    width: 187,
    backgroundColor: 'white',
    borderRadius: 5,
    outline: 'none',
    marginLeft: 12,
    borderColor: '#E8E8FC',
    borderWidth: 1,
    borderStyle: 'solid',
    color: 'black',
    fontSize: 12,
    fontWeight: 400,
  },
}));

export default useStyles;
