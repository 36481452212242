import React, { useState } from "react";
import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";
import useStyles from "../SubMenuPage.styles";
import Text from "../../../../components/Text";
import HistoryTableHead from "./HistoryTableHead";
import { Box } from "../../../../components/Layout";
import { dateFunction } from '../../../../constants/dateFunction';

export const HistoryTable = (props) => {
  const classes = useStyles();
  const blocked_shop_detail = props.blocked_shop_detail;

  const _motivos = (installment) => {
    if(installment?.reasons) {
      if(installment.reasons.length === 0) {
        return(
          <Text className={classes.textStyle}>
            -
          </Text>
        )
      }
  
      return installment.reasons.map((nameElement) => {
        let name;
        if(nameElement === 'COMPLETE_CLOSURE_OF_THE_BUSINESS') {
          name = 'Cierre total del negocio 🔴'
        }
        if(nameElement === 'DIFFICULTY_OF_COLLECTION') {
          name = 'Dificultad de cobranaza'
        }
        if(nameElement === 'HARD_TO_FIND_CUSTOMER') {
          name = 'Cliente dificil de encontrar 🔴'
        }
        if(nameElement === 'INCREASES_IN_PERSONAL_EXPENSES') {
          name = 'Incremento de gastos'
        }
        if(nameElement === 'INVENTORY_DECREASE') {
          name = 'Reduccion de inventario'
        }
        if(nameElement === 'PARTIAL_CLOSURE_OF_BUSINESS') {
          name = 'Cierre parcial del negocio 🔴'
        }
        if(nameElement === 'SALES_REDUCTION') {
          name = 'Reduccion de ventas'
        }
        if(nameElement === 'UNREACHABLE_CUSTOMER') {
          name = 'Cliente inubicable 🔴'
        }
        if(nameElement === 'RESTRUCTURING') {
          name = 'Reestructura 🔴'
        }
        if(nameElement === 'SICKNESS') {
          name = 'Enfermedad'
        }
        if(nameElement === 'OWNERSHIP_CHANGE') {
          name = 'Traspaso de tienda'
        }
        if(nameElement === 'THEFT_OR_BLACKMAIL') {
          name = 'Robo o Extorsión'
        }
        if(nameElement === 'CREDIT_LINE_FOR_EMPLOYEE') {
          name = 'Empleado con préstamo personal 🔴'
        }
        if(nameElement === 'DEBT_CANCELLATION') {
          name = 'Liquidado con condonación 🔴'
        }
        return(
          <Text className={classes.textStyle} style={{marginBottom: '7px'}}>
            - { name }
          </Text>
        )
      })
    }

    return(
      <Text className={classes.textStyle}>
        -
      </Text>
    )
  }

  return (
    <Box>
      <TableContainer className={classes.tableContainerPaginator}>
        <Table aria-label="custom pagination table">
          <HistoryTableHead />
          <TableBody>
            { blocked_shop_detail &&
              <>
                {
                  blocked_shop_detail.map((lock, index) => (
                    <TableRow key={index} style={{ maxHeight: 43 }}>
                      <TableCell>
                        <Text className={classes.textStyle}>
                          { lock?.created_at ? dateFunction(lock.created_at) : '-' }
                        </Text>
                      </TableCell>
                      <TableCell>
                        <Text className={classes.textStyle}>
                          { lock?.allow ? 'Desbloqueo' : 'Bloqueo' }
                        </Text>
                      </TableCell>
                      <TableCell>
                        <Text className={classes.textStyle}>
                          { lock?.asked_by ? lock?.asked_by : '-' }
                        </Text>
                      </TableCell>
                      <TableCell>
                        <Text className={classes.textStyle}>
                          { lock?.type || '-' }
                        </Text>
                      </TableCell>
                      <TableCell>
                        <Text className={classes.textStyle} style={{ fontWeight: 'bold', marginBottom: '7px' }}>
                          Motivos:
                        </Text>
                        { _motivos(lock) }
                        <Text className={classes.textStyle} style={{ fontWeight: 'bold', marginTop: 20 }}>
                          Descripción:
                        </Text>
                        <Text className={classes.textStyle}>
                          { lock?.description || '-' }
                        </Text>
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              }
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

HistoryTable.propTypes = {};

export default HistoryTable;