import React, { Component } from 'react';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps';

class Map extends Component {
    constructor(props) {
        super(props);
        this.state = {
            width: props.width || 600,
            height: props.height || 400
        };
    }
    render() {
        const { width, height } = this.state;
        const MapWithAMarker = withScriptjs(withGoogleMap(props =>
            <GoogleMap
                defaultZoom={15}
                defaultCenter={{ lat: props.lat, lng: props.lng }}
            >
                <Marker
                    position={{ lat: props.lat, lng: props.lng }}
                />
            </GoogleMap>
        ));

        return (
            <MapWithAMarker
                googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCsbpIouXS84jTyPBzdgY-xBsyrb5Ov8w0&v=3.exp&libraries=geometry,drawing,places"
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `${height}px`, width: `${width}px` }} />}
                mapElement={<div style={{ height: `100%` }} />}
                lat={this.props.lat}
                lng={this.props.lng}
            />
        );
    }
}

export default Map;
