import { Collapse, List, ListItem, ListItemText } from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import React from "react";
import Text from "../Text/Text";

const MessageListItem = ({
  openListItem,
  body,
  listHeader,
  onClick,
  selectedIndex,
  isSelected,
}) => {
  return (
    <>
      <ListItem
        button
        onClick={onClick}
        selected={selectedIndex}
        style={{ backgroundColor: isSelected ? "#2252C5" : "transparent" }}
      >
        <ListItemText
          primary={listHeader}
          style={{ color: isSelected ? "white" : "black" }}
        />
        {openListItem ? (
          <ExpandLess style={{ color: isSelected ? "white" : "black" }} />
        ) : (
          <ExpandMore style={{ color: isSelected ? "white" : "black" }} />
        )}
      </ListItem>
      <Collapse in={openListItem} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem>
            <Text>{body}</Text>
          </ListItem>
        </List>
      </Collapse>
    </>
  );
};

export default MessageListItem;
