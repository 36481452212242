import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    TextField
} from '@material-ui/core';
import moment from 'moment';
import AccountBalance from '@material-ui/icons/AccountBalance';
import LocalAtm from '@material-ui/icons/LocalAtm';

import useStyles from '../SubMenuPage.styles';
import Text from '../../../../components/Text';
import { getTransactionsDetail } from './Transactions.actions';
import { getLegacyTransactionsDetail } from '../LegacyTransactions/LegacyTransactions.actions';
import TransactionItem from './TransactionItem';
import LegacyTransactionItem from '../LegacyTransactions/LegacyTransactionItem';
import Icons from '../../../../assets/icons';

const TransactionPage = ({
    creditLineId,
    credit_line_shop_detail,
    accountId,
    dataIds,
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const dl = (credit_line_shop_detail?.disbursement_list || [{}]).filter((e, i) => { return e.type === 'DISBURSEMENT' })
    dl.sort((a, b) => moment(b.created_at) - moment(a.created_at))
    const lastDisbursment = dl[0] || {};
    const [headerSelected, setHeaderSelected] = useState('credit');
    const [allTransactions, setAllTransactions] = useState([]);

    const selectCredit = () => {
        setHeaderSelected('credit');
    }
    const selectLegacyTxs = () => {
        setHeaderSelected('legacy-txs');
    }

    const {
        isFetchingTransaction,
        transactionsDetail,
        legacyTransactionsDetail,
        error
    } = useSelector((state) => state.transactionsReducer);

    useEffect(() => {
        dispatch(getLegacyTransactionsDetail(accountId));
    }, []);

    useEffect(() => {
        if(Object.keys(dataIds).length !== 0) {
            dataIds.forEach((dataId) => {
                dispatch(getTransactionsDetail(dataId?.credit_line_id));
            })
        }
    }, [dataIds, dispatch]);

    useEffect(() => {
        // Verificamos si tenemos una nueva transacción en Redux
        if (transactionsDetail) {
            setAllTransactions(prevTransactions => {
                // Creamos una copia del estado anterior como un arreglo de objetos
                const newTransaction = [...prevTransactions];

                // Iteramos sobre cada transacción recibida
                transactionsDetail.forEach(transaction => {
                    const { date, movements } = transaction; // Extraemos la fecha y los movimientos

                    // Buscamos si ya existe un objeto con la misma fecha
                    const existingEntry = newTransaction.find(entry => entry.date === date);

                    if (existingEntry) {
                        // Si ya existe la fecha, concatenamos los movimientos
                        existingEntry.movements = [...existingEntry.movements, ...movements];
                    } else {
                        // Si no existe, agregamos un nuevo objeto con la fecha y los movimientos
                        newTransaction.push({ date, movements });
                    }
                });

                return newTransaction;
            });
        }
    }, [transactionsDetail]);

    return (
        <div className={classes.transactionContainer} style={{ alignItems: 'flex-start' }}>
            <div className={classes.transactionBoxContainer}>
                <div className={classes.transactionTitleContainer}>
                    <img src={Icons.headerCredit} style={{ width: 25, height: 25, marginLeft: 10, marginRight: 10 }} />
                    <Text className={classes.transactionTitleText}>Crédito</Text>
                </div>
                <div style={{ ...styles.containerGeneralTransaction, justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex' }}>
                        <Text style={{
                            fontSize: '14px',
                            color: 'rgba(102, 110, 117, 0.79)',
                            marginRight: 5
                        }}>
                            Saldo usado:
                        </Text>
                        <Text className={classes.transactionTitleText} style={{ fontSize: '14px' }}>{`$ ${credit_line_shop_detail?.balance || 0}`}</Text>
                    </div>
                    <div style={{ display: 'flex' }}>
                        <Text style={{
                            fontSize: '14px',
                            color: 'rgba(102, 110, 117, 0.79)',
                            marginRight: 5
                        }}>
                            Disponible:
                        </Text>
                        <Text className={classes.transactionTitleText} style={{ fontSize: '14px' }}>{`$ ${credit_line_shop_detail?.available_balance || 0}`}</Text>
                    </div>
                </div>
                <div style={{ ...styles.containerGeneralTransaction, flexDirection: 'column', marginTop: 15 }}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Text className={classes.transactionTitleText}>Fecha de corte</Text>
                        <Text className={classes.transactionTitleText}>{credit_line_shop_detail?.next_payment && credit_line_shop_detail.next_payment?.deadline_date ? moment(credit_line_shop_detail.next_payment?.deadline_date).format('DD [de] MMMM') : ''}</Text>
                    </div>
                    <div style={{ ...styles.containerSubMenuCredit, justifyContent: 'space-between', alignItems: 'center' }}>
                        <div
                            style={{
                                display: 'flex', flexDirection: 'row', alignItems: 'center'
                            }}
                        >
                            <img src={Icons.nextPaymentIcon} style={{ width: 20, height: 20, marginRight: 5, padding: 5, border: '1px solid #3e8bff', borderRadius: 5 }} />
                            <Text style={{
                                fontSize: '14px',
                                color: 'rgba(102, 110, 117, 0.79)'
                            }}>
                                Próximo pago
                            </Text>
                        </div>
                        <Text className={classes.transactionTitleText} style={{ fontSize: '14px' }}>{credit_line_shop_detail?.next_payment && credit_line_shop_detail.next_payment?.amount ? `$ ${credit_line_shop_detail.next_payment.amount}` : '$ 0'}</Text>
                    </div>
                    <div style={{ ...styles.containerSubMenuCredit, justifyContent: 'space-between', alignItems: 'center' }}>
                        <div
                            style={{
                                display: 'flex', flexDirection: 'row', alignItems: 'center'
                            }}
                        >
                            <img src={Icons.buyStock} style={{ width: 20, height: 20, marginRight: 5, padding: 5, border: '1px solid #3e8bff', borderRadius: 5 }} />
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Text style={{
                                    fontSize: '14px',
                                    color: 'rgba(102, 110, 117, 0.79)'
                                }}>
                                    Compra de
                                </Text>
                                <Text style={{
                                    fontSize: '14px',
                                    color: 'rgba(102, 110, 117, 0.79)',
                                    marginTop: 5
                                }}>
                                    inventario
                                </Text>
                            </div>
                        </div>
                        <Text className={classes.transactionTitleText} style={{ fontSize: '14px' }}>{`$ ${credit_line_shop_detail?.total_inventory_purchased || 0}`}</Text>
                    </div>
                    <div style={{ ...styles.containerSubMenuCredit, justifyContent: 'space-between', alignItems: 'center' }}>
                        <div
                            style={{
                                display: 'flex', flexDirection: 'row', alignItems: 'center'
                            }}
                        >
                            <img src={Icons.totalPayment} style={{ width: 20, height: 20, marginRight: 5, padding: 5, border: '1px solid #3e8bff', borderRadius: 5 }} />
                            <Text style={{
                                fontSize: '14px',
                                color: 'rgba(102, 110, 117, 0.79)'
                            }}>
                                Pago total
                            </Text>
                        </div>
                        <Text className={classes.transactionTitleText} style={{ fontSize: '14px' }}>$ {(credit_line_shop_detail?.next_payment && credit_line_shop_detail.next_payment?.amount ? credit_line_shop_detail.next_payment.amount : 0) + (credit_line_shop_detail?.total_inventory_purchased || 0)}</Text>
                    </div>
                </div>
                {Object.keys(lastDisbursment).length !== 0 &&
                    <>
                        {lastDisbursment.status === 'PENDING' ?
                            <div style={{ ...styles.containerGeneralTransaction, flexDirection: 'column', marginTop: 15 }}>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <Text className={classes.transactionTitleText}>Disposición de dinero</Text>
                                    <Text className={classes.transactionTitleText}>{lastDisbursment?.created_at ? moment(lastDisbursment.created_at).format('DD [de] MMMM') : ''}</Text>
                                </div>
                                <div style={{ ...styles.containerSubMenuCredit, justifyContent: 'center', alignItems: 'center', border: '1px solid #6B46D9', borderRadius: 5 }}>
                                    <Text style={{
                                        fontSize: '14px',
                                        color: '#6B46D9'
                                    }}>
                                        Preparando tu transferencia (${lastDisbursment.amount})
                                    </Text>
                                </div>
                            </div> :
                            <div style={{ ...styles.containerGeneralTransaction, flexDirection: 'column', marginTop: 15 }}>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <Text className={classes.transactionTitleText}>Disposición de dinero</Text>
                                    <Text className={classes.transactionTitleText}>{lastDisbursment?.created_at ? moment(lastDisbursment.created_at).format('DD [de] MMMM') : ''}</Text>
                                </div>
                                <div style={{ ...styles.containerSubMenuCredit, justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div
                                        style={{
                                            display: 'flex', flexDirection: 'row', alignItems: 'center'
                                        }}
                                    >
                                        <img src={Icons.disbursmentAmount} style={{ width: 20, height: 20, marginRight: 5, padding: 5, border: '1px solid #3e8bff', borderRadius: 5 }} />
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <Text style={{
                                                fontSize: '14px',
                                                color: 'rgba(102, 110, 117, 0.79)'
                                            }}>
                                                Monto a
                                            </Text>
                                            <Text style={{
                                                fontSize: '14px',
                                                color: 'rgba(102, 110, 117, 0.79)',
                                                marginTop: 5
                                            }}>
                                                desembolsar
                                            </Text>
                                        </div>
                                    </div>
                                    <Text className={classes.transactionTitleText} style={{ fontSize: '13px' }}>{`$ ${lastDisbursment?.amount || 0}`}</Text>
                                </div>
                            </div>
                        }
                    </>
                }
            </div>
            <div className={classes.transactionBoxContainer}>
                <div className={classes.transactionTitleContainer}>
                    <img src={Icons.headerTransaction} style={{ width: 25, height: 25, marginLeft: 10, marginRight: 10 }} />
                    <Text className={classes.transactionTitleText}>Transacciones</Text>
                </div>
                <div className={classes.transactionHeaderContainer}>
                    <div className={headerSelected === 'legacy-txs' ? classes.transactionHeaderSelected : classes.transactionHeaderNonSelected} onClick={selectLegacyTxs}>
                        <Text className={classes.transactionTitleText}>Tienda</Text>
                    </div>
                    <div className={headerSelected === 'credit' ? classes.transactionHeaderSelected : classes.transactionHeaderNonSelected} onClick={selectCredit}>
                        <Text className={classes.transactionTitleText}>Crédito</Text>
                    </div>
                </div>
                {(headerSelected === 'credit' && <div style={{ marginLeft: '10px', marginRight: '10px', overflowY: 'scroll', height: '440px', marginTop: 20 }}>
                    {allTransactions.length !== 0 &&
                        <>
                            {allTransactions.map((data, index) => (
                                <>
                                    <div style={{ display: 'flex', marginTop: '20px', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingRight: 20, paddingLeft: 20 }}>
                                        <Text className={classes.transactionTitleText}>{moment(data.date).format('DD [de] MMMM YYYY')}</Text>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', marginTop: '20px', paddingRight: 20, paddingLeft: 20 }}>
                                        {data.movements.map((dataMovement, indexMovements) => (
                                            <TransactionItem
                                                movementList={data.movements}
                                                dataMovement={dataMovement}
                                                indexMovements={indexMovements}
                                                transactionsDetail={allTransactions}
                                            />
                                        ))}
                                    </div>
                                </>
                            ))
                            }
                        </>
                    }
                </div>)}
                {(headerSelected === 'legacy-txs' && <div style={{ marginLeft: '10px', marginRight: '10px', overflowY: 'scroll', height: '440px', marginTop: 20 }}>
                    {legacyTransactionsDetail &&
                        <>
                            <div style={{ display: 'flex', flexDirection: 'column', marginTop: '20px', paddingRight: 20, paddingLeft: 20 }}>
                                {legacyTransactionsDetail.map((data, index) => <LegacyTransactionItem {...data} key={index.toString()} />)}
                            </div>
                        </>
                    }
                </div>)}
            </div>
        </div>
    )
}

export default TransactionPage;

const styles = {
    containerGeneralTransaction: {
        display: 'flex',
        backgroundColor: '#f6f9fc',
        padding: 20,
        borderRadius: 18,
        marginRight: 20,
        marginLeft: 20
    },
    containerSubMenuCredit: {
        display: 'flex',
        backgroundColor: '#ffffff',
        padding: 15,
        borderRadius: 18,
        marginTop: 15
    }
}