import React, { useState, useEffect } from 'react';
import {
  TableContainer, Table, TableBody, TableRow, TableCell, TableFooter, TablePagination,
} from '@material-ui/core';
import TablePaginationActions from './TablePaginationActions';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import useStyles from './PaginationTable.style';
import Text from '../../../components/Text';
import ArrowedText from '../../../components/Text';
import Button from '../../../components/Button';
import { Box } from '../../../components/Layout';
import OrderedTableHead from './CustomerTableHead';
import {monthNames} from '../../../constants/constants'
import dateFunction from '../dateFunction'

export const CustomersTable = () => {
  const classes = useStyles();
  const dispatch = useDispatch()
  const {
    customers, page, pages, next,
  } = useSelector((state) => state.customersState);
  const [rowsPerPage, setRowsPerPage] = useState(10);

const statusColor = (e) =>
{
  if(e == "CANCELLED"){
    return "#db0202"
  } else { return "#0ec23e"}
}
const dateColor = (e) =>
{
  if(e === undefined){
    return "#0ec23e"
  } 
}
  return (
    <Box>
      <TableContainer className={classes.tableContainer}>
        <Table aria-label="custom pagination table">
          <OrderedTableHead />
          <TableBody>
            {
              customers.map((customer, index) => (
                <TableRow key={index} style={{ maxHeight: 43 }}>
                   <TableCell>
                    <Text className={classes.textStyle} >{customer.full_name}</Text>
                  </TableCell>
                  <TableCell>
                    <Text className={classes.textStyle} style={{color:dateColor(customer.overdue_date)}}>
                      {
                        customer.overdue_date ?  dateFunction(customer.overdue_date) : "AL DÍA"
                      }
                    </Text>
                  </TableCell>
                  <TableCell>
                    <Text className={classes.textStyle}>{customer.phone_number}</Text>
                  </TableCell>
                  <TableCell>
                  <Text className={classes.textStyle} style={{color:statusColor(customer.credit_status)}}>{customer.credit_status}</Text>
                  </TableCell>
                  <TableCell>
                  <Text className={classes.textStyle}>${customer.credit_details.credit_amount}</Text>
                  </TableCell>
                  <TableCell>
                    <Text className={classes.textStyle}>${customer.credit_details.balance}</Text>
                  </TableCell>
                  <TableCell>
                    <Text className={classes.textStyle}style={{textAlign:'center'}}>{customer.overdue_days}</Text> 
                  </TableCell>
                  <TableCell>
                    <Text className={classes.textStyle} style={{textAlign:'center'}}>{customer.next_payment_days}</Text>
                  </TableCell>
                  <TableCell>
                    <Link to={`/customers/${customer.customer_id}/customer-info`}>
                      <Button>
                        <ArrowedText>
                          Ver detalle
                        </ArrowedText>
                      </Button>
                    </Link>
                  </TableCell>
                </TableRow>
              ))
            }
          </TableBody>
              <TableFooter>
            <TableRow>
              <TablePagination
                classes={{
                  toolbar: classes.toolbar,
                  spacer: classes.spacer,
                }}
                rowsPerPageOptions={[]}
                rowsPerPage={rowsPerPage}
                labelDisplayedRows={() => ''}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                ActionsComponent={() => (
                  <TablePaginationActions
                    has_next={next}
                    page={(page - 1)}
                    totalPages={pages - 1}
                    count={customers.length}
                  />
                )}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Box>
  );
};

CustomersTable.propTypes = {
};

export default CustomersTable;
