import React from "react";

import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  TableFooter,
  TablePagination,
  Tooltip,
  Modal,
  Snackbar,
  CircularProgress,
} from "@material-ui/core";

import redAlert from "../../assets/icons/AlertRed.png";
import okCheck from "../../assets/icons/header-credit.png";
import warn from "../../assets/icons/warning.png";
import Text from "../Text";
import Button from "../Button";
import TextInput from "../TextInput";
import { Box } from "../Layout";
import { headCellsTreasuryDealer } from "../../constants/constants";
import { simpleDateFunction } from "../../constants/dateFunction";
import CopyButton from "../CopyButton/CopyButton";
import ConfirmationAlertModal from '../ConfirmationAlertModal/ConfirmationAlertModal';
import { Alert } from "@material-ui/lab";
function TableTreasuryDealer({
  classes,
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
  supplierData,
  isModalVisible,
  setIsModalVisible,
  handleInput,
  updateTreasuryElement,
  selectedTreasuryElement,
  sendDisbursement,
  sendTransfer,
  isDisabledSendTransfer,
  isDeleteDisbursementModalVisible,
  setIsDeleteDisbursementModalVisible,
  handleConfirmDisbursementModal,
  handleOpenDisbursementModal,
  isError,
  errorMessage,
  loading,
}) {
  const tableData =
    rowsPerPage > 0
      ? supplierData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      : supplierData;

  const mapValidationStatus = (status) => {
    switch (status) {
      case "NEW":
      case "INITIATED":
      case "PENDING":
        return "Procesando Validación";
      case "NOT_CREATED":
        return "Sin Validación";
      case "COMPLETED":
        return "Validación no Coincide";
      case "REJECTED":
        return "Validación Rechazada";
      default:
        return "Fallida";
    }
  };

  const informationTable = () => {
    return (
      <TableBody>
        {tableData.map((data, index) => {
          let warningTxt = "";
          if (!data?.allow_supplier_purchases) {
            warningTxt = "El cliente tiene bloqueo para disponer";
          }
          let bankAccountValidationTxt =
            "La cuenta bancaria no pertenece al titular";
          if (!data?.bank_account_validation?.has_validation) {
            bankAccountValidationTxt = "La cuenta bancaria no ha sido validada";
          }
          let rowStyle = { maxHeight: 43 };
          if (data?.has_pending_transfer) {
            rowStyle["backgroundColor"] = "#f2f4f5";
          }
          if(data?.revision_status === "APPROVED") {
            rowStyle["backgroundColor"] = "#D9E9FF";
          }
          if(data?.revision_status === "REJECTED") {
            rowStyle["backgroundColor"] = "#FFD9D9";
          }

          return (
            <TableRow key={index} style={rowStyle}>
              <TableCell>
                <Text className={classes.textCellTable}>
                  {data?.country || "-"}
                </Text>
              </TableCell>
              <TableCell>
                <Text
                  className={classes.textBoldStyle}
                  style={{ textAlign: "center", cursor: "pointer" }}
                >
                  {!data?.allow_supplier_purchases && (
                    <Tooltip arrow placement="top-end" title={warningTxt}>
                      <img width={"20px"} src={warn} />
                    </Tooltip>
                  )}
                </Text>
                <Text className={classes.textCellTable}>Tienda</Text>
              </TableCell>
              <TableCell style={{ minWidth: "70px" }}>
                <Text className={classes.textCellTable}>
                  {data?.account_id.substr(data.account_id.length - 6)}
                  <CopyButton elementToCopy={data?.account_id || ""} toolTipText={"Copiar id de la cuenta"}/>
                </Text>
              </TableCell>
              <TableCell>
                <Text className={classes.textCellTable}>
                  {simpleDateFunction(data?.created_at)}
                </Text>
              </TableCell>
              <TableCell>
                <Text className={classes.textCellTable}>
                  {data?.shopkeeper_name}
                </Text>
              </TableCell>
              <TableCell>
                <Text className={classes.textCellTable}>
                  {data?.bank_account_validation?.cep_beneficiary_name || "-"}
                </Text>
              </TableCell>
              <TableCell>
                <Text className={classes.textCellTable}>
                  {data?.bank_account_validation?.personhood_id || "-"}
                </Text>
              </TableCell>
              <TableCell>
                <Text className={classes.textCellTable}>
                  {data?.bank_account_validation?.cep_beneficiary_id || "-"}
                </Text>
              </TableCell>
              <TableCell>
                <Text className={classes.textCellTable}>{data?.shop_name}</Text>
              </TableCell>
              <TableCell>
                <Text className={classes.textCellTable}>
                  {data?.shopkeeper_email || "-"}
                </Text>
              </TableCell>
              <TableCell>
                <Text className={classes.textCellTable}>
                  {data?.bank_detail?.bank_account_manager || "-"}
                </Text>
              </TableCell>
              <TableCell>
                <Text className={classes.textCellTable}>
                  {data?.bank_detail?.bank_account_number || "-"}
                </Text>
              </TableCell>
              <TableCell>
                <Text className={classes.textCellTable}>
                  {!data?.bank_account_validation?.is_valid && (
                    <Tooltip
                      arrow
                      placement="top-end"
                      title={bankAccountValidationTxt}
                    >
                      <img width={"20px"} src={redAlert} />
                    </Tooltip>
                  )}
                </Text>
                <Text className={classes.textCellTable}>
                  {data?.bank_account_validation?.is_valid && (
                    <Tooltip
                      arrow
                      placement="top-end"
                      title={"Cuenta bancaria validada"}
                    >
                      <img width={"20px"} src={okCheck} />
                    </Tooltip>
                  )}
                </Text>
                <Text className={classes.textCellTable}>
                  {!data?.bank_account_validation?.is_valid && (
                    <p>
                      {mapValidationStatus(
                        data?.bank_account_validation?.validation_status
                      )}
                    </p>
                  )}
                </Text>
                <Text className={classes.textCellTable}>
                  {data?.bank_account_validation?.cep_url && (
                    <a
                      target={"_blank"}
                      href={data?.bank_account_validation?.cep_url}
                    >
                      Descargar CEP
                    </a>
                  )}
                </Text>
              </TableCell>
              <TableCell>
                <Text className={classes.textCellTable}>{`$${
                  data?.amount || "-"
                }`}</Text>
              </TableCell>
              <TableCell>
                <TextInput
                  className={classes.input}
                  type="text"
                  name="settled_amount"
                  onBlur={(event) =>
                    handleInput(
                      event,
                      `${data.account_id}-${data.credit_line_id}`
                    )
                  }
                />
              </TableCell>
              <TableCell>
                <TextInput
                  className={classes.input}
                  type="text"
                  name="id_shop_ref"
                  onBlur={(event) =>
                    handleInput(
                      event,
                      `${data.account_id}-${data.credit_line_id}`
                    )
                  }
                />
              </TableCell>
              <TableCell>
                {data?.country !== "DO" ? 
                  <>
                    {data?.has_pending_transfer ? (
                      <Text
                        className={classes.textCellTable}
                        style={{ textAlign: "center", fontSize: "12px" }}
                      >
                        En Proceso
                      </Text>
                    ) : (
                      <Button
                        isDisabled={isDisabledSendTransfer}
                        style={{ backgroundColor: "#0253cc", color: "#FFFFFF" }}
                        onClick={() => {
                          sendTransfer(data.credit_line_id, data.purchase_id);
                        }}
                      >
                        ENVIAR TRANSFER
                      </Button>
                    )}
                  </> :
                  <Text
                    className={classes.textCellTable}
                    style={{ textAlign: "center", fontSize: "20px" }}
                  >
                    -
                  </Text>
                }
              </TableCell>
              <TableCell>
                <Button
                  style={{ backgroundColor: "#0253cc", color: "#FFFFFF" }}
                  onClick={() => {
                    updateTreasuryElement(
                      data,
                      `${data.account_id}-${data.credit_line_id}`
                    );
                  }}
                >
                  DESEMBOLSAR
                </Button>
              </TableCell>
              <TableCell>
                <Button
                  style={{ backgroundColor: "#0253cc", color: "#FFFFFF" }}
                  onClick={() => {
                    handleOpenDisbursementModal(data.credit_line_id, data.purchase_id);
                  }}
                >
                  CANCELAR DESEMBOLSO
                </Button>
              </TableCell>
            </TableRow>
          );
        })}
        {tableData.length === 0 && (
          <TableRow>
            <TableCell colSpan={15}>
              Sin transferencias pendientes por el momento ...
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    );
  };

  return (
    <>
      <TableContainer>
        {loading?.GET_SUPPLIER ? (
          <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
          <CircularProgress  size={55}/>
          <Box mt={2}>
            <Text>Cargando...</Text>
          </Box>
        </Box>
        ) : (
          <Table aria-label="a dense table">
          <TableHead className={classes.tableHead}>
            <TableRow>
              {headCellsTreasuryDealer.map((headCell) => (
                <TableCell align="center" scope="col">
                  <Text className={classes.titleTable}>{headCell.label}</Text>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {informationTable()}
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[50, 100]}
                colSpan={15}
                count={supplierData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
  )}
      </TableContainer>
      <ConfirmationAlertModal
        open={isDeleteDisbursementModalVisible}
        setOpen={setIsDeleteDisbursementModalVisible}
        handleConfirmation={handleConfirmDisbursementModal}
      />
      <Modal
        open={isModalVisible}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={classes.modal}
      >
        <Box className={classes.modalContainer}>
          <Text className={classes.titleModal}>Confirmación de desembolso</Text>
          <Box className={classes.containerInfoModal}>
            <Text className={classes.titleInfoModal}>Tienda:</Text>
            <Text className={classes.textInfoModal}>
              {selectedTreasuryElement.shop_name}
            </Text>
          </Box>
          <Box className={classes.containerInfoModal}>
            <Text className={classes.titleInfoModal}>ID Tienda:</Text>
            <Text className={classes.textInfoModal}>
              {selectedTreasuryElement.shopkeeper_id_simple}
            </Text>
          </Box>
          <Box className={classes.containerInfoModal}>
            <Text className={classes.titleInfoModal}>Total a desembolsar:</Text>
            <Text
              className={classes.textInfoModal}
            >{`$${selectedTreasuryElement.amount_text}`}</Text>
          </Box>
          <Box className={classes.containerButtonModal}>
            <Button
              style={{
                backgroundColor: "#0253cc",
                color: "#FFFFFF",
                width: 100,
              }}
              onClick={() =>
                sendDisbursement(
                  selectedTreasuryElement.purchase_id,
                  selectedTreasuryElement.amount_text
                )
              }
            >
              Confirmar
            </Button>
            <Button
              style={{
                backgroundColor: "#0253cc",
                color: "#FFFFFF",
                width: 100,
              }}
              onClick={() => setIsModalVisible(false)}
            >
              Cancelar
            </Button>
          </Box>
        </Box>
      </Modal>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={3500}
        open={isError}
        className={classes.snackbar}
      >
        <Alert severity={'error'}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

export default TableTreasuryDealer;
