import React from 'react';
import { Link } from 'react-router-dom';
import AppBarM from '@material-ui/core/AppBar';
import { Toolbar, Grid } from '@material-ui/core';
import logo from '../../assets/logo.svg';
import defaultUser from '../../assets/user_profile.png';
import Text from '../Text';
import useStyles from './AppBar.styles';

const AppBar = ({ userName = 'nuevamente', imgProfile = defaultUser }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBarM
        elevation={0}
        position="static"
      >
        <Toolbar>
          <Link to="/registers">
            <div className={classes.appLogo}>
              <img className={classes.appLogo} src={logo} alt="logo" />
            </div>
          </Link>
          <div className={classes.emptySpace} />
          <div className={classes.rightMenu}>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="flex-end"
            >
              <Text className={classes.normalText}>{`¡Hola ${userName}!`}</Text>
              <Link to="/home" className={classes.customLink}>
                <Text className={classes.linkText}>Ir a mi perfil</Text>
              </Link>
            </Grid>
            <div className={classes.containerMenu}>
              <img className={classes.userLogo} src={imgProfile} alt="user_logo" />
            </div>
          </div>
        </Toolbar>
      </AppBarM>
    </div>
  );
};

export default AppBar;
