import React from 'react';
import { IconButton, Button } from '@material-ui/core';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { useDispatch } from 'react-redux';
import Text from '../../Text';
import useStyles from './TablePaginationActions.style';
import { getNextPage, getPreviousPage, getRegisters } from '../../../containers/Registers/registersActions';


export const TablePaginationActions = (props) => {
  const {
    count, page, rowsPerPage, onChangePage, has_next, totalPages,
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleBackButtonClick = (event) => dispatch(getPreviousPage());

  const handleNextButtonClick = (event) => dispatch(getNextPage());

  return (
    <div className={classes.root}>
      <IconButton
        className={classes.arrowButtonColor}
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        <KeyboardArrowLeft />
      </IconButton>
      {totalPages > 1 && (
      <Button className={classes.currentPage} onClick={() => dispatch(getRegisters(page + 1))} size="small">
        <Text>{`${page + 1}`}</Text>
      </Button>
      )}
      {totalPages > 2 && (
      <Button size="small" onClick={() => dispatch(getRegisters(page + 2))}>
        <Text>{`${page + 2}`}</Text>
      </Button>
      )}
      {totalPages > 3 && (
      <Button size="small" onClick={() => dispatch(getRegisters(page + 3))}>
        <Text>{`${page + 3}`}</Text>
      </Button>
      )}
      {totalPages > 4 && (
      <Button size="small" onClick={() => dispatch(getRegisters(page + 4))}>
        <Text>{`${page + 4}`}</Text>
      </Button>
      )}
      <IconButton
        className={classes.arrowButtonColor}
        onClick={handleNextButtonClick}
        disabled={!has_next}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
    </div>
  );
};

export default TablePaginationActions;
