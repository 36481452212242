import * as ACTION from './actionTypes';
import client from '../../client';
import { appService } from '../../services/app.service';

export const getShopCredit = (page) => async (dispatch, getState) => {

  const { searchString, sort, orderBy } = getState().registersState;
  const url = `${appService.config.paths.loan.route}?status=REVIEWING`;

  dispatch({ type: ACTION.FETCH_CUSTOMERS_INIT });
  const response = await client.get(url);
  console.log('url___> ', url);
  const { data } = response;
  console.log('DATA___> ', data);

  if (data && data.length > 0) {
    dispatch({ type: ACTION.FETCH_CUSTOMERS_SUCCESS });
    dispatch({ type: ACTION.SET_STATE, label: 'shopCredits', value: data });
  } else {
    dispatch({ type: ACTION.FETCH_CUSTOMERS_ERROR, error: 'No users returned from service' });
    dispatch({ type: ACTION.FETCH_CUSTOMERS_ERROR, error: null });
  }


}

export const getRegisters = (page) => async (dispatch, getState) => {
  const { searchString, sort, orderBy } = getState().registersState;
  const url = `${appService.config.paths.registers.route}${(page || orderBy || sort || searchString ? '?' : '')}${page ? `page=${page}&` : ''}${searchString ? `search=${searchString}&` : ''}${orderBy ? `order_by=${orderBy}&` : ''}${sort ? `sort=${sort}` : ''}`;
  dispatch({ type: ACTION.FETCH_CUSTOMERS_INIT });
  const response = await client.get(url);
  const { data } = response;
  const {
    data: customers, links, next, page: dataPage, pages, total_elements,
  } = data;

  if (data) {
    if (customers && 'length' in customers && customers.length > 0) {
      dispatch({ type: ACTION.FETCH_CUSTOMERS_SUCCESS });
      dispatch({ type: ACTION.SET_STATE, label: 'customers', value: customers });
    } else {
      dispatch({
        type: ACTION.FETCH_CUSTOMERS_ERROR, error: 'No users returned from service',
      });
      dispatch({
        type: ACTION.FETCH_CUSTOMERS_ERROR, error: null,
      });
    }

    dispatch({ type: ACTION.SET_STATE, label: 'links', value: links });
    dispatch({ type: ACTION.SET_STATE, label: 'next', value: next });
    dispatch({ type: ACTION.SET_STATE, label: 'page', value: dataPage });
    dispatch({ type: ACTION.SET_STATE, label: 'pages', value: pages });
    dispatch({ type: ACTION.SET_STATE, label: 'links', value: total_elements });
  }
};

export const getNextPage = () => (dispatch, getState) => {
  const { page } = getState().registersState;
  dispatch(getRegisters(page + 1));
};

export const getPreviousPage = () => (dispatch, getState) => {
  const { page } = getState().registersState;
  dispatch(getRegisters(page - 1));
};

export const approveCredit = (loan_id) => async (dispatch) => {
  const url = `${appService.config.paths.loan.route}/${loan_id}/approval`;

  try {
    await client.put(url, {}).then(
      () => {
        dispatch({ type: ACTION.UPDATE_ALERT_SUCCESS, value: 'Préstamo aprobado con éxito!' });
      },
    ).catch((error) => {
      dispatch({ type: ACTION.UPDATE_ALERT_ERROR, value: error.response.data.message });
    });
  } catch (e) {
    console.log(e);
  }
}
