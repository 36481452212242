import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
    modal: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    modalContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: 700,
        maxHeight: 500,
        borderRadius: 10,
        padding: 20,
        backgroundColor: "#FFF"
    },
    secondModalContainer: {
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 20
    },
    tableHead: {
        width: '100%',
        backgroundColor: '#F8F9F9',
    },
    titleTable: {
        color: '#858d9c',
        fontSize: 12,
        textAlign: 'center'
    },
    textCellTable: {
        color: '#3a3b3f',
        fontSize: 11,
        textAlign: 'center'
    },
}));

export default useStyles;