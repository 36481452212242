import React, { useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

import { appService } from '../../../services/app.service';

const options = {
    cMapUrl: 'cmaps/',
    cMapPacked: true,
    standardFontDataUrl: 'standard_fonts/',
};

const ContractPdfViewer = ({
    account_type_id,
    account_id,
}) => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const pdfFile = {
        url: `${appService.config.paths.api.basePath}${appService.config.paths.loan_request.route}/${account_type_id}/${account_id}/contract`,
        httpHeaders: {
            authorization: localStorage.getItem('firebaseToken'),
        },
    }

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    function changePage(offset) {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    function previousPage() {
        changePage(-1);
    }

    function nextPage() {
        changePage(1);
    }

    const downloadFile = (pdfFile) => () => {
        fetch(pdfFile.url, {
            method: 'GET',
            headers: pdfFile.httpHeaders,
        })
            .then((response) => response.blob())
            .then((blob) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'target',
                    '_blank',
                );
                link.setAttribute(
                    'download',
                    'contract.pdf',
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
            });
    }

    return (
        <div>
            <a href='#' onClick={downloadFile(pdfFile)}>Descargar</a>
            <Document
                file={pdfFile}
                onLoadSuccess={onDocumentLoadSuccess}
                loading="Cargando el PDF del Contrato ..."
                noData="PDF del Contrato no encontrado"
                error="Error cargando PDF del Contrato"
                options={options}>
                <Page pageNumber={pageNumber} />
            </Document>
            <div>
                <p>
                    P&aacute;gina {pageNumber || (numPages ? 1 : '--')} de {numPages || '--'}
                </p>
                <button
                    type="button"
                    disabled={pageNumber <= 1}
                    onClick={previousPage}
                >
                    &lt;
                </button>
                <button
                    type="button"
                    disabled={pageNumber >= numPages}
                    onClick={nextPage}
                >
                    &gt;
                </button>
            </div>
        </div>
    );
}

export default ContractPdfViewer;