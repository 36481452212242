import React, { useState } from "react";

import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
} from "@material-ui/core";

import moment from "moment";
import Text from "../Text";

import { headCellDatingHistory } from "../../constants/constants";
import Button from "../../components/Button";
import CustomModal from "../../components/CustomModal";
import { putAppointmentAction } from "../../reducers/appointment.action";
import { useDispatch } from "react-redux";
import TextInput from "../TextInput/TextInput";
function TableDatingHistory({ classes, appointmentData }) {
  const dispatch = useDispatch();
  let id = "";
  let status = "";
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [appointmentId, setAppointmentId] = useState(id);
  const [appointmentResult, setAppointmentResult] = useState("");
  const [appointmentStatus, setAppointmentStatus] = useState(status);
  const [commentContent, setCommentContent] = useState("");

  const handleChangeAppointmentResult = (event) => {
    setAppointmentResult(event.target.value);
  };
  const updateAppointmentStatus = (appointment_id) => {
    let body = {
      result:
        appointmentStatus === "Scheduled"
          ? appointmentResult
          : appointmentStatus,
      commnets: commentContent,
    };
    dispatch(putAppointmentAction(appointment_id, body)).then(
      setTimeout(() => {
        window.location.reload();
      }, 1800)
    );
  };

  function capitalizarCadena(status) {
    var minusculas = status.toLowerCase();
    var capitalizada = minusculas.charAt(0).toUpperCase() + minusculas.slice(1);
    return capitalizada;
  }
  const revisionStatus = (data) => {
    if (data?.appointment_status === "INACTIVE") {
      return (
        <div
          style={{
            backgroundColor: "rgba(199, 87, 25, 0.2)",
            alignItems: "center",
            paddingTop: 3,
            paddingBottom: 3,
            borderRadius: 50,
          }}
        >
          <Text className={classes.textCellTable}>Cancelada</Text>
        </div>
      );
    } else if (data?.appointment_status === "ACTIVE") {
      return (
        <div
          style={{
            backgroundColor: "rgba(87, 199, 25, 0.2)",
            alignItems: "center",
            paddingTop: 3,
            paddingBottom: 3,
            borderRadius: 50,
          }}
        >
          <Text className={classes.textCellTable}>Vigente</Text>
        </div>
      );
    } else {
      return;
    }
  };
  const informationTable = () => {
    return (
      <TableBody>
        {appointmentData.map((data, index) => (
          <TableRow key={index} style={{ maxHeight: 43 }}>
            <TableCell>
              <Text className={classes.textCellTable}>{data?.event_type}</Text>
            </TableCell>
            <TableCell>
              <Text className={classes.textCellTable}>
                {data?.event_type_name}
              </Text>
            </TableCell>
            <TableCell>
              <Text className={classes.textCellTable}>
                {moment(data?.event_start_time).format("DD/MM/YYYY HH:mm")}
              </Text>
            </TableCell>
            <TableCell>
              <Text className={classes.textCellTable}>
                {data?.invitee_name}
              </Text>
            </TableCell>
            <TableCell>{revisionStatus(data)}</TableCell>
            <TableCell>
              {!data?.result || data?.result === "SCHEDULED" ? (
                <Text className={classes.alertTextCellTable}>
                  Pendiente de resultado
                </Text>
              ) : (
                <Text className={classes.textCellTable}>
                  {data?.result === "RESCHEDULED"
                    ? "Reagendada"
                    : data?.result === "ATTENDED"
                    ? "Atendida"
                    : "Cancelada"}
                </Text>
              )}
            </TableCell>
            <TableCell>
              {!data?.comments && data?.result !== "" ? (
                <Button
                  variant="contained"
                  color="secondary"
                  children={"Agregar resultado y comentario"}
                  onClick={() => {
                    id = data?.appointment_id;
                    status = data?.result;
                    setAppointmentId(id);
                    setAppointmentStatus(capitalizarCadena(status));
                    setIsOpenModal(true);
                  }}
                />
              ) : (
                <Text className={classes.textCellTable}>{data?.comments}</Text>
              )}
            </TableCell>
          </TableRow>
        ))}
        <CustomModal
          closeButton
          open={isOpenModal}
          setOpen={setIsOpenModal}
          title={"Resultado de la cita"}
        >
          <FormControl style={{ marginLeft: 50, width: 150 }}>
            {appointmentStatus === "Scheduled" && (
              <InputLabel>Resultado: </InputLabel>
            )}
            {appointmentStatus === "Scheduled" ? (
              <Select
                value={appointmentResult}
                onChange={handleChangeAppointmentResult}
              >
                <MenuItem value={"Attended"}>Asistió</MenuItem>
                <MenuItem value={"Rescheduled"}>Reagendó</MenuItem>
                <MenuItem value={"Canceled"}>Canceló</MenuItem>
              </Select>
            ) : (
              <Select value={appointmentStatus} disabled>
                <MenuItem value={appointmentStatus}>
                  {appointmentStatus === "Rescheduled"
                    ? "Reagendó"
                    : appointmentStatus === "Canceled"
                    ? "Canceló"
                    : appointmentStatus === "Attended" && "Atendió"}
                </MenuItem>
              </Select>
            )}

            <Box style={{ marginTop: 20 }}>
              <TextInput
                label="Comentarios"
                variant="standard"
                value={commentContent}
                onChange={(value) => setCommentContent(value.target.value)}
                multiline
                rows={4}
                inputProps={{ maxLength: 150 }}
              />
              <Box style={{ marginTop: 12, marginBottom: 12 }}>
                <Text>
                  {150 - commentContent.length + " caracteres disponibles"}
                </Text>
              </Box>
            </Box>
            <Box style={{ marginTop: 12, display: "flex", height: 30 }}>
              <Button
                variant="contained"
                color="secondary"
                children={"Aceptar"}
                onClick={() => {
                  updateAppointmentStatus(appointmentId);
                  setIsOpenModal(false);
                }}
                isDisabled={
                  appointmentStatus === "Scheduled"
                    ? appointmentResult === "" || commentContent.length < 10
                    : commentContent.length < 10
                }
              />
            </Box>
          </FormControl>
        </CustomModal>
      </TableBody>
    );
  };

  return (
    <TableContainer>
      <Table aria-label="a dense table">
        <TableHead className={classes.tableHead}>
          <TableRow>
            {headCellDatingHistory.map((headCell) => (
              <TableCell align="center" scope="col">
                <Text key={headCell.id} className={classes.titleTable}>
                  {headCell.label}
                </Text>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {informationTable()}
      </Table>
    </TableContainer>
  );
}

export default TableDatingHistory;
