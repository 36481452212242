import client from "../client";

export const getPresaleAdvisors = () => {
  const url = `admin/presale-advisors`;
  return new Promise((resolve, reject) => {
    client.get(url).then(
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      }
    );
  });
};

export const putPresaleAdvisor = (userType,userId,body) => {
  const url = `admin/user/${userType}/${userId}/presale-advisor`;
  return new Promise((resolve, reject) => {
    client.put(url,body).then(
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      }
    );
  });
};

export const postPresaleAdvisorTask = (body) => {
  const url = `admin/presale-advisors/task`;
  return new Promise((resolve, reject) => {
    client.post(url,body).then(
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      }
    );
  });
};