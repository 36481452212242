import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    minHeight: 'calc(100vh - 64px)',
  },
  content: {
    width: '100%',
    maxWidth: 1200,
    padding: theme.spacing(2),
  },
  title: {
    color: '#000',
    fontSize: 14,
    marginBottom: 20,
    textAlign: 'left',
  },
  centerContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 300,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  tableHead: {
    backgroundColor: '#F8F9F9',
  },
  titleTable: {
    color: '#858d9c',
    fontSize: 12,
    textAlign: 'center'
  },
  textCellTable: {
    color: '#3a4760',
    fontSize: 12,
    textAlign: 'center'
  },
}));

export default useStyles;