import React, { useEffect } from 'react';
import {
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from '../../../Shops/OrderedTableHead.style';
import Text from '../../../../components/Text';

export const HistoryTableHead = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { page, orderBy, sort } = useSelector((state) => state.customersState);

  useEffect(() => {
  }, [orderBy, sort]);
  
  const headCells = [
    {
      id: 'created_at', numeric: false, disablePadding: false, label: 'Fecha',
    },
    {
      id: 'action', numeric: false, disablePadding: false, label: 'Acción',
    },
    {
      id: 'asked_by', numeric: false, disablePadding: false, label: 'Solicitado por',
    },
    {
      id: 'type', numeric: false, disablePadding: false, label: 'Tipo de bloqueo',
    },
    {
      id: 'reasons_description', numeric: false, disablePadding: false, label: 'Razones / Descripcion',
    }
  ];

  return (
    <TableHead className={classes.tableHead}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            align="center"
            scope="col"
            sort={orderBy === headCell.id ? sort : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? sort : 'asc'}
            >
              <Text className={classes.titleLabel}>{headCell.label}</Text>
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default HistoryTableHead;
