import React from 'react';
import Text from '../Text';
import useStyles from './SectionTitle.style';

export const SectionTitle = ({ children }) => {
  const classes = useStyles();
  return (
    <Text className={classes.sectionTitle}>{children}</Text>
  );
};

SectionTitle.defaultProps = {
  children: '',
};

export default SectionTitle;
