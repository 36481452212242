import React from "react";
import PropTypes from "prop-types";
import CheckIcon from "@material-ui/icons/Check";
import ErrorTwoToneIcon from "@material-ui/icons/ErrorTwoTone";
import { useHistory } from "react-router-dom";
import Text from "../Text";
import { StepArrowIcon } from "../Icons/StepArrowIcon";
import useStyles from "../../containers/CustomerDetails/CustomerDetails.style";
import Box from "../Layout/Box";

const StepArrowShopkeeper = (props) => {
    const classes = useStyles();
    let stepText = "";
    let arrowColor = "#14b497";
    const history = useHistory();
    const { error, status } = props
    let done = false, current = false, disabled = false, alert = false

    const { step, nextStep } = props;

    function getText(step) {
        switch (step) {
            case 'INIT':
                return 'Flujo iniciado';
            case 'SIGN_UP':
                return 'Sobre ti';
            case 'EXTRA_DATA':
                return 'Sobre ti';
            case 'TAX_INFO':
                return 'Datos fiscales';
            case 'ADDRESS':
                return 'Datos fiscales';
            case 'ABOUT_STORE':
                return 'Sobre tu negocio';
            case 'BUSINESS_ADDRESS':
                return 'Dirección del negocio'
            case 'ACCOUNT_NIP':
                return 'NIP Buró';
            case 'ACCOUNT_BUREAU':
                return 'Buró consultado';
            case 'PASSWORD':
                return 'Contraseña asignada';
            case 'INFO_NEEDED':
                return 'Requisitos';
            case 'PENDING_APPROVAL':
                return 'Mesa de aprobación';
            case 'CONTRACT_GENERATED':
                return 'Contrato generado';
            case 'KYC_REQUEST':
                 return 'Contrato generado';
            case 'PROCESSING_KYC':
                return 'Contrato generado';
            case 'TITULAR_SIGNED':
                return 'Contrato firmado';
            case 'CONTRACT_SIGNED':
                return 'Contrato firmado';
            case 'WAITING_CONTRACT_COLLATERALS':
                return 'Contrato firmado';
            case 'END':
                return 'Aprobado';
            default:
                break;
        }
        return '';
    }

    let ns = nextStep
    if (nextStep === 'WISH_NEW_COLLATERAL' || nextStep === 'ASK_NEW_COLLATERAL' || nextStep === 'ASK_REPLACE_PARTNER') {
        ns = 'NEW_COLLATERAL'
        if (step === 'NEW_COLLATERAL' && (nextStep === 'ASK_NEW_COLLATERAL' || nextStep === 'ASK_REPLACE_PARTNER')) {
            alert = true
        }
    }
    if (status === 'SUCCESS') {
        ns = 'END'
    }

    const steps = ['INIT',
        'SIGN_UP',
        'EXTRA_DATA',
        'TAX_INFO',
        'ADDRESS',
        'ABOUT_STORE',
        'BUSINESS_ADDRESS',
        'ACCOUNT_NIP',
        'ACCOUNT_BUREAU',
        'PASSWORD',
        'INFO_NEEDED',
        'PENDING_APPROVAL',
        'CONTRACT_GENERATED',
        'KYC_REQUEST',
        'PROCESSING_KYC',
        'TITULAR_SIGNED',
        'WAITING_CONTRACT_COLLATERALS',
        'CONTRACT_SIGNED',
        'END']
    if (ns === 'KYC_REQUEST') {
        ns = 'CONTRACT_GENERATED';
    } else if (ns === 'TITULAR_SIGNED' || ns === 'WAITING_CONTRACT_COLLATERALS') {
        ns = 'CONTRACT_SIGNED';
    }
    const i = steps.indexOf(step);
    const j = steps.indexOf(ns);
    current = i === j
    done = i < j
    disabled = i > j

    stepText = getText(step);

    if (current) {
        arrowColor = "#0253cc";
    } else if (disabled) {
        arrowColor = "#3a4760";
    } else if (done) {
        arrowColor = "#14b497";
    } else if (error) {
        arrowColor = "#ea4b4b";
    }
    return (
        <Box className={classes.stepArrowContainer}>
            <StepArrowIcon
                aria-label="delete"
                className={classes.stepArrowChild}
                color={arrowColor}
                size={2.1}
                disabled={disabled}
            >
                <Box className={classes.stepArrowLabel}>
                    {done && <CheckIcon htmlColor="white" />}
                    {error && <ErrorTwoToneIcon htmlColor="white" />}
                    {alert && <ErrorTwoToneIcon htmlColor="#ffc300" />}
                    <Text>{`${stepText}`}</Text>
                </Box>
            </StepArrowIcon>
        </Box>
    );
};

export default StepArrowShopkeeper;

StepArrowShopkeeper.propTypes = {
    step: PropTypes.string.isRequired,
};
