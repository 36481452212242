import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    controls: {
        display: 'flex',
        position: 'relative',
        width: '100%',
        marginTop: '20px',
        color: '#858d9c',
        alignItems: 'center',
    },
    buttonSubMenuContainer: {
        marginRight: '20px',
        marginTop: '20px',
    },
    subMenuTextOn: {
        fontWeight: 'bold',
        color: '#0253cc',
        paddingBottom: '6px',
        borderBottom: '2px solid #0253cc',
    },
    subMenuTextOff: {
        fontWeight: 'bold',
        color: '#858d9c'
    },
    modalRejection: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalContainerZoom: {
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 15,
        backgroundColor: '#ffffff',
    },
    imageZoomStyle: {
        marginTop: 15,
        width: '500x',
        height: '600px',
        borderEndStartRadius: 15,
        borderEndEndRadius: 15,
    },
    buttonCloseZoom: {
        width: 30,
        height: 30,
        marginTop:  10,
        marginRight: 15,
        alignSelf: 'end'
    },
    textTickets: {
        fontSize: 14,
        fontWeight: 'bold',
        marginTop: 20,
        marginLeft: 10
    },
    containerDealer: {
        marginLeft: 10,
        marginTop: 10,
        flexDirection: 'row',
        alignItems: 'center'
    },
    titleDealer: {
        fontWeight: 'bold',
        marginRight: 5
    }
}));

export default useStyles;
