import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  creditStatusText: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    color: 'white',
    borderRadius: 10,
    paddingBottom: 6,
    paddingTop: 4,
    height: 10,
    alignSelf: 'center',
    textAlign: 'center',
    fontSize: 'smaller',
    width: '5%',
    minWidth: 80,
  },
  pending: {
    border: 'solid 1px #d9a706',
    backgroundColor: '#ffc300',
  },
  blocked: {
    border: 'solid 1px #b02828',
    backgroundColor: '#ea4b4b',
  },
  rejected: {
    border: 'solid 1px #b02828',
    backgroundColor: '#ea4b4b',
  },
  approved: {
    border: 'solid 1px #14b497',
    backgroundColor: '#0a967d',
  },
}));

export default useStyles;
