import * as ACTION from "./notifications.actionTypes";
import * as notificationsService from "../services/notifications.service";

export const postWhatsappMessage = (body) => async (dispatch) => {
  dispatch({
    type: ACTION.INIT_POST_WHATSAPP_MESSAGE,
  });

  try {
    const messageData = await notificationsService.postWhatsappMessage(body);
    if (messageData) {
      dispatch({
        type: ACTION.RESPONSE_POST_WHATSAPP_MESSAGE,
        result: messageData,
      });
    }
  } catch (error) {
    dispatch({
      type: ACTION.ERROR_POST_WHATSAPP_MESSAGE,
      error,
    });
  }
};

export const clearWhatsappMessageData = () => async (dispatch) => {
  dispatch({
    type: ACTION.CLEAR_POST_WHATSAPP_MESSAGE,
  });
};

export const clearErrors = () => async (dispatch) => {
  dispatch({
    type: ACTION.CLEAR_ERRORS,
  });
};
