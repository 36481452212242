import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  customerValidationStatus: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingTop: 10,
    paddingBottom: 10,
    width: '30%',
  },
  validationRow: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    color: '#858d9c',

    '& div': {
      display: 'flex',

      '& p': {
        display: 'flex',
        alignSelf: 'center',
      },
    },

    '& img': {
      width: 40,
      marginRight: 10,
    },
  },
}));

export default useStyles;
