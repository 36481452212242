import React, { useState } from "react";
import PropTypes, { func } from "prop-types";
import { List } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import FormHelperText from "@material-ui/core/FormHelperText";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Box from "../../../components/Layout/Box";
import useStyles from "./CutoffList.styles";
import Text from "../../../components/Text";
import CutoffCard from "../../../components/CutoffCard/CutoffCard";
import { monthNamesFull } from "../../../constants/constants";

const CutoffList = (props) => {
  const {
    deadline_date,
    name,
    start_date,
    date,
    cutoff_id,
    amount,
    status,
    balance,
    click,
    selected,
    list
  } = props;

  const click_fn = click || function () {};
  const [cut, setCut] = useState(0);

  return (
    <Box
      style={{ flex: 0.2, marginRight: "18%", borderColor: "lightgray" }}
      borderRight={2}
    >
      <List component="nav" aria-label="secondary mailbox folders">
        <ListItem button onClick={click_fn}>
          <CutoffCard
            cutoff_id={cutoff_id}
            name={name}
            start_date={start_date}
            date={date}
            deadline_date={deadline_date}
            amount={amount}
            status={status}
            balance={balance}
            selected={selected}
            list={list}
          />
        </ListItem>
      </List>
    </Box>
  );
};

CutoffList.propTypes = {};

export default CutoffList;
