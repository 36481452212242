export const INIT_POST_PAYMENT_AGREEMENTS = 'PAYMENT::INIT_POST_PAYMENT_AGREEMENTS';
export const RESPONSE_POST_PAYMENT_AGREEMENTS = 'PAYMENT::RESPONSE_POST_PAYMENT_AGREEMENTS';
export const ERROR_POST_PAYMENT_AGREEMENTS = 'PAYMENT::ERROR_POST_PAYMENT_AGREEMENTS';
export const CLEAR_POST_PAYMENT_AGREEMENTS = 'PAYMENT::CLEAR_POST_PAYMENT_AGREEMENTS';

export const INIT_GET_PAYMENT_AGREEMENTS = 'PAYMENT::INIT_GET_PAYMENT_AGREEMENTS';
export const RESPONSE_GET_PAYMENT_AGREEMENTS = 'PAYMENT::RESPONSE_GET_PAYMENT_AGREEMENTS';
export const ERROR_GET_PAYMENT_AGREEMENTS = 'PAYMENT::ERROR_GET_PAYMENT_AGREEMENTS';
export const CLEAR_GET_PAYMENT_AGREEMENTS = 'PAYMENT::CLEAR_GET_PAYMENT_AGREEMENTS';

export const CLEAR_ERRORS = 'PAYMENT::CLEAR_ERRORS';
