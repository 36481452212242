import React from "react";
import TextField from "@material-ui/core/TextField";
import { styled } from "@material-ui/styles";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#3050FF",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#3050FF",
  },
});

const TextInput = (props) => (
  <CssTextField {...props} style={{ fontFamily: "Poppins", lineHeight: 1.3 }}>
    {props.children}
  </CssTextField>
);

export default TextInput;
