import * as ACTIONS from '../../reducers/shops.actionTypes';
import shopsService from '../../services/shops.service';

export const getSettlementsByType = (settlementType) => async (dispatch, getState) => {
  dispatch({
    type: ACTIONS.FETCH_SHOPS_INIT,
  });

  try {
    const shops = await shopsService.getResourceList({ type: settlementType });

    if (shops) {
      dispatch({
        type: ACTIONS.FETCH_SHOPS_SUCCESS,
      });
      dispatch({
        type: ACTIONS.SET_STATE,
        payload: {
          label: 'settlements',
          value: shops,
        },
      });
    } else {
      dispatch({
        type: ACTIONS.FETCH_SHOPS_ERROR,
      });
    }
  } catch (e) {
    dispatch({
      type: ACTIONS.FETCH_SHOPS_ERROR,
    });
  }
};

export const getSettlementDetailByType = (settlementId, settlementType) => async (dispatch, getState) => {
  dispatch({
    type: ACTIONS.FETCH_SHOPS_INIT,
  });

  try {
    const shops = await shopsService.getSingleResource(settlementId, { type: settlementType });

    const shopsWithKey = shops.map((shop) => ({ ...shop, key: `${shop.shopkeeper_id}-${shop.settlement_id}` }));

    if (shops) {
      dispatch({
        type: ACTIONS.FETCH_SHOPS_SUCCESS,
      });

      dispatch({
        type: ACTIONS.SET_STATE,
        payload: {
          label: 'shops',
          value: shopsWithKey,
        },
      });
    }
  } catch (e) {
    dispatch({
      type: ACTIONS.FETCH_SHOPS_ERROR,
    });
  }
};

export const getSettlementTransactions = (settlementId, shopkeeperId) => async (dispatch, getState) => {
  dispatch({
    type: ACTIONS.FETCH_SHOPS_INIT,
  });

  try {
    const transactions = await shopsService.getTransactions(settlementId, { shopkeeper_id: shopkeeperId });

    if (transactions) {
      dispatch({
        type: ACTIONS.FETCH_SHOPS_SUCCESS,
      });

      dispatch({
        type: ACTIONS.SET_STATE,
        payload: {
          label: 'transactions',
          value: transactions,
        },
      });
    }
  } catch (e) {
    dispatch({
      type: ACTIONS.FETCH_SHOPS_ERROR,
    });
  }
};

export const postOneTransaction = (settlementId, transaction) => async (dispatch, getState) => {
  const transactions = [transaction];
  try {
    await shopsService.postOneTransaction(settlementId, transactions);
    dispatch({
      type: ACTIONS.ADD_SETTLEMENT_TRANSACTION,
    });
  } catch (e) {
    console.log(e);
    dispatch({
      type: ACTIONS.FETCH_SHOPS_ERROR,
    });
  }
};

export const addSelectedShop = (selectedShop) => async (dispatch, getState) => {
  dispatch({
    type: ACTIONS.ADD_SELECTED_SHOP,
    payload: selectedShop,
  });
};

export const deleteSelectedShop = (selectedShop) => async (dispatch, getState) => {
  dispatch({
    type: ACTIONS.DELETE_SELECTED_SHOP,
    payload: selectedShop,
  });
};

export const clearSelectedShopsSet = () => async (dispatch, getState) => {
  dispatch({
    type: ACTIONS.CLEAR_SELECTED_SHOPS,
  });
};

export const toggleSelectedShop = (shop, newState) => async (dispatch, getState) => {
  if (newState) {
    dispatch(addSelectedShop(shop));
  } else {
    dispatch(deleteSelectedShop(shop));
  }
};

export const getSearchShops = (settlementType) => async (dispatch, getState) => {
  dispatch({
    type: ACTIONS.FETCH_SHOPS_INIT,
  });

  try {
    const shops = await shopsService.getSearchShopsList();

    if (shops) {
      dispatch({
        type: ACTIONS.FETCH_SHOPS_SUCCESS,
      });
      dispatch({
        type: ACTIONS.SET_STATE,
        payload: {
          label: 'searchShops',
          value: shops,
        },
      });
    } else {
      dispatch({
        type: ACTIONS.FETCH_SHOPS_ERROR,
      });
    }
  } catch (e) {
    dispatch({
      type: ACTIONS.FETCH_SHOPS_ERROR,
    });
  }
};

export const getTransactionTypes = () => async (dispatch, getState) => {
  dispatch({
    type: ACTIONS.FETCH_SHOPS_INIT,
  });

  try {
    const transactionTypes = await shopsService.getTransactionTypes();

    if (transactionTypes) {
      dispatch({
        type: ACTIONS.FETCH_SHOPS_SUCCESS,
      });
      dispatch({
        type: ACTIONS.SET_STATE,
        payload: {
          label: 'transactionTypes',
          value: transactionTypes,
        },
      });
    } else {
      dispatch({
        type: ACTIONS.FETCH_SHOPS_ERROR,
      });
    }
  } catch (e) {
    dispatch({
      type: ACTIONS.FETCH_SHOPS_ERROR,
    });
  }
};

export const postTransaction = (transaction) => async (dispatch, getState) => shopsService.postTransaction(transaction).then(({ status }) => status);

export const getSettlementsForCollections = (shopId) => async (dispatch, getState) => {
  dispatch({
    type: ACTIONS.FETCH_SHOPS_COLLECTION_INIT,
  });

  try {
    const shopsPositive = await shopsService.getResourceList({ type: 'POSITIVE', shopkeeper_id: shopId });
    const shopsNegative = await shopsService.getResourceList({ type: 'NEGATIVE', shopkeeper_id: shopId });

    if (shopsPositive && shopsNegative) {
      dispatch({
        type: ACTIONS.FETCH_SHOPS_COLLECTION_SUCCESS,
      });
      dispatch({
        type: ACTIONS.SET_STATE_COLLECTION,
        payload: {
          label: 'settlementsPositive',
          value: shopsPositive,
        },
      });
      dispatch({
        type: ACTIONS.SET_STATE_COLLECTION,
        payload: {
          label: 'settlementsNegative',
          value: shopsNegative,
        },
      });
    } else {
      dispatch({
        type: ACTIONS.FETCH_SHOPS_COLLECTION_ERROR,
      });
    }
  } catch (e) {
    dispatch({
      type: ACTIONS.FETCH_SHOPS_COLLECTION_ERROR,
    });
  }
};
