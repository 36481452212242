import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '../Layout';
import Text from '../Text';

const LabeledText = ({ text, label }) => (
  <Box  style={{display: 'flex', flexDirection: 'column', padding:10}} >
    <Text variant="h3">{label}</Text>
    <Text variant="h6">{text}</Text>
  </Box>
);

LabeledText.propTypes = {
  text: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default LabeledText;
