import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';

import DetailReviewVouchersUI from "./DetailReviewVouchers";
import useStyles from './DetailReviewVouchers.styles';
import { getShokeeperDetailById } from "../Shop/ShopPage.actions";
import { getCreditDetail } from "../Shop/SubMenu/CreditInformation/CreditInformation.actions";
import { 
    getSuppliers,
    clearSupplier,
} from "../../reducers/dealer.action";
import { 
    getVouchersWeekly, 
    clearGetVouchersWeekly,
} from "../../reducers/reviewVouchers.action";


function DetailReviewVouchers(props) {
    const { creditLineId, accountId } = props.match.params;
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const [dataVouchersClient, setDataVouchersClient] = useState([]);
    const [ zoomModal, setZoomModal ] = useState({
        show: false,
        image: ''
    });

    const {
        shokeeper_detail,
    } = useSelector((state) => state.shopState);

    const {
        credit_line_shop_detail,
    } = useSelector((state) => state.creditInformationState);

    const {
        getVouchersWeeklyData,
    } = useSelector((state) => state.reviewVoucherReducer);

    useEffect(() => {
        dispatch(getShokeeperDetailById(accountId));
        dispatch(getCreditDetail(creditLineId));
        dispatch(getVouchersWeekly(creditLineId));
        dispatch(getSuppliers());
    }, [accountId, creditLineId]);

    useEffect(() => {
        if(Object.keys(getVouchersWeeklyData).length !== 0) {
            if(getVouchersWeeklyData?.data && getVouchersWeeklyData.data) {
                setDataVouchersClient(getVouchersWeeklyData.data)
            }
        }
    }, [getVouchersWeeklyData]);

    const toReturn = () => {
        dispatch(clearGetVouchersWeekly());
        dispatch(clearSupplier());
        history.replace(`/review-voucher`);
    };

    return <DetailReviewVouchersUI
        classes={classes}
        toReturn={toReturn}
        shopkeeper_detail={shokeeper_detail}
        credit_line_shop_detail={credit_line_shop_detail}
        dataVouchersClient={dataVouchersClient}
        zoomModal={zoomModal}
        setZoomModal={setZoomModal}
        creditLineId={creditLineId}
        accountId={accountId}
    />
}

export default DetailReviewVouchers;