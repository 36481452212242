import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  generalContainer: { margin: 15, display: 'flex', alignItems: 'center' },
  icon: { marginRight: 15 },
  text: { color: '#3050FF', fontWeight: 600, fontSize: 16 },
  buttonContainer: {
    height: 60,
    backgroundColor: '#0253CC0D',
    borderColor: '#E8E8FC',
    borderWidth: 1,
    borderStyle: 'solid',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    width: '100%',
  },
  datePickerContainer: { display: 'flex', flexDirection: 'row' },
}));

export default useStyles;
