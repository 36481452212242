import client from '../client';

// Policy Statement Closing
export const getPolicyStatementClosing = (account_id, policy_id, payment_status, closing_date) => {
    const url = `insurance/user/${account_id}/policy/${policy_id}/statement-closings?payment_status=${payment_status}&closing_date=${closing_date}`;
    return new Promise((resolve, reject) => {
        client.get(url).then(
            (response) => {
                resolve(response);
            },
            (error) => {
                reject(error);
            },
        );
    });
};

// Policy
export const getPolicy = (account_id, policy_type, policy_status) => {
    const url = `insurance/user/${account_id}/policies?policy_type=${policy_type}&policy_status=${policy_status}`;
    return new Promise((resolve, reject) => {
        client.get(url).then(
            (response) => {
                resolve(response);
            },
            (error) => {
                reject(error);
            },
        );
    });
};