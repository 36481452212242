export const formatCurrency = (value) => {
    const number = parseFloat(value);

    return new Intl.NumberFormat('es-MX', { 
        style: 'currency', 
        currency: 'MXN', 
        minimumFractionDigits: 2, 
        maximumFractionDigits: 2 
    }).format(number);
};

export const generateLastFiveMonths = () => {
    const months = [
        'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
        'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
    ];
    
    const currentDate = new Date();
    const options = [];

    for (let i = 0; i < 5; i++) {
        const d = new Date(currentDate.getFullYear(), currentDate.getMonth() - i, 1);
        const monthName = months[d.getMonth()];
        const year = d.getFullYear();
        const monthValue = `${year}-${String(d.getMonth() + 1).padStart(2, '0')}`;
        
        options.push({
            value: monthValue,
            label: `${monthName} ${year}`
        });
    }

    return options;
};