import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  sectionTitle: {
    fontSize: 22,
    lineHeight: 2.55,
    color: '#000000'
  }
}));

export default useStyles;
