import React from "react";
import PropTypes from "prop-types";
import { 
    Modal,
    Checkbox,
    TableContainer,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Snackbar
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

import CloseIcon from '@material-ui/icons/Close';
import { Box } from "../Layout";
import Text from "../Text/Text";
import Button from "../Button/Button";
import { headCellReactivateCollateral } from "../../constants/constants";

function ModalReactivateCollateral({
    classes,
    open,
    setOpen,
    avalsData,
    isRowSelected,
    handleCheckboxChange,
    reactivateCollateralAction,
    openAlert,
    handleCloseAlert,
    isError
}) {
    
    const filterAvals = avalsData?.avals?.filter(item => item.aval_type === "COLLATERAL" && item.bureau_decision === "REJECTED");

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    function informationTable() {
        return (
            <TableBody>
                {filterAvals.map((data) => (
                    <TableRow key={data?.id} style={{maxHeight: 30 }}>
                        <TableCell style={{width: 10}}>
                            <Checkbox
                                checked={isRowSelected(data?.id)}
                                onChange={(event) => handleCheckboxChange(event, data?.id)}
                                size="small"
                            />
                        </TableCell>
                        <TableCell>
                            <Text className={classes.textCellTable}>{`${data?.name} ${data?.lastname} ${data?.maternal_lastname}`}</Text>
                        </TableCell>
                        <TableCell>
                            <Text className={classes.textCellTable}>{data?.phone.slice(-10)}</Text>
                        </TableCell>
                        <TableCell>
                            <Text className={classes.textCellTable}>{data?.rfc}</Text>
                        </TableCell>
                        <TableCell>
                            <div style={{alignItems: 'center', justifyContent: 'center', display: 'flex'}}>
                                <Button
                                    isDisabled={!isRowSelected(data?.id)}
                                    style={{ backgroundColor: !isRowSelected(data?.id) ? "#B0C4DE" : "#0253cc", color: "#FFFFFF" }}
                                    onClick={() => reactivateCollateralAction(data?.phone, avalsData?.id)}
                                >
                                    REACTIVAR
                                </Button>
                            </div>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        )
    }

    return(
        <>  
            <Snackbar open={openAlert} autoHideDuration={3000} onClose={handleCloseAlert}>
                <Alert severity={isError ? "error" : "success"}>
                    {isError ? "Hubo un error, intenta nuevamente." : "¡Aval reactivado con éxito!"}
                </Alert>
            </Snackbar>
            <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className={classes.modal}
            >
                <Box className={classes.modalContainer}>
                    <Box className={classes.secondModalContainer}>
                        <Text
                            className={classes.titleModal}
                            color="initial"
                            align="justify"
                            variant="h3"
                        >
                            REACTIVAR AVAL
                        </Text>
                        <CloseIcon onClick={() => setOpen(false)}/>
                    </Box>
                    <TableContainer>
                        <Table aria-label="a dense table">
                            <TableHead className={classes.tableHead}>
                                <TableRow>
                                {headCellReactivateCollateral.map((headCell) => (
                                    <TableCell key={headCell.id} align="center" scope="col">
                                        <Text className={classes.titleTable}>{headCell.label}</Text>
                                    </TableCell>
                                ))}
                                </TableRow>
                            </TableHead>
                            {informationTable()}
                        </Table>
                    </TableContainer>
                </Box>
            </Modal>
        </>
    )
}

export default ModalReactivateCollateral;