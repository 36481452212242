import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(() => ({

  CollectionsStyles: {
    display: 'flex',
    marginTop: '3%',
    marginLeft: '7%',
  },
  formStyles: {
    display: 'flex',
    margin: 10,
    minWidth: 120,
    marginLeft: '5%',
    marginRight: '10%',
  },
  listStyles: {
    minWidth: 300,
  },
}));

export default useStyles;
