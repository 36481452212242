import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    containerScore: {
        width: '250px',
        height: '250px',
    },
    textScoreContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        top: -50,
    },
    textScoreStyle: {
        fontSize: '18px',
        color: '#666e75',
        fontWeight: 'bold',
    }
}));

export default useStyles;