import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useParams } from 'react-router';
import useStyles from './PageContainer.style';
import { Box } from '../Layout';
import Text from '../Text';
import AppBar from '../AppBar';
import MenuItem from '../MenuItem';
import useFirebaseAuth from '../../hooks/useFirebaseAuth';
import defaultUser from '../../assets/user_profile.png';

const defaultItems = ['Configuración', 'Salir'];
const menuItems = {
  Registro_Landing: { route: '/register-landing'},
  Registros: { route: '/registers' },
  Verificación_de_MATI: { route: '/mati-verification' },
  TIENDAS: { route: '/shop/' },
  Crédito_a_Tienda: { route : '/credits' },
  Ampliación_Express: { route : '/express' },
  Tesorería: { route: '/treasury' },
  Pagos_a_Tienda: { route : '/shop-payments' },
  Dispersion_manual: { route : '/disbursement' },
  Dar_de_baja: { route : '/delete' },
  Compra_distribuidor: { route: '/buy-dealer'},
  Tesorería_distribuidor: { route: '/dealerTreasury'},
  Revisión_tickets: { route: '/review-voucher'},
  Ampliaciones: {route: '/extensions'},
  BODEGAS: { route : '/wholesalers' },
  Registro: { route: '/wholesalers/register' }
};

const PageContainer = (props) => {
  const { query } = useLocation();
  const location = useLocation();

  const { children } = props;
  const classes = useStyles();
  const { user } = useFirebaseAuth();
  const [page, setPage] = useState(query && 'route' in query ? query.route : 'Registros');

  const formatText = (text) => {
    return text.replace(/_/g, ' ');
  };

  return (
    <div className={classes.root}>
      <AppBar
        userName={user ? user.displayName : 'Usuario'}
        imgProfile={user ? user.photoURL : defaultUser}
      />
      <Box className={classes.page}>
        { !location.pathname.endsWith('_') && (
        <Box className={classes.leftContainer}>
          <Text className={classes.titleMenu}>Menú</Text>
          <Box className={classes.menuContainer}>
            <MenuItem
              id="customized-menu-section-user"
              textItem={'USUARIOS'}
            />
            {Object.keys(menuItems).map((name, index) => {
              if (index === 3) {
                return (
                  <MenuItem
                    id="customized-menu"
                    key={name}
                    onClick={(e) => {
                      setPage(name);
                    }}
                    selected={location.pathname.includes(menuItems[name].route)}
                    textItem={formatText(name)}
                    route={menuItems[name].route}
                  />
                )
              }
              if (index === 14) {
                return (
                  <MenuItem
                    id="customized-menu"
                    key={name}
                    onClick={(e) => {
                      setPage(name);
                    }}
                    selected={location.pathname.includes(menuItems[name].route)}
                    textItem={formatText(name)}
                    route={menuItems[name].route}
                  />
                )
              }
              return (
                <Box className={classes.subMenuContainer}>
                  <MenuItem
                    id="customized-menu"
                    key={name}
                    onClick={(e) => {
                      setPage(name);
                    }}
                    selected={location.pathname.includes(menuItems[name].route)}
                    textItem={formatText(name)}
                    route={menuItems[name].route}
                  />
                </Box>
              )
            })}
          </Box>
          <Box>
            {defaultItems.map((name) => (
              <MenuItem key={name} textItem={formatText(name)} />
            ))}
          </Box>
        </Box>
        )}
        <div className={classes.divider} />
        <Box className={classes.rightContainer}>{children}</Box>
      </Box>
    </div>
  );
};

export default PageContainer;

PageContainer.propTypes = {
  children: PropTypes.element,
};

PageContainer.defaultProps = {
  children: '',
};
