import React, { useState } from "react";
import { CircularProgress } from "@material-ui/core";
import useStyles from './ImageWithPlaceholder.style';

const ImageWithPlaceholder = ({
    src,
    width,
    height,
}) => {
    const classes = useStyles();
    const [loaded, setLoaded] = useState(false);
    const [scale, setScale] = useState(1);
    const [translate, setTranslate] = useState({ x: 0, y: 0 });
    const [isDragging, setIsDragging] = useState(false);
    const [lastPosition, setLastPosition] = useState({ x: 0, y: 0, current: { x: 0, y: 0 } });

    const handleZoomIn = () => {
        setScale(scale + 0.1);
    };

    const handleZoomOut = () => {
        if (scale > 0.2) {
            setScale(scale - 0.1);
        }
    };

    const handleMouseMove = (event) => {
        if (isDragging) {
            const newTranslate = { x: translate.x + event.clientX - lastPosition.current.x, y: translate.y + event.clientY - lastPosition.current.y };
            setTranslate(newTranslate);
            lastPosition.current = { x: event.clientX, y: event.clientY };
        }
    };

    const handleMouseDown = e => {
        setIsDragging(true);
        setLastPosition({
            current: { x: e.clientX, y: e.clientY },
            x: e.clientX,
            y: e.clientY
        });
    };

    const handleMouseUp = e => {
        setIsDragging(false);
    };

    return (
        <div className={classes.imageHolder} style={{ width: width, height: height}}>
            <div className={classes.imageHolder}
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
                onMouseMove={handleMouseMove}
            >
                {!loaded && <CircularProgress />}
                <img
                    draggable="false"
                    src={src}
                    width={width}
                    style={{ transform: `scale(${scale}) translate(${translate.x}px, ${translate.y}px)`, transition: 'transform .1s linear' }}
                    onLoad={() => setLoaded(true)} />
            </div>
            <div className={classes.zoomButtons}>
                <button className={classes.zoomButton} onClick={handleZoomIn}>+</button>
                <button className={classes.zoomButton} onClick={handleZoomOut}>-</button>
            </div>
        </div>
    )
}

export default ImageWithPlaceholder;
