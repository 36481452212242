import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Box from '../../components/Layout/Box';
import PageContainer from '../../components/PageContainer';
import SectionTitle from '../../components/SectionTitle';

import useStyles from './CreditPage.styles';
import MissingDataAlert from '../../components/MissingDataAlert/MissingDataAlert';

import Button from '../../components/Button';
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";
import EventOutlinedIcon from "@material-ui/icons/EventOutlined";
import { monthNamesFull, daysOfMonth } from "../../constants/constants";
import * as ACTIONS from "./actionTypes";

import { Link } from "react-router-dom";
import { useRouteMatch } from 'react-router';

import PaginationTable from '../../components/Table/PaginationTable/PaginationCreditTable';
import { getRegisters } from './creditActions';


const CreditPage = (props) => {

  const { resourceType } = props.match.params;
  const { url } = useRouteMatch();

  const classes = useStyles();
  const dispatch = useDispatch();
  const { error } = useSelector((state) => state.registersState);
  const [inputValue, setInputVale] = useState('');
  const [nothingFoundNoticeShown, showNothingFoundNotice] = useState(false);

  const { settlements } = useSelector((state) => state.shopsState);
  const [dates, setDates] = useState([]);
  let date = new Date();

  useEffect(() => {
    if (error) {
      showNothingFoundNotice(true);
      setInputVale('');
    }
  }, [error]);

  useEffect(() => {
    setTimeout(() => showNothingFoundNotice(false), 3000);
  }, [nothingFoundNoticeShown]);

  const handleChecks = (event) => {
    setDates(event.target.value);
    dispatch({
      type: ACTIONS.SET_STATE,
      label: 'filter',
      value: event.target.value,
    });
  }

  return (
    <PageContainer>
      <Box className={classes.rootContainer}>
        <Box
          className={classes.topContainer}
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <SectionTitle>Tiendas y clientes con solicitud de crédito</SectionTitle>

          {nothingFoundNoticeShown && (<MissingDataAlert status="BAD" text="No se encontraron Tiendas o Clientes" />)}

          <Box component="form" className={classes.searchContainer}>
            <InputBase
              value={inputValue}
              onChange={(event) => {
                setInputVale(event.target.value);
              }}
              className={classes.input}
              placeholder="Buscar por nombre de cliente, email, ID..."
              inputProps={{ 'aria-label': 'search user' }}
              onKeyUp={(event) => {
                if (event.key === 'Enter') {
                  //dispatch(getSettlementsByType());
                }
              }}
            />
            <Divider className={classes.divider} orientation="vertical" />
            <IconButton
              //onClick={() => dispatch(getSettlementsByType())}
              className={classes.iconButton}
              aria-label="search">
              <SearchIcon />
            </IconButton>

          </Box>

          <Box className={classes.buttonTop}>
            <Link to={`${url}/create`}>
              <Button style={{ backgroundColor: '#0253cc', color: 'white' }}>
                Crear Solicitud
              </Button>
            </Link>
          </Box>

        </Box>

        <Box className={classes.tableContainer}>
          <PaginationTable />
        </Box>

      </Box>
    </PageContainer>
  );
};

export default CreditPage;
