import React from "react";
import { Link } from "react-router-dom";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Button, Modal, IconButton, Grid } from "@material-ui/core";
import Close from "@material-ui/icons/Close";

import PageContainer from "../../components/PageContainer";
import Box from "../../components/Layout/Box";
import Text from "../../components/Text";
import TopBarBuyDealer from "../../components/TopBarBuyDealer/TopBarBuyDealer";
import CardVouchers from "../../components/CardVouchers";

function DetailBuyDealer({
  classes,
  shokeeper_detail,
  credit_line_shop_detail,
  subMenu,
  setSubMenu,
  zoomModal,
  setZoomModal,
  saveVouchers,
  saveSuppliers,
  saveAmounts,
  purchaseId,
  creditLineId,
  accountId,
}) {
  function viewVoucher() {
    if (saveVouchers.length !== 0) {
      return saveVouchers.map((data) => {
        return (
          <CardVouchers
            data={data}
            setZoomModal={setZoomModal}
            purchaseId={purchaseId}
            creditLineId={creditLineId}
            accountId={accountId}
          />
        );
      });
    } else {
      return (
        <Text className={classes.textTickets}>
          Esta compra no cuenta con tickets para justificar gastos.
        </Text>
      );
    }
  }

  function viewSuppliers() {
    if (saveSuppliers.length !== 0 && saveVouchers.length !== 0) {
      return saveSuppliers.map((data) => {
        return <Text>{data?.supplier},</Text>;
      });
    }
  }

  function viewDealer() {
    if (saveSuppliers.length !== 0 && saveVouchers.length !== 0) {
      return (
        <Grid
          className={classes.containerDealer}
          container
          direction="row"
          alignItems="center"
        >
          <Text className={classes.titleDealer}>Distribuidor:</Text>
          {viewSuppliers()}
        </Grid>
      );
    }
  }

  function viewAmounts() {
    if (saveSuppliers.length !== 0 && saveVouchers.length !== 0) {
      return (
        <>
          <Grid
            className={classes.containerDealer}
            container
            direction="row"
            alignItems="center"
          >
            <Text className={classes.titleDealer}>Monto de la compra:</Text>
            <Text>{`$${saveAmounts?.amount}`}</Text>
          </Grid>
          <Grid
            className={classes.containerDealer}
            container
            direction="row"
            alignItems="center"
          >
            <Text className={classes.titleDealer}>Monto comprobado:</Text>
            <Text>{`$${saveAmounts?.aceptedAmount}`}</Text>
          </Grid>
        </>
      );
    }
  }

  return (
    <PageContainer>
      <Link to="/buy-dealer" className={classes.controls}>
        <ArrowBackIcon />
        <Text>Regresar</Text>
      </Link>
      <TopBarBuyDealer
        shokeeper_detail={shokeeper_detail?.data || shokeeper_detail}
        credit_line_shop_detail={credit_line_shop_detail}
      />
      <Button
        className={classes.buttonSubMenuContainer}
        onClick={() => setSubMenu("buy_dealer")}
      >
        <Text
          className={
            subMenu === "buy_dealer"
              ? classes.subMenuTextOn
              : classes.subMenuTextOff
          }
        >
          Compra con distribuidor
        </Text>
      </Button>
      {viewDealer()}
      {viewAmounts()}
      <Grid container direction="row" alignItems="flex-start">
        {viewVoucher()}
      </Grid>
      <Modal
        open={zoomModal.show}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={classes.modalRejection}
      >
        <Box className={classes.modalContainerZoom}>
          <IconButton
            className={classes.buttonCloseZoom}
            onClick={() =>
              setZoomModal({
                show: false,
                image: "",
              })
            }
          >
            <Close />
          </IconButton>
          <img src={zoomModal.image} className={classes.imageZoomStyle} />
        </Box>
      </Modal>
    </PageContainer>
  );
}

export default DetailBuyDealer;
