import React, { useEffect } from 'react';
import {
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from '../Shops/OrderedTableHead.style';
import Text from '../../components/Text';

export const OrderedTableHead = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { page, orderBy, sort } = useSelector((state) => state.customersState);

  useEffect(() => {
  }, [orderBy, sort]);

  const headCells = [
    {
        id: 'shop_name', numeric: false, disablePadding: false, label: 'Nombre de tienda',
    },
    {
        id: 'id_shop', numeric: false, disablePadding: false, label: 'ID Tienda',
    },
    {
        id: 'shopkeeper_name', numeric: false, disablePadding: false, label: 'Tendero',
    },
    {
        id: 'phone_shop', numeric: true, disablePadding: false, label: 'Teléfono',
    },
    {
        id: 'cutoff_date', numeric: false, disablePadding: false, label: 'Fecha de corte',
    },
    {
        id: 'date_expiration', numeric: false, disablePadding: false, label: 'Vencimiento',
    },
    {
        id: 'cutoff_balance', numeric: true, disablePadding: false, label: 'Balance al corte',
    },
    {
      id: 'status', label: 'Estatus',
    },
    {
      id: 'detail', numeric: true, disablePadding: false, label: 'Ver detalle',
    },
  ];

  return (
    <TableHead className={classes.tableHead}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            align="center"
            scope="col"
            sort={orderBy === headCell.id ? sort : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? sort : 'asc'}
            >
              <Text className={classes.titleLabel}>{headCell.label}</Text>
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default OrderedTableHead;
