import React, { useEffect } from 'react';
import {
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from '../../../Shops/OrderedTableHead.style';
import Text from '../../../../components/Text';

export const CollectionTableHead = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const resourceType = props.resourceType;
  const { page, orderBy, sort } = useSelector((state) => state.customersState);

  useEffect(() => {
  }, [orderBy, sort]);
  
  const headCells = [
    {
        id: 'id_shop', numeric: false, disablePadding: false, label: 'ID Tienda',
    },
    {
        id: 'shop_name', numeric: false, disablePadding: false, label: 'Nombre de tienda',
    },
    {
        id: 'shop_email', numeric: false, disablePadding: false, label: 'Email de tienda',
    },
    {
        id: 'bank', numeric: false, disablePadding: false, label: 'Banco',
    },
    {
        id: 'sell_total', numeric: true, disablePadding: false, label: 'Total de ventas',
    },
    {
        id: 'charges_total', numeric: true, disablePadding: false, label: 'Total de cobros',
    },
    {
        id: 'balance', numeric: true, disablePadding: false, label: 'Balance',
    },
    {
        id: 'status', numeric: false, disablePadding: false, label: 'Estatus',
    },
    {
        id: 'show_shops', label: 'Transacciones',
    },
  ];

  return (
    <TableHead className={classes.tableHead}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            align="center"
            scope="col"
            sort={orderBy === headCell.id ? sort : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? sort : 'asc'}
            >
              <Text className={classes.titleLabel}>{headCell.label}</Text>
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default CollectionTableHead;
