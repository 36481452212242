import React, { useState } from 'react';
import Button from '../../components/Button';
import { Box } from '../../components/Layout';
import Text from '../../components/Text';
import useStyles from './HomePage.styles';

export const HomePage = () => {
  const classes = useStyles();
  const [count, setCount] = useState(0);
  return (
    <Box className={classes.page}>
      <Box className={classes.container}>
        <Text variant='h1' color='primary' align='center'>Home Page</Text>
        <Text variant='h4' color='secondary' align='center'>{`clicks: ${count}`}</Text>
        <Button variant='contained' color='primary' onClick={() => setCount(count + 1)}>Click me</Button>
      </Box>
    </Box>
  );
};


export default HomePage;
