import React from 'react';
import { useRouteMatch } from "react-router";
import {
  TableContainer, Table, TableBody, TableRow, TableCell, TableFooter,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from './PaginationTable.style';
import Text from '../../../components/Text';
import { Box } from '../../../components/Layout';
import { OrderedTableHead } from './ShopTransactionsTableHead';
import {transactionType} from '../../../constants/constants'


export const ShopTransactionsTable = (props) => {
  const { resources } = props;
  const dispatch = useDispatch();
  const classes = useStyles();

  return (
    <Box>
      <TableContainer className={classes.tableContainer}>
        <Table aria-label="custom pagination table">
          <OrderedTableHead />
          <TableBody>
            { resources &&
              resources.map((item, index) => (
                <TableRow key={index} style={{ maxHeight: 43 }}>
                  <TableCell>
                    <Text className={classes.textStyle}>{item.transaction_id}</Text>
                  </TableCell>
                  <TableCell>
                    <Text className={classes.textStyle}>{transactionType[item.transaction_type]}</Text>
                  </TableCell>
                  <TableCell>
                    <Text className={classes.textStyle}>{item.amount}</Text>
                  </TableCell>
                </TableRow>
              ))
            }
          </TableBody>
          <TableFooter />
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ShopTransactionsTable;
