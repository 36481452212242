import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Alert from '@material-ui/lab/Alert';

import { useDispatch, useSelector } from 'react-redux';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import FormControl from '@material-ui/core/FormControl';
import {
  TextField, Select, FormHelperText, Input, InputAdornment, Snackbar,
} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';

import { withStyles } from '@material-ui/core/styles';
import { moneyFunction } from '../../../constants/moneyFunction';
import MissingDataAlert from '../../../components/MissingDataAlert/MissingDataAlert';

import { getFindShop, getCreditLine, applyDisbursement } from './disbursementActions';
import { getFindCustomer } from '../Create/creditCreateActions';
import * as ACTIONS from '../actionTypes';
import useStyles from './Disbursement.style';
import SectionTitle from '../../../components/SectionTitle';
import Button from '../../../components/Button';
import PageContainer from '../../../components/PageContainer';
import Text from '../../../components/Text';
import { Box } from '../../../components/Layout';
import { banks } from '../../../constants/constants';

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'green',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'white',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'red',
      },
      '&:hover fieldset': {
        borderColor: 'yellow',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'white',
      },
    },
  },
})(TextField);

const CreditCreatePage = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [inputCustomerValue, setInputCustomerVale] = useState('');

  const { alert } = useSelector((state) => state.registersState);
  const closeAlert = () => {
    dispatch({ type: ACTIONS.UPDATE_CLOSE_ALERT });
  };

  const { error, shops, displayedCreditLine, customersFound } = useSelector((state) => state.registersState);

  // const { resourceType } = props.match.params;
  const [values, setValues] = React.useState({
    accountType: '',
    disbursementType: 'RESTRUCTURING',
    shopSelected: '',
    customerSelected: '',
    amount: '',
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleSelectShop = () => (event) => {
    handleChange('shopSelected')(event);
    dispatch(
      getCreditLine(event.target.value.credit_line_id),
    );
  };

  const handleSelectCustomer = () => (event) => {
    handleChange('customerSelected')(event);
    dispatch(
      getCreditLine(event.target.value.credit_line_id),
    );
  }

  const [errorMessages, setErrorMessages] = React.useState({
    accountType: '',
    disbursementType: '',
    shopSelected: '',
    customerSelected: '',
    amount: '',
  });

  const errorMsgs = {
    accountType: '* Seleccione el tipo de crédito !',
    disbursementType: '* Seleccione el tipo de dispersión !',
    shopSelected: '* Seleccione la tienda !',
    customerSelected: '* Seleccione el cliente !',
    amount: '* Ingrese el monto !',
  };

  const handleErrorMessages = (errors) => {
    setErrorMessages({ ...errorMessages, ...errors });
  };

  const [inputValue, setInputVale] = useState('');
  const [nothingFoundNoticeShown, showNothingFoundNotice] = useState(false);

  const handleSubmit = () => {
    let hasError = false;
    const errors = {};
    Object.entries(values).forEach(([k, v]) => {
      if (v === '' || v == 0) {
        if (values.accountType === 'CU') {
          if (k !== 'shopSelected') {
            hasError = true;
            errors[k] = errorMsgs[k];
          }
        } else {
          if (k !== 'customerSelected') {
            hasError = true;
            errors[k] = errorMsgs[k];
          }
        }
      } else {
        errors[k] = '';
      }
    });
    handleErrorMessages(errors);
    if (hasError) {
      return;
    }
    dispatch(
      applyDisbursement(values.accountType === 'CU' ? values.customerSelected.credit_line_id : values.shopSelected.credit_line_id, values.amount, values.disbursementType),
      setValues({ ...values, amount: '' }),
    );
  };

  useEffect(() => {
    if (error) {
      showNothingFoundNotice(true);
      setInputVale('');
    }
  }, [error]);

  useEffect(() => {
    setTimeout(() => showNothingFoundNotice(false), 3000);
  }, [nothingFoundNoticeShown]);

  useEffect(() => {
    if (values.accountType !== '') {
      setInputVale('');
      setValues((prevProps) => ({
        ...prevProps,
        shopSelected: '',
        customerSelected: '',
        amount: '',
      }))
    }
  }, [values.accountType]);

  useEffect(() => () => dispatch({
    type: ACTIONS.SET_STATE,
    label: 'searchString',
    value: '',
  }), []);

  const shopList = shops && shops.length > 0 ?
    shops.map((shop, i) => {
      if (shop.credit_line_id === '') {
        return;
      }
      return (<MenuItem value={shop}>{shop.personhood_name} / {shop.shopkeeper_name}</MenuItem>);
    }) : '';

  const shopAsAdvisorList = shops && shops.length > 0 ?
    shops.map((shop) => {
      if (shop.credit_line_id === undefined) {
        return;
      }
      return (<MenuItem value={shop.account_shopkeeper_id}>{shop.personhood_name} / {shop.shopkeeper_name}</MenuItem>);
    }) : '';

  const customersFoundList = customersFound && customersFound.length > 0 ?
    customersFound.map((customer) => {
      if (customer.credit_status !== 'ACTIVED' || customer.credit_line_id === '') {
        return;
      }
      return (<MenuItem value={customer}>{customer.full_name} / {customer.phone_number}</MenuItem>);
    }) : '';
  console.log(displayedCreditLine)
  return (
    <PageContainer>
      <Snackbar open={alert.open} onClose={closeAlert} autoHideDuration={10000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert severity={alert.severity} onClose={closeAlert}>
          {alert.message}
        </Alert>
      </Snackbar>

      <Link to="/credits" className={classes.controls}>
        <ArrowBackIcon />
        <Text>Regresar</Text>
      </Link>

      <Box className={classes.mainPanel}>
        <SectionTitle>Aplicar dispersiones manuales a l&iacute;nea de cr&eacute;dito</SectionTitle>
      </Box>

      <Box>
        {nothingFoundNoticeShown && <MissingDataAlert status="BAD" text="No se encontraron tiendas" />}
      </Box>

      <Box style={{ width: '35%', paddingTop: 15, paddingBottom: 15 }}>
        <Text variant="h4" className={classes.textBlue}>
          Tipo de cr&eacute;dito
        </Text>
        <Select
          displayEmpty
          style={{ display: 'flex' }}
          labelId="demo-simple-select-label"
          id="advisor"
          value={values.accountType}
          onChange={handleChange('accountType')}>
          <MenuItem value="" disabled>Selecciona un tipo de cr&eacute;dito</MenuItem>
          <MenuItem value="SK">Tendero</MenuItem>
          <MenuItem value="CU">Cliente</MenuItem>
        </Select>
        <FormHelperText>
          {errorMessages.accountType}
        </FormHelperText>
      </Box>

      {values.accountType === 'SK' ?
        <>
          <Box>
            <Text variant="h4" className={classes.textBlue}>
              Nombre de la tienda
            </Text>
          </Box>

          <Box component="form" className={classes.searchContainer}>
            <InputBase
              value={inputValue}
              onChange={(event) => {
                setInputVale(event.target.value);
                dispatch({
                  type: ACTIONS.SET_STATE,
                  label: 'searchString',
                  value: event.target.value,
                });
              }}
              className={classes.input}
              placeholder="Nombre de la tienda"
              inputProps={{ 'aria-label': 'search user' }}
              onKeyUp={(event) => {
                if (event.key === 'Enter') {
                  event.preventDefault();
                }
              }}
            />
            <Divider className={classes.divider} orientation="vertical" />
            <IconButton
              onClick={() => { dispatch(getFindShop()); }}
              className={classes.iconButton}
              aria-label="search"
            >
              <SearchIcon />
            </IconButton>
          </Box>
          <FormHelperText>
            {errorMessages.shopSelected}
          </FormHelperText>

          {shops && shops.length >= 1 ?
            (<Box style={{ width: '35%', paddingTop: 15, paddingBottom: 15 }}>
              <Text variant="h4" className={classes.textBlue}>
                Elige una tienda
              </Text>
              <Select
                displayEmpty
                style={{ display: 'flex' }}
                labelId="demo-simple-select-label"
                id="advisor"
                value={values.shopSelected}
                onChange={handleSelectShop()}>
                <MenuItem value="" disabled>Selecciona una tienda</MenuItem>
                {shopList}
              </Select>
            </Box>) : (<Box></Box>)
          }
          {values.shopSelected ?
            (<Box>
              <Box style={{ width: '100%', paddingTop: 15, paddingBottom: 15 }}>
                <CssTextField
                  disabled
                  style={{ width: 300, marginRight: 20, }}
                  className={classes.margin}
                  id="custom-css-standard-input"
                  label="Responsable de la tienda"
                  defaultValue=""
                  value={values.shopSelected.shopkeeper_name}
                  InputLabelProps={{ shrink: true, }} />
                <CssTextField
                  disabled
                  style={{ width: 300, marginRight: 20, }}
                  className={classes.margin}
                  label="Teléfono del responsable"
                  id="custom-css-outlined-input"
                  defaultValue="No viene del WS"
                  value={values.shopSelected.shopkeeper_phone_number}
                  InputLabelProps={{ shrink: true, }} />
              </Box>
              <Box style={{ width: '100%', paddingTop: 15, paddingBottom: 15 }}>
                <CssTextField
                  disabled
                  style={{ width: 300, marginRight: 20, }}
                  className={classes.margin}
                  label="CURP del responsable de la tienda"
                  id="custom-css-outlined-input"
                  defaultValue=""
                  value={values.shopSelected.shopkeeper_curp}
                  InputLabelProps={{ shrink: true, }} />
                <CssTextField
                  disabled
                  style={{ width: 300, marginRight: 20, }}
                  className={classes.margin}
                  label="Dirección de la tienda"
                  id="custom-css-standard-input"
                  defaultValue=""
                  value={`${values.shopSelected.street_name} ${values.shopSelected.street_number} ${values.shopSelected.apartment_number} CP ${values.shopSelected.zip_code} - ${values.shopSelected.neighborhood}, ${values.shopSelected.delegation}, ${values.shopSelected.state}`}
                  InputLabelProps={{ shrink: true, }} />
              </Box>
            </Box>) : (<Box></Box>)}

          {values.shopSelected && displayedCreditLine ? (
            <>
              <Box style={{ width: '100%', paddingTop: 15, paddingBottom: 15 }}>
                <CssTextField
                  disabled
                  style={{ width: 300, marginRight: 20, }}
                  className={classes.margin}
                  label="Total para liquidar"
                  id="custom-css-standard-input"
                  defaultValue={moneyFunction(0)}
                  value={moneyFunction(displayedCreditLine?.settlement_amount || 0)}
                  InputLabelProps={{ shrink: true, }} />
                <CssTextField
                  disabled
                  style={{ width: 300, marginRight: 20, }}
                  className={classes.margin}
                  label="Cuota"
                  id="custom-css-standard-input"
                  defaultValue={moneyFunction(0)}
                  value={moneyFunction(displayedCreditLine.installment)}
                  InputLabelProps={{ shrink: true, }} />
              </Box>
              <Box style={{ width: '100%', paddingTop: 15, paddingBottom: 15 }}>
                <CssTextField
                  disabled
                  style={{ width: 300, marginRight: 20, }}
                  className={classes.margin}
                  label="Estado de la línea de crédito"
                  id="custom-css-standard-input"
                  defaultValue=""
                  value={displayedCreditLine.status}
                  InputLabelProps={{ shrink: true, }} />
              </Box>
            </>
          ) : (<Box></Box>)}

          {values.shopSelected && displayedCreditLine && displayedCreditLine.next_payment ? (
            <Box style={{ width: '100%', paddingTop: 15, paddingBottom: 15 }}>
              <CssTextField
                disabled
                style={{ width: 300, marginRight: 20, }}
                className={classes.margin}
                label="Próximo Pago"
                id="custom-css-standard-input"
                defaultValue={moneyFunction(0)}
                value={moneyFunction(displayedCreditLine.next_payment.amount)}
                InputLabelProps={{ shrink: true, }} />
              <CssTextField
                disabled
                style={{ width: 300, marginRight: 20, }}
                className={classes.margin}
                label="Próxima Fecha de Pago"
                id="custom-css-standard-input"
                defaultValue=""
                value={displayedCreditLine.next_payment.deadline_date}
                InputLabelProps={{ shrink: true, }} />
            </Box>
          )
            : (<Box></Box>)}
        </> :
        <></>
      }
      {values.accountType === 'CU' ?
        (
          <Box width="100%">

            <Box>
              <Text variant="h4" className={classes.textBlue}>
                Tel&eacute;fono del cliente
              </Text>
            </Box>

            <Box component="form" className={classes.searchContainer}>
              <InputBase
                value={inputCustomerValue}
                onChange={(event) => {
                  setInputCustomerVale(event.target.value);
                  dispatch({
                    type: ACTIONS.SET_STATE,
                    label: 'searchCustomerString',
                    value: event.target.value,
                  });
                }}
                className={classes.input}
                placeholder="N&uacute;mero telef&oacute;nico del cliente"
                inputProps={{ 'aria-label': 'search user' }}
                onKeyUp={(event) => {
                  if (event.key === 'Enter') {
                    event.preventDefault();
                  }
                }}
              />
              <Divider className={classes.divider} orientation="vertical" />
              <IconButton
                onClick={() => { dispatch(getFindCustomer()); }}
                className={classes.iconButton}
                aria-label="search">
                <SearchIcon />
              </IconButton>
            </Box>
            <FormHelperText>
              {errorMessages.customerSelected}
            </FormHelperText>

            {customersFound && customersFound.length >= 1 ?
              (<Box style={{ width: '35%', paddingTop: 15, paddingBottom: 15 }}>
                <Text variant="h4" className={classes.textBlue}>
                  Elige el cliente
                </Text>
                <Select
                  displayEmpty
                  style={{ display: 'flex' }}
                  labelId="demo-simple-select-label"
                  id="customer"
                  value={values.customerSelected}
                  onChange={handleSelectCustomer()}>
                  <MenuItem value="" disabled>Selecciona un cliente</MenuItem>
                  {customersFoundList}
                </Select>
              </Box>) : (<Box></Box>)
            }

            {values.customerSelected ?
              (<Box>
                <Box style={{ width: '100%', paddingTop: 15, paddingBottom: 15 }}>
                  <CssTextField
                    disabled
                    style={{ width: 300, marginRight: 20, }}
                    className={classes.margin}
                    id="custom-css-standard-input"
                    label="Responsable de la tienda"
                    defaultValue=""
                    value={values.customerSelected.full_name}
                    InputLabelProps={{ shrink: true, }} />
                  <CssTextField
                    disabled
                    style={{ width: 300, marginRight: 20, }}
                    className={classes.margin}
                    label="Teléfono del responsable"
                    id="custom-css-outlined-input"
                    defaultValue="No viene del WS"
                    value={values.customerSelected.phone_number}
                    InputLabelProps={{ shrink: true, }} />
                </Box>
                <Box style={{ width: '100%', paddingTop: 15, paddingBottom: 15 }}>
                  <CssTextField
                    disabled
                    style={{ width: 300, marginRight: 20, }}
                    className={classes.margin}
                    label="CURP del responsable de la tienda"
                    id="custom-css-outlined-input"
                    defaultValue=""
                    value={values.customerSelected.email}
                    InputLabelProps={{ shrink: true, }} />
                </Box>
              </Box>) : (<Box></Box>)}

            {values.customerSelected && displayedCreditLine ? (
              <>
                <Box style={{ width: '100%', paddingTop: 15, paddingBottom: 15 }}>
                  <CssTextField
                    disabled
                    style={{ width: 300, marginRight: 20, }}
                    className={classes.margin}
                    label="Total para liquidar"
                    id="custom-css-standard-input"
                    defaultValue={moneyFunction(0)}
                    value={moneyFunction(displayedCreditLine?.settlement_amount || 0)}
                    InputLabelProps={{ shrink: true, }} />
                  <CssTextField
                    disabled
                    style={{ width: 300, marginRight: 20, }}
                    className={classes.margin}
                    label="Cuota"
                    id="custom-css-standard-input"
                    defaultValue={moneyFunction(0)}
                    value={moneyFunction(displayedCreditLine.installment)}
                    InputLabelProps={{ shrink: true, }} />
                </Box>
                <Box style={{ width: '100%', paddingTop: 15, paddingBottom: 15 }}>
                  <CssTextField
                    disabled
                    style={{ width: 300, marginRight: 20, }}
                    className={classes.margin}
                    label="Estado de la línea de crédito"
                    id="custom-css-standard-input"
                    defaultValue=""
                    value={displayedCreditLine.status}
                    InputLabelProps={{ shrink: true, }} />
                </Box>
              </>
            ) : (<Box></Box>)}

            {values.customerSelected && displayedCreditLine && displayedCreditLine.next_payment ? (
              <Box style={{ width: '100%', paddingTop: 15, paddingBottom: 15 }}>
                <CssTextField
                  disabled
                  style={{ width: 300, marginRight: 20, }}
                  className={classes.margin}
                  label="Próximo Pago"
                  id="custom-css-standard-input"
                  defaultValue={moneyFunction(0)}
                  value={moneyFunction(displayedCreditLine.next_payment.amount)}
                  InputLabelProps={{ shrink: true, }} />
                <CssTextField
                  disabled
                  style={{ width: 300, marginRight: 20, }}
                  className={classes.margin}
                  label="Próxima Fecha de Pago"
                  id="custom-css-standard-input"
                  defaultValue=""
                  value={displayedCreditLine.next_payment.deadline_date}
                  InputLabelProps={{ shrink: true, }} />
              </Box>
            )
              : (<Box></Box>)}
          </Box>
        ) : (<Box></Box>)}

      <Box style={{ width: '35%', paddingTop: 15, paddingBottom: 15 }}>
        <Text variant="h4" className={classes.textBlue}>
          Tipo de pago
        </Text>
        <Select
          displayEmpty
          style={{ display: 'flex' }}
          labelId="demo-simple-select-label"
          id="disbursementType"
          value={values.disbursementType}
          onChange={handleChange('disbursementType')}>
          <MenuItem value="RESTRUCTURING">Reestructuraci&oacute;n</MenuItem>
          <MenuItem value="CREDIT_RESERVE_CHARGE">Reserva por mora (YoFio 2.0 - Banquero de la esquina)</MenuItem>
          <MenuItem value="CHANGE_OF_CONDITIONS">Cambio de condiciones</MenuItem>
          <MenuItem value="LATE_PAYMENT_FEE">Comisi&oacute;n por moratorios</MenuItem>
        </Select>
        <FormHelperText>
          {errorMessages.disbursementType}
        </FormHelperText>
      </Box>

      <Box style={{ width: '35%', paddingTop: 15, paddingBottom: 15 }}>
        <Text variant="h4" className={classes.textBlue}>¿Cu&aacute;nto va a dispersar?</Text>
        <Input
          id="credit-amount"
          style={{ display: 'flex' }}
          value={values.amount}
          type="number"
          onChange={handleChange('amount')}
          placeholder="De $1 a $1,000,000"
          startAdornment={<InputAdornment position="start">$</InputAdornment>}
        />
        <FormHelperText>
          {errorMessages.amount}
        </FormHelperText>
      </Box>

      <Box style={{ width: '35%' }}>
        <FormControl className={classes.formStyles}>
          <Box className={classes.buttonContainer}>
            <Button
              variant="outlined"
              className={classes.button}
              style={{ backgroundColor: '#0253cc', color: 'white' }}
              onClick={() => {
                handleSubmit();
              }} >
              Aplicar Dispersi&oacute;n
            </Button>
          </Box>
        </FormControl>
      </Box>

    </PageContainer>
  );
};

export default CreditCreatePage;
