import * as ACTION from './reviewVouchers.actionTypes';
import * as reviewVoucherService from '../services/reviewVouchers.service';

export const getVouchers = (queries) => async (dispatch) => {
    dispatch({
        type: ACTION.INIT_GET_VOUCHERS,
    });

    try {
        const getVouchersData = await reviewVoucherService.getVouchers(queries);
        if (getVouchersData) {
            dispatch({
                type: ACTION.RESPONSE_GET_VOUCHERS,
                result: getVouchersData,
            });
        }
    } catch (error) {
        dispatch({
            type: ACTION.ERROR_GET_VOUCHERS,
            error,
        });
    }
};

export const clearGetVouchers = () => async (dispatch) => {
    dispatch({
        type: ACTION.CLEAR_GET_VOUCHERS,
    });
};

export const putVouchers = (voucher_id, body) => async (dispatch) => {
    dispatch({
        type: ACTION.INIT_PUT_VOUCHERS,
    });

    try {
        const putVouchersData = await reviewVoucherService.putVouchers(voucher_id, body);
        if (putVouchersData) {
            dispatch({
                type: ACTION.RESPONSE_PUT_VOUCHERS,
                result: putVouchersData,
            });
        }
    } catch (error) {
        dispatch({
            type: ACTION.ERROR_PUT_VOUCHERS,
            error,
        });
    }
};

export const clearPutVouchers = () => async (dispatch) => {
    dispatch({
        type: ACTION.CLEAR_PUT_VOUCHERS,
    });
};

export const getVouchersWeekly = (id, from_date) => async (dispatch) => {
    dispatch({
        type: ACTION.INIT_GET_VOUCHERS_WEEKLY,
    });

    try {
        const getVouchersWeeklyData = await reviewVoucherService.getVouchersWeekly(id, from_date);
        if (getVouchersWeeklyData) {
            dispatch({
                type: ACTION.RESPONSE_GET_VOUCHERS_WEEKLY,
                result: getVouchersWeeklyData,
            });
        }
    } catch (error) {
        dispatch({
            type: ACTION.ERROR_GET_VOUCHERS_WEEKLY,
            error,
        });
    }
};

export const clearGetVouchersWeekly = () => async (dispatch) => {
    dispatch({
        type: ACTION.CLEAR_GET_VOUCHERS_WEEKLY,
    });
};