import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    textBlue: {
        paddingTop: 10,
        paddingBottom: 10,
        color: '#0253cc',
    },
    textBlue: {
        paddingTop: 10,
        paddingBottom: 10,
        fontSize: 13,
        color: 'black',
    },
    divider: {
        margin: '10px 0px 10px 0px',
        width: '648px',
    },
    containerTable: {
        width: '800px',
        marginBottom: 30
    },
    tableHead: {
        backgroundColor: '#EEEEEE',
    },
    textHeadTable: {
        textAlign: 'center',
        fontSize: 15
    },
    textCellTable: {
        textAlign: 'center',
    },
    imageTable: {
        marginLeft: 30,
        width: 30,
        height: 30
    },
    formElement: {
        marginBottom: 30,
        width: "300px"
    },
    modalEditInventory: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalContainerEditInventory: {
        display: 'flex',
        flexDirection: 'column',
        width: '350px',
        padding: 15,
        borderRadius: 15,
        backgroundColor: '#ffffff',
    },
    rowContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 20
    },
    titleModal: {
        fontSize: 16,
        fontWeight: 'bold',
        color: "#000"
    },
    buttonClose: {
        width: 30,
        height: 30,
    },
    formElementModal: {
        marginTop: 20,
        marginBottom: 10,
        marginRight: 10,
        marginLeft: 10
    },
    buttonModalContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 20,
    },
    buttonConfirmChange: {
        backgroundColor: "#0253cc",
        fontSize: '12px',
        fontWeight: '600',
    },
    inventoryPhotosContainer: {
        display: 'flex',
        flexDirection: 'column',
        border: '2px solid #D3DEE7',
        marginLeft: 20,
        marginBottom: 20,
        maxWidth: '450px',
        maxHeight: '520px',
        borderRadius: 10
    },
    photoInventoryStyle: {
        width: '450px',
        height: '450px',
        borderTopRightRadius: 10,
        borderTopLeftRadius: 10,
        cursor: 'pointer'
    },
    rowButtonsContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
        marginTop: 20,
        marginBottom: 20
    },
    modalContainerViewPhoto: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        maxWidth: "1,334.23px",
        maxHeight: "660px",
        padding: 5,
        borderRadius: 10,
        backgroundColor: '#FFF',
    },
    modalActionPhotosContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '350px',
        padding: 15,
        borderRadius: 15,
        backgroundColor: '#FFF',
    },
    dividerModal: {
        width: '100%',
    },
    textDeleteModal: {
        color: "#F8494B",
        fontSize: '14px',
        fontWeight: '400',
        lineHeight: '24px',
        letterSpacing: '-0.09px',
    }
}));

export default useStyles;
