import * as ACTION from './dealer.actionTypes';
import * as dealerService from '../services/dealer.service';

export const getSupplier = (queries) => async (dispatch) => {
    dispatch({
        type: ACTION.INIT_GET_SUPPLIER,
    });

    try {
        const supplierData = await dealerService.getSupplier(queries);
        if (supplierData) {
            dispatch({
                type: ACTION.RESPONSE_GET_SUPPLIER,
                result: supplierData,
            });
        }
    } catch (error) {
        dispatch({
            type: ACTION.ERROR_GET_SUPPLIER,
            error,
        });
    }
};

export const clearSupplier = () => async (dispatch) => {
    dispatch({
        type: ACTION.CLEAR_GET_SUPPLIER,
    });
};

export const getSupplierRejectReason = () => async (dispatch) => {
    dispatch({
        type: ACTION.INIT_GET_SUPPLIER_REJECT_REASON,
    });

    try {
        const supplierRejectReassonData = await dealerService.getSupplierRejectReason();
        if (supplierRejectReassonData) {
            dispatch({
                type: ACTION.RESPONSE_GET_SUPPLIER_REJECT_REASON,
                result: supplierRejectReassonData,
            });
        }
    } catch (error) {
        dispatch({
            type: ACTION.ERROR_GET_SUPPLIER_REJECT_REASON,
            error,
        });
    }
};

export const clearSupplierRejectReason = () => async (dispatch) => {
    dispatch({
        type: ACTION.CLEAR_GET_SUPPLIER_REJECT_REASON,
    });
};

export const putSupplierAprove = (creditLineId, supplierPurchaseId, voucherId, body) => async (dispatch) => {
    dispatch({
        type: ACTION.INIT_PUT_SUPPLIER_APROVE,
    });

    try {
        const supplierAproveData = await dealerService.putSupplierAprove(creditLineId, supplierPurchaseId, voucherId, body);
        if (supplierAproveData) {
            dispatch({
                type: ACTION.RESPONSE_PUT_SUPPLIER_APROVE,
                result: supplierAproveData,
            });
        }
    } catch (error) {
        dispatch({
            type: ACTION.ERROR_PUT_SUPPLIER_APROVE,
            error,
        });
    }
};

export const clearSupplierAprove = () => async (dispatch) => {
    dispatch({
        type: ACTION.CLEAR_PUT_SUPPLIER_APROVE,
    });
};

export const putSupplierReject = (creditLineId, supplierPurchaseId, voucherId, body) => async (dispatch) => {
    dispatch({
        type: ACTION.INIT_PUT_SUPPLIER_REJECT,
    });

    try {
        const supplierRejectData = await dealerService.putSupplierReject(creditLineId, supplierPurchaseId, voucherId, body);
        if (supplierRejectData) {
            dispatch({
                type: ACTION.RESPONSE_PUT_SUPPLIER_REJECT,
                result: supplierRejectData,
            });
        }
    } catch (error) {
        dispatch({
            type: ACTION.ERROR_PUT_SUPPLIER_REJECT,
            error,
        });
    }
};

export const clearSupplierReject = () => async (dispatch) => {
    dispatch({
        type: ACTION.CLEAR_PUT_SUPPLIER_REJECT,
    });
};

export const getSuppliers = () => async (dispatch) => {
    dispatch({
        type: ACTION.INIT_GET_SUPPLIERS,
    });

    try {
        const suppliersData = await dealerService.getSuppliers();
        if (suppliersData) {
            dispatch({
                type: ACTION.RESPONSE_GET_SUPPLIERS,
                result: suppliersData,
            });
        }
    } catch (error) {
        dispatch({
            type: ACTION.ERROR_GET_SUPPLIERS,
            error,
        });
    }
};

export const clearSuppliers = () => async (dispatch) => {
    dispatch({
        type: ACTION.CLEAR_GET_SUPPLIERS,
    });
};

export const putSupplierDisbursement = (id, body) => async (dispatch) => {
    dispatch({
        type: ACTION.INIT_PUT_SUPPLIERS_DISBURSEMENT,
    });

    try {
        const supplierDisbursementData = await dealerService.putSupplierDisbursement(id, body);
        if (supplierDisbursementData) {
            dispatch({
                type: ACTION.RESPONSE_PUT_SUPPLIERS_DISBURSEMENT,
                result: supplierDisbursementData,
            });
        }
    } catch (error) {
        dispatch({
            type: ACTION.ERROR_PUT_SUPPLIERS_DISBURSEMENT,
            error,
        });
    }
};

export const postSendTransfer = (credit_line_id, body) => async (dispatch) => {
    dispatch({
        type: ACTION.INIT_POST_SEND_TRANSFER,
    });

    try {
        const supplierDisbursementData = await dealerService.postSendTransfer(credit_line_id, body);
        if (supplierDisbursementData) {
            dispatch({
                type: ACTION.RESPONSE_POST_SEND_TRANSFER,
            });
        }
    } catch (error) {
        dispatch({
            type: ACTION.ERROR_POST_SEND_TRANSFER,
            error,
        });
    }
};

export const clearSupplierDisbursement = () => async (dispatch) => {
    dispatch({
        type: ACTION.CLEAR_PUT_SUPPLIERS_DISBURSEMENT,
    });
};

export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: ACTION.CLEAR_ERRORS,
    });
};