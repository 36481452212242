export const FETCH_TREASURY_INIT = 'TREASURY::FETCH_TREASURY_INIT';
export const FETCH_TREASURY_SUCCESS = 'TREASURY::FETCH_TREASURY_SUCCESS';
export const FETCH_TREASURY_ERROR = 'TREASURY::FETCH_TREASURY_ERROR';
export const SET_STATE = 'TREASURY::SET_STATE';

export const ADD_SELECTED_TREASURY = 'TREASURY::ADD_SELECTED_TREASURY';
export const DELETE_SELECTED_TREASURY = 'TREASURY::DELETE_SELECTED_TREASURY';
export const CLEAR_SELECTED_TREASURY = 'TREASURY::CLEAR_SELECTED_TREASURY';

export const FETCH_TREASURY_UPDATE_SUCCESS = 'TREASURY::FETCH_TREASURY_UPDATE_SUCCESS';
export const FETCH_TREASURY_UPDATE_ERROR = 'TREASURY::FETCH_TREASURY_UPDATE_ERROR';
export const FETCH_TREASURY_UPDATE_CLEAR = 'TREASURY::FETCH_TREASURY_UPDATE_CLEAR';

export const SEND_TRANSFER_SUCCESS = 'TREASURY::SEND_TRANSFER_SUCCESS';
export const SEND_TRANSFER_ERROR = 'TREASURY::SEND_TRANSFER_ERROR';
export const SEND_TRANSFER_CLEAR = 'TREASURY::SEND_TRANSFER_CLEAR';
