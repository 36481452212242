import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Text from '../Text';
import Box from '../Layout/Box';
import SectionTitle from '../SectionTitle/SectionTitle';
import Button from '../Button';
import { restartSmsProcess, getVerificationCode } from '../../containers/Registers/registersActions';
import useStyles from './PhoneValidationInfo.styles';
import dateFunction from '../../containers/CustomerTransactions/dateFunction';

const PhoneValidationInfo = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const userAuthID = useSelector((state) => state.registersState.displayedCustomer.personal_data.user_auth_id);

  const { phone_number_validation } = props;
  const [code, setCode] = React.useState('******');

  const {
    status,
    generation_attempts,
    validation_attempts,
    change_phone_number_request,
    last_attempt,
  } = phone_number_validation;

  return (
    <Box>
      <Box>
        <SectionTitle>
          VALIDACIÓN DE TELÉFONO CELULAR
        </SectionTitle>
        <Text>
          Intento de envios de SMS:
          {' '}
          {generation_attempts}
          {' '}
          intentos de 5 intentos
        </Text>
        <Text>
          Intento de validación de código:
          {' '}
          {validation_attempts}
          {' '}
          intentos de 5 intentos
        </Text>
        <Text>
          Fecha de último envío:
          {' '}
          {dateFunction(last_attempt)}
        </Text>
        <Text>
          Código enviado al cliente:
          {' '}
          {code}
        </Text>
      </Box>
      <Box className={classes.phoneValidationTableContainer}>
        <Box borderBottom={1} className={classes.tableHeader}>
          <Box className={classes.tableRowItem}>
            Fecha de último intento
          </Box>
          <Box className={classes.tableRowItem}>
            Teléfono ingresado
          </Box>
          <Box className={classes.tableRowItem}>
            Resultado de envío de SMS
          </Box>
          <Box className={classes.tableRowItem}>
            Nuevo número de teléfono
          </Box>
        </Box>
        <Box>
          {
            ('length' in change_phone_number_request && change_phone_number_request.length > 0 ? change_phone_number_request : []).map((request) => (
              <Box borderBottom={1} className={classes.tableRow}>
                <Box className={classes.tableRowItem}>
                  {request.change_phone_date.split('T')[0].replace(/-/gm, '/')}
                </Box>
                <Box className={classes.tableRowItem}>
                  {request.phone_number}
                </Box>
                <Box className={classes.tableRowItem}>
                  {request.send_status}
                </Box>
                <Box className={classes.tableRowItem}>
                  {request.new_phone_number}
                </Box>
              </Box>
            ))
          }
        </Box>
      </Box>
      <Button
        variant="contained"
        color="secondary"
        onClick={() => {
          dispatch(restartSmsProcess(userAuthID));
        }}
      >
        Reiniciar proceso SMS
      </Button>
      <Text>{' '}</Text>
      <Button
        variant="contained"
        onClick={() => {
          dispatch(getVerificationCode(userAuthID)).then((c) => {
            setCode(c);
            setTimeout(() => {
              setCode('******');
            }, 10000);
          });
        }}
      >
        Mostrar código
      </Button>
    </Box>
  );
};

export default PhoneValidationInfo;
