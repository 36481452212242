import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Box
} from '@material-ui/core';
import Switch from '@material-ui/core/Switch'
import moment from 'moment';
import Modal from '@material-ui/core/Modal';
import CancelIcon from '@material-ui/icons/Close';
import InputBase from '@material-ui/core/InputBase';
import Checkbox from '@material-ui/core/Checkbox';

import useStyles from '../SubMenuPage.styles';
import Text from '../../../../components/Text';
import HistoryTable from './HistoryTable'
import { getHistoryBlocked } from '../CreditInformation/CreditInformation.actions';
import { preferredPaymentDayOptions } from '../../../../constants/constants';
import Button from '../../../../components/Button'

const History = ({ 
    creditLineId,
    dataIds
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [allBlockedShop, setAllBlockedShop] = useState([]);

    const {
        blocked_shop_detail,
        error
    } = useSelector((state) => state.creditInformationState);

    useEffect(() => {
        if(Object.keys(dataIds).length !== 0) {
            dataIds.forEach((dataId) => {
                dispatch(getHistoryBlocked(dataId?.credit_line_id));
            })
        }
    }, [dataIds, dispatch]);

    useEffect(() => {
        if (blocked_shop_detail) {
            setAllBlockedShop(prevTransactions => {
                const updatedBlockedShop = [
                    ...prevTransactions,
                    ...blocked_shop_detail
                ];

                // Ordenamos por la fecha (created_at), de la más reciente a la más antigua
                return updatedBlockedShop.sort((a, b) => 
                    new Date(b.created_at) - new Date(a.created_at)
                );
            });
        }
    }, [blocked_shop_detail]);

    return (
        <Box className={classes.tableContainer}>
            <HistoryTable blocked_shop_detail={ allBlockedShop } />
        </Box>
    )
}

export default History;
