import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Alert from '@material-ui/lab/Alert';

import { useDispatch, useSelector } from 'react-redux';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import FormControl from '@material-ui/core/FormControl';
import {
  TextField, Select, FormHelperText, Input, InputAdornment, Snackbar,
} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';

import { withStyles } from '@material-ui/core/styles';

import { getClient } from '../Detail/creditDetailActions';
import { getAdvisor, getFindShop } from '../Create/creditCreateActions';
import * as ACTIONS from '../actionTypes';
import useStyles from '../Create/CreditCreate.style';
import SectionTitle from '../../../components/SectionTitle';
import Button from '../../../components/Button';
import PageContainer from '../../../components/PageContainer';
import Text from '../../../components/Text';
import { Box } from '../../../components/Layout';
import { banks } from '../../../constants/constants';

import { updateCredit } from './creditUpdateActions';

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'green',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'white',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'red',
      },
      '&:hover fieldset': {
        borderColor: 'yellow',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'white',
      },
    },
  },
})(TextField);


const CreditCreatePage = (props) => {

  const classes = useStyles();
  const { resourceType } = props.match.params;
  const dispatch = useDispatch();

  const { alert } = useSelector((state) => state.registersState);
  const closeAlert = () => {
    dispatch({ type: ACTIONS.UPDATE_CLOSE_ALERT });
  };
  const { advisors, shops } = useSelector((state) => state.registersState);

  // const { resourceType } = props.match.params;
  const [values, setValues] = React.useState({
    accountType: '',
    credit_line_id: '',
    name: '',
    email: '',
    phone_number: '',
    curp: '',
    shop_name: '',
    shopkeeper_name: '',
    shopkeeper_phone_number: '',
    shopkeeper_curp: '',
    amount: '',
    installment: '',
    day: '',
    advisor: '',
    address: '',
  });

  const [inputValue, setInputVale] = useState('');

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const [errorMessages, setErrorMessages] = React.useState({
    amount: '',
    installment: '',
    day: '',
    advisor: '',
  });

  const errorMsgs = {
    amount: '* Ingrese el monto !',
    installment: '* Ingrese el valor de la cuota !',
    day: '* Seleccione el día de pago !',
    advisor: '* Seleccione la cartera !',
  };

  const handleErrorMessages = (errors) => {
    setErrorMessages({ ...errorMessages, ...errors });
  };

  const { displayedCustomer: displayedLoan } = useSelector((state) => state.registersState);
  console.log(displayedLoan)
  const handleSubmit = () => {
    let hasError = false;
    const errors = {};
    Object.entries(values).forEach(([k, v]) => {
      if (v === '' || v == 0) {
        if(values.accountType === 'CU') {
          if(
            k !== 'shopSelected' &&
            k !== 'shop_name' &&
            k !== 'shopkeeper_name' &&
            k !== 'shopkeeper_phone_number' &&
            k !== 'shopkeeper_curp'
          ) {
            console.log(k)
            hasError = true;
            errors[k] = errorMsgs[k];
          }
        } else {
          if(
            k !== 'customerSelected' &&
            k !== 'name' &&
            k !== 'email' &&
            k !== 'phone_number' &&
            k !== 'curp'
          ) {
            hasError = true;
            errors[k] = errorMsgs[k];
          }
        }
      } else {
        errors[k] = '';
      }
    });
    if (values.amount % 500 !== 0) {
      hasError = true;
      errors.amount = '* El monto del crédito debe ser múltiplo de 500 !';
    }
    if(values.accountType === 'CU') {
      if (values.amount < 1000 || values.amount > 5000) {
        hasError = true;
        errors.amount = '* El monto del crédito no esta entre el rango permitido !';
      }
    } else {
      if (values.amount < 5000 || values.amount > 50000) {
        hasError = true;
        errors.amount = '* El monto del crédito no esta entre el rango permitido !';
      }
    }
    handleErrorMessages(errors);
    if (hasError) {
      console.log('aqui')
      return;
    }
    dispatch(
      updateCredit(
        values.credit_line_id,
        values.advisor,
        values.amount,
        values.installment,
        values.day,
      ),
    );
  };

  useEffect(() => {
    const { customerID } = props.match.params;
    dispatch(getAdvisor());
    dispatch(getClient(customerID));
    console.log('customerID ..< ', customerID);
  }, []);

  useEffect(() => {
    try {
      if (displayedLoan) {
        let new_values;
        if(displayedLoan.account_type_id !== 'CU') {
          new_values = {
            accountType: displayedLoan.account_type_id,
            credit_line_id: displayedLoan.credit_line_id,
            shop_name: displayedLoan.shop_name,
            shopkeeper_name: displayedLoan.shopkeeper_name,
            shopkeeper_phone_number: displayedLoan.phone_number,
            shopkeeper_curp: displayedLoan.curp,
            installment: displayedLoan.installment,
            amount: displayedLoan.credit_line_amount,
            day: displayedLoan.payment_day,
            advisor: displayedLoan.sales_advisor_id || '',
            address: `${displayedLoan?.street_name} ${displayedLoan?.street_number} ${displayedLoan?.apartment_number} CP ${displayedLoan?.zip_code} - ${displayedLoan?.neighborhood}, ${displayedLoan?.delegation}, ${displayedLoan?.state}`,
          };
        } else {
          new_values = {
            accountType: displayedLoan.account_type_id,
            credit_line_id: displayedLoan.credit_line_id,
            name: displayedLoan.name,
            email: displayedLoan.email,
            phone_number: displayedLoan.phone_number,
            curp: displayedLoan.curp,
            installment: displayedLoan.installment,
            amount: displayedLoan.credit_line_amount,
            day: displayedLoan.payment_day,
            advisor: displayedLoan.sales_advisor.sales_advisor_id || '',
            address: `${displayedLoan?.street_name} ${displayedLoan?.street_number} ${displayedLoan?.apartment_number} CP ${displayedLoan?.zip_code} - ${displayedLoan?.neighborhood}, ${displayedLoan?.delegation}, ${displayedLoan?.state}`,
          };
        }
        setValues({
          ...values,
          ...new_values,
        });
      }
    } catch (e) {
      console.log(e);
    }
  }, [displayedLoan]);

  const advisorList = advisors && advisors.length > 0 ?
    advisors.map((advisor, i) => {
      return (<MenuItem value={advisor.sales_advisor_id}>{advisor.name}</MenuItem>);
    }) : '';

  return (
    <PageContainer>
      <Snackbar open={alert.open} onClose={closeAlert} autoHideDuration={10000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert severity={alert.severity} onClose={closeAlert}>
          {alert.message}
        </Alert>
      </Snackbar>

      <Link to="/credits" className={classes.controls}>
        <ArrowBackIcon />
        <Text>Regresar</Text>
      </Link>

      <Box className={classes.mainPanel}>
        <SectionTitle>Editar crédito</SectionTitle>
      </Box>
      { displayedLoan.account_type_id === 'SK' ?
        <>
          <Box style={{ width: '100%', paddingTop: 15, paddingBottom: 15 }}>
            <TextField
              disabled
              style={{ width: 300, marginRight: 20 }}
              className={classes.margin}
              id="custom-css-standard-input"
              label="Nombre de la tienda"
              defaultValue="N/D"
              value={values.shop_name} />
            <TextField
              disabled
              style={{ width: 300, marginRight: 20, }}
              className={classes.margin}
              id="custom-css-standard-input"
              label="Responsable de la tienda"
              defaultValue="Joe Doe"
              value={values.shopkeeper_name} />
            <CssTextField
              disabled
              style={{ width: 200, marginRight: 20, }}
              className={classes.margin}
              label="Teléfono del responsable"
              id="custom-css-outlined-input"
              defaultValue="000 000 0000"
              value={values.shopkeeper_phone_number}
            />
            <CssTextField
              disabled
              style={{ width: 250, marginRight: 20, }}
              className={classes.margin}
              label="CURP del responsable de la tienda"
              id="custom-css-outlined-input"
              defaultValue="CURP78998HDFLRG"
              value={values.shopkeeper_curp}
            />
          </Box>
          <Box>
            <CssTextField
              disabled
              style={{ width: 400, marginRight: 20, }}
              className={classes.margin}
              label="Dirección de la tienda"
              id="custom-css-standard-input"
              defaultValue="N/A"
              value={values.address} />
          </Box>

          <Box style={{ width: '35%', paddingTop: 15, paddingBottom: 15 }}>
            <Text variant="h4" className={classes.textBlue}>¿Cu&aacute;nto necesita?</Text>
            <Input
              id="credit-amount"
              style={{ display: 'flex' }}
              value={values.amount}
              type="number"
              onChange={handleChange('amount')}
              placeholder="De $5,000 a $20,000"
              startAdornment={<InputAdornment position="start">$</InputAdornment>}
            />
            <FormHelperText>
              {errorMessages.amount}
            </FormHelperText>
          </Box>

          <Box style={{ width: '35%', paddingTop: 15, paddingBottom: 15 }}>
            <Text variant="h4" className={classes.textBlue}>¿Cu&aacute;nto dinero puede pagar semanalmente?</Text>
            <Input
              id="installment-amount"
              style={{ display: 'flex' }}
              value={values.installment}
              type="number"
              onChange={handleChange('installment')}
              placeholder="Escribe la cantidad"
              startAdornment={<InputAdornment position="start">$</InputAdornment>}
            />
            <FormHelperText>
              {errorMessages.installment}
            </FormHelperText>
          </Box>

          <Box style={{ width: '35%', paddingTop: 15, paddingBottom: 15 }}>
            <Text variant="h4" className={classes.textBlue}>¿Qu&eacute; día va a realizar el pago?</Text>
            <Select
              displayEmpty
              style={{ display: 'flex' }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={values.day}
              onChange={handleChange('day')}>
              <MenuItem value="" disabled>Selecciona el día</MenuItem>
              <MenuItem value={'MONDAY'}>Lunes</MenuItem>
              <MenuItem value={'TUESDAY'}>Martes</MenuItem>
              <MenuItem value={'WEDNESDAY'}>Miércoles</MenuItem>
              <MenuItem value={'THURSDAY'}>Jueves</MenuItem>
              <MenuItem value={'FRIDAY'}>Viernes</MenuItem>
            </Select>
            <FormHelperText>
              {errorMessages.day}
            </FormHelperText>
          </Box>

          <Box style={{ width: '35%', paddingTop: 15, paddingBottom: 15 }}>
            <Text variant="h4" className={classes.textBlue}>
              N&uacute;mero de cartera
            </Text>
            <Select
              displayEmpty
              style={{ display: 'flex' }}
              labelId="demo-simple-select-label"
              id="advisor"
              value={values.advisor}
              onChange={handleChange('advisor')}>
              <MenuItem value="" disabled>Selecciona la cartera</MenuItem>
              {advisorList}
            </Select>
            <FormHelperText>
              {errorMessages.advisor}
            </FormHelperText>
          </Box>
        </>
        :
        <>
          <Box style={{ width: '100%', paddingTop: 15, paddingBottom: 15 }}>
            <TextField
              disabled
              style={{ width: 300, marginRight: 20 }}
              className={classes.margin}
              id="custom-css-standard-input"
              label="Nombre del cliente"
              defaultValue="N/D"
              value={values.name} />
            <TextField
              disabled
              style={{ width: 300, marginRight: 20, }}
              className={classes.margin}
              id="custom-css-standard-input"
              label="Email"
              defaultValue="Joe Doe"
              value={values.email} />
            <CssTextField
              disabled
              style={{ width: 200, marginRight: 20, }}
              className={classes.margin}
              label="Teléfono del cliente"
              id="custom-css-outlined-input"
              defaultValue="000 000 0000"
              value={values.phone_number}
            />
            <CssTextField
              disabled
              style={{ width: 250, marginRight: 20, }}
              className={classes.margin}
              label="CURP del cliente"
              id="custom-css-outlined-input"
              defaultValue="CURP78998HDFLRG"
              value={values.curp}
            />
          </Box>
          <Box>
            <CssTextField
              disabled
              style={{ width: 400, marginRight: 20, }}
              className={classes.margin}
              label="Dirección del cliente"
              id="custom-css-standard-input"
              defaultValue="N/A"
              value={values.address} />
          </Box>
          <Box style={{ width: '100%', paddingTop: 15, paddingBottom: 15 }}>
            <CssTextField
              disabled
              style={{ width: 200, marginRight: 20, }}
              className={classes.margin}
              label="Banco"
              id="custom-css-outlined-input"
              defaultValue="Banco"
              value={displayedLoan.bank}
            />
            <TextField
              disabled
              style={{ width: 300, marginRight: 20 }}
              className={classes.margin}
              id="custom-css-standard-input"
              label="Tienda que avala"
              defaultValue="N/D"
              value={displayedLoan.sales_advisor.name} />
            <TextField
              disabled
              style={{ width: 300, marginRight: 20, }}
              className={classes.margin}
              id="custom-css-standard-input"
              label="Nombre del aval"
              defaultValue="Joe Doe"
              value={displayedLoan.sales_advisor.advisor_name} />
          </Box>

          <Box style={{ width: '35%', paddingTop: 15, paddingBottom: 15 }}>
            <Text variant="h4" className={classes.textBlue}>¿Cu&aacute;nto necesita?</Text>
            <Input
              id="credit-amount"
              style={{ display: 'flex' }}
              value={values.amount}
              type="number"
              onChange={handleChange('amount')}
              placeholder="De $1,000 a $5,000"
              startAdornment={<InputAdornment position="start">$</InputAdornment>}
            />
            <FormHelperText>
              {errorMessages.amount}
            </FormHelperText>
          </Box>

          <Box style={{ width: '35%', paddingTop: 15, paddingBottom: 15 }}>
            <Text variant="h4" className={classes.textBlue}>¿Cu&aacute;nto dinero puede pagar semanalmente?</Text>
            <Input
              id="installment-amount"
              style={{ display: 'flex' }}
              value={values.installment}
              type="number"
              onChange={handleChange('installment')}
              placeholder="Escribe la cantidad"
              startAdornment={<InputAdornment position="start">$</InputAdornment>}
            />
            <FormHelperText>
              {errorMessages.installment}
            </FormHelperText>
          </Box>

          <Box style={{ width: '35%', paddingTop: 15, paddingBottom: 15 }}>
            <Text variant="h4" className={classes.textBlue}>¿Qu&eacute; día va a realizar el pago?</Text>
            <Select
              displayEmpty
              style={{ display: 'flex' }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={values.day}
              onChange={handleChange('day')}>
              <MenuItem value="" disabled>Selecciona el día</MenuItem>
              <MenuItem value={'MONDAY'}>Lunes</MenuItem>
              <MenuItem value={'TUESDAY'}>Martes</MenuItem>
              <MenuItem value={'WEDNESDAY'}>Miércoles</MenuItem>
              <MenuItem value={'THURSDAY'}>Jueves</MenuItem>
              <MenuItem value={'FRIDAY'}>Viernes</MenuItem>
            </Select>
            <FormHelperText>
              {errorMessages.day}
            </FormHelperText>
          </Box>
        </>
      }

      <Box style={{ width: '35%' }}>
        <FormControl className={classes.formStyles}>
          <Box className={classes.buttonContainer}>
            <Button
              variant="outlined"
              className={classes.button}
              style={{ backgroundColor: '#0253cc', color: 'white' }}
              onClick={() => {
                handleSubmit();
              }}>
              Calcular cr&eacute;dito
            </Button>
          </Box>
        </FormControl>
      </Box>



    </PageContainer>
  );
};

export default CreditCreatePage;
