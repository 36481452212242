export const INIT_POST_WHATSAPP_MESSAGE =
  "NOTIFICATIONS::INIT_POST_WHATSAPP_MESSAGE";
export const RESPONSE_POST_WHATSAPP_MESSAGE =
  "NOTIFICATIONS::RESPONSE_POST_WHATSAPP_MESSAGE";
export const ERROR_POST_WHATSAPP_MESSAGE =
  "NOTIFICATIONS::ERROR_POST_WHATSAPP_MESSAGE";
export const CLEAR_POST_WHATSAPP_MESSAGE =
  "NOTIFICATIONS::CLEAR_POST_WHATSAPP_MESSAGE";

export const CLEAR_ERRORS = "NOTIFICATIONS::CLEAR_ERRORS";
