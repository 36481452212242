import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  VendorValidationInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',

    color: '#3a4760',
    '& p': {
      marginTop: 20,
      marginLeft: 10,
      marginBottom: 20,
    },
  },
  CustomerInfoCard: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    color: '#3a4760',
    width: 400,
    height: 200,
  },
  CardContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
}));

export default useStyles;
