import React, { useState } from "react";
import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  TablePagination,
  CircularProgress
} from "@material-ui/core";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import useStyles from "./PaginationTable.style";
import Text from "../../Text";
import ArrowedText from "../../ArrowedText";
import TablePaginationActions from "../TablePaginationActions";
import OrderedTableHead from "../OrderedTableHead";
import StepText from "../../StepText/StepText";
import shoppingBag from "../../../assets/shopping-bag.svg";
import shop from "../../../assets/shop.svg";
import CreditStatusLabel from "../../../containers/Registers/CreditStatusLabel/CreditStatusLabel";
import Button from "../../Button";
import CustomerStepModal from "../../CustomerStepModal";
import { Box } from "../../Layout";
import { dateFunction } from '../../../containers/CustomerTransactions/dateFunction';

export const PaginationTable = (props) => {
  const classes = useStyles();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [showModal, setShowModal] = useState(false);
  const { customers, page, pages, next } = useSelector(
    (state) => state.registersState
  );

  const [selectedCustomer, setSelectedCustomer] = useState({
    name: "",
    profile: 1,
    step: undefined,
  });
  
  return (
    <Box>
      {Object.keys(customers).length === 0 && (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
          <CircularProgress  size={55} style={{marginRight: 10}}/>
          <Text>Cargando...</Text>
        </Box>
      )}
      <TableContainer className={classes.tableContainer}>
        <Table aria-label="custom pagination table">
          <OrderedTableHead />
          <TableBody>
            {
              customers.map((customer, index) => (
                <TableRow key={index} style={{ maxHeight: 43 }}>
                  <TableCell>
                    <Text className={classes.textStyle}>
                      {`${dateFunction(customer.date)}`}
                    </Text>
                  </TableCell>
                  <TableCell>
                    <img src={customer.profile === 1 ? shoppingBag : shop} alt="logo" />
                  </TableCell>
                  <TableCell>
                    <Text className={classes.textStyle}>{customer.name}</Text>
                  </TableCell>
                  <TableCell>
                    <Text className={classes.textStyle}>{customer.phone_number}</Text>
                  </TableCell>
                  <TableCell>
                    <Text
                      className={classes.textStyle}
                    >
                      {`${dateFunction(customer.last_step_date)}`}
                    </Text>
                  </TableCell>
                  <TableCell>
                    <StepText
                      step={customer.step}
                      phoneValidation={'is_phone_number_validated' in customer ? customer.is_phone_number_validated : false}
                      customerType={customer.profile}
                      newStep={customer.new_step}
                    />
                  </TableCell>
                  <TableCell>
                    <CreditStatusLabel
                      status={customer.credit_validation}
                      newStatus={customer.new_status}
                    />
                  </TableCell>
                  <TableCell>
                    <Link to={`/registers/${customer.profile === 1 ? 'customers' : 'shopkeeper'}/${customer.account_id}/customer-info`}>
                      <Button>
                        <ArrowedText>
                          Ver detalle
                        </ArrowedText>
                      </Button>
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Button onClick={() => {
                      setSelectedCustomer(customer);
                      setShowModal(true);
                    }}
                  >
                    <CreateOutlinedIcon htmlColor="gray" />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                classes={{
                  toolbar: classes.toolbar,
                  spacer: classes.spacer,
                }}
                rowsPerPageOptions={[]}
                rowsPerPage={rowsPerPage}
                labelDisplayedRows={() => ""}
                SelectProps={{
                  inputProps: { "aria-label": "rows per page" },
                  native: true,
                }}
                ActionsComponent={() => (
                  <TablePaginationActions
                    has_next={next}
                    page={page - 1}
                    totalPages={pages - 1}
                    count={customers.length}
                  />
                )}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <CustomerStepModal
        open={showModal}
        setOpen={setShowModal}
        selectedCustomer={selectedCustomer}
      />
    </Box>
  );
};

PaginationTable.propTypes = {};

export default PaginationTable;
