import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    
  },
  cutoffState: {
    '& h2': {
      color: '#0052d4',
    },
  },
  upperPanel: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
    borderColor: '#e8e8fc',

    '& h6': {
      color: '#3a4760',
      fontSize: 9,
    },
    '& h3': {
      fontWeight: 'bold',
    },
  },
  lowerPanel: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'space-around',
    alignContent: 'center',
    alignItems: 'center',
    height: 100,
    backgroundColor: '#e7f0fa',
    '& h3': {
      paddingTop: '10%',
      fontSize: 21,
      color: '#3a4760',
    },
    '& h6': {
      fontSize: 9,
      color: '#3a4760',
    },
  },
  cutoffDate: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '100%',
    width: '40%',
    backgroundColor: '#c8daf4',
    justifyContent: 'space-around',
  },
  cutOffTotals: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '60%',
    justifyContent: 'space-around',
  },
  customerStatus: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '40%',
    '& h2': {
      fontSize: 21,
    },
    '& h4': {
      color: '#3a4760',
      fontFamily: 'Poppins',
      fontSize: 12,
    },
  },
}));

export default useStyles;

