import React, { useEffect, useState } from 'react';
import {
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from './OrderedTableHead.style';
import Text from '../../../components/Text';
import {getCustomers} from '../customersActions'
import * as ACTIONS from '../actionTypes'

const headCells = [
  {
    id: 'name', numeric: false, disablePadding: false, label: 'Nombre de Cliente',
  },
  {
    id: 'last_cutoff_date', numeric: false, disablePadding: false, label: 'Último corte vencido más antiguo',
  },
  {
    id: 'phone_number', numeric: true, disablePadding: false, label: 'Teléfono',
  },
  {
    id: 'credit_status', numeric: true, disablePadding: false, label: 'Estatus',
  },
  {
    id: 'credit_amount', numeric: true, disablePadding: false, label: 'Límite de crédito',
  },
  {
    id: 'current', numeric: true, disablePadding: false, label: 'Saldo Actual',
  },
  {
    id: 'overdue_days', numeric: false, disablePadding: false, label: 'Días de atraso',
  },
  {
    id: 'next_payment_days', numeric: true, disablePadding: false, label: 'Días a próximo vencimiento',
  },
  {
    id: 'details', label: 'Ver detalle',
  },
];

export const OrderedTableHead = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { page, orderBy, sort } = useSelector((state) => state.customersState);

  useEffect(() => {
    dispatch(getCustomers(page));
  }, [orderBy, sort]);

  return (
    <TableHead className={classes.tableHead}>
    <TableRow>
      {headCells.map((headCell) => (
        <TableCell
          align="center"
          scope="col"
          sort={orderBy === headCell.id ? sort : false}
        >
          <TableSortLabel
            active={orderBy === headCell.id}
            direction={orderBy === headCell.id ? sort : 'asc'}

            onClick={() => {
              if (orderBy === headCell.id) {
                dispatch({
                  type: ACTIONS.SET_STATE,
                  label: 'sort',
                  value: (sort === 'asc' ? 'desc' : 'asc'),
                });
                dispatch({
                  type: ACTIONS.SET_STATE,
                  label: 'page',
                  value: 0,
                });
              } else {
                dispatch({
                  type: ACTIONS.SET_STATE,
                  label: 'orderBy',
                  value: headCell.id,
                });
                dispatch({
                  type: ACTIONS.SET_STATE,
                  label: 'page',
                  value: 0,
                });
              }
            }}
          >
            <Text className={classes.titleLabel}>{headCell.label}</Text>
          </TableSortLabel>
        </TableCell>
      ))}
    </TableRow>
  </TableHead>
);
};

export default OrderedTableHead;
