import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  tableHead: {
    width: '100%',
  },
  titleLabel: {
    fontSize: 10,
    letterSpacing: 'normal',
    lineHeight: 1.44,
    color: '#858d9c',
    whiteSpace: 'pre-line',
  },
}));

export default useStyles;
