import React from "react";

import { 
    Button, 
    MenuItem,
    IconButton,
    Modal,
    Tab,
    Tabs,
} from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Close from "@material-ui/icons/Close";
import { format } from 'date-fns';

import Box from '../../components/Layout/Box';
import Text from '../../components/Text';
import TextInput from "../../components/TextInput";
import icons from "../../assets/icons";
import PdfDownloader from "../PdfDownloader";

function StatementPDF({
    classes,
    label,
    isOpenModal,
    setIsOpenModal,
    selectedDate,
    setSelectedDate,
    handleChange,
    monthOptions,
    creditLineId,
    dateRange,
    setDateRange,
    handleDateRangeChange,
    selectedTab,
    handleTabChange
}) {
    const formatDateForUrl = (date) => {
        return date ? format(date, 'yyyy-MM-dd') : '';
    };

    return (
        <Box className={classes.generalContainer}>
            <Button 
                onClick={() => setIsOpenModal(true)}
                className={classes.buttonStyle}
            >
                {label}
            </Button>
            <Modal
                open={isOpenModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className={classes.modalContainer}
            >
                <Box className={classes.modalStyleContainer}>
                <Box className={classes.rowContainer}>
                    <Text className={classes.titleModal}>Descargar estado de cuenta</Text>
                    <IconButton
                    className={classes.buttonClose}
                    onClick={() => (setIsOpenModal(false), setSelectedDate(''), setDateRange({ start: null, end: null } ))}
                    >
                    <Close style={{color: "#3f51b5"}}/>
                    </IconButton>
                </Box>
                <Tabs
                    value={selectedTab}
                    onChange={handleTabChange}
                    indicatorColor="secondary"
                    className={classes.tabStyle}
                >
                    <Tab label="POR MES" value="month" />
                    <Tab label="PERIODO PERSONALIZADO" value="custom" />
                </Tabs>
                {selectedTab === 'month' ? (
                    <TextInput
                        className={classes.formElementModal}
                        label="Elige el més a descargar"
                        variant="standard"
                        select
                        value={selectedDate}
                        onChange={handleChange}
                    >   
                        {monthOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextInput>
                ):(
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Box className={classes.dateRangeContainer}>
                            <DatePicker
                                label="Fecha inicial"
                                value={dateRange.start}
                                onChange={handleDateRangeChange('start')}
                                format="dd/MM/yyyy"
                                className={classes.datePicker}
                            />
                            <DatePicker
                                label="Fecha final"
                                value={dateRange.end}
                                onChange={handleDateRangeChange('end')}
                                format="dd/MM/yyyy"
                                className={classes.datePicker}
                            />
                        </Box>
                    </MuiPickersUtilsProvider>
                )}
                <Box className={classes.buttonModalContainer}>
                    <Button
                    style={{backgroundColor: '#FFF', color: '#3f51b5', fontWeight: '600', fontSize: '12px'}}
                    onClick={() => (setIsOpenModal(false), setSelectedDate(''), setDateRange({ start: null, end: null } ))}
                    >
                        Cancelar
                    </Button>
                    <PdfDownloader
                        url={selectedTab === 'month' ?
                            `/admin/reports/account-statement?type=month&month=${selectedDate}&credit-line-id=${creditLineId}`:
                            `/admin/reports/account-statement?type=range&start_date=${formatDateForUrl(dateRange.start)}&end_date=${formatDateForUrl(dateRange.end)}&credit-line-id=${creditLineId}`
                        }
                        filename={`estado_de_cuenta_${creditLineId}.pdf`}
                        label="Descargar"
                        isStatement={true}
                        disabled={selectedTab === 'month' ? !selectedDate : !dateRange.start || !dateRange.end}
                    />
                </Box>
                </Box>
            </Modal>
        </Box>
    )
}

export default StatementPDF;