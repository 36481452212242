import React from 'react';
import {
    Button
} from '@material-ui/core';

import useStyles from './SubMenuTabBar.style';
import Text from '../../../components/Text';

const SubMenuTabBar = ({
    subMenu,
    setSubMenu,
}) => {
    const classes = useStyles();
    return (
        <div className={classes.subMenuSectionSelector}>
            <Button
                className={classes.buttonSubMenuContainer}
                onClick={() => setSubMenu('general')}
            >
                <Text
                    className={subMenu === 'general' ? classes.subMenuTextOn : classes.subMenuTextOff}
                >
                    Información General
                </Text>
            </Button>
            <Button
                className={classes.buttonSubMenuContainer}
                onClick={() => setSubMenu('inventory')}
            >
                <Text
                    className={subMenu === 'inventory' ? classes.subMenuTextOn : classes.subMenuTextOff}
                >
                    Inventario
                </Text>
            </Button>
            <Button
                className={classes.buttonSubMenuContainer}
                onClick={() => setSubMenu('documents')}
            >
                <Text
                    className={subMenu === 'documents' ? classes.subMenuTextOn : classes.subMenuTextOff}
                >
                    Documentos
                </Text>
            </Button>
            <Button
                className={classes.buttonSubMenuContainer}
                onClick={() => setSubMenu('quote')}
            >
                <Text
                    className={subMenu === 'quote' ? classes.subMenuTextOn : classes.subMenuTextOff}
                >
                    Historico de Citas
                </Text>
            </Button>
        </div>
    )
}

export default SubMenuTabBar;