import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  tableHead: {
    width: '100%',
    backgroundColor: '#F8F9F9',
  },
  titleTable: {
    color: '#858d9c',
    fontSize: 12,
    textAlign: 'center'
  },
  textCellTable: {
    color: '#3a4760',
    fontSize: 12,
    textAlign: 'center'
  },
  statusContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  circleStyle: {
    width: 20, 
    height: 20, 
    borderRadius: '100%',
    marginRight: 5
  },
  linkStyle: {
    display: 'flex',
    justifyContent: 'center',
    color: '#0253cc',
    width: 100,
    textDecoration: 'underline',
    fontSize: 14,
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonRowContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  buttonRejected: {
    backgroundColor: 'red',
    color: 'white',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContainer: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 15,
    backgroundColor: '#ffffff',
    padding: 20,
    width: 'auto',
    height: 'auto'
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '2px solid #e8eefa',
    paddingBottom: 20,
    marginBottom: 20,
  },
  titleModal: {
    textAlign: 'left',
    fontSize: 20,
    color: '#3a4760',
  },
  textModal: {
    fontSize: 16,
    textAlign: 'left',
    color: '#000',
  },
  buttonModalContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 20,
  },
}));

export default useStyles;