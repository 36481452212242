import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import ModalReactivateCollateralUI from "./ModalReactivateCollateral";
import useStyles from "./ModalReactivateCollateral.styles";
import useFirebaseAuth from "../../hooks/useFirebaseAuth";

import { 
    putBureauDecisionAction, 
    clearPutBureauDecisionAction 
} from "../../reducers/avals.action";

function ModalReactivateCollateral(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { user } = useFirebaseAuth();
    const {open, setOpen, avalsData} = props;
    const [selectedRows, setSelectedRows] = useState({});
    const [openAlert, setOpenAlert] = useState(false);
    const [isError, setIsError] = useState(false);

    const {
        error,
    } = useSelector((state) => state.avalsReducer);

    useEffect(() => {
        if(error?.PUT_BUREAU_DECISION) {
            if(error.PUT_BUREAU_DECISION != null) {
                setIsError(true)
            }
        }
    }, [error.PUT_BUREAU_DECISION]);

    function handleCheckboxChange(event, id) {
        const newSelectedRows = {};
      
        if (event.target.checked) {
          newSelectedRows[id] = true;
        }
      
        setSelectedRows(newSelectedRows);
    }

    function isRowSelected(id) {
        return selectedRows.hasOwnProperty(id);
    }

    function handleCloseAlert(event, reason) {
        if (reason === "clickaway") {
          return;
        }
        setOpenAlert(false)
    };

    function handleReactivateCollateral() {
        setOpenAlert(true);
        setOpen(false);
        setSelectedRows({});
        dispatch(clearPutBureauDecisionAction());
        setTimeout(() => {
            window.location.reload();
        }, 3000);
    };

    function reactivateCollateralAction(phone, user_id) {
        let body = {
            phone_number: phone,
            bureau_decision: "APPROVED",
            aval_type: "COLLATERAL",
            requested_by: user?.email
        };

        if(user.email !== undefined && user.email !== null && user.email !== "" && phone !== "") {
            dispatch(putBureauDecisionAction(user_id, body)).then(handleReactivateCollateral())        
        }
    }

    return <ModalReactivateCollateralUI
        classes={classes}
        open={open}
        setOpen={setOpen}
        avalsData={avalsData}
        isRowSelected={isRowSelected}
        handleCheckboxChange={handleCheckboxChange}
        reactivateCollateralAction={reactivateCollateralAction}
        handleCloseAlert={handleCloseAlert}
        openAlert={openAlert}
        isError={isError}
    />
}

export default ModalReactivateCollateral;