import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Alert from '@material-ui/lab/Alert';

import { useDispatch, useSelector } from 'react-redux';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import FormControl from '@material-ui/core/FormControl';
import {
  TextField, Select, FormHelperText, Input, InputAdornment, Snackbar, CircularProgress,
} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';

import { withStyles } from '@material-ui/core/styles';
import MissingDataAlert from '../../../components/MissingDataAlert/MissingDataAlert';

import { getFindShop, getFindCustomer, getAdvisor, createCredit } from './creditCreateActions';
import { getInventory, clearInventory } from '../../../reducers/inventory.action';
import * as ACTIONS from '../actionTypes';
import useStyles from './CreditCreate.style';
import SectionTitle from '../../../components/SectionTitle';
import Button from '../../../components/Button';
import PageContainer from '../../../components/PageContainer';
import Text from '../../../components/Text';
import { Box } from '../../../components/Layout';
import { banks } from '../../../constants/constants';

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'green',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'white',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'red',
      },
      '&:hover fieldset': {
        borderColor: 'yellow',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'white',
      },
    },
  },
})(TextField);

const CreditCreatePage = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { alert } = useSelector((state) => state.registersState);
  const closeAlert = () => {
    dispatch({ type: ACTIONS.UPDATE_CLOSE_ALERT });
  };

  const { error, advisors, shops, customersFound } = useSelector((state) => state.registersState);

  const {
    inventoryData,
  } =  useSelector((state) => state.inventoryReducer);

  // const { resourceType } = props.match.params;
  const [values, setValues] = React.useState({
    accountType: '',
    shopSelected: '',
    customerSelected: '',
    amount: '',
    installment: '',
    day: '',
    advisor: '',
    bank_name: '',
    bank_account_number: '',
    payment_frequency: '',
    monthly_day: 0,
    interest_rate: 10,
    loan_portfolio_status: 'NORMAL',
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const [errorMessages, setErrorMessages] = React.useState({
    accountType: '',
    shopSelected: '',
    customerSelected: '',
    amount: '',
    installment: '',
    day: '',
    advisor: '',
    bank_name: '',
    bank_account_number: '',
    payment_frequency: '',
    monthly_day: '',
    interest_rate: '',
    loan_portfolio_status: '',
  });

  const errorMsgs = {
    accountType: '* Seleccione el tipo de crédito !',
    shopSelected: '* Seleccione la tienda !',
    customerSelected: '* Seleccione el cliente !',
    amount: '* Ingrese el monto !',
    installment: '* Ingrese el valor de la cuota !',
    advisor: values.accountType === 'CU' ? '* Selecciona la tienda que avala !' : '* Seleccione la cartera !',
    bank_name: '* Selecciona el banco del cliente !',
    bank_account_number: '* Ingrese el número de cuenta bancaria !',
    payment_frequency: '* Selecciona la frecuencia de pago',
    interest_rate: '* Ingrese el interés de la línea de crédito',
  };

  const handleErrorMessages = (errors) => {
    setErrorMessages({ ...errorMessages, ...errors });
  };

  const [inputValue, setInputVale] = useState('');
  const [inputCustomerValue, setInputCustomerVale] = useState('');
  const [nothingFoundNoticeShown, showNothingFoundNotice] = useState(false);
  const [creatingCreditLine, setCreatingCreditLine] = useState(false);

  const handleSubmit = () => {
    let hasError = false;
    const errors = {};
    Object.entries(values).forEach(([k, v]) => {
      if (v === '' || v === 0) {
        if (k !== 'monthly_day') {
          if (k !== 'day') {
            if (values.accountType === 'CU') {
              if (k !== 'shopSelected') {
                hasError = true;
                errors[k] = errorMsgs[k];
              }
            } else if (k !== 'customerSelected' && k !== 'bank_name' && k !== 'bank_account_number') {
              hasError = true;
              errors[k] = errorMsgs[k];
            }
          }
        }
      } else {
        errors[k] = '';
      }
    });
    if (values.amount % 500 !== 0) {
      hasError = true;
      errors.amount = '* El monto del crédito debe ser múltiplo de 500 !';
    }
    if (values.payment_frequency === 'MONTHLY') {
      if (!(values.monthly_day > 0  && values.monthly_day < 27)) {
        hasError = true;
        errors.monthly_day = '* Selecciona un día de pago !';
      }
    } else {
      if (values.day === '') {
        hasError = true;
        errors.day = '* Selecciona un día';
      }
    }
    handleErrorMessages(errors);
    if (hasError) {
      return;
    }
    let accountId = values.shopSelected.account_shopkeeper_id;
    let leadSource = values.shopSelected.lead_source;
    if (values.accountType === 'CU') {
      accountId = values.customerSelected.customer_id;
    }
    setCreatingCreditLine(true);
    dispatch(
      createCredit(
        accountId,
        values.accountType,
        values.advisor,
        values.amount,
        values.installment,
        values.day,
        values.bank_name,
        values.bank_account_number,
        values.payment_frequency,
        values.monthly_day,
        values.interest_rate,
        values.loan_portfolio_status,
        leadSource,
      ),
    ).then(() => setCreatingCreditLine(false));
  };

  useEffect(() => {
    if(values.accountType !== '') {
      setValues(prev => ({
        ...prev,
        shopSelected: '',
        customerSelected: '',
        amount: '',
        installment: '',
        day: '',
        advisor: '',
        bank_name: '',
        bank_account_number: '',
        payment_frequency: '',
        monthly_day: 0
      }))
      setErrorMessages(prev => ({
        ...prev,
        accountType: '',
        shopSelected: '',
        customerSelected: '',
        amount: '',
        installment: '',
        day: '',
        advisor: '',
        bank_name: '',
        bank_account_number: '',
        payment_frequency: '',
        monthly_day: ''
      }))
      setInputVale('');
      setInputCustomerVale('');
    }
  }, [values.accountType])

  useEffect(() => {
    if(values.payment_frequency !== '') {
      setValues(prev => ({
        ...prev,
        monthly_day: 0
      }))
    }
  }, [values.payment_frequency])

  useEffect(() => {
    if (error) {
      showNothingFoundNotice(true);
      setInputVale('');
      setInputCustomerVale('');
    }
  }, [error]);

  useEffect(() => {
    setTimeout(() => showNothingFoundNotice(false), 3000);
  }, [nothingFoundNoticeShown]);

  useEffect(() => () => dispatch({
    type: ACTIONS.SET_STATE,
    label: 'searchString',
    value: '',
  }), []);

  useEffect(() => {
    dispatch(getAdvisor());
  }, []);

  useEffect(() => {
    if(values?.shopSelected?.shopkeeper_phone_number !== '' && values?.shopSelected?.shopkeeper_phone_number !== undefined) {
      dispatch(getInventory(`%2B${values.shopSelected.shopkeeper_phone_number.slice(1)}`))
    }
  },[values.shopSelected.shopkeeper_phone_number]);

  useEffect(() => {
    if(Object.keys(inventoryData).length !== 0) {
      if(inventoryData?.data && inventoryData.data?.credit_proposal) {
        setValues(prev => ({
          ...prev,
          amount: inventoryData.data.credit_proposal?.proposed_amount ? inventoryData.data.credit_proposal.proposed_amount.toString() : '',
          installment: inventoryData.data.credit_proposal?.proposed_installment ? inventoryData.data.credit_proposal.proposed_installment.toString() : '',
          payment_frequency: inventoryData.data.credit_proposal?.proposed_term_type ? inventoryData.data.credit_proposal.proposed_term_type : '',
          advisor: inventoryData.data.sales_advisor?.advisor_name ? inventoryData.data.sales_advisor.advisor_name : '',
          interest_rate: parseFloat(inventoryData.data.credit_proposal?.proposed_interest_rate ? inventoryData.data.credit_proposal.proposed_interest_rate.toString() * 4 : '') 
        }))
      }
    }
  }, [inventoryData]);
  
  const advisorList = advisors && advisors.length > 0 ?
    advisors.map((adv) => <MenuItem value={adv.sales_advisor_id}>{adv.name}</MenuItem>) : '';

  const shopList = shops && shops.length > 0 ?
    shops.map((shop, i) => {
      return (<MenuItem value={shop}>{shop.personhood_name}/{shop.shopkeeper_name}</MenuItem>);
    }) : '';

  const shopAsAdvisorList = shops && shops.length > 0 ?
    shops.map((shop) => {
      if (shop.credit_line_id === undefined) {
        return;
      }
      return (<MenuItem value={shop.account_shopkeeper_id}>{shop.personhood_name} / {shop.shopkeeper_name}</MenuItem>);
    }) : '';

  const customersFoundList = customersFound && customersFound.length > 0 ?
    customersFound.map((customer) => {
      if (customer.credit_status !== 'ACTIVED') {
        return;
      }
      return (<MenuItem value={customer}>{customer.full_name} / {customer.phone_number}</MenuItem>);
    }) : '';

  const MonthlyDays = () => {
    let arrayDay = [];
    for(let day = 1; day < 27; day++) {
      arrayDay.push(day);
    }
    return arrayDay.map((day) => {
      return <MenuItem value={day}>{day}</MenuItem>
    })
  }

  return (
    <PageContainer>
      <Snackbar open={alert.open} onClose={closeAlert} autoHideDuration={10000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert severity={alert.severity} onClose={closeAlert}>
          {alert.message}
        </Alert>
      </Snackbar>

      <Link to="/credits" className={classes.controls}>
        <ArrowBackIcon />
        <Text>Regresar</Text>
      </Link>

      <Box className={classes.mainPanel}>
        <SectionTitle>Cr&eacute;dito productivo</SectionTitle>
      </Box>

      <Box style={{ width: '35%', paddingTop: 15, paddingBottom: 15 }}>
        <Text variant="h4" className={classes.textBlue}>
          Tipo de cr&eacute;dito
        </Text>
        <Select
          displayEmpty
          style={{ display: 'flex' }}
          labelId="demo-simple-select-label"
          id="advisor"
          value={values.accountType}
          onChange={handleChange('accountType')}>
          <MenuItem value="" disabled>Selecciona un tipo de cr&eacute;dito</MenuItem>
          <MenuItem value="SK">Tendero</MenuItem>
          <MenuItem value="CU">Cliente</MenuItem>
        </Select>
        <FormHelperText>
          {errorMessages.accountType}
        </FormHelperText>
      </Box>

      {values.accountType === 'SK' ?
        (
          <Box width="100%">

            <Box>
              {nothingFoundNoticeShown && <MissingDataAlert status="BAD" text="No se encontraron tiendas" />}
            </Box>

            <Box>
              <Text variant="h4" className={classes.textBlue}>
                Nombre de la tienda
              </Text>
            </Box>

            <Box className={classes.searchContainer}>
              <InputBase
                value={inputValue}
                onChange={(event) => {
                  setInputVale(event.target.value);
                  dispatch({
                    type: ACTIONS.SET_STATE,
                    label: 'searchString',
                    value: event.target.value,
                  });
                }}
                className={classes.input}
                placeholder="Nombre de la tienda"
                inputProps={{ 'aria-label': 'search user' }}
                onKeyUp={(event) => {
                  if (event.key === 'Enter') {
                    dispatch(getFindShop());
                    //event.preventDefault();
                  }
                }}
              />
              <Divider className={classes.divider} orientation="vertical" />
              <IconButton
                onClick={() => { dispatch(getFindShop()); }}
                className={classes.iconButton}
                aria-label="search">
                <SearchIcon />
              </IconButton>
            </Box>
            <FormHelperText>
              {errorMessages.shopSelected}
            </FormHelperText>

            {shops && shops.length >= 1 ?
              (<Box style={{ width: '35%', paddingTop: 15, paddingBottom: 15 }}>
                <Text variant="h4" className={classes.textBlue}>
                  Elige una tienda
                </Text>
                <Select
                  displayEmpty
                  style={{ display: 'flex' }}
                  labelId="demo-simple-select-label"
                  id="advisor"
                  value={values.shopSelected}
                  onChange={handleChange('shopSelected')}>
                  <MenuItem value="" disabled>Selecciona una tienda</MenuItem>
                  {shopList}
                </Select>
              </Box>) : (<Box></Box>)
            }
            {values.shopSelected ?
              (<Box>
                <Box style={{ width: '100%', paddingTop: 15, paddingBottom: 15 }}>
                  <CssTextField
                    disabled
                    style={{ width: 300, marginRight: 20, }}
                    className={classes.margin}
                    id="custom-css-standard-input"
                    label="Responsable de la tienda"
                    defaultValue=""
                    value={values.shopSelected.shopkeeper_name}
                    InputLabelProps={{ shrink: true, }} />
                  <CssTextField
                    disabled
                    style={{ width: 300, marginRight: 20, }}
                    className={classes.margin}
                    label="Teléfono del responsable"
                    id="custom-css-outlined-input"
                    defaultValue="No viene del WS"
                    value={values.shopSelected.shopkeeper_phone_number}
                    InputLabelProps={{ shrink: true, }} />
                </Box>
                <Box style={{ width: '100%', paddingTop: 15, paddingBottom: 15 }}>
                  <CssTextField
                    disabled
                    style={{ width: 300, marginRight: 20, }}
                    className={classes.margin}
                    label="RFC del responsable de la tienda"
                    id="custom-css-outlined-input"
                    defaultValue=""
                    value={values.shopSelected.shopkeeper_curp}
                    InputLabelProps={{ shrink: true, }} />
                  <CssTextField
                    disabled
                    style={{ width: 300, marginRight: 20, }}
                    className={classes.margin}
                    label="Dirección de la tienda"
                    id="custom-css-standard-input"
                    defaultValue=""
                    value={`${values.shopSelected.street_name} ${values.shopSelected.street_number} ${values.shopSelected.apartment_number} CP ${values.shopSelected.zip_code} - ${values.shopSelected.neighborhood}, ${values.shopSelected.delegation}, ${values.shopSelected.state}`}
                    InputLabelProps={{ shrink: true, }} />
                </Box>
              </Box>) : (<Box></Box>)}

            <Box style={{ width: '35%', paddingTop: 15, paddingBottom: 15 }}>
              <Text variant="h4" className={classes.textBlue}>¿Cu&aacute;nto necesita?</Text>
              <Input
                id="credit-amount"
                style={{ display: 'flex' }}
                value={values.amount}
                type="number"
                onChange={handleChange('amount')}
                placeholder="De $5,000 a $20,000"
                startAdornment={<InputAdornment position="start">$</InputAdornment>}
              />
              <FormHelperText>
                {errorMessages.amount}
              </FormHelperText>
            </Box>

            <Box style={{ width: '35%', paddingTop: 15, paddingBottom: 15 }}>
              <Text variant="h4" className={classes.textBlue}>¿Cu&aacute;nto dinero puede pagar semanalmente?</Text>
              <Input
                id="installment-amount"
                style={{ display: 'flex' }}
                value={values.installment}
                type="number"
                onChange={handleChange('installment')}
                placeholder="Escribe la cantidad"
                startAdornment={<InputAdornment position="start">$</InputAdornment>}
              />
              <FormHelperText>
                {errorMessages.installment}
              </FormHelperText>
            </Box>

            <Box style={{ width: '35%', paddingTop: 15, paddingBottom: 15 }}>
              <Text variant="h4" className={classes.textBlue}>¿Con que frecuencia va a realizar el pago?</Text>
              <Select
                displayEmpty
                style={{ display: 'flex' }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={values.payment_frequency}
                onChange={handleChange('payment_frequency')}>
                <MenuItem value="" disabled>Selecciona la frecuencia de pago</MenuItem>
                <MenuItem value={'WEEKLY'}>Semanal</MenuItem>
                <MenuItem value={'BIWEEKLY'}>Catorcenal</MenuItem>
                <MenuItem value={'MONTHLY'}>Mensual</MenuItem>
              </Select>
              <FormHelperText>
                {errorMessages.payment_frequency}
              </FormHelperText>
            </Box>

            { values.payment_frequency === 'MONTHLY' &&
              <Box style={{ width: '35%', paddingTop: 15, paddingBottom: 15 }}>
                <Text variant="h4" className={classes.textBlue}>Selecciona un día del mes para realizar tú pago</Text>
                <Select
                  displayEmpty
                  style={{ display: 'flex' }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={values.monthly_day}
                  onChange={handleChange('monthly_day')}>
                  <MenuItem value="" disabled>Selecciona un día</MenuItem>
                  { MonthlyDays() }
                </Select>
                <FormHelperText>
                  {errorMessages.monthly_day}
                </FormHelperText>
              </Box>
            }

            { values.payment_frequency !== 'MONTHLY' && values.payment_frequency !== '' &&
              <Box style={{ width: '35%', paddingTop: 15, paddingBottom: 15 }}>
                <Text variant="h4" className={classes.textBlue}>¿Qu&eacute; día va a realizar el pago?</Text>
                <Select
                  displayEmpty
                  style={{ display: 'flex' }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={values.day}
                  onChange={handleChange('day')}>
                  <MenuItem value="" disabled>Selecciona el día</MenuItem>
                  <MenuItem value={'MONDAY'}>Lunes</MenuItem>
                  <MenuItem value={'TUESDAY'}>Martes</MenuItem>
                  <MenuItem value={'WEDNESDAY'}>Miércoles</MenuItem>
                  <MenuItem value={'THURSDAY'}>Jueves</MenuItem>
                  <MenuItem value={'FRIDAY'}>Viernes</MenuItem>
                </Select>
                <FormHelperText>
                  {errorMessages.day}
                </FormHelperText>
              </Box>
            }

            <Box style={{ width: '35%', paddingTop: 15, paddingBottom: 15 }}>
              <Text variant="h4" className={classes.textBlue}>
                N&uacute;mero de cartera
              </Text>
              <Select
                displayEmpty
                style={{ display: 'flex' }}
                labelId="demo-simple-select-label"
                id="advisor"
                value={values.advisor}
                onChange={handleChange('advisor')}>
                <MenuItem value="" disabled>Selecciona la cartera</MenuItem>
                {advisorList}
              </Select>
              <FormHelperText>
                {errorMessages.advisor}
              </FormHelperText>
            </Box>

            <Box style={{ width: '35%', paddingTop: 15, paddingBottom: 15 }}>
              <Text variant="h4" className={classes.textBlue}>¿Cu&aacute;l es el inter&eacute;s <b>mensual</b> de la l&iacute;nea de cr&eacute;dito?</Text>
              <Input
                id="bank-account-number"
                style={{ display: 'flex' }}
                value={values.interest_rate}
                type="number"
                onChange={handleChange('interest_rate')}
                placeholder="Ingresa el inter&eacute;s de la l&iacute;nea de cr&eacute;dito"
                inputProps={{ maxLength: 5 }}
              />
              <FormHelperText>
                {errorMessages.interest_rate}
              </FormHelperText>
            </Box>

            <Box style={{ width: '35%', paddingTop: 15, paddingBottom: 15 }}>
              <Text variant="h4" className={classes.textBlue}>¿La l&iacute;nea de cr&eacute;dito tiene alg&uacute;n estado especial?</Text>
              <Select
                displayEmpty
                style={{ display: 'flex' }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={values.loan_portfolio_status}
                onChange={handleChange('loan_portfolio_status')}>
                <MenuItem value={'NORMAL'}>Normal</MenuItem>
                <MenuItem value={'LIGHT'}>Light</MenuItem>
                <MenuItem value={'RESTRUCTURING'}>Reestructuraci&oacute;n</MenuItem>
                <MenuItem value={'CHANGE_OF_CONDITIONS'}>Cambio de condiciones</MenuItem>
              </Select>
              <FormHelperText>
                {errorMessages.loan_portfolio_status}
              </FormHelperText>
            </Box>

          </Box>
        ) : (<Box></Box>)}

      {values.accountType === 'CU' ?
        (
          <Box width="100%">
            <Box>
              {nothingFoundNoticeShown && <MissingDataAlert status="BAD" text="No se encontraron tiendas" />}
            </Box>

            <Box>
              <Text variant="h4" className={classes.textBlue}>
                Tel&eacute;fono del cliente
              </Text>
            </Box>

            <Box component="form" className={classes.searchContainer}>
              <InputBase
                value={inputCustomerValue}
                onChange={(event) => {
                  setInputCustomerVale(event.target.value);
                  dispatch({
                    type: ACTIONS.SET_STATE,
                    label: 'searchCustomerString',
                    value: event.target.value,
                  });
                }}
                className={classes.input}
                placeholder="N&uacute;mero telef&oacute;nico del cliente"
                inputProps={{ 'aria-label': 'search user' }}
                onKeyUp={(event) => {
                  if (event.key === 'Enter') {
                    event.preventDefault();
                  }
                }}
              />
              <Divider className={classes.divider} orientation="vertical" />
              <IconButton
                onClick={() => { dispatch(getFindCustomer()); }}
                className={classes.iconButton}
                aria-label="search">
                <SearchIcon />
              </IconButton>
            </Box>
            <FormHelperText>
              {errorMessages.customerSelected}
            </FormHelperText>

            {customersFound && customersFound.length >= 1 ?
              (<Box style={{ width: '35%', paddingTop: 15, paddingBottom: 15 }}>
                <Text variant="h4" className={classes.textBlue}>
                  Elige el cliente
                </Text>
                <Select
                  displayEmpty
                  style={{ display: 'flex' }}
                  labelId="demo-simple-select-label"
                  id="customer"
                  value={values.customerSelected}
                  onChange={handleChange('customerSelected')}>
                  <MenuItem value="" disabled>Selecciona un cliente</MenuItem>
                  {customersFoundList}
                </Select>
              </Box>) : (<Box></Box>)
            }

            <Box style={{ width: '35%', paddingTop: 15, paddingBottom: 15 }}>
              <Text variant="h4" className={classes.textBlue}>¿Cu&aacute;nto necesita?</Text>
              <Input
                id="credit-amount"
                style={{ display: 'flex' }}
                value={values.amount}
                type="number"
                onChange={handleChange('amount')}
                placeholder="De $1,000 a $5,000"
                startAdornment={<InputAdornment position="start">$</InputAdornment>}
              />
              <FormHelperText>
                {errorMessages.amount}
              </FormHelperText>
            </Box>

            <Box style={{ width: '35%', paddingTop: 15, paddingBottom: 15 }}>
              <Text variant="h4" className={classes.textBlue}>¿Cu&aacute;nto dinero puede pagar semanalmente?</Text>
              <Input
                id="installment-amount"
                style={{ display: 'flex' }}
                value={values.installment}
                type="number"
                onChange={handleChange('installment')}
                placeholder="Escribe la cantidad"
                startAdornment={<InputAdornment position="start">$</InputAdornment>}
              />
              <FormHelperText>
                {errorMessages.installment}
              </FormHelperText>
            </Box>

            <Box style={{ width: '35%', paddingTop: 15, paddingBottom: 15 }}>
              <Text variant="h4" className={classes.textBlue}>¿Con que frecuencia va a realizar el pago?</Text>
              <Select
                displayEmpty
                style={{ display: 'flex' }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={values.payment_frequency}
                onChange={handleChange('payment_frequency')}>
                <MenuItem value="" disabled>Selecciona la frecuencia de pago</MenuItem>
                <MenuItem value={'MONTHLY'}>Mensual</MenuItem>
                <MenuItem value={'BIWEEKLY'}>Catorcenal</MenuItem>
                <MenuItem value={'WEEKLY'}>Semanal</MenuItem>
              </Select>
              <FormHelperText>
                {errorMessages.payment_frequency}
              </FormHelperText>
            </Box>

            { values.payment_frequency === 'MONTHLY' &&
              <Box style={{ width: '35%', paddingTop: 15, paddingBottom: 15 }}>
                <Text variant="h4" className={classes.textBlue}>Selecciona un día del mes para realizar tú pago</Text>
                <Select
                  displayEmpty
                  style={{ display: 'flex' }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={values.monthly_day}
                  onChange={handleChange('monthly_day')}>
                  <MenuItem value="" disabled>Selecciona un día</MenuItem>
                  { MonthlyDays() }
                </Select>
                <FormHelperText>
                  {errorMessages.monthly_day}
                </FormHelperText>
              </Box>
            }

            { values.payment_frequency !== 'MONTHLY' && values.payment_frequency !== '' &&
              <Box style={{ width: '35%', paddingTop: 15, paddingBottom: 15 }}>
                <Text variant="h4" className={classes.textBlue}>¿Qu&eacute; día va a realizar el pago?</Text>
                <Select
                  displayEmpty
                  style={{ display: 'flex' }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={values.day}
                  onChange={handleChange('day')}>
                  <MenuItem value="" disabled>Selecciona el día</MenuItem>
                  <MenuItem value={'MONDAY'}>Lunes</MenuItem>
                  <MenuItem value={'TUESDAY'}>Martes</MenuItem>
                  <MenuItem value={'WEDNESDAY'}>Miércoles</MenuItem>
                  <MenuItem value={'THURSDAY'}>Jueves</MenuItem>
                  <MenuItem value={'FRIDAY'}>Viernes</MenuItem>
                </Select>
                <FormHelperText>
                  {errorMessages.day}
                </FormHelperText>
              </Box>
            }

            <Box>
              {nothingFoundNoticeShown && <MissingDataAlert status="BAD" text="No se encontraron tiendas" />}
            </Box>

            <Box>
              <Text variant="h4" className={classes.textBlue}>
                Nombre de la tienda que avala
              </Text>
            </Box>

            <Box component="form" className={classes.searchContainer}>
              <InputBase
                value={inputValue}
                onChange={(event) => {
                  setInputVale(event.target.value);
                  dispatch({
                    type: ACTIONS.SET_STATE,
                    label: 'searchString',
                    value: event.target.value,
                  });
                }}
                className={classes.input}
                placeholder="Nombre de la tienda que avala"
                inputProps={{ 'aria-label': 'search user' }}
                onKeyUp={(event) => {
                  if (event.key === 'Enter') {
                    event.preventDefault();
                  }
                }}
              />
              <Divider className={classes.divider} orientation="vertical" />
              <IconButton
                onClick={() => { dispatch(getFindShop()); }}
                className={classes.iconButton}
                aria-label="search">
                <SearchIcon />
              </IconButton>
            </Box>
            <FormHelperText>
              {errorMessages.advisor}
            </FormHelperText>

            {shops && shops.length >= 1 ?
              (<Box style={{ width: '35%', paddingTop: 15, paddingBottom: 15 }}>
                <Text variant="h4" className={classes.textBlue}>
                  Elige la tienda que avala
                </Text>
                <Select
                  displayEmpty
                  style={{ display: 'flex' }}
                  labelId="demo-simple-select-label"
                  id="advisor"
                  value={values.advisor}
                  onChange={handleChange('advisor')}>
                  <MenuItem value="" disabled>Selecciona una tienda</MenuItem>
                  {shopAsAdvisorList}
                </Select>
              </Box>) : (<Box></Box>)
            }

            <Box style={{ width: '35%', paddingTop: 15, paddingBottom: 15 }}>
              <Text variant="h4" className={classes.textBlue}>¿Qu&eacute; banco tiene el cliente?</Text>
              <Select
                displayEmpty
                style={{ display: 'flex' }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={values.bank_name}
                onChange={handleChange('bank_name')}>
                <MenuItem value="" disabled>Selecciona el banco</MenuItem>
                {
                  banks.map((bank) => {
                    if (bank.code === undefined) {
                      return;
                    }
                    return <MenuItem value={bank.value}>{bank.title}</MenuItem>
                  })
                }
              </Select>
              <FormHelperText>
                {errorMessages.bank_name}
              </FormHelperText>
            </Box>

            <Box style={{ width: '35%', paddingTop: 15, paddingBottom: 15 }}>
              <Text variant="h4" className={classes.textBlue}>¿Cu&aacute;l es el n&uacute;mero de cuenta bancaria?</Text>
              <Input
                id="bank-account-number"
                style={{ display: 'flex' }}
                value={values.bank_account_number}
                // type="number"
                onChange={handleChange('bank_account_number')}
                placeholder="Escribe el n&uacute;mero de cuenta"
                inputProps={{ maxLength: 18 }}
              />
              <FormHelperText>
                {errorMessages.bank_account_number}
              </FormHelperText>
            </Box>

            <Box style={{ width: '35%', paddingTop: 15, paddingBottom: 15 }}>
              <Text variant="h4" className={classes.textBlue}>¿Cu&aacute;l es el inter&eacute;s <b>mensual</b> de la l&iacute;nea de cr&eacute;dito?</Text>
              <Input
                id="bank-account-number"
                style={{ display: 'flex' }}
                value={values.interest_rate}
                type="number"
                onChange={handleChange('interest_rate')}
                placeholder="Ingresa el inter&eacute;s de la l&iacute;nea de cr&eacute;dito"
                inputProps={{ maxLength: 5 }}
              />
              <FormHelperText>
                {errorMessages.interest_rate}
              </FormHelperText>
            </Box>

            <Box style={{ width: '35%', paddingTop: 15, paddingBottom: 15 }}>
              <Text variant="h4" className={classes.textBlue}>¿La l&iacute;nea de cr&eacute;dito tiene alg&uacute;n estado especial?</Text>
              <Select
                displayEmpty
                style={{ display: 'flex' }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={values.loan_portfolio_status}
                onChange={handleChange('loan_portfolio_status')}>
                <MenuItem value={'NORMAL'}>Normal</MenuItem>
                <MenuItem value={'LIGHT'}>Light</MenuItem>
                <MenuItem value={'RESTRUCTURING'}>Reestructuraci&oacute;n</MenuItem>
                <MenuItem value={'CHANGE_OF_CONDITIONS'}>Cambio de condiciones</MenuItem>
              </Select>
              <FormHelperText>
                {errorMessages.loan_portfolio_status}
              </FormHelperText>
            </Box>
          </Box>
        ) : (<Box></Box>)}

      <Box style={{ width: '35%' }}>
        <FormControl className={classes.formStyles}>
          <Box className={classes.buttonContainer}>
            <Button
              variant="outlined"
              className={classes.button}
              style={{ backgroundColor: '#0253cc', color: 'white' }}
              onClick={() => {
                handleSubmit();
              }}
              disabled={creatingCreditLine}
            >
              Crear cr&eacute;dito
            </Button>
          </Box>
          <Box style={{ display: creatingCreditLine ? 'block' : 'none' }}>
            <CircularProgress />
          </Box>
        </FormControl>
      </Box>

    </PageContainer>
  );
};

export default CreditCreatePage;
