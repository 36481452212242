import * as ACTION from '../actionTypes';
import client from '../../../client';
import { appService } from '../../../services/app.service';

// eslint-disable-next-line max-len
export const updateCredit = (credit_line_id, sales_advisor_id, amt, inst, payment_day) => async (dispatch) => {
  const url = `${appService.config.paths.loan.route}/${credit_line_id}`;
  const term_type = 'WEEKLY';

  try {
    const amount = parseFloat(amt);
    const installment = parseFloat(inst);

    await client.put(url, {
      sales_advisor_id,
      amount,
      installment,
      term_type,
      payment_day,
    }).then(
      (response) => {
        window.location = `/credits/${response.data.credit_line_id}/detail`;
      },
    ).catch((error) => {
      dispatch({ type: ACTION.UPDATE_ALERT_ERROR, value: error.response.data.message });
    });
  } catch (e) {
    console.log(e);
  }
};

export default updateCredit;
