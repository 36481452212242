import React, { useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

import { appService } from '../../../services/app.service';

const options = {
    cMapUrl: 'cmaps/',
    cMapPacked: true,
    standardFontDataUrl: 'standard_fonts/',
};

const DocumentPdfViewer = ({
    pdf_path,
}) => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const newPath = pdf_path?.replace(new RegExp("/v1", 'g'), '');
    const pdfFile = {
        url: `${appService.config.paths.api.basePath}${newPath}`,
        httpHeaders: {
            authorization: localStorage.getItem('firebaseToken'),
        },
    }
    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    function changePage(offset) {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    function previousPage() {
        changePage(-1);
    }

    function nextPage() {
        changePage(1);
    }

    const downloadFile = (pdfFile) => () => {
        if (!pdfFile || !pdfFile.url || pdfFile === undefined) {
            console.error("La URL del PDF no está definida.");
            return;
        }
    
        fetch(pdfFile.url, {
            method: 'GET',
            headers: pdfFile.httpHeaders,
        })
            .then((response) => response.blob())
            .then((blob) => {
                // Crear enlace blob para descargar
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('target', '_blank');
                link.setAttribute('download', 'bureau.pdf');
    
                // Añadir al elemento de enlace html de la página
                document.body.appendChild(link);
    
                // Iniciar descarga
                link.click();
    
                // Limpiar y eliminar el enlace
                link.parentNode.removeChild(link);
            })
            .catch(error => {
                console.error("Error al descargar el archivo PDF:", error);
            });
    }
    
    return (
        <>
            {pdf_path &&
                <div>
                    <a href='#' onClick={downloadFile(pdfFile)}>Descargar</a>
                    <Document
                        file={pdfFile}
                        onLoadSuccess={onDocumentLoadSuccess}
                        loading="Cargando el PDF de Buró ..."
                        noData="PDF de Buró no encontrado"
                        error="Error cargando PDF de Buró"
                        options={options}>
                        <Page pageNumber={pageNumber} />
                    </Document>
                    <div>
                        <p>
                            P&aacute;gina {pageNumber || (numPages ? 1 : '--')} de {numPages || '--'}
                        </p>
                        <button
                            type="button"
                            disabled={pageNumber <= 1}
                            onClick={previousPage}
                        >
                            &lt;
                        </button>
                        <button
                            type="button"
                            disabled={pageNumber >= numPages}
                            onClick={nextPage}
                        >
                            &gt;
                        </button>
                    </div>
                </div>
            }
        </>
    );
}

export default DocumentPdfViewer;