import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Box from '../../components/Layout/Box';
import PageContainer from '../../components/PageContainer';
import SectionTitle from '../../components/SectionTitle';
import { ShopsTable } from './ShopsTable';
import useStyles from './ShopsPage.styles';
import MissingDataAlert from '../../components/MissingDataAlert/MissingDataAlert';
import { clearSelectedShopsSet, getSettlementsByType } from './ShopsPage.actions';


const ShopsPage = (props) => {
  const { resourceType } = props.match.params;

  const classes = useStyles();
  const dispatch = useDispatch();
  const [nothingFoundNoticeShown, showNothingFoundNotice] = useState(false);
  const [inputValue, setInputVale] = useState('');
  const { error } = useSelector((state) => state.shopsState);
  const { settlements } = useSelector((state) => state.shopsState);

  useEffect(() => {
    if (error) {
      showNothingFoundNotice(true);
      setInputVale('');
    }
  }, [error]);

  useEffect(() => {
    setTimeout(() => showNothingFoundNotice(false), 3000);
  }, [nothingFoundNoticeShown]);


  useEffect(() => {
    dispatch(getSettlementsByType(resourceType === 'settlements' ? 'POSITIVE' : 'NEGATIVE'));
  }, [resourceType]);

  useEffect(() => {
    dispatch(clearSelectedShopsSet());
  }, []);

  return (
    <PageContainer>
      <Box className={classes.rootContainer}>
        <Box
          className={classes.topContainer}
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <SectionTitle>{resourceType === 'settlements' ? 'Liquidaciones' : 'Cobros'}</SectionTitle>
          {nothingFoundNoticeShown && (
            <MissingDataAlert status="BAD" text="No se encontraron Tiendas" />
          )}
          <Box component="form" className={classes.searchContainer}>
            <InputBase
              value={inputValue}
              onChange={(event) => {
                setInputVale(event.target.value);
              }}
              className={classes.input}
              placeholder="Buscar por nombre de cliente, email, ID..."
              inputProps={{ 'aria-label': 'search user' }}
              onKeyUp={(event) => {
                if (event.key === 'Enter') {
                  dispatch(getSettlementsByType());
                }
              }}
            />
            <Divider className={classes.divider} orientation="vertical" />
            <IconButton
              onClick={() => dispatch(getSettlementsByType())}
              className={classes.iconButton}
              aria-label="search"
            >
              <SearchIcon />
            </IconButton>
          </Box>
        </Box>
        <Box className={classes.tableContainer}>
          <ShopsTable resourceType={resourceType} resources={settlements} />
        </Box>
      </Box>
    </PageContainer>
  );
};

export default ShopsPage;
