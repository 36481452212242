/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import PropTypes from 'prop-types';
import BoxM from '@material-ui/core/Box';

const Box = ({ children, ...others }) => (
  <BoxM {...others}>
    {children}
  </BoxM>
);


Box.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.shape({})
  ]).isRequired,
};

export default Box;
