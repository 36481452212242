/* eslint-disable import/prefer-default-export */
import { combineReducers } from 'redux';
import aboutState from './aboutReducer';
import dashboardState from './dashboardReducer';
import homeState from './homeReducer';
import notFoundState from './notFoundReducer';
import registersState from './registersReducer';
import authState from './authReducer';
import customersState from './customersReducer';
import shopsState from './shops.reducer';
import loanState from './loan.reducer';
import shopState from './shopReducer';
import creditInformationState from './creditInformation.reducer';
import collectionReducer from './collectionReducer';
import transactionsReducer from './transactions.reducer';
import wholesalerReducer from './wholesalerReducer';
import wareHouseReducer from './warehouse.reducer';
import avalsReducer from './avals.reducer';
import inventoryReducer from './inventory.reducer';
import supplierReducer from './dealer.reducer';
import saveVouchersReducer from './vouchers.reducer';
import saveSuppliersReducer from './suppliers.reducer';
import saveAmountsReducer from './amounts.reducer';
import usersReducer from './users.reducer';
import businessReducer from './business.reducer';
import notificationsReducer from './notifications.reducer';
import appointmentReducer from './appointment.reducer';
import modificationsReducer from './modificationsReducer';
import presaleAdvisorsReducer from './presaleadvisors.reducer';
import creditLineReducer from './creditline.reducer';
import catalogReducer from './catalogReducer';
import paymentReducer from './payment.reducer';
import reviewVoucherReducer from './reviewVouchers.reducer';
import kycReducer from './kyc.reducer';
import utilsReducer from './utils.reducer';
import insuranceReducer from './insurance.reducer';

export const rootReducer = combineReducers({
  aboutState,
  dashboardState,
  homeState,
  notFoundState,
  registersState,
  authState,
  customersState,
  shopsState,
  loanState,
  shopState,
  creditInformationState,
  collectionReducer,
  transactionsReducer,
  wholesalerReducer,
  wareHouseReducer,
  avalsReducer,
  inventoryReducer,
  supplierReducer,
  saveVouchersReducer,
  saveSuppliersReducer,
  saveAmountsReducer,
  usersReducer,
  businessReducer,
  notificationsReducer,
  appointmentReducer,
  modificationsReducer,
  presaleAdvisorsReducer,
  creditLineReducer,
  catalogReducer,
  paymentReducer,
  reviewVoucherReducer,
  kycReducer,
  utilsReducer,
  insuranceReducer,
});
