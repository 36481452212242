import React from 'react';
import {
  TableContainer, Table, TableBody, TableRow, TableCell
} from '@material-ui/core';
import useStyles from '../Shops/PaginationTable.style';
import Text from '../../components/Text';
import ArrowedText from '../../components/ArrowedText';
import Button from '../../components/Button';
import { Box } from '../../components/Layout';
import { OrderedTableHead } from './WareHouseHead';

export const WareHouseTable = (props) => {
  const { resources, _showQR } = props;
  const classes = useStyles();

  return (
    <Box>
      <TableContainer className={classes.tableContainer}>
        <Table aria-label="custom pagination table">
          <OrderedTableHead />
          <TableBody>
            {
              resources.map((warehouse, index) => (
                <TableRow key={index} style={{ maxHeight: 43 }}>
                    <TableCell>
                        <Text className={classes.textStyle} style={{ textAlign: 'center' }}>
                            {warehouse?.account_id || ''}
                        </Text>
                    </TableCell>
                    <TableCell>
                        <Text className={classes.textStyle} style={{ textAlign: 'center' }}>
                          {warehouse?.headquarter?.trade_name || ''}
                        </Text>
                    </TableCell>
                    <TableCell>
                        <Text className={classes.textStyle} style={{ textAlign: 'center' }}>
                          {warehouse?.trade_name || ''}
                        </Text>
                    </TableCell>
                    <TableCell>
                        <Text className={classes.textStyle} style={{ textAlign: 'center' }}>
                            {(warehouse?.headquarter?.owner?.name || '') + ' ' + (warehouse?.headquarter?.owner?.last_name || '')}
                        </Text>
                    </TableCell>
                    <TableCell>
                        <Text className={classes.textStyle} style={{ textAlign: 'center' }}>
                            {warehouse?.headquarter?.phone_number || ''}
                        </Text>
                    </TableCell>
                    <TableCell>
                        <Text className={classes.textStyle} style={{ textAlign: 'center' }}>
                            {warehouse?.email || ''}
                        </Text>
                    </TableCell>
                    <TableCell>
                        <Text className={classes.textStyle} style={{ textAlign: 'center' }}>
                            {warehouse?.headquarter?.owner?.rfc || ''}
                        </Text>
                    </TableCell>
                    <TableCell>
                        <Text className={classes.textStyle} style={{ textAlign: 'center' }}>
                            {warehouse?.headquarter?.rfc || ''}
                        </Text>
                    </TableCell>
                    <TableCell>
                        <Text className={classes.textStyle} style={{ textAlign: 'center' }}>
                            $ {warehouse?.balance || 0}
                        </Text>
                    </TableCell>
                    <TableCell>
                      <Button
                        onClick={() => _showQR(warehouse)}
                      >
                          <ArrowedText>
                              Ver código QR
                          </ArrowedText>
                      </Button>
                    </TableCell>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default WareHouseTable;
