import React, { useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { appService } from '../../../services/app.service';

const options = {
  cMapUrl: 'cmaps/',
  cMapPacked: true,
  standardFontDataUrl: 'standard_fonts/',
};

const InventoryPdfViewer = ({
  phone_number,
  version,
  business_flow,
  isComparison,
}) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const pdfFile = !isComparison
    ? `${appService.config.paths.api.basePath}${appService.config.paths.loan_request.route}/documents?phone_number=${encodeURIComponent(
      phone_number,
    )}&pdf_type=INVENTORY`
    : `${appService.config.paths.api.basePath}${appService.config.paths.loan_request.route}/documents?phone_number=${encodeURIComponent(
      phone_number,
    )}&pdf_type=INVENTORY&businessflow=${encodeURIComponent(
      business_flow,
    )}&version=${encodeURIComponent(version)}`;


  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  return (
    <div>
      <Document
        file={pdfFile}
        onLoadSuccess={onDocumentLoadSuccess}
        loading="Cargando el PDF de Inventario ..."
        noData="PDF de Inventario no encontrado"
        error="Error cargando PDF de Inventario"
        options={options}
      >
        <Page pageNumber={pageNumber} />
      </Document>
      <div>
        <p>
          Página
          {' '}
          {pageNumber || (numPages ? 1 : '--')}
          {' '}
          de
          {' '}
          {numPages || '--'}
        </p>
        <button
          type="button"
          disabled={pageNumber <= 1}
          onClick={previousPage}
        >
          &lt;
        </button>
        <button
          type="button"
          disabled={pageNumber >= numPages}
          onClick={nextPage}
        >
          &gt;
        </button>
      </div>
    </div>
  );
};

export default InventoryPdfViewer;
