import { IconButton, Snackbar, Tooltip } from '@material-ui/core';
import React, { useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import Icons from '../../assets/icons';
import { copyTextToClipboard } from '../../constants/copyContent';
import useStyles from './CopyButton.styles';

export default function CopyButton({
  elementToCopy, toolTipText, isRequiredBitly, onClick,
}) {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const classes = useStyles();

  const handleOpenSnackbar = () => {
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <>
      <Tooltip title={toolTipText}>
        <img
          className={classes.copyBtn}
          onClick={() => {
            if (isRequiredBitly) {
              onClick();
              handleOpenSnackbar();
            } else {
              copyTextToClipboard(elementToCopy);
              handleOpenSnackbar();
            }
          }}
          src={Icons.contentCopy}
        />
      </Tooltip>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={openSnackbar}
        autoHideDuration={2500}
        onClose={handleCloseSnackbar}
        message="Valor copiado al portapapeles"
        action={(
          <>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleCloseSnackbar}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        )}
      />
    </>
  );
}
