import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  arrowButtonColor: {
    color: '#1c0fa9'
  },
  currentPage: {
    color: 'white',
    backgroundColor: '#0253cc',
  }
}));

export default useStyles;
