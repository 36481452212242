import * as ACTION from './actionTypes';
import client from '../../client';
import { appService } from '../../services/app.service';


export const getCustomers = (page) => async (dispatch, getState) => {
  const {
    searchString, sort, orderBy, filter,
  } = getState().customersState;

  const url = `${appService.config.paths.customers.route}${(page || orderBy || sort || searchString || filter ? '?' : '')}${page ? `page=${page}&` : ''}${searchString ? `search=${searchString}&` : ''}${orderBy ? `order_by=${orderBy}&` : ''}${sort ? `sort=${sort}&` : ''}${filter ? `debt-collection-filter=${filter}` : ''}`;

  dispatch({ type: ACTION.FETCH_CUSTOMERS_INIT });

  const response = await client.get(url);

  const { data } = response;
  const {
    data: customers, links, next, page: dataPage, pages, total_elements,
  } = data;

  if (data) {
    if (customers && 'length' in customers && customers.length > 0) {
      dispatch({ type: ACTION.FETCH_CUSTOMERS_SUCCESS });
      dispatch({ type: ACTION.SET_STATE, label: 'customers', value: customers });
    } else {
      dispatch({
        type: ACTION.FETCH_CUSTOMERS_ERROR, error: 'No users returned from service',
      });
      dispatch({
        type: ACTION.FETCH_CUSTOMERS_ERROR, error: null,
      });
    }

    dispatch({ type: ACTION.SET_STATE, label: 'links', value: links });
    dispatch({ type: ACTION.SET_STATE, label: 'next', value: next });
    dispatch({ type: ACTION.SET_STATE, label: 'page', value: dataPage });
    dispatch({ type: ACTION.SET_STATE, label: 'pages', value: pages });
    dispatch({ type: ACTION.SET_STATE, label: 'links', value: total_elements });
  }
};

export const getCustomer = (customerID) => async (dispatch) => {
  const url = `admin/customers/${customerID}`;

  try {
    dispatch({ type: ACTION.FETCH_CUSTOMERS_INIT });
    const response = await client.get(url);
    const { data: customer } = response.data;

    dispatch({
      type: ACTION.SET_STATE,
      label: 'displayedCustomer',
      value: customer,
    });
  } catch (e) {
    console.log(e);
    dispatch({
      type: ACTION.FETCH_CUSTOMERS_ERROR, error: e,
    });
  }
};
export const getActions = (customerID) => async (dispatch, getState) => {
  const { histories } = getState().customersState;
  const url = `${appService.config.paths.collections.route}?customer-id=${customerID}`;
  try {
    dispatch({ type: ACTION.FETCH_CUSTOMERS_INIT });
    const response = await client.get(url);
    const { data: histories } = response;

    dispatch({
      type: ACTION.SET_STATE,
      label: 'histories',
      value: histories,
    });
  } catch (e) {
    console.log(e);
  }
};
export const getNextPage = () => (dispatch, getState) => {
  const { page } = getState().customersState;
  dispatch(getCustomers(page + 1));
};

export const getPreviousPage = () => (dispatch, getState) => {
  const { page } = getState().customersState;
  dispatch(getCustomers(page - 1));
};

export const createAction = (customerID, date, action, promise, comment) => async (dispatch) => {
  const url = `${appService.config.paths.collections.route}`;

  try {
    await client.post(url, {
      customer_id: customerID,
      date,
      action,
      promise_of_payment_date: promise,
      comments: comment,
    }).then(window.location.reload());
  } catch (e) {
    console.log(e);
  }
};
