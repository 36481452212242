import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  loginButton: {
    borderRadius: 8,
    color: '#505f79',
    maxHeight: 80,
    maxWidth: 400,
    height: '20%',
    width: '50%',
    borderColor: 'rgb(2, 83, 204,0.5)',
    paddingLeft: '5%',
    paddingRight: '5%',
  },
  logo: {
    width: '30%',
    marginRight: '8%',
  },
}));

export default useStyles;
