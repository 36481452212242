import client from "../client";

export const getAppointment = (phone_number) => {
  const url = `admin/users/appointments?phone_number=${phone_number}`;
  return new Promise((resolve, reject) => {
    client.get(url).then(
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      }
    );
  });
};

export const putAppoinment = (appoinment_id, body) => {
  const url = `admin/users/appointment/${appoinment_id}/done`;
  return new Promise((resolve, reject) => {
    client.put(url, body).then(
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      }
    );
  });
};
