import React from 'react';
import { MenuItem, Select } from '@material-ui/core';
import moment from 'moment';
import { Box } from '../Layout';
import Text from '../Text/Text';
import useStyles from './InventoryDatePicker.style';

const InventoryDatePicker = ({ dateData, onSelectedItemChange, selectedValue, setSelectedValue }) => {
  const classes = useStyles();
  const originationArray = dateData?.origination || [];
  const postOriginationArray = dateData?.['post-origination'] || [];
  const combinedArray = originationArray.concat(postOriginationArray);
  combinedArray.sort((a, b) => moment(a.created_at).unix() - moment(b.created_at).unix());
  const combinedArrayWithIds = combinedArray.map((item, index) => ({
    ...item,
    id: index + 1,
  }));

  const isOriginationItem = (item) => originationArray.some(originationItem => originationItem.created_at === item.created_at);

  const handleSelectChange = (event) => {
    const selectedId = event.target.value;
    const selectedItem = combinedArrayWithIds.find(item => item.id === selectedId);
    setSelectedValue(selectedItem);
    const isOriginationItem = originationArray.some(item => item.created_at === selectedItem.created_at);
    onSelectedItemChange(selectedItem, isOriginationItem);
  };

  return (
    <Box className={classes.generalContainer}>
      <Text className={classes.text}>Selecciona una Fecha</Text>
      <Select
        className={classes.select}
        value={selectedValue ? selectedValue.id : ''}
        onChange={handleSelectChange}
      >
        {combinedArrayWithIds.map((item) => (
          <MenuItem key={item.id} value={item.id}>
          {moment(item.created_at).format('D [de] MMMM YYYY')}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

export default InventoryDatePicker;
