import * as ACTION from './modifications.actionTypes';
import * as modificationsService from '../services/modifications.service';

export const clearTaxPayerId = () => async (dispatch) => {
  dispatch({
    type: ACTION.CLEAR_PUT_TAXPAYER_ID,
  });
};

export const putTaxPayerId = (body) => async (dispatch) => {
  dispatch({
    type: ACTION.INIT_PUT_TAXPAYER_ID,
  });

  try {
    const taxPayerIdData = await modificationsService.putTaxPayerId(body);
    if (taxPayerIdData) {
      dispatch({
        type: ACTION.RESPONSE_PUT_TAXPAYER_ID,
        result: taxPayerIdData,
      });
    }
  } catch (error) {
    dispatch({
      type: ACTION.ERROR_PUT_TAXPAYER_ID,
      error,
    });
  }
};

export const clearInfoProperties = () => async (dispatch) => {
  dispatch({
    type: ACTION.CLEAR_PATCH_INFO_PROPERTIES,
  });
};

export const patchInfoProperties = (user_type, user_id, payload) => async (dispatch) => {
  dispatch({
    type: ACTION.INIT_PATCH_INFO_PROPERTIES,
  });

  try {
    const infoPropertiesData = await modificationsService.patchInfoProperties(user_type,user_id,payload);
    if (infoPropertiesData) {
      dispatch({
        type: ACTION.RESPONSE_PATCH_INFO_PROPERTIES,
      });
    }
  } catch (error) {
    dispatch({
      type: ACTION.ERROR_PATCH_INFO_PROPERTIES,
      error,
    });
  }
};

export const clearErrors = () => async (dispatch) => {
  dispatch({
    type: ACTION.CLEAR_ERRORS,
  });
};
