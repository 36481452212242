import axios from 'axios';

import * as ACTIONS from '../../reducers/loan.actionTypes';
import treasuryService from '../../services/loan.service';
import { appService } from '../../services/app.service';

export const getTreasuryList = (settlementType) => async (dispatch, getState) => {
  dispatch({
    type: ACTIONS.FETCH_TREASURY_INIT,
  });

  try {
    const treasury = await treasuryService.getResourceTreasuryList({ status: 'PENDING' });

    if (treasury) {
      dispatch({
        type: ACTIONS.FETCH_TREASURY_SUCCESS,
      });
      dispatch({
        type: ACTIONS.SET_STATE,
        payload: {
          label: 'treasuryList',
          value: treasury,
        },
      });
    } else {
      dispatch({
        type: ACTIONS.FETCH_TREASURY_ERROR,
      });
    }
  } catch (e) {
    dispatch({
      type: ACTIONS.FETCH_TREASURY_ERROR,
    });
  }
};

export const addSelectedTreasury = (selectedTreasuryElement) => async (dispatch, getState) => {
  dispatch({
    type: ACTIONS.ADD_SELECTED_TREASURY,
    payload: selectedTreasuryElement,
  });
};

export const deleteSelectedTreasury = (selectedTreasuryElement) => async (dispatch, getState) => {
  dispatch({
    type: ACTIONS.DELETE_SELECTED_TREASURY,
    payload: selectedTreasuryElement,
  });
};

export const clearSelectedTreasurySet = () => async (dispatch, getState) => {
  dispatch({
    type: ACTIONS.CLEAR_SELECTED_TREASURY,
  });
};

export const toggleSelectedTreasury = (treasuryElement, newState) => async (dispatch, getState) => {
  if (newState) {
    dispatch(addSelectedTreasury(treasuryElement));
  } else {
    dispatch(deleteSelectedTreasury(treasuryElement));
  }
};

export const getSearchTreasury = (search) => async (dispatch, getState) => {
  dispatch({
    type: ACTIONS.FETCH_TREASURY_INIT,
  });

  try {
    const treasuryList = await treasuryService.getSearchTreasuryList();

    if (treasuryList) {
      dispatch({
        type: ACTIONS.FETCH_TREASURY_SUCCESS,
      });
      dispatch({
        type: ACTIONS.SET_STATE,
        payload: {
          label: 'searchTreasuryElement',
          value: treasuryList,
        },
      });
    } else {
      dispatch({
        type: ACTIONS.FETCH_TREASURY_ERROR,
      });
    }
  } catch (e) {
    dispatch({
      type: ACTIONS.FETCH_TREASURY_ERROR,
    });
  }
};

export const updateTreasury = (resourceId, body) => async (dispatch, getState) => {
  dispatch({ type: ACTIONS.FETCH_TREASURY_UPDATE_CLEAR, value: false });
  const url = `${appService.config.paths.api.basePath}/admin/shop-loan/credit-line/${resourceId}/disbursement`;
  const token = localStorage.getItem('firebaseToken');

  axios({
    method: 'PUT',
    url,
    timeout: 5000,
    headers: {
      'Content-Type': 'application/json',
      Authorization: token || null,
    },
    data: body,
  }).then(() => {
    dispatch({ type: ACTIONS.FETCH_TREASURY_UPDATE_SUCCESS, value: true });
  }).catch((error) => {
    console.log(error);
    dispatch({ type: ACTIONS.FETCH_TREASURY_UPDATE_ERROR, value: false });
  });
};

export const postSendTransfer = (credit_line_id, body) => async (dispatch) => {
    dispatch({
        type: ACTIONS.SEND_TRANSFER_CLEAR,
    });

    try {
        const supplierDisbursementData = await treasuryService.postSendTransfer(credit_line_id, body);
        if (supplierDisbursementData) {
            dispatch({
                type: ACTIONS.SEND_TRANSFER_SUCCESS,
            });
        }
    } catch (error) {
        dispatch({
            type: ACTIONS.SEND_TRANSFER_ERROR,
            error,
        });
    }
};

export const clearUpdateState = () => async (dispatch, getState) => {
  dispatch({
    type: ACTIONS.FETCH_TREASURY_UPDATE_CLEAR,
  });
}
