import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  AboutMeContainer: {
    margin: '3%',
    display: 'flex',
    flexDirection: 'column',
  },
  button: {
    width: '25%',
    padding: 10,
    marginTop: 10,
    borderRadius: 20,
  },
  modalAval: {
    overflow: 'scroll',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginTop: 50,
    marginBottom: 50
  },
  modalContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '300px',
    padding: 30,
    borderRadius: 10,
    borderWidth: 10,
    backgroundColor: '#ffffff',
  },
  titleModal: {
    fontSize: 18
  },
  formElement: {
    marginTop: 20
  },
  selectButton: {
    paddingTop: 5,
    paddingBottom: 5,
    paddingRight: 50,
    paddingLeft: 50,
    borderRadius: 20,
  },
  selectButton2: {
    paddingTop: 5,
    paddingBottom: 5,
    paddingRight: 50,
    paddingLeft: 50,
    borderRadius: 20,
    marginLeft: 65
  },
  textAddress: {
    marginTop: 20,
    marginBottom: 20,
    fontSize: 13
  },
  numberAddress: {
    flexDirection: 'row',
  },
  formElementAddress: {
    marginTop: 20,
    width: '45%',
  },
  formElementAddress2: {
    marginTop: 20,
    width: '45%',
    marginLeft: 30
  },
  formElementAddress3: {
    marginTop: 20,
    width: '45%',
    marginLeft: 20,
  },
  buttonSave: {
    padding: 10,
    marginTop: 20,
    borderRadius: 20,
  },
  snackbarContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 9999,
    pointerEvents: 'none',
  },
  snackbar: {
    pointerEvents: 'auto',
  },
  snackbar2: {
    marginTop: 80,
    pointerEvents: 'auto',
  },
  snackbar3: {
    marginTop: 160,
    pointerEvents: 'auto',
  },
}));

export default useStyles;
