export const INIT_PUT_TAXPAYER_ID = 'MODIFICATIONS::INIT_PUT_TAXPAYER_ID';
export const RESPONSE_PUT_TAXPAYER_ID = 'MODIFICATIONS::RESPONSE_PUT_TAXPAYER_ID';
export const ERROR_PUT_TAXPAYER_ID = 'MODIFICATIONS::ERROR_PUT_TAXPAYER_ID';
export const CLEAR_PUT_TAXPAYER_ID = 'MODIFICATIONS::CLEAR_PUT_TAXPAYER_ID';
export const INIT_PATCH_INFO_PROPERTIES = 'MODIFICATIONS::INIT_PATCH_INFO_PROPERTIES';
export const RESPONSE_PATCH_INFO_PROPERTIES = 'MODIFICATIONS::RESPONSE_PATCH_INFO_PROPERTIES';
export const ERROR_PATCH_INFO_PROPERTIES = 'MODIFICATIONS::ERROR_PATCH_INFO_PROPERTIES';
export const CLEAR_PATCH_INFO_PROPERTIES = 'MODIFICATIONS::CLEAR_PATCH_INFO_PROPERTIES';

export const CLEAR_ERRORS = 'MODIFICATIONS::CLEAR_ERRORS';
