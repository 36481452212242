import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';

import { getSupplier } from "../../reducers/dealer.action";
import { saveVouchers } from '../../reducers/vouchers.action';
import { saveSuppliers } from "../../reducers/suppliers.action";
import { saveAmounts } from "../../reducers/amounts.action";

import TableBuyDealerUI from "./TableBuyDealer";
import useStyles from "./TableBuyDealer.styles";
    
function TableBuyDealer(props) {
    const { statusFilter } = props;
    const dispatch = useDispatch();
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const history = useHistory();

    const {
        supplierData
    } = useSelector((state) => state.supplierReducer);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    
    useEffect(() => {
        setPage(0);
    }, [supplierData]);

    useEffect(() => {
        dispatch(getSupplier({status: statusFilter}));
    }, [statusFilter])
    
    const getPendingVouchers = (data) => {
        let provitionalInfo = [];
        if(Object.keys(data).length !== 0 ) {
            provitionalInfo = data.vouchers.filter((voucher) => 
                voucher.revision_status === 'PENDING'
            )
        }
        return provitionalInfo;
    };

    const saveVouchersClick = (vouchers, credit_line_id, account_id,purchase_id, suppliers, amount, aceptedAmount) => {
        let body = {
            amount: amount,
            aceptedAmount: aceptedAmount
        };
        dispatch(saveVouchers(vouchers));
        dispatch(saveSuppliers(suppliers));
        dispatch(saveAmounts(body));
        history.replace(`/buy-dealer/detail/${credit_line_id}/${account_id}/${purchase_id}`)
    };

    return <TableBuyDealerUI
        classes={classes}
        page={page}
        rowsPerPage={rowsPerPage}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        supplierData={Object.keys(supplierData).length !== 0 ? supplierData?.data : []}
        saveVouchersClick={saveVouchersClick}
        getPendingVouchers={getPendingVouchers}
    />
};

export default TableBuyDealer;