import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Modal from '../CustomModal/CustomModal';
import { Box } from '../Layout';
import Text from '../Text';
import useStyles from './BlockAccountModal.style';
import Button from '../Button';
import { rejectCustomerCredit } from '../../containers/Registers/registersActions';

const BlockAccountModal = (props) => {
  const { open, setOpen } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { displayedCustomer: customer } = useSelector((state) => state.registersState);
  const history = useHistory();

  const handleAccept = (event) => {
    dispatch(rejectCustomerCredit(customer.personal_data.user_auth_id));
    setOpen(false);
    history.go();
  };

  return (
    <Modal
      title="Bloquear cuenta"
      closeButton
      open={open}
      setOpen={(value) => {
        setOpen(value);
      }}
    >
      <Box className={classes.CustomerStepContent}>
        <Text>
          Al bloquear la cuenta, el usuario no podrá continuar con su registro
        </Text>
        <Text>
          Perfil de usuario: Cliente
        </Text>
        <Text>
          Nombre:
          {' '}
          {`${customer.personal_data.full_name}`}
        </Text>
        <Box className={classes.buttonBox}>
          <Button variant="contained" color="secondary" onClick={handleAccept}>
            <Text>
              Aceptar
            </Text>
          </Button>
          <Button variant="contained" onClick={() => setOpen(false)}>
            <Text>
              Cancelar
            </Text>
          </Button>
        </Box>
      </Box>

    </Modal>
  );
};

export default BlockAccountModal;
