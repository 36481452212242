import React, { useState } from 'react';
import { Select } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { useDispatch } from 'react-redux';
import Modal from '../CustomModal/CustomModal';
import { Box } from '../Layout';
import Text from '../Text';
import StepText from '../StepText/StepText';
import useStyles from './CustomerStepModal.style';
import Button from '../Button';
import { updateCustomerStep } from '../../containers/Registers/registersActions';
import RegistersService from '../../services/registers.service';

const CustomerStepModal = (props) => {
  const { open, selectedCustomer, setOpen } = props;

  const classes = useStyles();
  const [selectValue, setSelectValue] = useState(0);
  const [page, setPage] = useState(0);
  const dispatch = useDispatch();

  const handleSelectChange = (event) => {
    setSelectValue(event.target.value);
  };

  const handleAccept = () => {
    dispatch(updateCustomerStep(selectedCustomer.account_id, selectValue));
    setOpen(false);
  };

  const confirmationPage = (
    <Box className={classes.CustomerStepContent} overflow="visible">
      <Text>
        Perfil de usuario:
        {selectedCustomer.profile === 1 ? 'CLIENTE' : 'TIENDA'}
      </Text>
      <Text>
        Nombre:
        {selectedCustomer.name}
      </Text>
      <Box style={{ marginTop: '3%' }}>
        <Text>
          Cambio de registro:
        </Text>
        <Box style={{ marginTop: '3%' }}>
          <Text>De:</Text>
          <StepText step={selectedCustomer.step} />
        </Box>
        <Box style={{ marginTop: '3%' }}>
          <Text>A:</Text>
          <StepText step={selectValue} />
        </Box>
      </Box>
      <Box style={{
        display: 'flex', marginTop: '4%', flexDirection: 'column', justifyContent: 'center', textAlign: 'center',
      }}
      >
        <Text>
          ¿Seguro que deseas editar el proceso de registro?
        </Text>
        <Button onClick={handleAccept}>
          <Text>
            Aceptar
          </Text>
        </Button>

      </Box>
    </Box>
  );

  const editProcessPage = (
    <Box className={classes.CustomerStepContent} overflow="visible">
      <Text>
        Perfil de usuario:
        {' '}
        {selectedCustomer.profile === 1 ? 'CLIENTE' : 'TIENDA'}
      </Text>
      <Text>
        Nombre:
        {' '}
        {selectedCustomer.name}
      </Text>
      <Text>
        Paso de registro actual
      </Text>
      <StepText
        phoneValidation={'is_phone_number_validated' in selectedCustomer ? selectedCustomer.is_phone_number_validated : false}
        step={selectedCustomer.step}
      />
      <Box className={classes.editProcessPage} overflow="visible">
        <Text style={{ marginBottom: '3%' }}>Selecciona el paso a regresar</Text>
        <FormControl color="secondary" variant="outlined" size="small" className={classes.formControl}>
          <InputLabel id="demo-simple-select-outlined-label">Paso</InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={selectValue}
            onChange={handleSelectChange}
            label="Paso"
            autoWidth
          >
            {
            selectedCustomer.step === 0 && (
              <MenuItem disabled>
                <Text>No se puede realizar la operación</Text>
              </MenuItem>
            )
          }
            {selectedCustomer.step > 0 && (
            <MenuItem value={0}>
              <StepText step={0} />
            </MenuItem>
            )}
            {
              selectedCustomer.step > 1 && (
              <MenuItem value={1}>
                <StepText step={1} />
              </MenuItem>
              )
          }
            {
              selectedCustomer.step > 2 && (
              <MenuItem value={2}>
                <StepText step={2} />
              </MenuItem>
              )
          }
            {
              selectedCustomer.step > 3 && (
              <MenuItem value={3}>
                <StepText step={3} />
              </MenuItem>
              )
          }
            {
              selectedCustomer.step > 4 && (
              <MenuItem value={4}>
                <StepText step={4} />
              </MenuItem>
              )
          }
            {
              selectedCustomer.step > 5 && (
              <MenuItem value={5}>
                <StepText step={5} />
              </MenuItem>
              )
          }
            {
              selectedCustomer.step === 6 && (
                <MenuItem value={6}>
                  <StepText step={6} />
                </MenuItem>
              )
            }
            {
              selectedCustomer.step > 6 && 'phone_number_validation' in selectedCustomer && (
                <MenuItem value={7}>
                  <StepText step={7} />
                </MenuItem>
              )
            }
          </Select>
        </FormControl>
      </Box>
      <Button disabled={selectValue === undefined} onClick={() => setPage(page + 1)}>
        <Text>
          Continuar
        </Text>
      </Button>
    </Box>
  );

  let pageTitle = '';

  if (page === 0) {
    pageTitle = 'Editar proceso de validación';
  } else if (page === 1) {
    pageTitle = 'Confirmación';
  }

  return (
    <Modal
      title={pageTitle}
      closeButton
      open={open}
      setOpen={(value) => {
        setOpen(value);
        setPage(0);
        setSelectValue(undefined);
      }}
    >
      {page === 1 && confirmationPage}
      {page === 0 && editProcessPage}
      <Button className={classes.smsButton} onClick={() => RegistersService.sendConfirmationSms(selectedCustomer.user_auth_id)}>
        <Text>
          Reenviar código SMS
        </Text>
      </Button>
    </Modal>
  );
};

export default CustomerStepModal;
