import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '../../components/Button';
import { Box } from '../../components/Layout';
import Text from '../../components/Text';
import useStyles from './AboutPage.styles';

export const AboutPage = (props) => {
  const handeClick = (where) => () => {
    const { history } = props;
    history.push(`/${where}`);
  };
  const classes = useStyles();

  return (
    <Box className={classes.page}>
      <Box className={classes.container}>
        <div className='about-main'>
          <Text variant='h1' color='primary' align='center'>About Page</Text>
          <Box className={classes.actionsSection} display='flex' flexDirection='row' justifyContent='center'>
            <Button variant='contained' color='primary' onClick={handeClick('dashboard')}>Go to Dashboard</Button>
            <div style={{ width: '15px' }} />
            <Button variant='contained' color='primary' onClick={handeClick('home')}>Go to Home Page</Button>
            <div style={{ width: '15px' }} />
            <Button variant='contained' color='primary' onClick={handeClick('milesaway')}>Go another place</Button>
          </Box>
        </div>
      </Box>
    </Box>
  );
};


AboutPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  aboutState: state.get('aboutState')
});

const mapDispatchToProps = () => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(AboutPage);
