export const INIT_GET_BUSINESS_TYPE = 'BUSINESS::INIT_GET_BUSINESS_TYPE';
export const RESPONSE_GET_BUSINESS_TYPE = 'BUSINESS::RESPONSE_GET_BUSINESS_TYPE';
export const ERROR_GET_BUSINESS_TYPE = 'BUSINESS::ERROR_GET_BUSINESS_TYPE';
export const CLEAR_GET_BUSINESS_TYPE = 'BUSINESS::CLEAR_GET_BUSINESS_TYPE';

export const INIT_PUT_UPDATE_BUSINESS_TYPE = 'BUSINESS::INIT_PUT_UPDATE_BUSINESS_TYPE';
export const RESPONSE_PUT_UPDATE_BUSINESS_TYPE = 'BUSINESS::RESPONSE_PUT_UPDATE_BUSINESS_TYPE';
export const ERROR_PUT_UPDATE_BUSINESS_TYPE = 'BUSINESS::ERROR_PUT_UPDATE_BUSINESS_TYPE';
export const CLEAR_PUT_UPDATE_BUSINESS_TYPE = 'BUSINESS::CLEAR_PUT_UPDATE_BUSINESS_TYPE';

export const CLEAR_ERRORS = 'BUSINESS::CLEAR_ERRORS';
