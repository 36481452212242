import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() => ({
    mainPanel: {
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-start',
    },
    textBlue: {
        paddingTop: 10,
        paddingBottom: 10,
        color: '#0253cc'
    },
    searchContainer: {
        display: 'flex',
        alignItems: 'center',
        width: '35%',
        background: '#E8E8FC33',
        borderRadius: 2,
    },
    input: {
        marginLeft: 9,
        marginTop: 7,
        marginBottom: 8,
        width: 260,
        flex: 1,
        fontSize: 10,
        fontWeight: 300,
    },
    iconButton: {
        padding: 10,
        color: '#0253cc',
    },
    divider: {
        height: 28,
        marginTop: 4,
        marginBottom: 4,
    },
    buttonContainer: {
        paddingTop: 5,
        paddingBottom: 5,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    button: {
        width: '100%',
        padding: 10,
    },
    formStyles: {
        display: 'flex',
        minWidth: 120,
    },
}));

export default useStyles;