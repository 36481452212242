/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';

const Text = ({ children, ...others }) => (
  <Typography {...others}>
    {children}
  </Typography>
);

Text.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
  ]),
};

Text.defaultProps = {
  children: '',
};

export default Text;
