import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '../Layout';
import LabeledText from '../LabeledText/LabeledText';
import customer from '../../assets/customer.svg';

const LabeledDate = ({ date, label }) => (
  <Box style={{
    display: 'flex', flexDirection: 'row',
  }}
  >
    <img style={{ marginRight: 10 }} src={customer} />
    <LabeledText label={date} text={label} />
  </Box>
);

LabeledDate.propTypes = {

};

export default LabeledDate;
