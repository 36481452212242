import React from "react";
import { 
    TableContainer,
    Table,
    TableBody,
    TableRow, 
    TableCell,
    TableHead,
    TextField,
    Button
} from "@material-ui/core";
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';

import Icons from "../../assets/icons";
import Box from '../Layout/Box';
import Text from '../Text';

import { headCellMetadataReviewVoucher } from "../../constants/constants";

function TableMetadataReviewVoucher({
    classes,
    metadata,
    formatCurrency,
    isEditable,
    setIsEditable,
    setEditableMetadata,
    handleSave,
    handleCancel,
    supplierName
}) {
    const handleChange = (e, field) => {
        const value = e.target.value;
        setEditableMetadata(prevState => ({
            ...prevState,
            [field]: value
        }));
    };

    const handleItemChange = (e, index, field) => {
        const value = e.target.value;
        const updatedItems = metadata.Items.map((item, i) => (
            i === index ? { ...item, [field]: value } : item
        ));
        setEditableMetadata(prevState => ({
            ...prevState,
            Items: updatedItems
        }));
    };

    const addItem = () => {
        const newItem = { Name: '', Quantity: '', Unit_Price: '', Price: '', Category: '' };
        setEditableMetadata(prevState => ({
            ...prevState,
            Items: [...prevState.Items, newItem]
        }));
    };

    const removeItem = (index) => {
        const updatedItems = metadata.Items.filter((_, i) => i !== index);
        setEditableMetadata(prevState => ({
            ...prevState,
            Items: updatedItems
        }));
    };

    const informationTable = () => {
        return (
            <TableBody>
                {metadata?.Items?.map((data, index) => (
                    <TableRow key={index} style={{ maxHeight: 43 }}>
                        <TableCell>
                            <Text className={classes.textCellTable}>{index + 1}</Text>
                        </TableCell>
                        <TableCell>
                            {isEditable ? (
                                <TextField
                                    value={data?.Name}
                                    onChange={(e) => handleItemChange(e, index, 'Name')}
                                />
                            ) : (
                                <Text className={classes.textCellTable}>{data?.Name}</Text>
                            )}
                        </TableCell>
                        <TableCell>
                            {isEditable ? (
                                <TextField
                                    value={data?.Quantity}
                                    onChange={(e) => handleItemChange(e, index, 'Quantity')}
                                    inputProps={{ 
                                        inputMode: "numeric",
                                        pattern: "[0-9]*"
                                    }}
                                />
                            ) : (
                                <Text className={classes.textCellTable}>{data?.Quantity}</Text>
                            )}
                        </TableCell>
                        <TableCell>
                            {isEditable ? (
                                <TextField
                                    value={data?.Unit_Price}
                                    onChange={(e) => handleItemChange(e, index, 'Unit_Price')}
                                    inputProps={{ 
                                        inputMode: "decimal",
                                        pattern: "[0-9]+([.][0-9]{1,2})?" 
                                    }}
                                />
                            ) : (
                                <Text className={classes.textCellTable}>{formatCurrency(data?.Unit_Price)}</Text>
                            )}
                        </TableCell>
                        <TableCell>
                            {isEditable ? (
                                <TextField
                                    value={data?.Price}
                                    onChange={(e) => handleItemChange(e, index, 'Price')}
                                    inputProps={{ 
                                        inputMode: "decimal",
                                        pattern: "[0-9]+([.][0-9]{1,2})?"
                                    }}
                                />
                            ) : (
                                <Text className={classes.textCellTable}>{formatCurrency(data?.Price)}</Text>
                            )}
                        </TableCell>
                        <TableCell className={classes.centerContentStyle}>
                            {isEditable ? (
                                <TextField
                                    value={data?.Category}
                                    onChange={(e) => handleItemChange(e, index, 'Category')}
                                />
                            ) : (
                                <Text className={classes.textCellTable}>{data?.Category}</Text>
                            )}
                        </TableCell>
                        {isEditable && (
                            <TableCell>
                                <Button onClick={() => removeItem(index)}>
                                    <img 
                                        src={Icons.trashIcon}
                                        className={classes.pencilIconStyle}
                                    />
                                </Button>
                            </TableCell>
                        )}
                    </TableRow>
                ))}
            </TableBody>
        );
    };

    return (
        <Box className={classes.metadataContainer}>
            <Box className={classes.headerContainer}>
                <Text className={classes.title}>{"Información del ticket" }</Text>
                {!isEditable && (
                    <Box className={classes.editButtonContainer} onClick={() => setIsEditable(true)}>
                        <Text className={classes.headerEndText}>Editar</Text>
                        <img 
                            src={Icons.pencilEditIcon}
                            className={classes.pencilIconStyle}
                        />
                    </Box>
                )}
                {isEditable && (
                    <Box className={classes.editButtonContainer}> 
                        <Button 
                            className={classes.buttonTopSave} 
                            onClick={handleSave}
                            variant="contained"
                            color='secondary'
                        >
                            <Text className={classes.textButtonTop}>Guardar edición</Text>
                            <SaveOutlinedIcon style={{color: "#FFFFFF"}} />
                        </Button>
                        <Button 
                            className={classes.buttonTopCancel} 
                            onClick={handleCancel}
                            variant="contained"
                            color='secondary'
                        >
                            <Text className={classes.textButtonTop}>Cancelar edición</Text>
                            <CancelOutlinedIcon style={{color: "#FFFFFF"}}/>
                        </Button>
                    </Box>
                )}
            </Box>
            <Box className={classes.infoTicketContainer}>
                <Box className={classes.infoTicketTexts}>
                    <Text className={classes.infoTicketTitle}>Distribuidor</Text>
                    <Text className={classes.infoTicketText}>{supplierName}</Text>
                </Box>
                <Box className={classes.infoTicketTexts}>
                    <Text className={classes.infoTicketTitle}>Fecha de compra</Text>
                    {isEditable ? (
                        <TextField
                            value={metadata?.Date}
                            onChange={(e) => handleChange(e, 'Date')}
                        />
                    ) : (
                        <Text className={classes.infoTicketText}>{metadata?.Date === ("undefined" || "null") ? "Sin definir" : metadata?.Date}</Text>
                    )}
                </Box>
                <Box className={classes.infoTicketTexts}>
                    <Text className={classes.infoTicketTitle}>Monto total</Text>
                    {isEditable ? (
                        <TextField
                            value={metadata?.Total_Amount}
                            onChange={(e) => handleChange(e, 'Total_Amount')}
                        />
                    ) : (
                        <Text className={classes.infoTicketText}>{metadata?.Total_Amount === ("undefined" || "null") ? "Sin definir" : formatCurrency(metadata?.Total_Amount)}</Text>
                    )}
                </Box>
                <Box className={classes.infoTicketTexts}>
                    <Text className={classes.infoTicketTitle}>Dirección</Text>
                    {isEditable ? (
                        <TextField
                            value={metadata?.Address}
                            onChange={(e) => handleChange(e, 'Address')}
                        />
                    ) : (
                        <Text className={classes.infoTicketText}>{metadata?.Address === ("undefined" || "null") ? "Sin definir" : metadata?.Address}</Text>
                    )}
                </Box>
            </Box>
            <Box className={classes.tableContainer}>
                <TableContainer style={{ flexGrow: 1, overflow: 'auto' }}>
                    <Table stickyHeader aria-label="a dense table">
                        <TableHead className={classes.tableHead}>
                            <TableRow>
                                {headCellMetadataReviewVoucher.map((headCell) => (
                                    <TableCell
                                        align="center"
                                        scope="col"
                                        key={headCell.id}
                                    >
                                        <Text className={classes.titleTable}>{headCell.label}</Text>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        {informationTable()}
                    </Table>
                </TableContainer>
            </Box>
            {isEditable && (
                <Box className={classes.addItemContainer}>
                    <Button onClick={addItem} className={classes.addItemButton}>
                        <Text className={classes.addItemText}>Agregar Item</Text>
                        <AddCircleOutlineRoundedIcon style={{color: "#374957"}} />
                    </Button>
                </Box>
            )}
        </Box>
    );
}

export default TableMetadataReviewVoucher;