import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    controls: {
        display: 'flex',
        position: 'relative',
        width: '100%',
        color: '#858d9c',
        alignItems: 'center',
        cursor: 'pointer'
    },
    textReturn: {
        marginLeft: '5px'
    },
    generalContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: '20px',
        width: '1670px',
        height: '823px',
    },
    imageTicket: {
        width: '536px',
        height: '827px',
    },
    buttonContainer: {
        display: 'flex',
        alignSelf: 'end',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
        marginTop: 20,
        marginBottom: 20,
    },
    buttonDecline: {
        width: '250px',
        height: '44px',
        borderRadius: '10px',
        border: '2px solid #D9E0EC',
        marginRight: '20px',
        fontSize: '14px',
        fontWeight: '600',
    },
    buttonValidate: {
        width: '250px',
        height: '44px',
        borderRadius: '10px',
        backgroundColor: "#3050FF",
        fontSize: '14px',
        fontWeight: '600',
    },
    modalRejection: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalContainerRejected: {
        display: 'flex',
        flexDirection: 'column',
        width: '350px',
        padding: 15,
        borderRadius: 15,
        backgroundColor: '#ffffff',
    },
    buttonClose: {
        width: 30,
        height: 30,
        marginBottom: 10,
        alignSelf: 'end'
    },
    formElement: {
        marginTop: 20,
        marginBottom: 10,
        marginRight: 10,
        marginLeft: 10
    },
    buttonModal: {
        backgroundColor: '#3050ff',
        marginTop: 30
    },
}));

export default useStyles;