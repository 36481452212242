import React from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { useSelector } from 'react-redux';
import StepArrow from '../StepArrow';
import StepArrowShopkeeper from '../StepArrowShopkeeper';
import useStyles from '../../containers/CustomerDetails/CustomerDetails.style';
import { Box } from '../Layout';

const StepsInfo = (props) => {
  const classes = useStyles();
  const { url } = useRouteMatch();
  const history = useHistory();
  const { step, userType, kycError, status } = props;
  const { pathname } = history.location;

  const { displayedCustomer } = useSelector((state) => state.registersState);
  let phone;
  if ('phone_number_validation' in displayedCustomer) {
    phone = displayedCustomer.phone_number_validation.is_validated
  }

  return (
    <Box className={classes.StepsInfoContainer}>
      {userType === 'customer' && (
        <>
          <StepArrow to={`${url}/customer-info`} selected={pathname.includes('customer-info')} step={1} />
          <StepArrow to={`${url}/residential-info`} disabled={step <= 1} selected={pathname.includes('residential-info')} step={2} />
          <StepArrow to={`${url}/laboral-info`} disabled={step <= 2} selected={pathname.includes('laboral-info')} step={3} />
          <StepArrow to={`${url}/reference-info`} disabled={step <= 3} selected={pathname.includes('reference-info')} step={4} />
          <StepArrow to={`${url}/confirmation-info`} disabled={step <= 4} selected={pathname.includes('confirmation-info')} step={5} />
          <StepArrow
            to={`${url}/phone-number-validation-info`}
            disabled={step <= 5 && phone == undefined}
            error={!('phone_number_validation' in displayedCustomer) || (step == 6 && phone == false)}
            selected={pathname.includes('phone-number-validation-info')}
            step={6}
          />
          <StepArrow
            to={`${url}/identity-validation-info`}
            disabled={step <= 6 && phone == undefined}
            error={('mati_data' in displayedCustomer && 'status' in displayedCustomer.mati_data && displayedCustomer.mati_data.status !== 'VERIFIED')}
            selected={pathname.includes('identity-validation-info')}
            step={7}
          />
          <StepArrow
            to={`${url}/yofio-validation-info`}
            disabled={step < 6 && !('yofio_validation' in displayedCustomer)}
            error={!('yofio_validation' in displayedCustomer) || ('yofio_validation' in displayedCustomer && 'status' in displayedCustomer.yofio_validation && displayedCustomer.yofio_validation.status !== 'verified')}
            selected={pathname.includes('yofio-validation-info')}
            step={8}
          />
        </>)}
      {userType === 'shopkeeper' && (
        <>
          <StepArrowShopkeeper status={status} nextStep={step} step="INIT" />
          <StepArrowShopkeeper status={status} nextStep={step} step="SIGN_UP" />
          <StepArrowShopkeeper status={status} nextStep={step} step="TAX_INFO" />
          <StepArrowShopkeeper status={status} nextStep={step} step="ABOUT_STORE" />
          <StepArrowShopkeeper status={status} nextStep={step} step="BUSINESS_ADDRESS" />
          <StepArrowShopkeeper status={status} nextStep={step} step="ACCOUNT_NIP" />
          <StepArrowShopkeeper status={status} nextStep={step} step="ACCOUNT_BUREAU" />
          <StepArrowShopkeeper status={status} nextStep={step} step="PASSWORD" />
          <StepArrowShopkeeper status={status} nextStep={step} step="INFO_NEEDED" />
          <StepArrowShopkeeper status={status} nextStep={step} step="PENDING_APPROVAL" />
          <StepArrowShopkeeper status={status} nextStep={step} step="CONTRACT_GENERATED" />
          <StepArrowShopkeeper status={status} nextStep={step} step="CONTRACT_SIGNED" />
        </>)}
    </Box>
  );
};

export default StepsInfo;
