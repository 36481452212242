import * as ACTION from './modifications.actionTypes';

const dataModifications = {
  loading: {
    PUT_TAXPAYER_ID: false,
    PATCH_INFO_PROPERTIES: false,
  },
  error: {
    PUT_TAXPAYER_ID: null,
    PATCH_INFO_PROPERTIES: null,
  },
  taxPayerIdData: {},
  infoPropertiesData: {},
};

const modificationsReducer = (state = dataModifications, action) => {
  switch (action.type) {
    case ACTION.INIT_PUT_TAXPAYER_ID:
      return { ...state, loading: { ...state.loading, PUT_TAXPAYER_ID: true }, error: { ...state.error, PUT_TAXPAYER_ID: null } };
    case ACTION.RESPONSE_PUT_TAXPAYER_ID:
      return {
        ...state, loading: { ...state.loading, PUT_TAXPAYER_ID: false }, error: { ...state.error, PUT_TAXPAYER_ID: null }, taxPayerIdData: action.result,
      };
    case ACTION.ERROR_PUT_TAXPAYER_ID:
      return { ...state, loading: { ...state.loading, PUT_TAXPAYER_ID: false }, error: { ...state.error, PUT_TAXPAYER_ID: action.error } };
    case ACTION.CLEAR_PUT_TAXPAYER_ID:
      return { ...state, taxPayerIdData: {} };

      case ACTION.INIT_PATCH_INFO_PROPERTIES:
      return { ...state, loading: { ...state.loading, PATCH_INFO_PROPERTIES: true }, error: { ...state.error, PATCH_INFO_PROPERTIES: null } };
    case ACTION.RESPONSE_PATCH_INFO_PROPERTIES:
      return {
        ...state, loading: { ...state.loading, PATCH_INFO_PROPERTIES: false }, error: { ...state.error, PATCH_INFO_PROPERTIES: null }, infoPropertiesData: action.result,
      };
    case ACTION.ERROR_PATCH_INFO_PROPERTIES:
      return { ...state, loading: { ...state.loading, PATCH_INFO_PROPERTIES: false }, error: { ...state.error, PATCH_INFO_PROPERTIES: action.error } };
    case ACTION.CLEAR_PATCH_INFO_PROPERTIES:
      return { ...state, infoPropertiesData: {} };

    case ACTION.CLEAR_ERRORS:
      return { ...state, error: dataModifications.error };
    default:
      return state;
  }
};

export default modificationsReducer;
