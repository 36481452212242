import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  toggle: {
  },
  open: {
    transform: 'rotate(-180deg)',
  },
  closed: {
    transform: 'rotate(0)',
  },
}));

export default useStyles;
