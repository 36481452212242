import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";

import Box from "../../components/Layout/Box";
import PageContainer from "../../components/PageContainer";
import { TreasuryTable } from "./TreasuryTable";
import SectionTitle from "../../components/SectionTitle";
import useStyles from "./TreasuryPage.style";
import { getTreasuryList, clearUpdateState } from "./TreasuryPage.actions";
import Button from "../../components/Button";
import Text from "../../components/Text";
import { banks, massiveTransactionLayouts } from "../../constants/constants";
import { clearSelectedShopsSet } from "../Shops/ShopsPage.actions";

const TreasuryPage = (props) => {
  const {
    selectedTreasuryElements,
    treasuryList,
    error,
    updateSuccessfullyTreasury,
    updateTreasuryErrorMsg,
    isFetchingShops,
  } = useSelector((state) => state.loanState);
  const classes = useStyles();
  const dispatch = useDispatch();

  const [nothingFoundNoticeShown, showNothingFoundNotice] = useState(false);
  const [updateNoticeShown, showUpdateNotice] = useState(false);
  const [registerModalIsVisible, setRegisterModalIsVisible] = useState(false);
  const [selectedSearchTreasuryElement, setSelectedSearchTreasuryElement] =
    useState(undefined);
  const [depositOption, setDepositOption] = useState(undefined);
  const [depositAmount, setDepositAmount] = useState(0);
  const [depositSuccess, setDepositSuccess] = useState(false);
  const [inputValue, setInputVale] = useState("");
  useEffect(() => {
    if (depositSuccess) {
      setTimeout(() => {
        setDepositSuccess(false);
        setRegisterModalIsVisible(false);
      }, 2000);
    }
  }, [depositSuccess]);

  useEffect(() => {
    if (error) {
      showNothingFoundNotice(true);
    }
  }, [error]);

  useEffect(() => {
    setTimeout(() => showNothingFoundNotice(false), 3000);
  }, [nothingFoundNoticeShown]);

  useEffect(() => {
    dispatch(clearUpdateState());
    if (updateSuccessfullyTreasury) {
      dispatch(clearSelectedShopsSet());
      dispatch(getTreasuryList());
    }
  }, [updateSuccessfullyTreasury]);

  useEffect(() => {
    showUpdateNotice(updateTreasuryErrorMsg !== "");
    setTimeout(() => {
      showUpdateNotice(false);
    }, 5000);
  }, [updateTreasuryErrorMsg]);

  const generateCSVFile = () => {
    const generateStoreObject = (layoutType) => {
      const mapStoreToLayout = (
        layoutType,
        layoutKey,
        selectedTreasuryElement
      ) => {
        const toTitleCase = (phrase) =>
          phrase
            .toLowerCase()
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");

        if (layoutType === "LTX05") {
          switch (layoutKey) {
            case "depositAccount":
              return selectedTreasuryElement.bank_number || "000000000000000";
            case "bankCode":
              return (
                banks.find(
                  (bank) => bank.value === selectedTreasuryElement.bank
                ) || { code: "ERROR" }
              ).code;
            case "beneficiary":
              return toTitleCase(selectedTreasuryElement.name)
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>{}[\]\\/]/gi, "");
            default:
              return "undefined";
            case "amount":
              return selectedTreasuryElement.amount || "$0.00";
            case "concept":
              return selectedTreasuryElement.name;
            case "orderingReference":
              return selectedTreasuryElement.account_id.split("-")[0];
            case "beneficiaryEmail":
              return selectedTreasuryElement.email;
          }
        } else if (layoutType === "LTX07") {
          switch (layoutKey) {
            case "depositAccount":
              return selectedTreasuryElement.bank_number || "000000000000000";
            case "bankCode":
              return (
                banks.find(
                  (bank) => bank.value === selectedTreasuryElement.bank
                ) || { code: "ERROR" }
              ).code;
            case "beneficiary":
              return toTitleCase(selectedTreasuryElement.name)
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>{}[\]\\/]/gi, "");
            default:
              return "ERROR";
            case "amount":
              return selectedTreasuryElement.amount || "$0.00";
            case "concept":
              return selectedTreasuryElement.name;
            case "orderingReference":
              return selectedTreasuryElement.account_id.split("-")[0];
            case "beneficiaryEmail":
              return selectedTreasuryElement.email;
            case "dateOfApplication":
              return (
                ("0" + new Date().getDate()).slice(-2) +
                ("0" + (new Date().getMonth() + 1)).slice(-2) +
                new Date().getFullYear()
              );
          }
        }
      };

      const layout = massiveTransactionLayouts[layoutType];

      if (layout === undefined) {
        throw Error(`Can't find layout type ${layoutType}`);
      }

      return (selectedTreasuryElement) => {
        const storeObject = layout.fields
          .map(({ fieldName }) => fieldName)
          .reduce(
            (object, currentVal) => ({
              ...object,
              [currentVal]: null,
            }),
            {}
          );

        layout.fields.forEach((field) => {
          let formattedValue = "";
          let storeValue = "";

          if (field && "value" in field) {
            storeValue = field.value;
          } else {
            storeValue = mapStoreToLayout(
              layoutType,
              field.fieldName,
              selectedTreasuryElement
            );
          }

          formattedValue = storeValue;

          const { fieldRules } = field;

          if (storeValue) {
            const { padding, length } = fieldRules;

            if (length) {
              try {
                formattedValue = storeValue.slice(0, length);
              } catch (e) {
                console.warn(e, storeValue);
              }
            }

            if ("type" in fieldRules) {
              switch (fieldRules.type) {
                case "currency":
                  formattedValue = Math.round(formattedValue * 100).toString();
                  break;
                case "concept":
                  formattedValue = `Disposicion YoFio ${new Date()
                    .toISOString()
                    .split("T")[0]
                    .replace(/-/gm, "")}`;
                  break;
                default:
                  break;
              }
            }

            if ("align" in fieldRules) {
              if (fieldRules.align === "right") {
                console.log(
                  `align ${formattedValue} to the ${fieldRules.align} to ${length} size with ${padding} character`
                );
                formattedValue = formattedValue.padStart(length, padding);
              } else {
                formattedValue = formattedValue.padEnd(length, padding);
              }
            }
          }

          storeObject[field.fieldName] = formattedValue;
        });

        console.log(storeObject);

        return storeObject;
      };
    };

    const arrayToCSV = (
      data = null,
      columnDelimiter = "",
      lineDelimiter = "\n"
    ) => {
      let result;
      let ctr;
      let keys;

      if (data === null || !data.length) {
        return null;
      }

      keys = Object.keys(data[0]);

      result = "";

      data.forEach((item) => {
        ctr = 0;
        keys.forEach((key) => {
          if (ctr > 0) {
            result += columnDelimiter;
          }

          result +=
            typeof item[key] === "string" && item[key].includes(columnDelimiter)
              ? `${item[key]}`
              : item[key];
          ctr++;
        });
        result += lineDelimiter;
      });

      return result;
    };
    const download = (content, fileName, mimeType) => {
      const a = document.createElement("a");
      mimeType = mimeType || "application/octet-stream";

      if (navigator.msSaveBlob) {
        // IE10
        navigator.msSaveBlob(
          new Blob([content], {
            type: mimeType,
          }),
          fileName
        );
      } else if (URL && "download" in a) {
        // html5 A[download]
        a.href = URL.createObjectURL(
          new Blob([content], {
            type: mimeType,
          })
        );
        a.setAttribute("download", fileName);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } else {
        window.location.href = `data:application/octet-stream,${encodeURIComponent(
          content
        )}`; // only this mime type is supported
      }
    };

    const santanderShops = selectedTreasuryElements.filter(
      (selectedTreasuryElement) => selectedTreasuryElement.bank === "santander"
    );
    const otherShops = selectedTreasuryElements.filter(
      (selectedTreasuryElement) => selectedTreasuryElement.bank !== "santander"
    );

    if (santanderShops.length > 0) {
      const SantanderShopsForCSV = santanderShops.map((santanderShop) =>
        generateStoreObject("LTX07")(santanderShop)
      );
      const santanderDownloadContent = arrayToCSV(SantanderShopsForCSV, "");
      download(
        santanderDownloadContent,
        "desembolsos-santander.txt",
        "text/csv;encoding:utf-8"
      );
    }

    if (otherShops.length > 0) {
      const otherShopsForCSV = otherShops.map((otherShop) =>
        generateStoreObject("LTX05")(otherShop)
      );
      const otherDownloadContent = arrayToCSV(otherShopsForCSV, "");
      download(
        otherDownloadContent,
        "desembolsos-otros.txt",
        "text/csv;encoding:utf-8"
      );
    }
  };

  return (
    <PageContainer>
      <Box className={classes.rootContainer}>
        <Box className={classes.topContainer}>
          <SectionTitle>Tesorería y Dispersiones</SectionTitle>
          <Button
            disabled={selectedTreasuryElements.length === 0}
            onClick={generateCSVFile}
          >
            <Text>Descargar CSV</Text>
          </Button>
        </Box>
        <Box className={classes.tableContainer}>
          <TreasuryTable resources={treasuryList} isFetchingShops={isFetchingShops} />
        </Box>
      </Box>
    </PageContainer>
  );
};

export default TreasuryPage;
