export default {
    storeGeneral: require('./store_general.png'),
    balanceGeneral: require('./balance_general.png'),
    accountGeneral: require('./account_general.png'),
    headerCredit: require('./header-credit.png'),
    headerTransaction: require('./header-transaction.png'),
    contentCopy: require('./content_copy.svg'),
    reference: require('./reference.svg'),
    // Transaction - Credit 
    nextPaymentIcon: require('./pago.png'),
    buyStock: require('./inventario.png'),
    totalPayment: require('./pagototal.png'),
    disbursmentAmount:  require('./desembolso.png'),
    iconAdjustment: require('./Adjustment.png'),
    iconRestructuring: require('./Restructuring.png'),
    iconReferral: require('./Referral.png'),
    iconCancellation: require('./Cancellation.png'),
    iconCashback: require('./Cashback.png'),
    iconChangeOfConditions: require('./ChangeOfConditions.png'),
    // Transaction - Transaction
    cashDisbursement: require('./cash-disbursement.png'),
    moneyDisbursment: require('./desembolso2.png'),
    amountCuota: require('./pagodecuota.png'),
    totalPaymentTransaction: require('./pagototal2.png'),
    disbursmentBuy: require('./comprade-inventario.png'),
    alertGreen: require('./AlertGreen.png'),
    alertYellow: require('./AlertYellow.png'),
    alertRed: require('./AlertRed.png'),
    arrowNext: require('./ArrowNext.png'),
    zoomIcon: require('./zoomIcon.png'),
    deletedDisbursment: require('./deletedDisbursment.png'),
    pencilEditIcon: require('./pencil-edit-icon.svg'),
    trashIcon: require('./trash-icon.svg'),
}