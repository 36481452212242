import client from "../client";

export const getVouchers = (queries) => {
    const url = `admin/vouchers${queries && Object.keys(queries).length > 0
        ? Object.keys(queries).reduce(
            (queryString, queryName, index) => `${queryString}${index !== 0 ? '&' : ''}${queryName}=${queries[queryName]}`, '?',
        )
        : ''}`;
    return new Promise((resolve, reject) => {
        client.get(url)
        .then((response) => {
            resolve(response);
        }, (error) => {
            reject(error);
        });
    });
};

export const putVouchers = (voucher_id, body) => {
    return new Promise((resolve, reject) => {
        client.put(`admin/vouchers/${voucher_id}`, body)
        .then((response) => {
            resolve(response);
        }, (error) => {
            reject(error);
        });
    });
};

export const getVouchersWeekly = (
    id,
    from_date
) => {
    let query = '';
    if (from_date !== undefined && from_date !== '') {
        query += `from_date=${from_date}&`;
    }
    if (query.endsWith('&')) {
        query = query.slice(0, -1);
    }
    return new Promise((resolve, reject) => {
        client.get(`admin/credit-line/${id}/vouchers-weekly${query ? `?${query}` : ''}`)
        .then(
            response => {
            resolve(response);
            },
            error => {
            reject(error);
            },
        );
    });
};