import * as firebase from 'firebase';

import config from '../firebase.config';

class Firebase {
  constructor() {
    firebase.initializeApp(config);
    this.provider = new firebase.auth.GoogleAuthProvider();
    this.provider.setCustomParameters({
      hd: 'yofio.co'
    });
    this.user = null;
  }
  getAuthToken() {
    return new Promise((resolve, reject) => {
      firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL).then(() => {
        firebase.auth().signInWithPopup(this.provider)
        .then(async (result) => {
          const { user } = result;
          this.user = user;
          const token = await firebase.auth().currentUser.getIdToken(false);
          resolve({ user, token: `Bearer ${token}` });
        }).catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log(`Error requesting Firebase auth token with status code ${errorCode}`);
          console.log(errorMessage);
          reject(error);
        });
      });
    });
  }
}

export const firebaseService = new Firebase();
