import React from "react";
import { useSelector } from "react-redux";
import { Box } from "../../../components/Layout";
import CustomerLaboralInfo from "../CustomerLaboralInfo/CustomerLaboralInfo";
import CustomerInfo from "../CustomerInfo/CustomerInfo";
import CustomerResidenceInfo from "../CustomerResidenceInfo/CustomerResidenceInfo";
import CustomerReferenceInfo from "../ReferenceInfo/CustomerReferenceInfo";

const ConfirmationInfo = (props) => {
  const {
    personal_data: personalInfo,
    laboral_data: laboralInfo,
    address: residentialInfo,
    references: referenceInfo,
  } = useSelector((state) => state.registersState.displayedCustomer);

  return (
    <Box style={{ display: "flex", flexDirection: "row", fontSize:"1rem"}}>
       <CustomerInfo {...personalInfo} />
       <CustomerResidenceInfo {...residentialInfo} />
      <CustomerLaboralInfo {...laboralInfo} />
      <CustomerReferenceInfo {...referenceInfo}/>
    </Box>
  );
};

export default ConfirmationInfo;
