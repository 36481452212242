import React, { useState } from 'react';
import {
  Collapse, Snackbar, Button, IconButton,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloseIcon from '@material-ui/icons/Close';

const StatusAlert = ({
  open, message, severity, alertHeader, isCollateralRfcAlert, isUserRfcAlert, isFacadePhotoAlert, setOpen
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const handleClose = () => {
    setOpen(!open);
  }

  const getSnackbarStyle = () => {
    if (isUserRfcAlert) {
      return {
        marginTop: 0,
        pointerEvents: 'auto',
      };
    } if (isCollateralRfcAlert) {
      return {
        marginTop: 80,
        pointerEvents: 'auto',
      };
    } if (isFacadePhotoAlert) {
      return {
        marginTop: 160,
        pointerEvents: 'auto',
      };
    }
  };

  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      style={getSnackbarStyle()}
    >
      <Alert severity={severity}>
        <div>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <span onClick={toggleExpand} style={{ cursor: 'pointer' }}>
              {alertHeader}
            </span>
            <IconButton
              size="small"
              onClick={toggleExpand}
              style={{ marginLeft: 'auto' }}
            >
              <ExpandMoreIcon fontSize="small" />
            </IconButton>
            <IconButton
            size="small"
              onClick={handleClose}
              style={{ top: 0, left: -2, position: 'absolute'}}
            >
              <CloseIcon fontSize="small" style={{ fontSize: 14 }}  />
            </IconButton>
          </div>
          <Collapse in={isExpanded}>
            <div>
              {message}
            </div>
          </Collapse>
        </div>
      </Alert>
    </Snackbar>
  );
};

export default StatusAlert;
