import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  tableHead: {
    width: "100%",
    backgroundColor: "#F8F9F9",
  },
  titleTable: {
    color: "#858d9c",
    fontSize: 12,
    textAlign: "center",
  },
  textCellTable: {
    color: "#3a3b3f",
    fontSize: 11,
    textAlign: "center",
  },
  alertTextCellTable: {
    color: "#e5534b",
    fontSize: 11,
    textAlign: "center",
  },
  input: {
    width: 500,
    height: 500,
  },
}));

export default useStyles;
