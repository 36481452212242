import React from 'react';
import { useDispatch } from 'react-redux';
import useStyles from './ConfirmationModal.style';
import Modal from '../../../components/CustomModal/CustomModal';
import { Box } from '../../../components/Layout';
import Text from '../../../components/Text';
import Button from '../../../components/Button';
import { postOneTransaction } from '../ShopsPage.actions';
import { updateTreasury } from '../../Treasury/TreasuryPage.actions';

const ConfirmationModal = (props) => {
  const {
    title, open, setOpen, selectedSettlement, type
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleConfirmation = () => {
    if(type === 'updateOneTransaction') {
      const { credit_line_id } = selectedSettlement;
      const treasuryElementBody = {
        disbursed_amount: selectedSettlement.amount_text,
        yofio_balance: selectedSettlement.yofio_balance,
        disbursed_reference: '1',
        disbursed_account_reference_id: selectedSettlement.shopkeeper_id_simple,
      };
      dispatch(updateTreasury(credit_line_id, treasuryElementBody));
    } else {
      const settlementId = selectedSettlement.settlement_id;
      dispatch(postOneTransaction(settlementId, selectedSettlement)).then(() => { window.history.go(); });
    }
    setOpen(false);
  };

  return (
    <Modal
      title={title}
      closeButton
      open={open}
      setOpen={(value) => { setOpen(value); }}
    >
      <Box className={classes.contentBox}>
        <Box className={classes.contentLabelTextBox}>
          <Text className={classes.labelText}>Tienda:</Text>
          <Text className={classes.valueText}>
            { selectedSettlement.shop_name }
          </Text>
        </Box>
        <Box className={classes.contentLabelTextBox}>
          <Text className={classes.labelText}>ID Tienda:</Text>
          <Text className={classes.valueText}>
            { selectedSettlement.shopkeeper_id_simple }
          </Text>
        </Box>
        <Box className={classes.contentLabelTextBox}>
          <Text className={classes.labelText}>Total a {type === 'updateOneTransaction' ? 'desembolsar:' : 'liquidar:'}</Text>
          <Text className={classes.valueText}>
            { selectedSettlement.amount_text }
          </Text>
        </Box>
        { selectedSettlement.bank_reference
          ? (
            <Box className={classes.contentLabelTextBox}>
              <Text className={classes.labelText}>Referencia Bancaria:</Text>
              <Text className={classes.valueText}>
                { selectedSettlement.bank_reference }
              </Text>
            </Box>
          ) : (<Text />)}
        { selectedSettlement.verification_account_id
          ? (
            <Box className={classes.contentLabelTextBox}>
              <Text className={classes.labelText}>Doble check ID Tienda:</Text>
              <Text className={classes.valueText}>
                { selectedSettlement.verification_account_id }
              </Text>
            </Box>
          ) : (<Text />)}
        <Box className={classes.buttonBox}>
          <Button variant="contained" color="secondary" onClick={handleConfirmation}>Confirmar</Button>
          <Button variant="contained" onClick={() => setOpen(false)}>Cancelar</Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ConfirmationModal;
