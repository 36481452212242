import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  Notice: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexWrap: 'nowrap',
    width: '100%',
    marginTop: '2%',
    marginBottom: '2%',
  },
  iconClass: {
  },
  textClass: {
    marginLeft: '0.9%',
    marginRight: '0.9%',
    fontSize: 14,
  },
}));

export default useStyles;
