import * as ACTION from './creditDetailActionTypes';
import client from '../../../client';
import { appService } from '../../../services/app.service';

export const getClient = (customerID) => async (dispatch, getState) => {
  const url = `${appService.config.paths.loan.route}/${customerID}`;
  try {
    dispatch({ type: ACTION.FETCH_CUSTOMERS_INIT });
    const response = await client.get(url);
    const { data } = response;
    dispatch({ type: ACTION.SET_STATE, label: 'displayedCustomer', value: data });
  } catch (e) {
    console.log(e);
    dispatch({type: ACTION.FETCH_CUSTOMERS_ERROR, error: e,});
  }
};
