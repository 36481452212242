import * as ACTION from './utils.actionTypes';
import * as utilsService from '../services/utils.service';

export const postShortUrl = (body) => async (dispatch) => {
  dispatch({
    type: ACTION.INIT_POST_SHORT_URL,
  });

  try {
    const shortUrlData = await utilsService.postShortUrl(body);
    if (shortUrlData) {
      dispatch({
        type: ACTION.RESPONSE_POST_SHORT_URL,
        result: shortUrlData,
      });
    }
  } catch (error) {
    dispatch({
      type: ACTION.ERROR_POST_SHORT_URL,
      error,
    });
  }
};

export const clearShortUrl = () => async (dispatch) => {
  dispatch({
    type: ACTION.CLEAR_POST_SHORT_URL,
  });
};

export const clearErrors = () => async (dispatch) => {
  dispatch({
    type: ACTION.CLEAR_ERRORS,
  });
};
