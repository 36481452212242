import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router';
import {
  TableContainer, Table, TableBody, TableRow, TableCell, TableFooter,
  TextField, Snackbar,
  CircularProgress
} from '@material-ui/core';
import { Alert } from "@material-ui/lab";
import Checkbox from '@material-ui/core/Checkbox';
import { Tooltip } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from './PaginationTable.style';
import Text from '../../components/Text';
import Button from '../../components/Button';
import { Box } from '../../components/Layout';
import { OrderedTableHead } from './TreasuryTableHead';
import ConfirmationModal from '../Shops/ConfirmationModal/ConfirmationModal';
import { toggleSelectedTreasury } from './TreasuryPage.actions';
import {deleteDisbursement} from '../../reducers/creditline.action'
import warn from "../../assets/icons/warning.png";
import redAlert from "../../assets/icons/AlertRed.png";
import okCheck from "../../assets/icons/header-credit.png";
import { postSendTransfer } from './TreasuryPage.actions';
import { simpleDateFunction } from "../../constants/dateFunction"
import CopyButton from '../../components/CopyButton/CopyButton';
import ConfirmationAlertModal from '../../components/ConfirmationAlertModal/ConfirmationAlertModal';

export const TreasuryTable = (props) => {
  const { resources, isFetchingShops } = props;
  const classes = useStyles();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDisabledSendTransfer, setIsDisabledSendTransfer] = useState(false);
  const [isDeleteDisbursementModalVisible, setIsDeleteDisbursementModalVisible] = useState(false);
  const [selectedShopId, setSelectedShopId] = useState('');
  const [selectedDisbursementId, setSelectedDisbursementId] = useState('');
  const [isError, setIsError] = useState(false);

  const { selectedTreasuryElements } = useSelector((state) => state.loanState);
  const { settlements } = useSelector((state) => state.shopsState);
  const { error} = useSelector(
    (state) => state.creditLineReducer
  );

  const [selectedTreasuryElement, setSelectedTreasuryElement] = useState({});

  const [displayedSettlementsInfo, setDisplayedSettlementsInfo] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (resources.length > 0) {
      setDisplayedSettlementsInfo(resources.map((resource) => ({ key: `${resource.account_id}-${resource.credit_line_id}` })));
    }
  }, [resources]);

  useEffect(() => {
    if (error?.DELETE_DISBURSEMENT) {
      if (error?.DELETE_DISBURSEMENT != null) {
        setIsError(true);
      }
    }
  }, [error?.DELETE_DISBURSEMENT]);




  const handleConfirmDisbursementModal = () => {
    setIsDeleteDisbursementModalVisible(false);
    dispatch(deleteDisbursement(selectedShopId,selectedDisbursementId)).then(() => {
      setTimeout(() => {
        window.location.reload();
        }, 2500);
    })
}

  const handleOpenDisbursementModal = (accountId, disbursementId) => {
    setIsDeleteDisbursementModalVisible(true);
    setSelectedShopId(accountId);
    setSelectedDisbursementId(disbursementId);
  }
  const handleInput = (e, settlementKey) => {
    const key = e.target.name;
    const currentSettlementIndex = displayedSettlementsInfo.findIndex((displayedSettlement) => displayedSettlement.key === settlementKey);

    const currentSettlement = { ...displayedSettlementsInfo[currentSettlementIndex], [key]: key !== 'id_shop_ref' ? e.target.value : e.target.value.toLowerCase() };

    const newDisplayedSettlements = [...displayedSettlementsInfo];

    newDisplayedSettlements[currentSettlementIndex] = currentSettlement;

    setDisplayedSettlementsInfo(newDisplayedSettlements);
  };

  const updateTreasuryElement = (settlement, settlementKey) => {
    const settlementDetails = displayedSettlementsInfo.find((settlement) => settlement.key === settlementKey);

    const numAmount = parseFloat(settlementDetails.settled_amount);
    const newSelectedTreasuryElement = {
      credit_line_id: settlement.credit_line_id,
      amount_text: numAmount,
      disbursed_reference: settlementDetails.bank_reference,
      shopkeeper_id_simple: settlementDetails.id_shop_ref,
      bank_reference: settlement.shopkeeper_bank,
      shop_name: settlement.shop_name,
      yofio_balance: settlement.yofio_balance,
    };
    if (numAmount > 0 && settlementDetails.id_shop_ref === settlement.account_id.substr(settlement.account_id.length - 6)) {
      setSelectedTreasuryElement(newSelectedTreasuryElement);
      setIsModalVisible(true);
    } else {
      alert('La validación no es correcta. Los números de cuenta no coinciden o el monto no es mayor a 0.');
    }
  };

  const sendTransfer = (credit_line_id, disbursement_id) => {
    setIsDisabledSendTransfer(true);
    const body = {
      origin_id: disbursement_id,
      transfer_type: 'DISBURSEMENT',
    }
    dispatch(postSendTransfer(credit_line_id, body)).then(() => {
      window.location.reload();
    });
  }

  const mapValidationStatus = (status) => {
    switch (status) {
      case 'NEW':
      case 'INITIATED':
      case 'PENDING':
        return 'Procesando Validación';
      case 'NOT_CREATED':
        return 'Sin Validación';
      case 'COMPLETED':
        return 'Validación no Coincide';
      case 'REJECTED':
        return 'Validación Rechazada';
      default:
        return 'Fallida';
    }
  };

  return (
    <Box>
     {isFetchingShops ? (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress  size={55} style={{marginRight: 10}}/>
        <Text>Cargando...</Text>
      </Box>
     ) : (
      <TableContainer className={classes.tableContainer}>
      <Table aria-label="custom pagination table">
        <OrderedTableHead />
        <TableBody>
          {
            resources.map((settlement, index) => {
              const settlementsShopsById = settlements.filter(shop => shop.settlement_id === settlement.account_id);
              let arrayDates = [], yofioBalance;
              if (settlementsShopsById.length !== 0) {
                settlementsShopsById.map(shop => {
                  arrayDates.push(new Date(shop.date))
                })
                let max = new Date(Math.max(...arrayDates));
                let settlementLastShop = settlementsShopsById.filter(shop => max.getTime() == new Date(shop.date).getTime())
                yofioBalance = parseFloat(settlementLastShop[0].balance.substr(1))
              } else {
                yofioBalance = 0.00
              }
              settlement.yofio_balance = yofioBalance
              const showWarn = !settlement.allow_disbursements;
              let warningTxt = ''
              if (!settlement.allow_disbursements) {
                warningTxt = 'El cliente tiene bloqueo para disponer';
              }
              let bankAccountValidationTxt = 'La cuenta bancaria no pertenece al titular';
              if (!settlement.bank_account_validation?.has_validation) {
                bankAccountValidationTxt = 'La cuenta bancaria no ha sido validada';
              }
              let rowStyle = { maxHeight: 43 };
              if (settlement.pending_transfer) {
                rowStyle['backgroundColor'] = '#f2f4f5';
              }
              return (
                <TableRow key={`${settlement.loan_id}-${settlement.account_id}`} style={rowStyle}>
                  <TableCell>
                    <Text className={classes.textBoldStyle}>{settlement?.country || "-"}</Text>
                  </TableCell>
                  <TableCell>
                    <Text className={classes.textBoldStyle} style={{ 'textAlign': 'center', 'cursor': 'pointer' }}>{showWarn && <Tooltip arrow
                      placement='top-end'
                      title={warningTxt}><img width={'20px'} src={warn} /></Tooltip>}
                    </Text>
                    <Text className={classes.textStyle}>
                      {settlement.account_id.substr(settlement.account_id.length - 6)}
                      <CopyButton elementToCopy={settlement?.account_id || ''} toolTipText={"Copiar id de la cuenta"}/>
                    </Text>
                  </TableCell>
                  <TableCell>
                    <Text className={classes.textBoldStyle}>{simpleDateFunction(settlement.created_at)}</Text>
                  </TableCell>
                  <TableCell>
                    <Text className={classes.textBoldStyle}>{settlement.name}</Text>
                  </TableCell>
                  <TableCell>
                    <Text className={classes.textBoldStyle}>{settlement.bank_account_validation.cep_beneficiary_name}</Text>
                  </TableCell>
                  <TableCell>
                    <Text className={classes.textBoldStyle}>{settlement.bank_account_validation.personhood_id}</Text>
                  </TableCell>
                  <TableCell>
                    <Text className={classes.textBoldStyle}>{settlement.bank_account_validation.cep_beneficiary_id}</Text>
                  </TableCell>
                  <TableCell>
                    <Text className={classes.textStyle}>{settlement.shop_name}</Text>
                  </TableCell>
                  <TableCell>
                    <Text className={classes.textStyle}>{settlement.bank}</Text>
                  </TableCell>
                  <TableCell>
                    <Text className={classes.textStyle}>{settlement.bank_number || '---'}</Text>
                  </TableCell>
                  <TableCell>
                    <Text>{!settlement.bank_account_validation?.is_valid && <Tooltip arrow
                      placement='top-end'
                      title={bankAccountValidationTxt}><img width={'20px'} src={redAlert} /></Tooltip>}
                    </Text>
                    <Text>{settlement.bank_account_validation?.is_valid && <Tooltip arrow
                      placement='top-end'
                      title={'Cuenta bancaria validada'}><img width={'20px'} src={okCheck} /></Tooltip>}
                    </Text>
                    <Text className={classes.textCellTable}>{!settlement?.bank_account_validation?.is_valid &&
                      <p>{mapValidationStatus(settlement?.bank_account_validation?.validation_status)}</p>}
                    </Text>
                    <Text>{settlement.bank_account_validation?.cep_url &&
                      <a target={'_blank'} href={settlement.bank_account_validation?.cep_url}>Descargar CEP</a>}
                    </Text>
                  </TableCell>
                  <TableCell>
                    <Text className={classes.textStyle}>${settlement.amount}</Text>
                  </TableCell>
                  <TableCell>
                    {settlement?.settled_amount && settlement.settled_amount !== 0.00
                      ? (<Text className={classes.textStyle} style={{ textAlign: 'center' }}>{settlement.settled_amount}</Text>)
                      : (
                        <TextField
                          type="text"
                          name="settled_amount"
                          onBlur={(event) => handleInput(event, `${settlement.account_id}-${settlement.credit_line_id}`)}
                        />
                      )}
                  </TableCell>
                  <TableCell>
                    {settlement?.id_shop_ref && settlement.id_shop_ref !== ''
                      ? (<Text className={classes.textStyle} style={{ textAlign: 'center' }}>{settlement.id_shop_ref}</Text>)
                      : (
                        <TextField
                          type="text"
                          name="id_shop_ref"
                          onBlur={(event) => handleInput(event, `${settlement.account_id}-${settlement.credit_line_id}`)}
                        />
                      )}
                  </TableCell>
                  <TableCell>
                    {settlement?.country !== "DO" ? 
                      <>
                        {settlement?.status !== 'PENDING'
                          ? (<Text className={classes.textStyle} style={{ textAlign: 'center', fontSize: '12px' }}>Desembolsado</Text>)
                          : (
                            <>
                              {settlement.pending_transfer ? (
                                <Text className={classes.textStyle} style={{ textAlign: 'center', fontSize: '12px' }}>En Proceso</Text>
                              )
                                : (
                                  <Button
                                    isDisabled={isDisabledSendTransfer}
                                    onClick={() => {
                                      sendTransfer(settlement.credit_line_id, settlement.disbursement_id);
                                    }}
                                    style={{ backgroundColor: '#0253cc', color: 'white' }}
                                  >
                                    Enviar Transfer
                                  </Button>
                                )}
                            </>
                        )}
                      </>: 
                      <Text className={classes.textStyle} style={{ textAlign: 'center', fontSize: '20px' }}>-</Text>
                    }
                  </TableCell>
                  <TableCell>
                    {settlement?.status && settlement.status !== 'PENDING'
                      ? (<Text className={classes.textStyle} style={{ textAlign: 'center' }}>Desembolsado</Text>)
                      : (
                        <Button
                          onClick={() => {
                            updateTreasuryElement(settlement, `${settlement.account_id}-${settlement.credit_line_id}`);
                          }}
                          style={{ backgroundColor: '#0253cc', color: 'white' }}
                        >
                          Desembolsar
                        </Button>
                      )}
                  </TableCell>
                  <TableCell>
                  <Button
                  onClick={() => {
                    handleOpenDisbursementModal(settlement.credit_line_id, settlement.disbursement_id);
                  }}
                        style={{ backgroundColor: '#0253cc', color: 'white' }}
                      >
                        Cancelar desembolso
                      </Button>
                </TableCell>
                  <TableCell>
                    <Checkbox
                      checked={!!selectedTreasuryElements.find((selectedTreasuryElement) => `${selectedTreasuryElement.account_id}-${selectedTreasuryElement.credit_line_id}` === `${settlement.account_id}-${settlement.credit_line_id}`)}
                      onChange={(event) => dispatch(toggleSelectedTreasury(settlement, event.target.checked))}
                    />
                  </TableCell>
                </TableRow>
              )
            })
          }
          {resources.length === 0 && (
            <TableRow>
              <TableCell colSpan={14}>
                Sin transferencias pendientes por el momento ...
              </TableCell>
            </TableRow>
          )
          }
        </TableBody>
        <TableFooter />
      </Table>
    </TableContainer>
     )
     }
      <ConfirmationModal
        title="Confirmación de desembolso"
        open={isModalVisible}
        setOpen={setIsModalVisible}
        selectedSettlement={selectedTreasuryElement}
        type={'updateOneTransaction'}
      />
      <ConfirmationAlertModal
        open={isDeleteDisbursementModalVisible}
        setOpen={setIsDeleteDisbursementModalVisible}
        handleConfirmation={handleConfirmDisbursementModal}
      />
      <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      autoHideDuration={3500}
      open={isError}
      className={classes.snackbar}
    >
      <Alert severity={'error'}>
        {error?.DELETE_DISBURSEMENT?.response?.data?.readable_message || 'Ocurrió un error'}
      </Alert>
    </Snackbar>
    </Box>
  );
};

export default TreasuryTable;
