import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 0,
  },
  appLogo: {
    maxHeight: 48,
    height: '100%'
  },
  userLogo: {
    width: 40,
    height: 40,
    overflow: 'hidden',
    borderRadius: '50%',
    border: '2px solid white',
  },
  emptySpace: {
    flexGrow: 1
  },
  rightMenu: {
    display: 'flex',
    flexDirection: 'row',
  },
  containerMenu: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '10px',
    marginLeft: '10px'
  },
  normalText: {
    fontSize: '14px',
    color: 'white',
    fontWeight: 'normal',
    whiteSpace: 'nowrap'
  },
  linkText: {
    fontSize: '12px',
    color: 'white',
    fontWeight: 'bold',
    marginTop: '5px'
  },
  customLink: {
    textDecoration: 'none'
  }
}));

export default useStyles;
