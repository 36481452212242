import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  container: {
    flexDirection: 'row',
    display: 'flex',
    marginBottom: '20px',
  },
  text: {
    color: '#00000',
    fontSize: '14px',
    fontWeight: 'normal',
  },
  facadeStatusContainerFalse: {
    width: '43px',
    height: '22px',
    justifyContent: 'center',
    alignContent: 'center',
    borderRadius: '7px',
    backgroundColor: '#ea4b4b',
    marginLeft: '15px',
  },
  facadeStatusContainerTrue: {
    width: '77px',
    backgroundColor: '#45c441',
    justifyContent: 'center',
    alignContent: 'center',
    borderRadius: '7px',
    marginLeft: '15px',
  },
  facadeStatusText: {
    color: '#ffff',
    fontSize: '12px',
    fontWeight: 'inherit',
    textAlign: 'center',
  },
  facadeSelectTitle: {
    color: '#000000',
    fontSize: '16px',
  },
  selectContainer: {
    marginTop: '12px',
    display: 'flex',
  },
  selectWrapper: { display: 'flex', width: 300, flexDirection: 'column' },
  link: {
    fontSize: 'larger', marginLeft: '20px', cursor: 'pointer', color: 'blue', textDecoration: 'underline',
  },
  formContainer: { marginLeft: 50 },
  formWrapper: {
    marginTop: 12, display: 'flex', flexDirection: 'column', height: 150,
  },
  buttonsContainer: {
    marginTop: 20, display: 'flex', flexDirection: 'row', justifyContent: 'space-between',
  },
}));

export default useStyles;
