import * as ACTION from './catalog.actionTypes';
import * as catalogService from '../services/catalog.service';

export const getVeredictReasonsCatalog = (veredict_type) => async (dispatch) => {
  dispatch({
    type: ACTION.INIT_GET_VEREDICT_REASONS_CATALOG,
  });

  try {
    const veredictReasonsCatalogInfo = await catalogService.getVeredictReasonsCatalog(veredict_type);
    if (veredictReasonsCatalogInfo) {
      dispatch({
        type: ACTION.RESPONSE_GET_VEREDICT_REASONS_CATALOG,
        result: veredictReasonsCatalogInfo,
      });
    }
  } catch (error) {
    dispatch({
      type: ACTION.ERROR_GET_VEREDICT_REASONS_CATALOG,
      error,
    });
  }
};

export const clearGetVeredictReasonsCatalog = () => async (dispatch) => {
  dispatch({
    type: ACTION.CLEAR_GET_VEREDICT_REASONS_CATALOG,
  });
};

export const getVeredictReviewRequestPropertiesCatalog = () => async (dispatch) => {
  dispatch({
    type: ACTION.INIT_GET_REVIEW_REQUEST_PROPERTIES_CATALOG,
  });

  try {
    const reviewRequestPropertiesCatalogInfo = await catalogService.getVeredictReviewRequestPropertiesCatalog();
    if (reviewRequestPropertiesCatalogInfo) {
      dispatch({
        type: ACTION.RESPONSE_GET_REVIEW_REQUEST_PROPERTIES_CATALOG,
        result: reviewRequestPropertiesCatalogInfo,
      });
    }
  } catch (error) {
    dispatch({
      type: ACTION.ERROR_GET_REVIEW_REQUEST_PROPERTIES_CATALOG,
      error,
    });
  }
};

export const clearGetVeredictReviewRequestPropertiesCatalog = () => async (dispatch) => {
  dispatch({
    type: ACTION.CLEAR_GET_REVIEW_REQUEST_PROPERTIES_CATALOG,
  });
};

export const getInventoryReasonsCatalog = () => async (dispatch) => {
  dispatch({
    type: ACTION.INIT_GET_INVENTORY_REASONS_CATALOG,
  });

  try {
    const invetoryReasonsCatalogInfo = await catalogService.getInventoryReasonsCatalog();
    if (invetoryReasonsCatalogInfo) {
      dispatch({
        type: ACTION.RESPONSE_GET_INVENTORY_REASONS_CATALOG,
        result: invetoryReasonsCatalogInfo,
      });
    }
  } catch (error) {
    dispatch({
      type: ACTION.ERROR_GET_INVENTORY_REASONS_CATALOG,
      error,
    });
  }
};

export const clearGetInventoryReasonsCatalog = () => async (dispatch) => {
  dispatch({
    type: ACTION.CLEAR_GET_INVENTORY_REASONS_CATALOG,
  });
};

export const getRejectReasonsCatalog = () => async (dispatch) => {
  dispatch({
    type: ACTION.INIT_GET_REJECT_REASONS_CATALOG,
  });

  try {
    const rejectReasonsCatalogInfo = await catalogService.getRejectReasonsCatalog();
    if (rejectReasonsCatalogInfo) {
      dispatch({
        type: ACTION.RESPONSE_GET_REJECT_REASONS_CATALOG,
        result: rejectReasonsCatalogInfo,
      });
    }
  } catch (error) {
    dispatch({
      type: ACTION.ERROR_GET_REJECT_REASONS_CATALOG,
      error,
    });
  }
};

export const clearGetRejectReasonsCatalog = () => async (dispatch) => {
  dispatch({
    type: ACTION.CLEAR_GET_REJECT_REASONS_CATALOG,
  });
};
