import React, { useEffect, useState } from "react";

import DisbursementDealerUI from './DisbursementDealer';
import useStyles from "./DisbursementDealer.styles";

function DisbursementDealer() {
    const classes = useStyles();

    return <DisbursementDealerUI
        classes={classes}
    />
}

export default DisbursementDealer