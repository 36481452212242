export const INIT_GET_PRESALE_ADVISORS = 'PRESALE_ADVISORS::INIT_GET_PRESALE_ADVISORS';
export const RESPONSE_GET_PRESALE_ADVISORS = 'PRESALE_ADVISORS::RESPONSE_GET_PRESALE_ADVISORS';
export const ERROR_GET_PRESALE_ADVISORS = 'PRESALE_ADVISORS::ERROR_GET_PRESALE_ADVISORS';
export const CLEAR_GET_PRESALE_ADVISORS = 'PRESALE_ADVISORS::CLEAR_GET_PRESALE_ADVISORS';

export const INIT_PUT_PRESALE_ADVISORS = 'PRESALE_ADVISORS::INIT_PUT_PRESALE_ADVISORS';
export const RESPONSE_PUT_PRESALE_ADVISORS = 'PRESALE_ADVISORS::RESPONSE_PUT_PRESALE_ADVISORS';
export const ERROR_PUT_PRESALE_ADVISORS = 'PRESALE_ADVISORS::ERROR_PUT_PRESALE_ADVISORS';
export const CLEAR_PUT_PRESALE_ADVISORS = 'PRESALE_ADVISORS::CLEAR_PUT_PRESALE_ADVISORS';

export const INIT_POST_PRESALE_ADVISORS_TASK = 'PRESALE_ADVISORS::INIT_POST_PRESALE_ADVISORS_TASK';
export const RESPONSE_POST_PRESALE_ADVISORS_TASK = 'PRESALE_ADVISORS::RESPONSE_POST_PRESALE_ADVISORS_TASK';
export const ERROR_POST_PRESALE_ADVISORS_TASK = 'PRESALE_ADVISORS::ERROR_POST_PRESALE_ADVISORS_TASK';
export const CLEAR_POST_PRESALE_ADVISORS_TASK = 'PRESALE_ADVISORS::CLEAR_POST_PRESALE_ADVISORS_TASK';

export const CLEAR_ERRORS = 'PRESALE_ADVISORS::CLEAR_ERRORS';
