import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  page: {
    display: 'flex',
    width: '100%',
    height: '100%',
    flex: 1,
    flexDirection: 'row',
    background: '#ea4b4b',
  },
  leftContainer: {
    maxWidth: 229,
    minWidth: 229,
    background: 'white',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 32,
    paddingLeft: 30,
    paddingRight: 12,
    paddingBottom: 28,
    overflow: 'hidden',
  },
  divider: {
    maxWidth: 1,
    minWidth: 1,
    display: 'flex',
    background: '#dcdee3',
  },
  rightContainer: {
    background: '#FFFFFF',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    flex: 1,
    padding: '20px 20px 0px 20px',
  },
  titleMenu: {
    fontSize: 14,
    letterSpacing: -0.16,
  },
  menuContainer: {
    flex: 1,
  },
  subMenuContainer: {
    marginLeft: '10%'
  }
}));

export default useStyles;
