import { Button } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '../Layout';
import Text from '../Text/Text';
import useStyles from './ComparisonComponent.style';
import comparativeIcon from '../../assets/comparative.svg';

const ComparisonComponent = ({ onClick }) => {
  const classes = useStyles();
  return (
    <>
      <Button
        className={classes.buttonContainer}
        onClick={onClick}
      >
        <Box className={classes.generalContainer}>
          <img src={comparativeIcon} alt="comparative-icon" className={classes.icon} />
          <Text className={classes.text}>
            Iniciar comparativo
          </Text>
        </Box>
      </Button>

    </>
  );
};

export default ComparisonComponent;

ComparisonComponent.propTypes = {
  onClick: PropTypes.func.isRequired,
};
