import * as ACTION from './reviewVouchers.actionTypes';

const dataVouchers = {
    loading: {
        GET_VOUCHERS: false,
        PUT_VOUCHERS: false,
        GET_VOUCHERS_WEEKLY: false
    },
    error: {
        GET_VOUCHERS: null,
        PUT_VOUCHERS: null,
        GET_VOUCHERS_WEEKLY: null
    },

    getVouchersData: {},
    putVouchersData: {},
    getVouchersWeeklyData: {}
};

const reviewVoucherReducer = (state= dataVouchers, action) => {
    switch (action.type) {
        case ACTION.INIT_GET_VOUCHERS:
            return { ...state, loading: { ...state.loading, GET_VOUCHERS: true }, error: { ...state.error, GET_VOUCHERS: null} };
        case ACTION.RESPONSE_GET_VOUCHERS:
            return { ...state, loading: { ...state.loading, GET_VOUCHERS: false }, error: { ...state.error, GET_VOUCHERS: null}, getVouchersData: action.result };
        case ACTION.ERROR_GET_VOUCHERS:
            return { ...state, loading: { ...state.loading, GET_VOUCHERS: false }, error: { ...state.error, GET_VOUCHERS: action.error} };
        case ACTION.CLEAR_GET_VOUCHERS:
            return { ...state, getVouchersData: {} };

        case ACTION.INIT_PUT_VOUCHERS:
            return { ...state, loading: { ...state.loading, PUT_VOUCHERS: true }, error: { ...state.error, PUT_VOUCHERS: null} };
        case ACTION.RESPONSE_PUT_VOUCHERS:
            return { ...state, loading: { ...state.loading, PUT_VOUCHERS: false }, error: { ...state.error, PUT_VOUCHERS: null}, putVouchersData: action.result };
        case ACTION.ERROR_PUT_VOUCHERS:
            return { ...state, loading: { ...state.loading, PUT_VOUCHERS: false }, error: { ...state.error, PUT_VOUCHERS: action.error} };
        case ACTION.CLEAR_PUT_VOUCHERS:
            return { ...state, putVouchersData: {} };

        case ACTION.INIT_GET_VOUCHERS_WEEKLY:
            return { ...state, loading: { ...state.loading, GET_VOUCHERS_WEEKLY: true }, error: { ...state.error, GET_VOUCHERS_WEEKLY: null} };
        case ACTION.RESPONSE_GET_VOUCHERS_WEEKLY:
            return { ...state, loading: { ...state.loading, GET_VOUCHERS_WEEKLY: false }, error: { ...state.error, GET_VOUCHERS_WEEKLY: null}, getVouchersWeeklyData: action.result };
        case ACTION.ERROR_GET_VOUCHERS_WEEKLY:
            return { ...state, loading: { ...state.loading, GET_VOUCHERS_WEEKLY: false }, error: { ...state.error, GET_VOUCHERS_WEEKLY: action.error} };
        case ACTION.CLEAR_GET_VOUCHERS_WEEKLY:
            return { ...state, getVouchersWeeklyData: {} };

        default:
            return state;
    }
};

export default reviewVoucherReducer;