import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  rootContainer: {
    marginLeft: "7%",
  },
  container: {
    marginLeft: "7%",
    marginRight: "7%",
    flex: 1,
    backgroundColor: "#fafcfe",
    marginTop: 16,
    borderRadius: 6,
    borderColor: "#e8e8fc",
    paddingLeft: "2%",
  },
  upperPanel: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    alignContent: "center",
    alignItems: "center",
    borderColor: "#e8e8fc",
    "& h2": {
      fontSize: 24,
    },

    "& h6": {
      color: "#3a4760",
      fontSize: 9,
    },
    "& h3": {
      fontWeight: "bold",
    },
  },

  lowerPanel: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-around",
    alignContent: "center",
    alignItems: "center",
    height: 100,
    fontSize: 16,
    borderColor: "#e8e8fc",

    "& h3": {
      paddingTop: "10%",
      fontSize: 17,
    },
    "& h6": {
      paddingTop: "5%",
      fontSize: 9,
      color: "#3a4760",
    },
  },

  controls: {
    display: "flex",
    position: "relative",
    top: 0,
    width: "100%",
    marginTop: "22px",
    color: "#858d9c",
    alignItems: "center",
  },
  customerInfo: {
    backgroundColor: "#f4f8ff",
  },
  mainPanel: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    justifyContent: "flex-start",
  },

  tabs: {
    marginLeft: "7%",
  },
  transactions: {
    display: "flex",
    flexDirection: "row",
    marginTop: "3%",
    marginLeft: "3%",
    marginRight: "5%",
    flexWrap: "wrap",
    alignContent: "center",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  pendingCutoffTable: {
    display: "flex",
    flexDirection: "row",
    marginLeft: "7%",
    marginRight: "7%",
    flex: 1,
    backgroundColor: "#fafcfe",
    marginTop: 16,
    borderRadius: 6,
    borderColor: "#e8e8fc",
  },

  cutoffContainer: {
    flex: 1,
    backgroundColor: "#fafcfe",
    marginTop: 16,
    borderRadius: 6,
    borderColor: "#e8e8fc",
  },
  cutOffTotals: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "60%",
    justifyContent: "space-around",
  },
  customerInfoCards: {
    display: "flex",
    flexDirection: "row",
    marginLeft: "7%",
    marginRight: "7%",
    flexWrap: "wrap",
    alignContent: "center",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  customerStatus: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    overflow: "hidden",
    padding: "3%",
    width: "40%",
    "& h3": {
      color: "#0253cc",
      fontSize: 20,
    },
    "& h4": {
      color: "#3a4760",
      fontFamily: "Poppins",
      fontSize: 12,
    },
  },
}));

export default useStyles;
