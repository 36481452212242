import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  copyBtn: {
    cursor: "pointer",
    marginLeft: "10px",
    height: "18px",
    "&:active": { backgroundColor: "#2252C5", borderRadius: 5 },
  },
}));

export default useStyles;