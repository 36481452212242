import React from "react";

import { Button, MenuItem } from "@material-ui/core";

import Box from '../../components/Layout/Box';
import Text from '../../components/Text';
import TextInput from "../../components/TextInput";
import icons from "../../assets/icons";

function CardReviewVoucher({
    classes,
    data,
    values,
    handleChangeInput,
    setZoomModal,
    dealerList,
    viewDetail,
    transformMetadata
}) {
    return (
        <Box className={classes.containerTicket} key={data?.voucher_id}>
            <img
                src={data?.url_photo}
                className={classes.imageContainer}
            />
            <img
                src={icons.zoomIcon}
                className={classes.zoomImage}
                onClick={() => setZoomModal({
                    show: true,
                    image: data?.url_photo
                })}
            />
            {data?.review_status === "APPROVED" &&
                <div className={classes.containerStatus}>
                    <div className={classes.containerInfoVoucher}>
                        <img
                            src={icons.alertGreen}
                            className={classes.statusImg}
                        />
                        <Text style={{fontWeight: 'bold'}}>{`Comprobante aprobado`}</Text>
                    </div>
                    <Text className={classes.textInfoStyle}>{`Proveedor: ${data?.supplier !== "" ?  data?.supplier : "-"}`}</Text>
                    <Text>{`Aprobado por: $${data?.approved_amount}`}</Text>
                </div>
            }
            {data?.review_status === "PENDING" &&
                <>
                    <TextInput
                        className={classes.formElement}
                        label="Distribuidor"
                        variant="standard"
                        select
                        value={values.dealer}
                        onChange={handleChangeInput('dealer')}
                    >
                        {dealerList.length !== 0 &&
                            dealerList.map(dealer => <MenuItem value={dealer.id}>{dealer.name}</MenuItem>)
                        }
                    </TextInput>
                    <Box className={classes.infoContainer}>
                        <Text className={classes.titleInfo}>{`Monto`}</Text>
                        <Text className={classes.textInfo}>{data?.metadata ? transformMetadata(data?.metadata, "total_amount") : "Sin datos"}</Text>
                    </Box>
                    <Box className={classes.infoContainer}>
                        <Text className={classes.titleInfo}>{`Fecha de compra`}</Text>
                        <Text className={classes.textInfo}>{data?.metadata ? transformMetadata(data?.metadata, "date") : "Sin datos"}</Text>
                    </Box>
                    <Button
                        className={classes.buttonViewDetail}
                        variant="contained"
                        color='secondary'
                        onClick={() => viewDetail(data?.voucher_id)}
                        disabled={
                            values.dealer !== ''
                            ? false : true
                        }
                    >
                        Ver detalle
                    </Button>
                </>
            }
            {data?.review_status === "REJECTED" &&
                <div className={classes.containerStatus}>
                    <div className={classes.containerInfoVoucher}>
                        <img
                            src={icons.alertRed}
                            className={classes.statusImg}
                        />
                        <Text style={{fontWeight: 'bold'}}>{`Comprobante rechazado`}</Text>
                    </div>
                    <Text className={classes.textInfoStyle}>{`Proveedor: ${data?.supplier !== "" ?  data?.supplier : "-"}`}</Text>
                    <Text style={{marginBottom: 10}}>{`Rechazado por: $${data?.approved_amount}`}</Text>
                    <Text>{`Razón de rechazo: ${data?.reject_reason !== "" ? data?.reject_reason : "-"}`}</Text>
                </div>
            }
        </Box>
    )
}

export default CardReviewVoucher;