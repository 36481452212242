export const remainingPayments = (balance, installment, interest, term_type) => {
    const Y = (interest / termMultiplier(term_type) / 100) * 1.16;
    return Math.ceil(
        -Math.log(1 - (balance * Y) / installment) / Math.log(1 + Y)
    );
};

export const termMultiplier = (term_type) => {
    let termMultiplier;
    switch (term_type) {
        case "WEEKLY":
            termMultiplier = 4;
            break;
        case "BIWEEKLY":
            termMultiplier = 2;
            break;
        case "MONTHLY":
            termMultiplier = 1;
            break;
    }
    return termMultiplier;
};