import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    topBarDetail: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        marginTop: '20px',
        paddingTop: '10px',
        paddingBottom: '10px',
        alignItems: 'center',
        border: '2px solid #e8e8fc',
        borderRadius: '6px',
        justifyContent: 'space-around',
        backgroundColor: 'rgba(232, 242, 252, 0.2)'
    },
    containerLogo: {
        display: 'flex',
        position: 'relative',
        top: 0,
        alignItems: 'center',
    },
    userLogo: {
        width: 40,
        height: 40,
        overflow: 'hidden',
        borderRadius: '50%',
        marginRight: '10px',
        border: '2px solid #ea4b4b',
    },
    mainTextBar: {
        fontWeight: 'bold',
    },
}));

export default useStyles;