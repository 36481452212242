import React from "react";
import { Box } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import PropTypes from "prop-types";
import Modal from "@material-ui/core/Modal";
import CloseIcon from "@material-ui/icons/Close";
import useStyles from "./CustomModal.style";
import SectionTitle from "../SectionTitle/SectionTitle";
import Button from "../Button";

export const CustomModal = (props) => {
  const { open, setOpen, children, closeButton, title, isMessage } = props;
  const classes = useStyles();
  return (
    <Modal
      open={open}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      style={{ top: !isMessage ? "25%" : "1%" }}
    >
      <Card className={classes.modalContent}>
        <Box className={classes.modalTitle}>
          <SectionTitle>{title}</SectionTitle>
          <Button onClick={() => setOpen(false)}>
            {closeButton && <CloseIcon />}
          </Button>
        </Box>
        {children}
      </Card>
    </Modal>
  );
};

CustomModal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.shape({}),
  ]),
  open: PropTypes.bool.isRequired,
};
export default CustomModal;
