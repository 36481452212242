import React from 'react';
import GaugeComponent from 'react-gauge-component';

import { Box } from '../Layout';
import Text from '../Text';

const ScoreIndicator = ({
    classes,
    score,
    textScore,
}) => {
    return (
        <Box className={classes.containerScore}>
            <GaugeComponent
                value={score}
                maxValue={100}
                minValue={0}
                type="radial"
                style={{backgroundColor: "#f6f9fc", borderRadius: "50%"}}
                marginInPercent={0.027}
                labels={{
                    valueLabel:{
                        hide: true,
                        formatTextValue: (value) => `${value}`,
                    },
                    tickLabels: {
                        type: "inner",
                        defaultTickValueConfig: {
                            formatTextValue: (value) => `${value}`,
                            style: {
                                fontSize: 12,
                                fill: "#666e75",
                                fontWeight: "bold",
                            },
                        },
                        defaultTickLineConfig: {
                            color: '#666e75',
                            width: 2,
                            length: 15,
                        },
                        ticks: [
                            { value: 0 },
                            { value: 10 },
                            { value: 20 },
                            { value: 30 },
                            { value: 40 },
                            { value: 50 },
                            { value: 60 },
                            { value: 70 },
                            { value: 80 },
                            { value: 90 },
                            { value: 100 }
                        ]
                    }
                }}
                arc={{
                    width: 0.1,
                    padding: 0,
                    colorArray: ['#F8494B', '#FDCD18', '#45C441'],
                    cornerRadius: 0,
                    nbSubArcs: 100,
                }}
                pointer={{
                    elastic: true,
                    animationDelay: 0,
                    color: "#3a4760",
                    length: 0.5,
                    width: 10,
                }}
            />
            <Box className={classes.textScoreContainer}>
                <Text className={classes.textScoreStyle}>{`Score: ${score ? score : '-'} ${textScore ? textScore.toUpperCase() : "-"}`}</Text>
            </Box>
        </Box>
    );
};

export default ScoreIndicator;
