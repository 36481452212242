import * as ACTION from './presaleadvisors.actionTypes';

const presaleAdvisorsInfo = {
  loading: {
    GET_PRESALE_ADVISORS: false,
    PUT_PRESALE_ADVISORS: false,
    POST_PRESALE_ADVISORS_TASK: false,
  },
  error: {
    GET_PRESALE_ADVISORS: null,
    PUT_PRESALE_ADVISORS: null,
    POST_PRESALE_ADVISORS_TASK: null,
  },

  presaleAdvisorsData: {},
  updatePresaleAdvisorsData: {},
  presaleAdvisorTaskData: {},
};

const presaleAdvisorsReducer = (state = presaleAdvisorsInfo, action) => {
  switch (action.type) {
    case ACTION.INIT_GET_PRESALE_ADVISORS:
      return { ...state, loading: { ...state.loading, GET_PRESALE_ADVISORS: true }, error: { ...state.error, GET_PRESALE_ADVISORS: null } };
    case ACTION.RESPONSE_GET_PRESALE_ADVISORS:
      return {
        ...state, loading: { ...state.loading, GET_PRESALE_ADVISORS: false }, error: { ...state.error, GET_PRESALE_ADVISORS: null }, presaleAdvisorsData: action.result,
      };
    case ACTION.ERROR_GET_PRESALE_ADVISORS:
      return { ...state, loading: { ...state.loading, GET_PRESALE_ADVISORS: false }, error: { ...state.error, GET_PRESALE_ADVISORS: action.error } };
    case ACTION.CLEAR_GET_PRESALE_ADVISORS:
      return { ...state, presaleAdvisorsData: {} };
    case ACTION.INIT_PUT_PRESALE_ADVISORS:
      return {
        ...state,
        loading: { ...state.loading, PUT_PRESALE_ADVISORS: true },
        error: { ...state.error, PUT_PRESALE_ADVISORS: null },
      };
    case ACTION.RESPONSE_PUT_PRESALE_ADVISORS:
      return {
        ...state,
        loading: { ...state.loading, PUT_PRESALE_ADVISORS: false },
        error: { ...state.error, PUT_PRESALE_ADVISORS: null },
        updatePresaleAdvisorsData: action.result,
      };
    case ACTION.ERROR_PUT_PRESALE_ADVISORS:
      return {
        ...state,
        loading: { ...state.loading, PUT_PRESALE_ADVISORS: false },
        error: { ...state.error, PUT_PRESALE_ADVISORS: action.error },
      };
    case ACTION.CLEAR_PUT_PRESALE_ADVISORS:
      return { ...state, updatePresaleAdvisorsData: {} };

    case ACTION.INIT_POST_PRESALE_ADVISORS_TASK:
      return {
        ...state,
        loading: { ...state.loading, POST_PRESALE_ADVISORS_TASK: true },
        error: { ...state.error, POST_PRESALE_ADVISORS_TASK: null },
      };
    case ACTION.RESPONSE_POST_PRESALE_ADVISORS_TASK:
      return {
        ...state,
        loading: { ...state.loading, POST_PRESALE_ADVISORS_TASK: false },
        error: { ...state.error, POST_PRESALE_ADVISORS_TASK: null },
        presaleAdvisorTaskData: action.result,
      };
    case ACTION.ERROR_POST_PRESALE_ADVISORS_TASK:
      return {
        ...state,
        loading: { ...state.loading, POST_PRESALE_ADVISORS_TASK: false },
        error: { ...state.error, POST_PRESALE_ADVISORS_TASK: action.error },
      };
    case ACTION.CLEAR_POST_PRESALE_ADVISORS_TASK:
      return { ...state, presaleAdvisorTaskData: {} };
    case ACTION.CLEAR_ERRORS:
      return { ...state, error: presaleAdvisorsInfo.error };
    default:
      return state;
  }
};

export default presaleAdvisorsReducer;
