import React from "react";

import PageContainer from '../../components/PageContainer';
import Box from '../../components/Layout/Box';
import { MenuItem } from "@material-ui/core";
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import SectionTitle from '../../components/SectionTitle';
import TextInput from "../../components/TextInput";
import TableMatiVerification from "../../components/TableMatiVerification";

function MatiVerification({
    classes,
    inputValue,
    setInputVale,
    status,
    setStatus,
    searchAction,
    dataMati
}) {
    return (
        <PageContainer>
            <Box className={classes.rootContainer}>
                <Box className={classes.topContainer}>
                    <SectionTitle>Verificación de MATI</SectionTitle>
                    <Box className={classes.rowContainer}>
                        <TextInput
                            className={classes.formElement}
                            label="Filtrar por estatus"
                            variant="standard"
                            select
                            value={status}
                            onChange={(value) => setStatus(value.target.value)}
                        >
                            <MenuItem value={'CREATED'}>Firmando</MenuItem>
                            <MenuItem value={'PENDING'}>Por verificar</MenuItem>
                            <MenuItem value={'APPROVED'}>Verificado</MenuItem>
                            <MenuItem value={'REJECTED'}>Rechazado</MenuItem>
                            <MenuItem value={'ALL'}>Todos</MenuItem>
                        </TextInput>
                        <Box className={classes.searchContainer}>
                            <IconButton onClick={() => searchAction()} className={classes.iconButton} aria-label="search">
                                <SearchIcon/>
                            </IconButton>
                            <Divider className={classes.divider} orientation="vertical" />
                            <InputBase
                                value={inputValue}
                                onChange={(event) => {
                                    setInputVale(event.target.value);
                                }}
                                className={classes.input}
                                placeholder="Buscar por número de télefono o nombre del comercio"
                            />
                        </Box>
                    </Box>
                </Box>
                <Box className={classes.tableContainer}>
                    <TableMatiVerification data={dataMati}/>
                </Box>
            </Box>
        </PageContainer>
    )
}

export default MatiVerification;
