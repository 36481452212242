export const INIT_GET_VOUCHERS = 'VOUCHERS::INIT_GET_VOUCHERS';
export const RESPONSE_GET_VOUCHERS = 'VOUCHERS::RESPONSE_GET_VOUCHERS';
export const ERROR_GET_VOUCHERS = 'VOUCHERS::ERROR_GET_VOUCHERS';
export const CLEAR_GET_VOUCHERS = 'VOUCHERS::CLEAR_GET_VOUCHERS';

export const INIT_PUT_VOUCHERS = 'VOUCHERS::INIT_PUT_VOUCHERS';
export const RESPONSE_PUT_VOUCHERS = 'VOUCHERS::RESPONSE_PUT_VOUCHERS';
export const ERROR_PUT_VOUCHERS = 'VOUCHERS::ERROR_PUT_VOUCHERS';
export const CLEAR_PUT_VOUCHERS = 'VOUCHERS::CLEAR_PUT_VOUCHERS';

export const INIT_GET_VOUCHERS_WEEKLY = 'VOUCHERS::INIT_GET_VOUCHERS_WEEKLY';
export const RESPONSE_GET_VOUCHERS_WEEKLY = 'VOUCHERS::RESPONSE_GET_VOUCHERS_WEEKLY';
export const ERROR_GET_VOUCHERS_WEEKLY = 'VOUCHERS::ERROR_GET_VOUCHERS_WEEKLY';
export const CLEAR_GET_VOUCHERS_WEEKLY = 'VOUCHERS::CLEAR_GET_VOUCHERS_WEEKLY';