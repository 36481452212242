import React from 'react';
import Text from '../../../components/Text';
import Box from '../../../components/Layout/Box';
import useStyles from './CustomerInfo.style';
import LabelValueTextList from '../../../components/LabelValueTextList/LabelValueTextList';

const CustomerInfo = (props) => {
  const classes = useStyles();

  const {
    genre, curp, email, birth_date, phone_number,
  } = props;

  const rows = [
    {
      label: 'Género',
      value: genre === 'M' ? ' Masculino' : ' Femenino',
    },
    {
      label: 'CURP',
      value: curp
    },
    {
      label: 'Correo',
      value: email
    },
    {
      label: 'Fecha de Nacimiento',
      value: birth_date ? birth_date.split('T')[0] : ''
    },
    {
      label: 'Teléfono Celular',
      value: phone_number
    }
  ];

  return (
    <Box className={classes.AboutMeContainer}>
      <Text variant="h3">
        SOBRE MI
      </Text>
      <Box style={{display: 'flex'}}>
        <LabelValueTextList rows={rows}/>
      </Box>
    </Box>
  );
};

export default CustomerInfo;
