import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  textStyle: {
    fontSize: 10,
    letterSpacing: -0.04,
    lineHeight: 1.44,
    color: '#3a3b3f'
  },
  horizontalContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  arrowIcon: {
    fontSize: 15,
    color: '#0253cc'
  },
}));

export default useStyles;
