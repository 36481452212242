import React, { useEffect, useState } from 'react';
import {
  TableContainer, 
  Table, 
  TableBody, 
  TableRow, 
  TableCell, 
  TableFooter, 
  TablePagination,
  CircularProgress,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import useStyles from './CreditExtensions.styles';
import Text from '../../components/Text';
import ArrowedText from '../../components/ArrowedText';
import Button from '../../components/Button';
import { Box } from '../../components/Layout';
import {CreditTableHeader } from './CreditTableHeader';
import CopyButton from '../../components/CopyButton/CopyButton';

export const CreditTable = (props) => {
  const { resources } = props;
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const formatValue = (value) => {
    if (!value) return '';
    return `$${Number(value).toLocaleString('en-US')}`;
  };

  useEffect(() => {
    setPage(0);
  }, [resources]);

  return (
    <Box>
      {resources === undefined && (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
          <CircularProgress  size={55} style={{marginRight: 10}}/>
          <Text>Cargando...</Text>
        </Box>
      )}
      <TableContainer className={classes.tableContainer}>
        <Table aria-label="custom pagination table">
          <CreditTableHeader />
          <TableBody>
            {(rowsPerPage > 0
              ? resources?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : resources
            )?.map((shop, index) => (
              <TableRow key={index} style={{ maxHeight: 43 }}>
                <TableCell>
                  <Text className={classes.textStyle} style={{ textAlign: 'center' }}>
                    {shop.shop_name}
                  </Text>
                </TableCell>
                <TableCell>
                  <Text className={classes.textStyle}>{shop.shopkeeper_name}</Text>
                </TableCell>
                <TableCell>
                  <Text className={classes.textStyle} style={{ textAlign: 'center'}}>
                   {shop.shop_id.substring(shop.shop_id?.length - 6)}
                  <CopyButton elementToCopy={shop?.shop_id || ''} toolTipText={"Copiar id de la cuenta"}/>
                  </Text>
                  
                </TableCell>
                <TableCell>
                  <Text className={classes.textStyle} style={{ textAlign: 'center' }}>
                    {shop.shop_phone}
                  </Text>
                </TableCell>
                <TableCell>
                  <Text className={classes.textStyle} style={{ textAlign: 'center' }}>
              {formatValue(shop.credit_line_amount)}
                  </Text>
                </TableCell>
                <TableCell>
                  <Text className={classes.textStyle} style={{ textAlign: 'center' }}>
                    {shop.status === "IN_PROGRESS" ? "EN PROCESO" : shop.status}
                  </Text>
                </TableCell>
                <TableCell>
                  <Link to={{
                    pathname: `/extensions/details/${shop.credit_line_extension_request_id}/${shop.shop_id}`,
                    state: { shop },
                  }}>
                    <Button>
                      <ArrowedText>
                        Ver detalle
                      </ArrowedText>
                    </Button>
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                colSpan={8}
                count={resources?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                  native: true,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default CreditTable;
