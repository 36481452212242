import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    controls: {
        display: 'flex',
        position: 'relative',
        top: 0,
        width: '100%',
        marginTop: '22px',
        color: '#858d9c',
        alignItems: 'center',
    },
    topBarDetail: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        marginTop: '22px',
        paddingTop: '10px',
        paddingBottom: '10px',
        alignItems: 'center',
        border: '2px solid #e8e8fc',
        borderRadius: '6px',
        justifyContent: 'space-around',
        paddingLeft: '20px',
        paddingRight: '20px'
    },
    containerLogo: {
        display: 'flex',
        position: 'relative',
        top: 0,
        alignItems: 'center',
    },
    userLogo: {
        width: 40,
        height: 40,
        overflow: 'hidden',
        borderRadius: '50%',
        marginRight: '10px',
        border: '2px solid #ea4b4b',
    },
    mainTextBar: {
        fontWeight: 'bold',
    },
    secondTextBarContainer: {
        display: 'flex',
        position: 'relative',
        top: 0,
        width: '100%',
        marginTop: '4px',
        color: '#858d9c',
        alignItems: 'center',
    },
    subMenuSectionSelector: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        marginTop: '22px',
        marginBottom: '22px'
    },
    buttonSubMenuContainer: {
        marginRight: '20px'
    },
    subMenuTextOn: {
        fontWeight: 'bold',
        color: '#0253cc',
        paddingBottom: '6px',
        borderBottom: '2px solid #0253cc',
    },
    subMenuTextOff: {
        fontWeight: 'bold',
        color: '#858d9c'
    },
    creditBar: {
        width: '100%',
        display: 'flex',
        position: 'relative',
        top: 0,
        alignItems: 'center',
        justifyContent: 'space-around'
    },
    creditBarElementFirst: {
        display: 'flex',
        flexDirection: 'row',
        padding: '15px',
        alignItems: 'center',
        border: '2px solid #e8e8fc',
        borderRadius: '6px',
        justifyContent: 'space-around',
    },
    creditBarSubElementFirst: {
        display: 'flex',
        position: 'relative',
        top: 0,
        alignItems: 'center',
        marginTop: '10px'
    },
    creditBarSubElementSecond: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '60px',
        alignItems: 'flex-end'
    },
    balanceTextCreditInformation: {
        fontWeight: 'bold',
        fontSize: '18px'
    },
    informationTextCreditInformation: {
        fontSize: '12px',
        color: 'rgba(102, 110, 117, 0.79)',
        marginRight: '10px'
    },
    informationTextCreditInformationBold: {
        fontWeight: 'bold',
        fontSize: '12px',
    },
    creditBarElementSecond: {
        padding: '15px',
        alignItems: 'center',
        border: '2px solid #e8e8fc',
        borderRadius: '6px',
    },
    graphicCreditBarLeft: {
        height: '15px', 
        backgroundColor: '#cdd3dc',
        borderEndStartRadius: '7px',
        borderStartStartRadius: '7px'
    },
    graphicCreditBarRight: {
        height: '15px', 
        backgroundColor: '#0253cc',
        borderStartEndRadius: '7px',
        borderEndEndRadius: '7px'
    },
    creditBarSubElementFirstSecondElement: {
        display: 'flex',
        position: 'relative',
        top: 0,
        alignItems: 'center',
        marginRight: '60px'
    },
    creditBarSubElementFirstSecondSubElement: {
        display: 'flex',
        position: 'relative',
        top: 0,
        alignItems: 'center',
    },
    informationTextCreditInformationThird: {
        fontSize: '12px',
        color: 'rgba(102, 110, 117, 0.79)',
    },
    informationTextCreditInformationBoldThird: {
        fontWeight: 'bold',
        fontSize: '18px',
        marginTop: '10px'
    },
    creditBarSubElementFirstThirdElement: {
        marginRight: '60px'
    },
    titleInformationCreditTable: {
        fontWeight: 'bold',
        marginTop: '40px'
    },
    tableContainer: {
        width: '100%',
        marginTop: 22,
    },
    tableContainerPaginator: {
        borderRadius: 2.9,
        border: '1px solid #e8e8fb',
    },
    toolbar: {
        justifyContent: 'center',
    },
    spacer: {
        flex: 0,
    },
    transactionContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-around'
    },
    transactionBoxContainer: {
        paddingTop: '10px',
        paddingBottom: '10px',
        border: '2px solid #e8e8fc',
        borderRadius: '6px',
        width: '40%',
        minHeight: '500px'
    },
    statusText: {
        display: 'flex',
        position: 'relative',
        top: 0,
        width: '100%',
        marginTop: '4px',
        color: '#858d9c',
        alignItems: 'center',
    },
    doubleTableContainerPaginator: {
        borderRadius: 2.9,
        border: '1px solid #e8e8fb',
        maxHeight: '250px'
    },
    transactionTitleContainer: {
        display: 'flex',
        flexDirection: 'row',
        padding: '10px',
        alignItems: 'center',
    },
    transactionTitleText: {
        fontSize: '14px',
        fontWeight: 600
    },
    transactionDateText: {
        fontSize: '12px',
        color: '#4a4b4b',
        fontWeight: 500
    },
    favorContianer: {
        display: 'flex',
        borderRadius: '12px',
        justifyContent: 'center'
    },
    switchButton: {
        marginBottom: 20
    },
    disallowDisbusment: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    containerDisallowDisbusment: {
        display: 'flex',
        flexDirection: 'column',
        width: '750px',
        borderRadius: 20,
        borderWidth: 10,
        paddingTop: 20,
        paddingBottom: 20,
        backgroundColor: '#D1D1D1',
    },
    modalHeaderContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 20,
        paddingLeft: 20,
        paddingRight: 20,
    },
    modalTitle: {
        fontSize: '18px',
        fontWeight: 600
    },
    divisorModal: {
        width: '100%',
        height: '1px',
        backgroundColor: '#666e75'
    },
    modalBodyContainer: {
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: 20,
        paddingRight: 20,
        marginTop: 20
    },
    modalSubTitle: {
        color: '#666e75',
        fontSize: '16px'
    },
    modalBodyCheckContainer: {
        display: 'flex',
        flexDirection: 'row',
        paddingLeft: 20,
        paddingRight: 20,
        marginTop: 20
    },
    modalCheckContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '50%',
    },
    checkElement: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    modalBottomContainer: {
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: 20,
        paddingRight: 20,
        marginTop: 20
    },
    inputModal: {
        width: '100%',
        padding: 10,
        fontSize: '12px',
        height: '200px',
        border: '1px solid #666e75',
        borderRadius: 5,
        marginTop: 20,
    },
    modalButtonContainer: {
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: 20,
        paddingRight: 20,
        marginTop: 20,
        alignItems: 'flex-end'
    },
    buttonModal: {
        width: '65%',
        backgroundColor: '#0253cc',
        borderRadius: 5
    },
    textButtonModal: {
        color: '#FFFFFF',
        fontSize: '12px',
        fontWeight: 'inherit'
    },
    transactionHeaderSelected: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '50%',
        paddingTop: 10,
        paddingBottom: 10,
        border: 'solid #dee5ee',
        borderWidth: '1px',
        borderRadius: 20,
        cursor: 'pointer'
    },
    transactionHeaderContainer: {
        display: 'flex',
        backgroundColor: '#f7f9fb',
        alignItems: 'center',
        marginLeft: 20,
        marginRight: 20,
        borderRadius: 20,
        cursor: 'pointer'
    },
    transactionHeaderNonSelected: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '50%',
        paddingTop: 10,
        paddingBottom: 10,
        color: '#8696a8'
    }
}));

export default useStyles;