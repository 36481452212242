import React from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { Box } from '../Layout';
import Text from '../Text';
import useStyles from './ValidateFacadeComponent.styles';
import CustomModal from '../CustomModal/CustomModal';
import Button from '../Button';

const ValidateFacadeComponent = ({
  isOpenModal,
  setOpenModal,
  onClickModalBtn,
  isDisabledModalBtn,
  onClickLink,
  facadeStatus,
  handleChangeFacadeStatus,
  defaultFacadeStatus,
}) => {
  const classes = useStyles();

  const facadeStatusContainerClass = defaultFacadeStatus
    ? classes.facadeStatusContainerTrue
    : classes.facadeStatusContainerFalse;

  const facadeStatusText = defaultFacadeStatus ? 'Verdadero' : 'Falso';

  return (
    <Box className={classes.container}>
      <Text className={classes.text}>Es fachada:</Text>
      {defaultFacadeStatus !== undefined && (
        <Box className={`${classes.facadeStatusContainer} ${facadeStatusContainerClass}`}>
          <Text className={classes.facadeStatusText}>{facadeStatusText}</Text>
        </Box>
      )}
      <a
        target="_blank"
        className={classes.link}
        onClick={onClickLink}
      >
        editar valoración
      </a>
      <CustomModal
        title="Cambiar valoración de fachada"
        open={isOpenModal}
        setOpen={setOpenModal}
        closeButton
      >
        <FormControl className={classes.formContainer}>
          <Box className={classes.formWrapper}>
            <Text className={classes.facadeSelectTitle}>Elige la valoración de la fachada</Text>
            <Box className={classes.selectWrapper}>
              <InputLabel style={{ top: 30 }}>¿Es una foto de fachada?</InputLabel>
              <Select
                value={facadeStatus}
                onChange={handleChangeFacadeStatus}
              >
                <MenuItem value={false}>Falso</MenuItem>
                <MenuItem value>Verdadero</MenuItem>
              </Select>
            </Box>
            <Box className={classes.buttonsContainer}
            >
              <Button
                variant="text"
                color="secondary"
                children="Cancelar"
                onClick={() => setOpenModal(!isOpenModal)}
              />
              <Button
                variant="contained"
                color="secondary"
                children="Confirmar cambio"
                onClick={onClickModalBtn}
                isDisabled={isDisabledModalBtn}
              />
            </Box>
          </Box>
        </FormControl>
      </CustomModal>
    </Box>
  );
};

ValidateFacadeComponent.propTypes = {
  isOpenModal: PropTypes.bool.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  onClickModalBtn: PropTypes.func.isRequired,
  isDisabledModalBtn: PropTypes.bool.isRequired,
  onClickLink: PropTypes.func.isRequired,
  facadeStatus: PropTypes.bool.isRequired,
  handleChangeFacadeStatus: PropTypes.func.isRequired,
  defaultFacadeStatus: PropTypes.bool.isRequired,
};

export default ValidateFacadeComponent;
