import React from "react";

import LandingPageUI from "./LandingPage";
import useStyles from "./LandingPage.styles";

function LandingPage() {
    const classes = useStyles();

    return <LandingPageUI
        classes={classes}
    />
}

export default LandingPage;