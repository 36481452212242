import * as ACTION from './utils.actionTypes';

const utilsData = {
  loading: {
    POST_SHORT_URL: false,
  },
  error: {
    POST_SHORT_URL: null,
  },

  shortUrlData: {},

};

const utilsReducer = (state = utilsData, action) => {
  switch (action.type) {
    case ACTION.INIT_POST_SHORT_URL:
      return { ...state, loading: { ...state.loading, POST_SHORT_URL: true }, error: { ...state.error, POST_SHORT_URL: null } };
    case ACTION.RESPONSE_POST_SHORT_URL:
      return {
        ...state, loading: { ...state.loading, POST_SHORT_URL: false }, error: { ...state.error, POST_SHORT_URL: null }, shortUrlData: action.result,
      };
    case ACTION.ERROR_POST_SHORT_URL:
      return { ...state, loading: { ...state.loading, POST_SHORT_URL: false }, error: { ...state.error, POST_SHORT_URL: action.error } };
    case ACTION.RESPONSE_POST_SHORT_URL:
      return { ...state, shortUrlData: action.result };
    case ACTION.CLEAR_POST_SHORT_URL:
      return { ...state, shortUrlData: {} };

    case ACTION.CLEAR_ERRORS:
      return { ...state, error: utilsData.error };
    default:
      return state;
  }
};

export default utilsReducer;
