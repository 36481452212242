import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  tableContainer: {
    borderRadius: 2.9,
    border: '1px solid #e8e8fb',
  },
  toolbar: {
    justifyContent: 'center',
  },
  spacer: {
    flex: 0,
  },
  textStyle: {
    fontSize: 10,
    letterSpacing: -0.04,
    lineHeight: 1.44,
  },
  modalContent: {
    display: 'block',
    position: 'absolute',
    width: '30%',
    height: '30%',
    left: '40%',
    top: '40%',
    padding: 20,
    borderRadius: 20,
  },
  modalTitle: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  snackbar: {
    pointerEvents: 'auto',
  },
}));

export default useStyles;
