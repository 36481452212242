import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '../../components/Button';
import nfImg from './assets/404-image.jpg';
import { Box } from '../../components/Layout';
import useStyles from './NotFoundPage.style';

export const NotFoundPage = (props) => {
  const handleBack = () => {
    const { history } = props;
    history.goBack();
  };
  const classes = useStyles();
  return (
    <Box className={classes.page}>
      <Box className={classes.container}>
        <img src={nfImg} alt="not-found-img" />
        <Button variant="contained" color="primary" onClick={handleBack}>Back</Button>
      </Box>
    </Box>
  );
};


NotFoundPage.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  notFoundState: state.notFoundState,
});

export default connect(mapStateToProps)(NotFoundPage);
