import * as ACTION from './kyc.actionTypes';
import * as kycService from '../services/kyc.service';

export const getMatiVerification = (contract_id) => async (dispatch) => {
    dispatch({
        type: ACTION.INIT_GET_MATI_VERIFICATION,
    });
    try {
        const getMatiVerificationData = await kycService.getMatiVerification(contract_id);
        if (getMatiVerificationData) {
            dispatch({
                type: ACTION.RESPONSE_GET_MATI_VERIFICATION,
                result: getMatiVerificationData,
            });
        }
    } catch (error) {
        dispatch({
            type: ACTION.ERROR_GET_MATI_VERIFICATION,
            error,
        });
    }
};

export const clearGetMatiVerification = () => async (dispatch) => {
    dispatch({
        type: ACTION.CLEAR_GET_MATI_VERIFICATION,
    });
};

export const putMatiVerification = (contract_id, body) => async (dispatch) => {
    dispatch({
        type: ACTION.INIT_PUT_MATI_VERIFICATION,
    });
    try {
        const putMatiVerificationData = await kycService.putMatiVerification(contract_id, body);
        if (putMatiVerificationData) {
            dispatch({
                type: ACTION.RESPONSE_PUT_MATI_VERIFICATION,
                result: putMatiVerificationData,
            });
        }
    } catch (error) {
        dispatch({
            type: ACTION.ERROR_PUT_MATI_VERIFICATION,
            error,
        });
    }
};

export const clearPutMatiVerification = () => async (dispatch) => {
    dispatch({
        type: ACTION.CLEAR_PUT_MATI_VERIFICATION,
    });
};
