import React, { useEffect, useState } from 'react';
import {
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from './OrderedTableHead.style';
import Text from '../../Text';
import { getShopCredit } from '../../../containers/Credit/creditActions';
import * as ACTIONS from '../../../containers/Registers/actionTypes';

// TODO: Remove implementation to generalize component

const headCells = [
  {
    id: 'account_type_id', numeric: false, disablePadding: true, label: 'Tipo de crédito',
  },
  {
    id: 'shop_id', numeric: false, disablePadding: true, label: 'ID del usuario',
  },
  {
    id: 'shop_name', numeric: true, disablePadding: false, label: 'Nombre de la tienda',
  },
  {
    id: 'shopkeeper_name', numeric: true, disablePadding: false, label: 'Nombre del responsable',
  },
  {
    id: 'shop_phone_number', numeric: true, disablePadding: false, label: 'Teléfono',
  },
  {
    id: 'loan_amount', numeric: true, disablePadding: false, label: 'Monto solicitado',
  },
  {
    id: 'loan_installment', numeric: true, disablePadding: false, label: 'Aporte semanal',
  },
  {
    id: 'detail', numeric: true, disablePadding: false, label: 'Ver detalle',
  },
];

export const OrderedCreditTableHead = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { page, orderBy, sort } = useSelector((state) => state.registersState);

  useEffect(() => {
    dispatch(getShopCredit(page));
  }, [orderBy, sort]);

  return (
    <TableHead className={classes.tableHead}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            align="center"
            scope="col"
            sort={orderBy === headCell.id ? sort : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? sort : 'asc'}

              onClick={() => {
                if (orderBy === headCell.id) {
                  dispatch({
                    type: ACTIONS.SET_STATE,
                    label: 'sort',
                    value: (sort === 'asc' ? 'desc' : 'asc'),
                  });
                  dispatch({
                    type: ACTIONS.SET_STATE,
                    label: 'page',
                    value: 0,
                  });
                } else {
                  dispatch({
                    type: ACTIONS.SET_STATE,
                    label: 'orderBy',
                    value: headCell.id,
                  });
                  dispatch({
                    type: ACTIONS.SET_STATE,
                    label: 'page',
                    value: 0,
                  });
                }
              }}
            >
              <Text className={classes.titleLabel}>{headCell.label}</Text>
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default OrderedCreditTableHead;
