import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  controls: {
    display: 'flex',
    position: 'relative',
    top: 0,
    width: '100%',
    marginTop: '22px',
    color: '#858d9c',
    alignItems: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '33%',
    height: '55%',
    marginTop: '22px',
    paddingTop: '10px',
    paddingBottom: '10px',
    alignItems: 'left',
    border: '2px solid #e8e8fc',
    borderRadius: '6px',
    paddingLeft: '20px',
    paddingRight: '20px',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '33%',
    marginTop: '22px',
    paddingTop: '10px',
    paddingBottom: '10px',
    alignItems: 'left',
    border: '2px solid #e8e8fc',
    borderRadius: '6px',
    paddingLeft: '20px',
    paddingRight: '20px',
    marginLeft: '50px',
  },
  rowInfo: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '20px',
    marginBottom: '10px',
    justifyContent: 'space-between',
  },
  wrapper: {
    display: 'flex', flexDirection: 'row', width: '100%',
  },
  formElement: {
    marginTop: 20,
    marginBottom: 35,
    marginRight: 10,
    marginLeft: 10,
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    marginTop: 20,
    marginBottom: 20,
  },
  modal: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px',
    textAlign: 'center',
    borderRadius: '5px',
    margin: 'auto',
    height: '308px',
    width: '412px',
  },
}));

export default useStyles;
