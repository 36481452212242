import * as ACTION from '../containers/Customers/actionTypes';

const initialState = {
  isFetchingCustomers: false,
  customers: [],
  displayedCustomer: undefined,
  displayedHistories: undefined,
  links: [],
  next: undefined,
  page: 1,
  pages: undefined,
  total_elements: undefined,
  searchString: '',
  sort: 'desc',
  orderBy: 'name',
  histories: [],
  filter: ' ',
};


const customersReducer = (state = initialState, action) => {

    switch (action.type) {
        case ACTION.FETCH_CUSTOMERS_INIT:
          return { ...state, isFetchingCustomers: true };
        case ACTION.FETCH_CUSTOMERS_SUCCESS:
          return {
            ...state, loadingCustomers: false, error: null,
          };
        case ACTION.FETCH_CUSTOMERS_ERROR:
          return { ...state, loadingCustomers: false, error: action.error };
        case ACTION.SET_STATE:
          return {
            ...state, [action.label]: action.value,
          };
        default:
          return { ...state };
      }
}

export default customersReducer
