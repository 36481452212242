import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { useRouteMatch } from "react-router";
import { Route, Switch } from "react-router-dom";

import RegistrationInfo from "../RegistrationInfo/RegistrationInfo";
import CustomerInfo from "../CustomerInfo/CustomerInfo";
import CustomerLaboralInfo from "../CustomerLaboralInfo/CustomerLaboralInfo";
import VendorValidationInfo from "../VendorValidationInfo/VendorValidationInfo";
import CustomerResidenceInfo from "../CustomerResidenceInfo/CustomerResidenceInfo";
import IdentityValidationInfo from "../IdentityValidationInfo/IdentityValidationInfo";
import CustomerReferenceInfo from "../ReferenceInfo/CustomerReferenceInfo";
import ConfirmationInfo from "../ConfirmationInfo/ConfirmationInfo";
import PhoneValidationInfo from "../../../components/PhoneValidationInfo/PhoneValidationInfo";
import Button from "../../../components/Button/Button";
import ModalReactivateCollateral from "../../../components/ModalReactivateCollateral";
import CopyButton from "../../../components/CopyButton/CopyButton";
import ScoreIndicator from "../../../components/ScoreIndicator";
import { useDispatch, useSelector } from "react-redux";
import {
  postResumeRegister,
  putUpdateMaritalStatus,
  putUpdateRequirements,
  getUsers,
} from "../../../reducers/users.action";
import { putUpdateBusinessType } from "../../../reducers/business.action";
import { getBusinessType } from "../../../reducers/business.action";
import { getAvalStatus, postWishNewAval } from "../../../reducers/avals.action";
import {putTaxPayerId} from "../../../reducers/modifications.action"
import AdminAction from "../../../components/AdminAction/AdminAction";
import TextInput from "../../../components/TextInput";
import validateRFC from "../../../constants/validateRFC";
import { postPresaleAdvisorTask } from "../../../reducers/presaleadvisors.action";
import { postReevaluateInventory } from "../../../reducers/inventory.action";
import ApprovalTableForm from "../../../components/ApprovalTableForm/ApprovalTableForm";
import { getInventoryReasonsCatalog } from "../../../reducers/catalog.action";
import {postShortUrl} from "../../../reducers/utils.action";
import { copyTextToClipboard } from "../../../constants/copyContent";
import { getShokeeperDetailById } from "../../Shop/ShopPage.actions";

const GeneralTab = ({
  registrationData,
  customerType,
  kycData,
  phone_number,
  customer,
  isOpenApprovalTable,
  checkboxValues,
  handleCheckboxChange,
  handleVeredictInputChange,
  veredictInputValues,
  shopkeeperInfo,
  veredictInputErrors,
}) => {
  const { path } = useRouteMatch();
  const dispatch = useDispatch();
  let personalData;
  let phoneNumberValidation;
  let laboralInfo;
  let referencesInfo;
  let vendorValidation;
  let residentialInfo;
  let identityValidation;
  if (customer) {
    personalData = "personal_data" in customer ? customer.personal_data : {};
    phoneNumberValidation =
      "phone_number_validation" in customer
        ? customer.phone_number_validation
        : {};
    laboralInfo = "laboral_data" in customer ? customer.laboral_data : {};
    referencesInfo = "references" in customer ? customer.references : {};
    vendorValidation =
      "yofio_validation" in customer ? customer.yofio_validation : {};
    residentialInfo = customer.address;
    identityValidation = customer.mati_data;
  }
  const { avalStatus, error } = useSelector((state) => state.avalsReducer);
  const { loading, error: usersError, usersResponseData } = useSelector((state) => state.usersReducer);

  const { businessType, loading: loadingBusiness, error: businessError } = useSelector(
    (state) => state.businessReducer
  );

  const { loading : modificationsLoading, error: modificationsError} = useSelector(
    (state) => state.modificationsReducer
  );

  const { loading : presaleAdvisorLoading, error: presaleAdvisorError} = useSelector(
    (state) => state.presaleAdvisorsReducer
  );
  
  const { inventoryData, error: inventoryError, loading: inventoryLoading } = useSelector(
    (state) => state.inventoryReducer
  );

  const {shortUrlData} = useSelector((state) => state.utilsReducer);

  const { inventoryReasonsCatalog} = useSelector(
    (state) => state.catalogReducer
  );
  
  const {
    shokeeper_detail,
  } = useSelector((state) => state.shopState);

  const user_type = "SK";
  const [isOpenMaritalStatusModal, setIsOpenMaritalStatusModal] = useState(false);
  const [isOpenBusinessTypeModal, setIsOpenBusinessTypeModal] = useState(false);
  const [isOpenRequirementsModal, setIsOpenRequirementsModal] = useState(false);
  const [isOpenRfcModal, setIsOpenRfcModal] = useState(false);
  const [maritalStatus, setMaritalStatus] = useState("");
  const [businessTypeValue, setBusinessTypeValue] = useState("");
  const [rfcValue,setRfcValue] = useState("");
  const [selectedRfc, setSelectedRfc] = useState("");
  const [selectedPhone, setSelectedPhone] = useState("");
  const [usersInfo, setUsersInfo] = useState([]);
  const [isCheckedCollateral, setIsCheckedCollateral] = useState(false);
  const [isCheckedPartner, setIsCheckedPartner] = useState(false);
  const [isOpenRequirementsSnackbar, setIsOpenRequirementsSnackbar] = useState(false);
  const [isOpenMaritalStatusSnackbar, setIsOpenMaritalStatusSnackbar] = useState(false);
  const [isOpenBusinessTypeSnackbar, setIsOpenBusinessTypeSnackbar] = useState(false);
  const [isOpenRfcSnackbar, setIsOpenRfcSnackbar] = useState(false);
  const [isOpenValidationSnackbar, setIsOpenValidationSnackbar] = useState(false);
  const [isOpenSecondChanceSnackbar, setIsOpenSecondChanceSnackbar] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSecondError, setIsSecondError] = useState(false);
  const [isReactivateCollateral, setIsReactivateCollateral] = useState(false);
  const [isOpenResumeRegisterSnackbar, setIsOpenResumeRegisterSnackbar] = useState(false);
  const [dataUsers, setDataUsers] = useState({});
  const [bitlyUrl, setBitlyUrl] = useState("");
  const [dataRiskAssessment, setDataRiskAssessment] = useState({});

  const handleChangeMaritalStatus = (event) => {
    setMaritalStatus(event.target.value);
  };
  const handleChangeBusinessType = (event) => {
    setBusinessTypeValue(event.target.value);
  };

  const handleSelectRfc = (event) => {
    const selectedValue = event.target.value;
    setSelectedRfc(selectedValue);
    setRfcValue(selectedValue);
    const selectedUserInfo = usersInfo.find((userInfo) => userInfo.rfc === selectedValue);

    if (selectedUserInfo) {
      setSelectedPhone(selectedUserInfo.phone);
    } else {
      setSelectedPhone("");
    }
  };
  
  const handleInputChange = (event) => {
    setRfcValue(event.target.value);
  };
  
  let validatedRFC = validateRFC(rfcValue);
  const handleChangeCheckCollateral = (event) => {
    setIsCheckedCollateral(event.target.checked);
  };
  const handleChangeCheckPartner = (event) => {
    setIsCheckedPartner(event.target.checked);
  };
  const updateMaritalStatus = (userId, userType) => {
    let body = {
      new_marital_status: maritalStatus,
    };
    dispatch(putUpdateMaritalStatus(userType, userId, body)).then(handleOpenMaritalStatusSnackbar());
  };

  const updateBusinessType = (userType, userId) => {
    let body = {
      new_business_type: businessTypeValue,
    };
    dispatch(putUpdateBusinessType(userType, userId, body)).then(handleOpenBusinessTypeSnackbar());
  };
  const updateRfc = (phoneNumber,newRfc, userId, userType) => {
    const userInfo = usersInfo.find((info) => info.rfc === selectedRfc);
    const isAval = userInfo?.isAval || false;
    let body = {
      aval_phone_number: isAval ? phoneNumber : undefined,
      new_taxpayer_id: newRfc,
      account_id: userId,
      account_type_id: userType,
    };
    dispatch(putTaxPayerId(body)).then(handleOpenRfcSnackbar());
  }

  const updateRequirements = (userId) => {
    let body = {
      collateral: isCheckedCollateral,
      partner: isCheckedPartner,
    };
    dispatch(putUpdateRequirements(userId, body)).then(handleOpenRequirementsSnackbar());
  };

  const presaleAdvisorTask = () => {
    let body = {
      task_type: "VALIDATION",
      account_id: registrationData.id,
      account_type_id: user_type
    };
    let bodyReevaluate = {
      reason_id: validationVisitInfo?.id
    }
    Promise.all([
      dispatch(postPresaleAdvisorTask(body)),
      dispatch(postReevaluateInventory(registrationData.id,bodyReevaluate))
    ])
      .then(() => handleOpenValidationSnackbar());
  }

  const resumeRegister = () => {
    let body = {
      requested_by: undefined
    };
    dispatch(postResumeRegister(user_type,registrationData?.id,body)).then(handleOpenResumeRegister());
  }

  const shortUrl =  (url) => {
    let body = { url: url };
        dispatch(postShortUrl(body));
  
  };
  useEffect(() => {
    if (shortUrlData?.data?.short_url) {
      copyTextToClipboard(shortUrlData.data.short_url);
    }
  }, [shortUrlData]);

  const handleOpenRequirementsSnackbar = () => {
    setIsOpenRequirementsSnackbar(true);
    setIsOpenRequirementsModal(false);
    setTimeout(() => {
    window.location.reload();
    }, 3000);
  };
  const handleOpenMaritalStatusSnackbar = () => {
    setIsOpenMaritalStatusSnackbar(true);
    setIsOpenMaritalStatusModal(false);
    setTimeout(() => {
      window.location.reload();
    }, 3000);
  };
  const handleOpenBusinessTypeSnackbar = () => {
    setIsOpenBusinessTypeSnackbar(true);
    setIsOpenBusinessTypeModal(false);
    setTimeout(() => {
      window.location.reload();
    }, 3000);
  };
  const handleOpenRfcSnackbar = () => {
    setIsOpenRfcSnackbar(true);
    setIsOpenRfcModal(false);
    setTimeout(() => {
      window.location.reload();
    }, 3000);
  };

  const handleOpenResumeRegister = () => {
    setTimeout(() => {
      setIsOpenResumeRegisterSnackbar(true);
    }, 500);
    setTimeout(() => {
      window.location.reload();
    }, 4500);
  };

  const handleOpenValidationSnackbar = () => {
    setIsOpenValidationSnackbar(true);
    if(isSecondError){
      setIsOpenSecondChanceSnackbar(true);
    }
    setTimeout(() => {
      setIsOpenValidationSnackbar(false);
      setIsOpenSecondChanceSnackbar(false);
    }, 5000);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsOpenRequirementsSnackbar(false);
    setIsOpenMaritalStatusSnackbar(false);
    setIsOpenBusinessTypeSnackbar(false);
    setIsOpenRfcSnackbar(false);
    setIsOpenResumeRegisterSnackbar(false);
    setIsOpenSecondChanceSnackbar(false);
  };

  const replaceAval = (userId) => {
    let body = {
      wish_new_aval: true,
    };
    dispatch(postWishNewAval(userId, body)).then(handleOpenRequirementsSnackbar());
  };

  const handleClickNewAval = () => {
    replaceAval(registrationData.id);
    setIsOpenRequirementsModal(false);
  };

  useEffect(() => {
    dispatch(getBusinessType());
    dispatch(getInventoryReasonsCatalog());
  }, []);

  useEffect(() => {
    if(registrationData) {
      dispatch(getShokeeperDetailById(registrationData?.id));
    }
  }, [registrationData]);

  useEffect(() => {
    if(Object.keys(shokeeper_detail).length !== 0){
      if(shokeeper_detail?.data && 
        shokeeper_detail.data?.registration_data &&
        shokeeper_detail.data.registration_data?.risk_assessment        
      ) {
        setDataRiskAssessment(shokeeper_detail.data.registration_data.risk_assessment[0]);
      }
    }
  }, [shokeeper_detail]);

  const validationVisitInfo = inventoryReasonsCatalog?.data
  ?.find((reason) => reason.name === "Validación de Campo");

  useEffect(() => {
    if (error?.POST_WISH_NEW_AVAL) {
      if (error?.POST_WISH_NEW_AVAL != null) {
        setIsError(true);
      }
    }
  }, [error?.POST_WISH_NEW_AVAL]);

  useEffect(() => {
    if (usersError?.PUT_UPDATE_MARITAL_STATUS) {
      if (usersError?.PUT_UPDATE_MARITAL_STATUS != null) {
        setIsError(true);
      }
    }
  }, [usersError?.PUT_UPDATE_MARITAL_STATUS]);

  useEffect(() => {
    if (usersError?.POST_RESUME_REGISTER) {
      if (usersError?.POST_RESUME_REGISTER != null) {
        setIsError(true);
      }
    }
  }, [usersError?.POST_RESUME_REGISTER]);

  useEffect(() => {
    if (businessError?.PUT_UPDATE_BUSINESS_TYPE) {
      if (businessError?.PUT_UPDATE_BUSINESS_TYPE != null) {
        setIsError(true);
      }
    }
  }, [businessError?.PUT_UPDATE_BUSINESS_TYPE]);

  useEffect(() => {
    if (usersError?.PUT_UPDATE_REQUIREMENTS) {
      if (usersError?.PUT_UPDATE_REQUIREMENTS != null) {
        setIsError(true);
      }
    }
  }, [usersError?.PUT_UPDATE_REQUIREMENTS]);

  useEffect(() => {
    if (modificationsError?.PUT_TAXPAYER_ID) {
      if (modificationsError?.PUT_TAXPAYER_ID != null) {
        setIsError(true);
      }
    }
  }, [modificationsError?.PUT_TAXPAYER_ID]);

  useEffect(() => {
    if (presaleAdvisorError?.POST_PRESALE_ADVISORS_TASK) {
      if (presaleAdvisorError?.POST_PRESALE_ADVISORS_TASK != null) {
        setIsError(true);
      }
    }
  }, [presaleAdvisorError?.POST_PRESALE_ADVISORS_TASK]);

  useEffect(() => {
    if (inventoryError?.POST_REEVALUATE_INVENTORY) {
      if (inventoryError?.POST_REEVALUATE_INVENTORY != null) {
        setIsSecondError(true);
      }
    }
  }, [inventoryError?.POST_REEVALUATE_INVENTORY]);
  
  useEffect(() => {
    if(registrationData !== undefined && registrationData !== null) {
      dispatch(getAvalStatus(user_type, registrationData?.id));
      setIsCheckedCollateral(avalStatus?.data?.collateral?.needed);
      setIsCheckedPartner(avalStatus?.data?.partner?.needed);
    }
  }, [
    avalStatus?.data?.collateral?.needed || avalStatus?.data?.partner?.needed,
  ]);

  useEffect(() => {
    if (registrationData !== undefined && registrationData !== null) {
      const newUsersInfo = []; 
      if (registrationData?.avals?.length !== 0 && registrationData?.avals !== null && registrationData?.avals !== undefined ) {
        const sortedAvals = registrationData?.avals?.sort((a, b) => {
          return a.unykoo_id >= b.unykoo_id ? 1 : -1;
        });
        sortedAvals.forEach((aval) => {
          const { name, lastname, rfc, phone } = aval;
          const avalInfo = {
            name,
            lastname,
            rfc,
            phone,
            isAval: true
          };
          newUsersInfo.push(avalInfo);
        });
      }
      const {name,last_name,rfc, phone_number} = registrationData;
      const ownerInfo = {name,lastname: last_name,rfc, phone: phone_number, isAval:false};
      newUsersInfo.push(ownerInfo);
      setUsersInfo(newUsersInfo);
      dispatch(getUsers(registrationData.id, user_type));
    }
  }, [registrationData]);

  useEffect(() => {
    if(Object.keys(usersResponseData).length !== 0){
      if(usersResponseData?.data && usersResponseData.data) {
        setDataUsers(usersResponseData.data);
      }
    }
  }, [usersResponseData])
  return (
    <Box display="flex" flexDirection="row">
     <Box flex={1}>
      <Box style={{position: "absolute", marginLeft: '450px'}}>
        <ScoreIndicator score={dataRiskAssessment?.probability} textScore={dataRiskAssessment?.tier}/>
      </Box>
     {registrationData && customerType === "shopkeeper" && (
      <Box>
        {(registrationData.request || {}).deal_id && (
          <div style={{flexDirection: 'row'}}>
            <a
              target={"_blank"}
              style={{ fontSize: "larger" }}
              href={`${process.env.REACT_APP_PIPEDRIVE_PATH}/${
                (registrationData.request || {}).deal_id
              }`}
            >
              Enlace a Pipedrive
            </a>
            <CopyButton isRequiredBitly toolTipText='Copiar liga a pipedrive'onClick={() => shortUrl(`${process.env.REACT_APP_PIPEDRIVE_PATH}/${
                (registrationData.request || {}).deal_id
              }`)}/>
            <br />
          </div>
        )}
        {kycData?.status &&
          kycData?.status !== "PROCESSING" &&
          kycData?.verification_url && (
            <div style={{flexDirection: 'row'}}>
            <a
            target={"_blank"}
            style={{ fontSize: "larger" }}
            href={kycData?.verification_url}
          >
            Enlace a Mati
          </a>
           <CopyButton isRequiredBitly toolTipText='Copiar liga a MATI'onClick={() => shortUrl(kycData?.verification_url)}/>
          <br />
            
            </div>
          )}
          {(
              <div style={{flexDirection: 'row'}}>
                <a
                  target={"_blank"}
                  style={{ fontSize: "larger" }}
                  href={`${process.env.REACT_APP_NEW_MATI_WEB_FLOW}?contract_id=${dataUsers.contract_id}&person_name=${encodeURIComponent(registrationData.name + " " + registrationData.last_name)}&person_type=ACCOUNT`}
                >
                  Enlace a Firma Titular
                </a>
              <CopyButton isRequiredBitly toolTipText='Copiar liga a firma de titular'onClick={() => shortUrl(`${process.env.REACT_APP_NEW_MATI_WEB_FLOW}?contract_id=${dataUsers.contract_id}&person_name=${encodeURIComponent(registrationData.name + " " + registrationData.last_name)}&person_type=ACCOUNT`)}/>
                <br />
              </div>
            )}
              
        {(registrationData.request || {}).next_step &&
          ((registrationData.request || {}).next_step === "PROCESSING_KYC" ||
            (registrationData.request || {}).next_step === "KYC_REQUEST") && (
            <div style={{flexDirection: 'row'}}>
              <a
                target={"_blank"}
                style={{ fontSize: "larger" }}
                href={`${
                  process.env.REACT_APP_WEB_MATI
                }&metadata=${encodeURIComponent(
                  `{"phoneNumber":"${phone_number}"}`
                )}`}
              >
                Reintentar Mati
              </a>
              <CopyButton isRequiredBitly toolTipText='Copiar liga a reintento de MATI'onClick={() => shortUrl(`${
                  process.env.REACT_APP_WEB_MATI
                }&metadata=${encodeURIComponent(
                  `{"phoneNumber":"${phone_number}"}`
                )}`)}/>
              <br />
            </div>
          )}
        {(registrationData?.avals || []).map((aval) => {
          let bureauUrl = `${
            process.env.REACT_APP_WEB_REG_LP
          }send-nip-partner?user_type_id=SK&user_id=${
            registrationData.id
          }&to=${aval.aval_type}&phone_number=${encodeURIComponent(
            aval.phone
          )}`;
          let signatureUrl = `${process.env.REACT_APP_NEW_MATI_WEB_FLOW}?contract_id=${dataUsers.contract_id}&person_name=${encodeURIComponent(aval.name + " " + aval.lastname)}&person_type=${aval.aval_type}`;
          if (aval.aval_type === "COLLATERAL" && aval.bureau_decision === "REJECTED") {
            return
          }
          let param_name = "collateral_full_name";
          let spanish_aval_type = "aval";
          if (aval.aval_type === "PARTNER") {
            param_name = "partner_full_name";
            spanish_aval_type = "cónyuge";
          }
          return (
            <>
         {
  aval.aval_type === 'COLLATERAL' && (
    <>
      {avalStatus?.data?.collateral?.info && avalStatus?.data?.collateral?.needed && avalStatus?.data?.collateral?.status === undefined && (
       <div style={{flexDirection: 'row'  }}>
        <a
          target={"_blank"}
          style={{ fontSize: "larger" }}
          href={bureauUrl}
        >
          Enlace a Autorización de Buró de {spanish_aval_type}{" "}
          {aval.name} {aval.lastname} {aval.maternal_lastname}
        </a>
         <CopyButton toolTipText={`Copiar liga a buró de ${spanish_aval_type}`}  isRequiredBitly onClick={() => shortUrl(bureauUrl)}  />
         <br />
       </div>
      )}
      {avalStatus?.data?.collateral?.needed && avalStatus?.data?.collateral?.status === 'APPROVED' &&  (
        <div style={{flexDirection: 'row'}}>
        <a
          target={"_blank"}
          style={{ fontSize: "larger" }}
          href={signatureUrl}
        >
          Enlace a Firma de {spanish_aval_type}{" "}
          {aval.name} {aval.lastname} {aval.maternal_lastname}
        </a>
         <CopyButton  toolTipText={`Copiar liga a firma de ${spanish_aval_type}`} isRequiredBitly onClick={() => shortUrl(signatureUrl)}  />
        <br />
        </div>
      )}
    </>
  )
}
{
  aval.aval_type === 'PARTNER' && (
    <>
      {avalStatus?.data?.partner?.status === undefined && avalStatus?.data?.partner?.needed && avalStatus?.data?.partner?.info &&(
       <div style={{flexDirection: 'row'}}>
        <a
          target={"_blank"}
          style={{ fontSize: "larger" }}
          href={bureauUrl}
        >
          Enlace a Autorización de Buró de {spanish_aval_type}{" "}
          {aval.name} {aval.lastname} {aval.maternal_lastname}
        </a>
        <CopyButton  toolTipText={`Copiar liga a buró de ${spanish_aval_type}`} isRequiredBitly onClick={() => shortUrl(bureauUrl)}  />

        <br />
       </div>
        
      )}
      {(avalStatus.data?.partner?.status === 'APPROVED' || avalStatus.data?.partner?.status === 'REJECTED') && avalStatus?.data?.partner?.needed && (
       <div style={{flexDirection:'row'}}>
        <a
          target={"_blank"}
          style={{ fontSize: "larger" }}
          href={signatureUrl}
        >
          Enlace a Firma de {spanish_aval_type}{" "}
          {aval.name} {aval.lastname} {aval.maternal_lastname}
        </a>
         <CopyButton  toolTipText={`Copiar liga a firma de ${spanish_aval_type}`} isRequiredBitly onClick={() => shortUrl(signatureUrl)}  />

        <br />
       </div>
      )}
    </>
  )
}

            </>
          );
        })}
        <AdminAction
          splashBtnText="Cambiar estado civil"
          isOpenModal={isOpenMaritalStatusModal}
          setOpenModal={setIsOpenMaritalStatusModal}
          modalTitle={"Cambiar estado civil"}
          onClickSplashBtn={() => setIsOpenMaritalStatusModal(true)}
          onClickModalBtn={() => {
            updateMaritalStatus(registrationData.id, user_type);
            setIsOpenMaritalStatusModal(false);
          }}
          isLoading={loading?.PUT_UPDATE_MARITAL_STATUS}
          handleCloseSnackbar={handleCloseSnackbar}
          isOpenSnackbar={isOpenMaritalStatusSnackbar}
          msgType={isError ? "error" : "success"}
          snackBarMsg={
            isError
              ? usersError?.PUT_UPDATE_MARITAL_STATUS?.response?.data
                  ?.readable_message
              : "Estado civil actualizado"
          }
          children={
            <FormControl style={{ width: 150 }}>
              <InputLabel>Estado civil:</InputLabel>
              <Select
                value={maritalStatus}
                onChange={handleChangeMaritalStatus}
              >
                <MenuItem value={"SINGLE"}>Soltero</MenuItem>
                <MenuItem value={"DIVORCED"}>Divorciado</MenuItem>
                <MenuItem value={"FREE_UNION"}>Unión Libre</MenuItem>
                <MenuItem value={"MARRIED"}>Casado</MenuItem>
                <MenuItem value={"WIDOW"}>Viudo</MenuItem>
              </Select>
            </FormControl>
          }
        />
        <AdminAction
          splashBtnText="Cambiar giro de negocio"
          isLoading={loadingBusiness?.PUT_UPDATE_BUSINESS_TYPE}
          isOpenModal={isOpenBusinessTypeModal}
          setOpenModal={setIsOpenBusinessTypeModal}
          modalTitle={"Cambiar giro de negocio"}
          onClickSplashBtn={() => setIsOpenBusinessTypeModal(true)}
          onClickModalBtn={() => {
            updateBusinessType(user_type, registrationData.id);
            setIsOpenBusinessTypeModal(false);
          }}
          handleCloseSnackbar={handleCloseSnackbar}
          isOpenSnackbar={isOpenBusinessTypeSnackbar}
          msgType={isError ? "error" : "success"}
          snackBarMsg={
            isError
              ? businessError?.PUT_UPDATE_BUSINESS_TYPE?.response?.data
                  ?.readable_message
              : "Giro de negocio actualizado"
          }
          children={
            <FormControl style={{ width: 150 }}>
              <InputLabel>Giro:</InputLabel>
              <Select
                value={businessTypeValue}
                onChange={handleChangeBusinessType}
              >
                {businessType?.data?.map((businessType) => (
                  <MenuItem value={businessType}>{businessType}</MenuItem>
                ))}
              </Select>
            </FormControl>
          }
        />
        <AdminAction
          splashBtnText="Cambiar requerimientos avales"
          onClickSplashBtn={() => setIsOpenRequirementsModal(true)}
          onClickModalBtn={() => {
            updateRequirements(registrationData.id);
            setIsOpenRequirementsModal(false);
          }}
          isLoading={loading?.PUT_UPDATE_REQUIREMENTS}
          isOpenModal={isOpenRequirementsModal}
          setOpenModal={setIsOpenRequirementsModal}
          modalTitle={"Cambiar requerimientos"}
          handleCloseSnackbar={handleCloseSnackbar}
          isOpenSnackbar={isOpenRequirementsSnackbar}
          msgType={isError ? "error" : "success"}
          snackBarMsg={isError ?  error?.POST_WISH_NEW_AVAL?.response?.data?.readable_message || usersError?.PUT_UPDATE_REQUIREMENTS?.response?.data?.readable_message : "Requerimientos actualizados"}
          children={<FormControl>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked={avalStatus?.data?.collateral?.needed}
                  checked={isCheckedCollateral}
                  onChange={handleChangeCheckCollateral}
                />
              }
              label="Aval"
            />
            <Box>
              <Button
                style={{ height: 25, marginTop: 8 }}
                variant="contained"
                color="secondary"
                children={"Reemplazar aval"}
                isDisabled={!isCheckedCollateral}
                onClick={handleClickNewAval}
              />
            </Box>
          </Box>
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked={avalStatus?.data?.partner?.needed}
                checked={isCheckedPartner}
                onChange={handleChangeCheckPartner}
              />
            }
            label="Conyúge"
          />
        </FormControl>}
        />
        <AdminAction
        splashBtnText="Cambiar RFC"
        onClickSplashBtn={() => setIsOpenRfcModal(true)}
        isOpenModal={isOpenRfcModal}
        setOpenModal={setIsOpenRfcModal}
        modalTitle={"Cambiar RFC:"}
        isDisabledModalBtn={!validatedRFC}
        isOpenSnackbar={isOpenRfcSnackbar}
        handleCloseSnackbar={handleCloseSnackbar}
        isLoading={modificationsLoading?.PUT_TAXPAYER_ID}
        msgType={isError ? "error" : "success"}
        snackBarMsg={isError ?  modificationsError?.PUT_TAXPAYER_ID?.response?.data?.readable_message : "RFC Actualizado"}
        onClickModalBtn={() => {
          updateRfc(selectedPhone,rfcValue,registrationData.id, user_type)
          setIsOpenRfcModal(false);
        }}
        children={
          <>
            <InputLabel>Usuario:</InputLabel>
            <Select
              value={selectedRfc}
              onChange={handleSelectRfc}
            >    
            {usersInfo.map((userInfo, index) => (
              <MenuItem key={index} value={userInfo.rfc}>{userInfo.name}{" " + userInfo.lastname}</MenuItem>    
            ))}
            </Select>
            <Box style={{ marginTop: 12, marginBottom: 15 }}>
              <TextInput
                label="RFC del usuario:"
                variant="standard"
                value={rfcValue}
                onChange={handleInputChange}
                error={!validatedRFC ? true : false}
                helperText={
                  rfcValue?.length > 0 && !validatedRFC
                    ? "RFC incorrecto"
                    : ""
                }
                inputProps={{ maxLength: 13 }}
                />
              </Box>
          </>
        }
      />
      <AdminAction 
      splashBtnText="Pedir Validación de Campo" 
      handleCloseSnackbar={handleCloseSnackbar} 
      isOpenSnackbar={isOpenValidationSnackbar} 
      onClickSplashBtn={presaleAdvisorTask}
      isLoading={presaleAdvisorLoading?.POST_PRESALE_ADVISORS_TASK && inventoryLoading?.POST_REEVALUATE_INVENTORY}
      msgType={isError ? 'error' : 'success'}
      snackBarMsg={isError ? 
          `Error en validación: ${presaleAdvisorError?.POST_PRESALE_ADVISORS_TASK?.response?.data?.readable_message || ""}`
        : "Validación solicitada con éxito"}
      isSecondError={isSecondError}
      secondMsgType={'error'}
      isOpenSecondSnackbar={isOpenSecondChanceSnackbar}
      secondSnackbarMsg={isSecondError ? ' Error en segunda oportunidad: {inventoryError?.POST_REEVALUATE_INVENTORY?.response?.data?.readable_message || "" }' : ""}
      />
       <AdminAction 
      splashBtnText="Reactivar lead" 
      handleCloseSnackbar={handleCloseSnackbar} 
      isOpenSnackbar={isOpenResumeRegisterSnackbar} 
      onClickSplashBtn={resumeRegister}
      isLoading={loading?.POST_RESUME_REGISTER && loading?.POST_RESUME_REGISTER}
      msgType={isError ? 'error' : 'success'}
      snackBarMsg={isError ? usersError?.POST_RESUME_REGISTER?.response?.data?.readable_message || 'Ocurrió un error' : 'Lead reactivado correctamente'}
      />
      
      {registrationData?.avals !== null && registrationData?.avals !== undefined &&
        <>
          {registrationData?.avals.some(item => item.aval_type === "COLLATERAL" && item.bureau_decision === "REJECTED") &&
            <div style={{marginTop: 12}}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => setIsReactivateCollateral(true)}
                children={"Reactivar Aval"}
              />
              <ModalReactivateCollateral
                open={isReactivateCollateral}
                setOpen={setIsReactivateCollateral}
                avalsData={registrationData}
              />
            </div>
          }
        </>
      }

        <RegistrationInfo
          registrationData={registrationData}
          kycData={kycData}
        />
      </Box>
    )}
     </Box>
     {isOpenApprovalTable &&
      <Box flex={1} marginLeft={15}>
      <ApprovalTableForm checkboxValues={checkboxValues} handleCheckboxChange={handleCheckboxChange} handleInputChange={handleVeredictInputChange} inputValues={veredictInputValues} shopkeeperInfo={shopkeeperInfo} inputErrors={veredictInputErrors}/>
      </Box>
    }
      {customer && customerType === "customer" && (
        <Switch>
          <Route
            path={`${path}/customer-info`}
            component={() => <CustomerInfo {...personalData} />}
          />
          <Route
            path={`${path}/residential-info`}
            component={() => <CustomerResidenceInfo {...residentialInfo} />}
          />
          <Route
            path={`${path}/laboral-info`}
            component={() => <CustomerLaboralInfo {...laboralInfo} />}
          />
          <Route
            path={`${path}/reference-info`}
            component={() => <CustomerReferenceInfo {...referencesInfo} />}
          />
          <Route
            path={`${path}/confirmation-info`}
            component={() => <ConfirmationInfo />}
          />
          <Route
            path={`${path}/phone-number-validation-info`}
            component={() => (
              <PhoneValidationInfo
                phone_number_validation={phoneNumberValidation}
              />
            )}
          />
          <Route
            path={`${path}/identity-validation-info`}
            component={() => <IdentityValidationInfo {...identityValidation} />}
          />
          <Route
            path={`${path}/yofio-validation-info`}
            component={() =>
              customer && (
                <VendorValidationInfo
                  customerId={customer.personal_data.account_customer_id}
                  yofio_validation_info={vendorValidation}
                />
              )
            }
          />
        </Switch>
      )}
    </Box>
  );
};

export default GeneralTab;
