import React, { useEffect } from 'react';
import {
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import useStyles from './TreasuryPage.style';
import Text from '../../components/Text';

const headCells = [
  {
    id: 'country', numeric: false, disablePadding: false, label: 'País',
  },
  {
      id: 'id_shop', numeric: false, disablePadding: false, label: 'ID usuario',
  },
  {
      id: 'date', numeric: false, disablePadding: false, label: 'Fecha de Solicitud',
  },
  {
      id: 'sk_name', numeric: false, disablePadding: false, label: 'Nombre de usuario',
  },
  {
      id: 'beneficiary_name', numeric: false, disablePadding: false, label: 'Nombre de beneficiario',
  },
  {
      id: 'sk_id', numeric: false, disablePadding: false, label: 'Documento de usuario',
  },
  {
      id: 'beneficiary_id', numeric: false, disablePadding: false, label: 'Documento de beneficiario',
  },
  {
      id: 'shop_name', numeric: false, disablePadding: false, label: 'Tienda',
  },
  {
      id: 'bank', numeric: false, disablePadding: false, label: 'Banco',
  },
  {
      id: 'acount', numeric: true, disablePadding: false, label: 'Cuenta',
  },
  {
      id: 'bank_acc_validation', numeric: false, disablePadding: false, label: 'Validación cuenta bancaria',
  },
  {
    id: 'total_to_disburse', numeric: true, disablePadding: false, label: 'Total a desembolsar',
  },
  {
    id: 'amount_paid', numeric: true, disablePadding: false, label: 'Monto pagado',
  },
  {
    id: 'id_shop_ref', numeric: true, disablePadding: false, label: 'ID Tienda',
  },
  {
    id: 'action', label: 'Acción',
  },
  {
    id: 'action', label: 'Acción',
  },
  {
    id: 'action', label: 'Acción',
  },
  {
    id: 'select', label: 'Seleccionar',
  },
];

export const OrderedTableHead = (props) => {
  const classes = useStyles();
  const { page, orderBy, sort } = useSelector((state) => state.customersState);

  useEffect(() => {
  }, [orderBy, sort]);

  return (
    <TableHead className={classes.tableHead}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            align="center"
            scope="col"
            sort={orderBy === headCell.id ? sort : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? sort : 'asc'}
            >
              <Text className={classes.titleLabel}>{headCell.label}</Text>
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default OrderedTableHead;
