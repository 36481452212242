import React from 'react';
import {
    Box
} from '@material-ui/core';

import useStyles from './SubMenuPage.styles';
import Text from '../../../components/Text';

const TopMenuItem = ({
    mainText,
    icon,
    secondText,
    rigthIcon,
    mainTextComponent,
    styleContainer
}) => {
    const classes = useStyles();
    return (
        <Box
            style={{ ...styleContainer }}
        >
            {   mainTextComponent ?
                mainTextComponent :
                <Text className={ classes.mainTextBar }>
                    {mainText}
                </Text>
            }
            <div className={classes.secondTextBarContainer}>
                {icon}
                <Text>
                    {secondText}
                </Text>
                {rigthIcon}
            </div>
        </Box>
    )
}

export default TopMenuItem