import client from '../client';

export const getVeredictReasonsCatalog = (veredict_type) => {
  const url = `catalog/veredict-reasons?veredict_type=${veredict_type}`;
  return new Promise((resolve, reject) => {
    client.get(url).then(
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      },
    );
  });
};

export const getVeredictReviewRequestPropertiesCatalog = () => {
  const url = 'catalog/review-request-properties';
  return new Promise((resolve, reject) => {
    client.get(url).then(
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      },
    );
  });
};

export const getInventoryReasonsCatalog = () => {
  const url = 'catalog/inventory-reasons';
  return new Promise((resolve, reject) => {
    client.get(url).then(
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      },
    );
  });
};

export const getRejectReasonsCatalog = () => {
  const url = 'catalog/reject-reasons';
  return new Promise((resolve, reject) => {
    client.get(url).then(
      (response) => {
        resolve(response);
      },
      (error) => {
        reject(error);
      },
    );
  });
};
