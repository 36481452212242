import React from 'react';
import { SvgIcon } from '@material-ui/core';
import Button from '../Button';

export const StepArrowIcon = (props) => (
  <Button disabled={props.disabled} onClick={props.onClick}>
    <SvgIcon
      {...props}
      style={{ width: 87 * props.size, height: 24 * props.size }}
      viewBox="76 0 10 45"
    >
      <polygon
        fill={props.color}
        points="0 0 149.975991 0 162 23.5307874 149.975991 46 0 46 12.0240093 23.5012336"
      />
    </SvgIcon>
    <div style={{ position: 'absolute' }}>
      {props.children}
    </div>
  </Button>
);

export default StepArrowIcon;
