import * as ACTION from './actionTypes';
import client from '../../client';
import { appService } from '../../services/app.service';
import shopsService from '../../services/shops.service';

export const getFindShop = () => async (dispatch, getState) => {
  const { searchString } = getState().registersState;
  const url = `${appService.config.paths.shop.route}?${searchString ? `name=${searchString}` : ''}&type=LIGHT`;
  dispatch({ type: ACTION.FETCH_CUSTOMERS_INIT });
  const response = await client.get(url);
  const { data } = response;
  const d = data.filter((shop) => shop.credit_line_id !== undefined);

  if (d && d.length > 0) {
    dispatch({ type: ACTION.FETCH_CUSTOMERS_SUCCESS });
    dispatch({ type: ACTION.SET_STATE, label: 'shops', value: d });
  } else {
    dispatch({ type: ACTION.FETCH_CUSTOMERS_ERROR, error: 'No shops returned from service' });
    dispatch({ type: ACTION.FETCH_CUSTOMERS_SUCCESS, error: null });
  }
};

export const postLightExtension = (credit_line_id, body) => async (dispatch, getState) => {
  try {
    const response = shopsService.postLightExtension(credit_line_id, body);
    dispatch({ type: ACTION.FETCH_RESPONSE_EXTENSION_LIGTH, response })
  } catch(error) {
    console.error(error);
  }
}