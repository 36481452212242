import client from "../client";

export const getMatiVerification = (contract_id) => {
    const url = `admin/verification/persons/pending-review${contract_id ? `?contract_id=${contract_id}` : ''}`;
    return new Promise((resolve, reject) => {
        client.get(url).then(
            (response) => {
                resolve(response);
            },
            (error) => {
                reject(error);
            }
        );
    });
};

export const putMatiVerification = (contract_id, body) => {
    const url = `admin/verification/${contract_id}/internal-review`;
    return new Promise((resolve, reject) => {
        client.put(url, body).then(
            (response) => {
                resolve(response);
            },
            (error) => {
                reject(error);
            }
        );
    });
};
