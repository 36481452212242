import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getSupplier,
  postSendTransfer,
  putSupplierDisbursement,
} from "../../reducers/dealer.action";

import { deleteInventoryPurchase } from "../../reducers/creditline.action";

import TableTreasuryDealerUI from "./TableTreasuryDealer";
import useStyles from "./TableTreasuryDealer.styles";

function TableTreasuryDealer(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDisabledSendTransfer, setIsDisabledSendTransfer] = useState(false);
  const [selectedTreasuryElement, setSelectedTreasuryElement] = useState({});
  const [displayedSettlementsInfo, setDisplayedSettlementsInfo] = useState([]);
  const [supplier, setSupplier] = useState([]);
  const [
    isDeleteDisbursementModalVisible,
    setIsDeleteDisbursementModalVisible,
  ] = useState(false);
  const [selectedShopId, setSelectedShopId] = useState("");
  const [selectedDisbursementId, setSelectedDisbursementId] = useState("");
  const [isError, setIsError] = useState(false);

  const { supplierData, supplierDisbursementData, loading } = useSelector(
    (state) => state.supplierReducer
  );
  const { error } = useSelector((state) => state.creditLineReducer);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    setPage(0);
  }, [supplierData]);

  useEffect(() => {
    dispatch(getSupplier({ inventory_info: true, was_disbursed: false }));
  }, []);

  useEffect(() => {
    if (error?.DELETE_INVENTORY_PURCHASE) {
      if (error?.DELETE_INVENTORY_PURCHASE != null) {
        setIsError(true);
      }
    }
  }, [error?.DELETE_INVENTORY_PURCHASE]);

  useEffect(() => {
    if (Object.keys(supplierData).length !== 0) {
      if (supplierData?.data) {
        const sortedData = supplierData.data.sort((a, b) =>
          a.created_at < b.created_at ? -1 : 1
        );

        setSupplier(sortedData);
      }
    }
  }, [supplierData]);

  useEffect(() => {
    if (supplier.length > 0) {
      setDisplayedSettlementsInfo(
        supplier.map((resource) => ({
          key: `${resource.account_id}-${resource.credit_line_id}`,
        }))
      );
    }
  }, [supplier]);

  useEffect(() => {
    if (Object.keys(supplierDisbursementData).length !== 0) {
      window.location.reload();
    }
  }, [supplierDisbursementData]);

  const updateTreasuryElement = (settlement, settlementKey) => {
    const settlementDetails = displayedSettlementsInfo.find(
      (settlement) => settlement.key === settlementKey
    );
    const numAmount = parseFloat(settlementDetails.settled_amount);
    const newSelectedTreasuryElement = {
      amount_text: numAmount,
      shopkeeper_id_simple: settlementDetails.id_shop_ref,
      shop_name: settlement.shop_name,
      purchase_id: settlement.purchase_id,
    };
    if (
      numAmount > 0 &&
      settlementDetails.id_shop_ref ===
        settlement.account_id.substr(settlement.account_id.length - 6)
    ) {
      setSelectedTreasuryElement(newSelectedTreasuryElement);
      setIsModalVisible(true);
    } else {
      alert(
        "La validación no es correcta. Los números de cuenta no coinciden o el monto no es mayor a 0."
      );
    }
  };

  const handleConfirmDisbursementModal = () => {
    setIsDeleteDisbursementModalVisible(false);
    dispatch(
      deleteInventoryPurchase(selectedShopId, selectedDisbursementId)
    ).then(() => {
      setTimeout(() => {
        window.location.reload();
      }, 2500);
    });
  };

  const handleOpenDisbursementModal = (accountId, disbursementId) => {
    setIsDeleteDisbursementModalVisible(true);
    setSelectedShopId(accountId);
    setSelectedDisbursementId(disbursementId);
  };

  const handleInput = (e, settlementKey) => {
    const key = e.target.name;
    const currentSettlementIndex = displayedSettlementsInfo.findIndex(
      (displayedSettlement) => displayedSettlement.key === settlementKey
    );

    const currentSettlement = {
      ...displayedSettlementsInfo[currentSettlementIndex],
      [key]:
        key !== "id_shop_ref" ? e.target.value : e.target.value.toLowerCase(),
    };

    const newDisplayedSettlements = [...displayedSettlementsInfo];

    newDisplayedSettlements[currentSettlementIndex] = currentSettlement;

    setDisplayedSettlementsInfo(newDisplayedSettlements);
  };

  const sendDisbursement = (purchaseID, disbursementAmount) => {
    let body = {
      disbursed_amount: disbursementAmount,
    };
    dispatch(putSupplierDisbursement(purchaseID, body));
    setIsModalVisible(false);
  };

  const sendTransfer = (credit_line_id, purchase_id) => {
    setIsDisabledSendTransfer(true);
    const body = {
      origin_id: purchase_id,
      transfer_type: "SUPPLIER_PURCHASE",
    };
    dispatch(postSendTransfer(credit_line_id, body)).then(() => {
      window.location.reload();
    });
  };

  return (
    <TableTreasuryDealerUI
      classes={classes}
      page={page}
      rowsPerPage={rowsPerPage}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      supplierData={supplier}
      isModalVisible={isModalVisible}
      setIsModalVisible={setIsModalVisible}
      handleInput={handleInput}
      updateTreasuryElement={updateTreasuryElement}
      selectedTreasuryElement={selectedTreasuryElement}
      sendDisbursement={sendDisbursement}
      sendTransfer={sendTransfer}
      isDisabledSendTransfer={isDisabledSendTransfer}
      isDeleteDisbursementModalVisible={isDeleteDisbursementModalVisible}
      setIsDeleteDisbursementModalVisible={setIsDeleteDisbursementModalVisible}
      handleConfirmDisbursementModal={handleConfirmDisbursementModal}
      handleOpenDisbursementModal={handleOpenDisbursementModal}
      errorMessage={
        error?.DELETE_INVENTORY_PURCHASE?.response?.data?.readable_message ||
        "Ocurrió un error"
      }
      isError={isError}
      loading={loading}
    />
  );
}

export default TableTreasuryDealer;
