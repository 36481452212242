import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import Backdrop from '@material-ui/core/Backdrop';
import CloseIcon from '@material-ui/icons/Close';
import FileCopy from '@material-ui/icons/FileCopy';
import SnackBar from '@material-ui/core/Snackbar';

import Box from '../../components/Layout/Box';
import PageContainer from '../../components/PageContainer';
import SectionTitle from '../../components/SectionTitle';
import MissingDataAlert from '../../components/MissingDataAlert/MissingDataAlert';
import useStyles from './WareHouse.styles';
import { WareHouseTable } from './WareHouseTable';
import { searchWareHouse, clearWareHoseState } from './WareHouse.actions';
import Text from '../../components/Text';

const WareHouse = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [nothingFoundNoticeShown, showNothingFoundNotice] = useState(false);
    const [inputValue, setInputVale] = useState('');
    const [queryingShops, setQueryingShops] = useState(false);
    const [showQRModal, setShowQRModal] = useState({
        open: false,
        tradeName: '',
        qrPath: ''
    });
    const [isCopyClipboard, setIsCopyClipboard] = useState(false);
    const [dataWareHouse, setDataWareHouse] = useState([]);

    const {
        LOADING,
        ERROR,
        getWareHouseData,
    } = useSelector((state) => state.wareHouseReducer);

    useEffect(() => {
        setQueryingShops(LOADING.getWareHouse);
    }, [LOADING]);

    useEffect(() => {
        if (ERROR.getWareHouse) {
            showNothingFoundNotice(true);
            setInputVale('');
        }
    }, [ERROR]);

    useEffect(() => {
      if(Object.keys(getWareHouseData).length !== 0) {
        setDataWareHouse(getWareHouseData)
      }
    }, [getWareHouseData])

    useEffect(() => {
        if(nothingFoundNoticeShown) {
            setTimeout(() => showNothingFoundNotice(false), 3000);
        }
    }, [nothingFoundNoticeShown]);

    const _searchWareHouse = () => {
        dispatch(searchWareHouse(inputValue))
    }

    const _showQR = (warehouse) => {
        setShowQRModal(prevState => ({
            ...prevState,
            open: true,
            tradeName: warehouse.trade_name,
            qrPath: warehouse.qr_path
        }))
    }

    const handleCloseModalQR = () => {
        setShowQRModal(prevState => ({
            ...prevState,
            open: false,
            tradeName: '',
            qrPath: ''
        }))
    }

    return (
        <PageContainer>
            <Box className={classes.rootContainer}>
                <Box
                    className={classes.topContainer}
                    onSubmit={(e) => {
                        e.preventDefault();
                    }}
                >
                <SectionTitle>Sucursales de Bodegas</SectionTitle>
                {   nothingFoundNoticeShown && (
                    <MissingDataAlert status="BAD" text="No se encontraron sucursales" />
                )}
                <Box style={{ display: queryingShops ? 'block' : 'none' }}>
                  <CircularProgress />
                </Box>
                <Box className={classes.actionsContainer}>
                  <Box component="form" className={classes.searchContainer}>
                      <InputBase
                          value={inputValue}
                          onChange={(event) => {
                              setInputVale(event.target.value);
                          }}
                          className={classes.input}
                          placeholder="Buscar por nombre, ID, nombre del dueño..."
                          inputProps={{ 'aria-label': 'search user' }}
                          onKeyUp={(event) => {
                              if (event.key === 'Enter') {
                                _searchWareHouse();
                              }
                          }}
                      />
                      <Divider className={classes.divider} orientation="vertical" />
                      <IconButton
                          onClick={() => {
                            _searchWareHouse();
                          }}
                          className={classes.iconButton}
                          aria-label="search"
                      >
                          <SearchIcon />
                      </IconButton>
                  </Box>
                </Box>
                </Box>
                <Box className={classes.tableContainer}>
                    <WareHouseTable resources={dataWareHouse} _showQR={_showQR} />
                </Box>
            </Box>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={showQRModal.open}
                onClose={handleCloseModalQR}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={showQRModal.open}>
                    <Box className={classes.modalContainer}>
                        <div className={classes.modalTextContainer}>
                            <Text className={classes.textTitleModalStyle}>
                                {`Codigo QR - ${showQRModal.tradeName || '-'}`}
                            </Text>
                            <div
                                onClick={() => handleCloseModalQR()}
                            >
                                <CloseIcon/>
                            </div>
                        </div>
                        <div className={classes.modalTextContainer} style={{ marginTop: 20 }}>
                            <Text className={classes.textModalStyle}>
                                {showQRModal.qrPath || '-'}
                            </Text>
                            <div
                                className={classes.subMenuImages}
                            >
                                <div
                                    className={classes.imagesSubMenuContainer}
                                    onClick={() => {
                                        navigator.clipboard.writeText(showQRModal.qrPath)
                                        setIsCopyClipboard(true);
                                    }}
                                >
                                    <FileCopy
                                        style={{ color: 'white' }}
                                    />
                                </div>
                            </div>
                        </div>
                        <Box style={{ display: 'flex', marginTop: 20, alignItems:'center', justifyContent: 'center' }}>
                            <img
                                style={{ width: '70%', height: '70%' }} 
                                src={showQRModal.qrPath}
                            />
                        </Box>
                    </Box>
                </Fade>
            </Modal>
            <SnackBar
                open={isCopyClipboard}
                autoHideDuration={3000}
                onClose={() => {
                    setIsCopyClipboard(false);
                }}
                message="Link de QR Copiado"
            />
        </PageContainer>
    );
};

export default WareHouse;
