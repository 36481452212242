import * as ACTIONS from './warehouse.actionTypes';

const initialState = {
    LOADING: {
        getWareHouse: false
    },
    ERROR: {
        getWareHouse: null
    },
    getWareHouseData: {}
};

const warehouseReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.FETCH_SEARCHWAREHOUSE_INIT:
            return {
                ...state,
                LOADING: {
                    getWareHouse: true
                }
            }
        case ACTIONS.FETCH_WAREHOUSE_SUCCESS:
            return {
                ...state,
                LOADING: {
                    getWareHouse: false
                },
                getWareHouseData: action.response
            }
        case ACTIONS.FETCH_WAREHOUSE_ERROR:
            return {
                ...state,
                LOADING: {
                    getWareHouse: false
                },
                ERROR: {
                    getWareHouse: action.error
                }
            }
        case ACTIONS.CLEAR_WAREHOUSE_STATE: 
            return { ...initialState }
        default:
            return { ...state };
    }
};

export default warehouseReducer;