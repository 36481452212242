import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Box from '../../components/Layout/Box';
import PageContainer from '../../components/PageContainer';
import SectionTitle from '../../components/SectionTitle';
import MissingDataAlert from '../../components/MissingDataAlert/MissingDataAlert';
import useStyles from './ShopPage.styles';
import { ShopTable } from './ShopTable';
import { getCreditLineShop, getAllShops } from './ShopPage.actions';

const ShopPage = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [nothingFoundNoticeShown, showNothingFoundNotice] = useState(false);
    const [inputValue, setInputVale] = useState('');
    const [queryingShops, setQueryingShops] = useState(false);
    const {
        isFetchingAllShops,
        credit_line_all_shops,
        error,
    } = useSelector((state) => state.shopState);

    useEffect(() => {
        if (error) {
            showNothingFoundNotice(true);
            setInputVale('');
        }
    }, [error]);

    useEffect(() => {
        if(nothingFoundNoticeShown) {
            setTimeout(() => showNothingFoundNotice(false), 3000);
        }
    }, [nothingFoundNoticeShown]);

    useEffect(() => {
      if(!inputValue) {
        setQueryingShops(true);
        dispatch(getAllShops()).then(() => setQueryingShops(false));
      }
    }, [inputValue]);

    return (
        <PageContainer>
            <Box className={classes.rootContainer}>
                <Box
                    className={classes.topContainer}
                    onSubmit={(e) => {
                        e.preventDefault();
                    }}
                >
                <SectionTitle>Tiendas</SectionTitle>
                {   nothingFoundNoticeShown && (
                    <MissingDataAlert status="BAD" text="No se encontraron Tiendas" />
                )}
                <Box style={{ display: queryingShops ? 'block' : 'none' }}>
                  <CircularProgress />
                </Box>
                <Box className={classes.actionsContainer}>
                  <Box component="form" className={classes.searchContainer}>
                      <InputBase
                          value={inputValue}
                          onChange={(event) => {
                              setInputVale(event.target.value);
                          }}
                          className={classes.input}
                          placeholder="Buscar por nombre de tienda, telefono o ID"
                          inputProps={{ 'aria-label': 'search user' }}
                          onKeyUp={(event) => {
                              if (event.key === 'Enter') {
                                setQueryingShops(true);
                                dispatch(getAllShops({ name: encodeURIComponent(inputValue) })).then(() => setQueryingShops(false));
                              }
                          }}
                      />
                      <Divider className={classes.divider} orientation="vertical" />
                      <IconButton
                          onClick={() => {
                            setQueryingShops(true);
                            dispatch(getAllShops({ name: encodeURIComponent(inputValue) })).then(() => setQueryingShops(false));
                          }}
                          className={classes.iconButton}
                          aria-label="search"
                      >
                          <SearchIcon />
                      </IconButton>
                  </Box>
                </Box>
                </Box>
                <Box className={classes.tableContainer}>
                    <ShopTable resources={credit_line_all_shops} />
                </Box>
            </Box>
        </PageContainer>
    );
};

export default ShopPage;
