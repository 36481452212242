import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Box from '../../../components/Layout/Box';
import PageContainer from '../../../components/PageContainer';
import SectionTitle from '../../../components/SectionTitle';
import { CollectionsTable } from './CollectionsTable';
import useStyles from './ShopsPage.styles';
import MissingDataAlert from '../../../components/MissingDataAlert/MissingDataAlert';
import Button from '../../../components/Button';
import { getSettlementDetailByType } from '../ShopsPage.actions';

const CollectionsDetails = (props) => {
  const { resourceId } = props.match.params;
  const resourceType = 'NEGATIVE';

  const dispatch = useDispatch();
  const classes = useStyles();
  const date = new Date();

  const [nothingFoundNoticeShown, showNothingFoundNotice] = useState(false);
  const [inputValue, setInputVale] = useState('');
  const { error } = useSelector((state) => state.shopsState);
  const [dates, setDates] = useState([]);

  const { shops } = useSelector((state) => state.shopsState);

  useEffect(() => {
    if (error) {
      showNothingFoundNotice(true);
      setInputVale('');
    }
  }, [error]);

  useEffect(() => {
    setTimeout(() => showNothingFoundNotice(false), 3000);
  }, [nothingFoundNoticeShown]);

  useEffect(() => {
    dispatch(getSettlementDetailByType(resourceId, resourceType));
  }, []);

  const handleChecks = (event) => {
  };

  return (
    <PageContainer>
      <Box className={classes.rootContainer}>
        <Box
          className={classes.topContainer}
          onSubmit={(e) => {
            e.preventDefault();
          }}>
          <SectionTitle>Cobros Detalle</SectionTitle>
          {nothingFoundNoticeShown && (
            <MissingDataAlert status="BAD" text="No se encontraron Tiendas" />
          )}
          <Box component="form" className={classes.searchContainer}>
            <InputBase
              value={inputValue}
              onChange={(event) => {
                setInputVale(event.target.value);
              }}
              className={classes.input}
              placeholder="Buscar por nombre de cliente, email, ID..."
              inputProps={{ 'aria-label': 'search user' }}
              onKeyUp={(event) => {
                if (event.key === 'Enter') {
                  dispatch(getSettlementDetailByType(resourceId, resourceType));
                }
              }}
            />
            <Divider className={classes.divider} orientation="vertical" />
            <IconButton
              onClick={() => dispatch(getSettlementDetailByType(resourceId, resourceType))}
              className={classes.iconButton}
              aria-label="search"
            >
              <SearchIcon />
            </IconButton>
            <Button style={{ backgroundColor: 'light-gray' }}
              // onClick={() => dispatch(sendCollectionNotice(resourceId))}
            >
              Notificar Cobro
            </Button>
          </Box>
        </Box>
        <Box className={classes.tableContainer}>
          <CollectionsTable resourceType={resourceType} resources={shops} />
        </Box>
      </Box>
    </PageContainer>
  );
};

export default CollectionsDetails;
