import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import Box from '../../../components/Layout/Box';
import PageContainer from '../../../components/PageContainer';
import SectionTitle from '../../../components/SectionTitle';
import { ShopTransactionsTable } from './ShopTransactionsTable';
import useStyles from './ShopsPage.styles';
import MissingDataAlert from '../../../components/MissingDataAlert/MissingDataAlert';
import { getSettlementTransactions } from '../ShopsPage.actions';

const ShopTransactions = (props) => {
  const { resourceId, shopkeeperId } = props.match.params;

  const classes = useStyles();
  const dispatch = useDispatch();

  const [nothingFoundNoticeShown, showNothingFoundNotice] = useState(false);
  const [inputValue, setInputVale] = useState('');
  const { error } = useSelector((state) => state.shopsState);
  const [dates, setDates] = useState([]);
  const { transactions } = useSelector((state) => state.shopsState);

  useEffect(() => {
    if (error) {
      showNothingFoundNotice(true);
      setInputVale('');
    }
  }, [error]);

  useEffect(() => {
    setTimeout(() => showNothingFoundNotice(false), 3000);
  }, [nothingFoundNoticeShown]);

  useEffect(() => {
    dispatch(getSettlementTransactions(resourceId, shopkeeperId));
  }, []);

  return (
    <PageContainer>
      <Box className={classes.rootContainer}>
        <Box
          className={classes.topContainer}
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <SectionTitle>Transacciones</SectionTitle>
          {nothingFoundNoticeShown && (
            <MissingDataAlert status="BAD" text="No se encontraron Tiendas" />
          )}
        </Box>
        <Box className={classes.tableContainer}>
          <ShopTransactionsTable resources={transactions} />
        </Box>
      </Box>
    </PageContainer>
  );
};

export default ShopTransactions;
