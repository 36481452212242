import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import {
  Select, Input, InputAdornment,
} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import { appService } from '../../../services/app.service';

import useStyles from './BureauDocuments.style';
import SectionTitle from '../../../components/SectionTitle';
import PageContainer from '../../../components/PageContainer';
import Text from '../../../components/Text';
import { Box } from '../../../components/Layout';
import Button from '../../../components/Button';

const BureauDocumentsPage = () => {
  const classes = useStyles();

  const [url, setUrl] = useState(`${appService.config.paths.api.basePath}/admin/reports/customer/helper`);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [pdfType, setPdfType] = useState('ACCOUNT');

  const handleChangePhoneNumber = () => (event) => {
    setPhoneNumber(event.target.value);
    setUrl(`${appService.config.paths.api.basePath}${appService.config.paths.loan_request.route}/documents`);
    if (event.target.value !== '') {
      setUrl(`${appService.config.paths.api.basePath}${appService.config.paths.loan_request.route}/documents?phone_number=%2B52${event.target.value}&pdf_type=${pdfType}`);
    }
  };

  const handleChangePdfType = () => (event) => {
    setPdfType(event.target.value);
    setUrl(`${appService.config.paths.api.basePath}${appService.config.paths.loan_request.route}/documents`);
    if (event.target.value !== '') {
      setUrl(`${appService.config.paths.api.basePath}${appService.config.paths.loan_request.route}/documents?phone_number=%2B52${phoneNumber}&pdf_type=${event.target.value}`);
    }
  };

  return (
    <PageContainer>

      <Link to="/credits" className={classes.controls}>
        <ArrowBackIcon />
        <Text>Regresar</Text>
      </Link>

      <Box className={classes.mainPanel}>
        <SectionTitle>Consultar PDF de Bur&oacute;</SectionTitle>
      </Box>

      <Box style={{ width: '80%', paddingTop: 15, paddingBottom: 15 }}>
        <Text variant="h4" className={classes.textBlue}>Ingrese el n&uacute;mero de tel&eacute;fono del usuario</Text>
        <Input
          id="phone-number"
          style={{ display: 'flex' }}
          value={phoneNumber}
          type="tel"
          onChange={handleChangePhoneNumber('phoneNumber')}
          placeholder="5500000000"
          startAdornment={<InputAdornment position="start">+52</InputAdornment>}
        />
      </Box>

      <Box style={{ width: '80%', paddingTop: 15, paddingBottom: 15 }}>
        <Text variant="h4" className={classes.textBlue}>
          ¿A qui&eacute;n quieres consultar el PDF de bur&oacute;?
        </Text>
        <Select
          displayEmpty
          style={{ display: 'flex' }}
          labelId="demo-simple-select-label"
          id="pdfType"
          value={pdfType}
          onChange={handleChangePdfType()}
        >
          <MenuItem value="ACCOUNT">Titular</MenuItem>
          <MenuItem value="PARTNER">Obligado Solidario</MenuItem>
          <MenuItem value="COLLATERAL">Aval</MenuItem>
          <MenuItem value="INVENTORY">Inventario</MenuItem>
        </Select>
      </Box>

      <Box style={{ width: '35%' }}>
        <a href={url} target="_blank" rel="noreferrer" className={classes.noUnderline}>
          <Button disabled={!phoneNumber} className={classes.downloadButton}>
            <Text className={classes.buttonLabel}>Descargar</Text>
          </Button>
        </a>
      </Box>

    </PageContainer>
  );
};

export default BureauDocumentsPage;
