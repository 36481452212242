import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { useRouteMatch } from "react-router";
import {
  TableContainer, Table, TableBody, TableRow, TableCell, TableFooter,
} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from './PaginationTable.style';
import Text from '../../../components/Text';
import ArrowedText from '../../../components/ArrowedText';
import { Box } from '../../../components/Layout';
import { OrderedTableHead } from './CollectionsTableHead';
import Button from '../../../components/Button';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import {
  addShopToSelectedShops,
  deleteShopFromSelectedShops, sendCollectionNotice,
} from '../ShopsPage.actions';
import { moneyFunction } from '../moneyFunction';
import { statusType } from '../../../constants/constants';

export const CollectionsTable = (props) => {
  const { resources } = props;
  const dispatch = useDispatch();
  const { selectedShops } = useSelector((state) => state.shopsState);
  const classes = useStyles();
  const { url } = useRouteMatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedSettlement, setSelectedSettlement] = useState({});


  const includesKey = (key) => !!selectedShops.find((selectedShop) => selectedShop.key === key);
  const handleShopCheck = (settlement) => {
    if (includesKey(`${settlement.shopkeeper_id_simple}-${settlement.shop_name}`)) {
      //dispatch(deleteShopFromSelectedShops(settlement));
    } else {
      //dispatch(addShopToSelectedShops(settlement));
    }
  };

  const updateSettlement = (settlement) => {
    const amount = settlement.balance;
    const numAmount = parseFloat(amount.substring(1).replace(/,/g, ''));
    selectedSettlement['settlement_id'] = settlement.settlement_id;
    selectedSettlement['shopkeeper_id'] = settlement.shopkeeper_id;
    selectedSettlement['shopkeeper_id_simple'] = settlement.shopkeeper_id_simple;
    selectedSettlement['shop_name'] = settlement.shop_name;
    selectedSettlement['transaction_type'] = 'DEPOSIT';
    selectedSettlement['amount'] = numAmount;
    selectedSettlement['amount_text'] = amount;
    setSelectedSettlement(selectedSettlement);
    setIsModalVisible(true);
  };

  return (
    <Box>
      <TableContainer className={classes.tableContainer}>
        <Table aria-label="custom pagination table">
          <OrderedTableHead />
          <TableBody>
            {
              (resources || []).map((settlement, index) => (
                <TableRow key={index} style={{ maxHeight: 43 }}>
                  <TableCell>
                    <Text className={classes.textStyle}>{settlement.shopkeeper_id_simple}</Text>
                  </TableCell>
                  <TableCell>
                    <Text className={classes.textStyle}>
                      {
                        settlement.shop_name
                      }
                    </Text>
                  </TableCell>
                  <TableCell>
                    <Text className={classes.textStyle}>{settlement.shopkeeper_account_bank}</Text>
                  </TableCell>
                  <TableCell>
                    <Text className={classes.textStyle}>{settlement.total_payments}</Text>
                  </TableCell>
                  <TableCell>
                    <Text className={classes.textStyle}>
                      {settlement.total_collections}
                    </Text>
                  </TableCell>
                  <TableCell>
                    <Text className={classes.textStyle} style={{ color: 'red' }}>
                      {settlement.balance}
                    </Text>
                  </TableCell>
                  <TableCell>
                  { settlement.status !== "PENDING" ?
                    ( <Text className={classes.textStyle} style={{ textAlign: 'center' }}>{statusType[settlement.status]}</Text>) :
                    ( <Button onClick={() => {
                      updateSettlement(settlement);
                    }} style={{ backgroundColor: '#0253cc', color: 'white' }}>
                          Cobrar
                      </Button>
                    )
                  }
                  </TableCell>
                  <TableCell>
                    <Text className={classes.textStyle} style={{ textAlign: 'center' }}>{statusType[settlement.status]}</Text>
                  </TableCell>
                  <TableCell>
                    <Link to={`${url}/transactions/${settlement.shopkeeper_id}`}>
                      <Button>
                        <ArrowedText>
                          Ver detalle
                        </ArrowedText>
                      </Button>
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Text className={classes.textStyle} style={{ textAlign: 'center' }}>
                      {/* <Checkbox
                        checked={includesKey(`${settlement.shopkeeper_id_simple}-${settlement.shop_name}`)}
                        inputProps={{ 'aria-label': 'Checkbox A' }}
                        onChange={() => handleShopCheck(settlement)}
                      /> */}
                    </Text>
                  </TableCell>
                </TableRow>
              ))
            }
          </TableBody>
          <TableFooter />
        </Table>
      </TableContainer>
      <ConfirmationModal
        title={"Confirmación de Cobro"}
        open={isModalVisible}
        setOpen={setIsModalVisible}
        selectedSettlement={selectedSettlement}
        type={'postOneTransaction'}
      />
    </Box>
  );
};

export default CollectionsTable;
