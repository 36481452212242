import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  rootContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '95%',
    paddingTop: 15,
    paddingLeft: 30,
    paddingRight: 30,
  },
  topContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  emptySpace: {
    flexGrow: 1,
  },
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    width: 500,
    background: '#E8E8FC33',
    borderRadius: 2,
    marginTop: 8,
  },
  input: {
    marginLeft: 9,
    marginTop: 7,
    marginBottom: 8,
    width: 260,
    flex: 1,
    fontSize: 10,
    fontWeight: 300,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    marginTop: 4,
    marginBottom: 4,
  },
  tableContainer: {
    marginTop: 22,
  },
  formControl: {
    minWidth: 160,
    display: 'flex',

  },
  datesContainer: {
    display: 'flex',
    '& Button': {
      marginTop: 10,
      marginLeft: 10,
    },
  },
}));

export default useStyles;
