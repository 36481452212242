import * as ACTION from './actionTypes';
import client from '../../client';
import { appService } from '../../services/app.service';

export const getRegisters = (page) => async (dispatch, getState) => {
  const { searchString, sort, orderBy } = getState().registersState;

  const url = `${appService.config.paths.registers.route}${(page || orderBy || sort || searchString ? '?' : '')}${page ? `page=${page}&` : ''}${searchString ? `search=${searchString}&` : ''}${orderBy ? `order_by=${orderBy}&` : ''}${sort ? `sort=${sort}` : ''}`;

  dispatch({ type: ACTION.FETCH_CUSTOMERS_INIT });

  const response = await client.get(url);

  const { data } = response;

  const {
    data: customers, links, next, page: dataPage, pages, total_elements,
  } = data;

  if (data) {
    if (customers && 'length' in customers && customers.length > 0) {
      dispatch({ type: ACTION.FETCH_CUSTOMERS_SUCCESS });
      dispatch({ type: ACTION.SET_STATE, label: 'customers', value: customers });
    } else {
      dispatch({
        type: ACTION.FETCH_CUSTOMERS_ERROR, error: 'No users returned from service',
      });
      dispatch({
        type: ACTION.FETCH_CUSTOMERS_ERROR, error: null,
      });
    }

    dispatch({ type: ACTION.SET_STATE, label: 'links', value: links });
    dispatch({ type: ACTION.SET_STATE, label: 'next', value: next });
    dispatch({ type: ACTION.SET_STATE, label: 'page', value: dataPage });
    dispatch({ type: ACTION.SET_STATE, label: 'pages', value: pages });
    dispatch({ type: ACTION.SET_STATE, label: 'links', value: total_elements });
  }
};

export const getNextPage = () => (dispatch, getState) => {
  const { page } = getState().registersState;
  dispatch(getRegisters(page + 1));
};

export const getPreviousPage = () => (dispatch, getState) => {
  const { page } = getState().registersState;
  dispatch(getRegisters(page - 1));
};

export const getClient = (customerType, customerID) => async (dispatch, getState) => {
  const url = `admin/${customerType}/${customerID}?is_new=true`;

  try {
    dispatch({ type: ACTION.FETCH_CUSTOMERS_INIT });
    const response = await client.get(url);
    const { data: customer } = response.data;

    dispatch({
      type: ACTION.SET_STATE,
      label: 'displayedCustomer',
      value: customer,
    });
    dispatch({ type: ACTION.FETCH_CUSTOMERS_SUCCESS });
  } catch (e) {
    console.log(e);
    dispatch({
      type: ACTION.FETCH_CUSTOMERS_ERROR, error: e,
    });
  }
};

export const updateCustomerStep = (customerID, step) => async (dispatch, getState) => {
  const url = `${appService.config.paths.registers.route}/${customerID}/step`;

  try {
    await client.put(url, { step_to: step }).then((response) => console.log(response))
      .then(window.location.reload());
  } catch (e) { console.log(e); }
};

export const restartSmsProcess = (customerID) => async (dispatch, getState) => {
  const url = `admin/users/account/${customerID}/restart-phone-number-validation`;
  try {
    await client.put(url).then((response) => console.log(response));
  } catch (e) {
    console.log(e);
  }
};

export const getVerificationCode = (userID) => async (dispatch, getState) => {
  const url = `admin/users/account/${userID}/verification-code`;
  let code;
  try {
    await client.get(url).then((response) => {
      code = response.data.code;
    });
    return Promise.resolve(code);
  } catch (e) {
    console.log(e);
    return Promise.reject(e);
  }
};

export const approveCustomerCredit = (customerID, approvedAccount, rejectedAccount) => async (dispatch, getState) => {
  const url = `admin/users/account/${customerID}/approved-user`;

  try {
    await client.put(url, {
      approved_account: approvedAccount,
      rejected_account: rejectedAccount,
    });
    return Promise.resolve();
  } catch (e) {
    console.log(e);
    return Promise.reject(e);
  }
};

export const rejectCustomerCredit = (customerID) => async (dispatch, getState) => {
  const url = `admin/users/account/${customerID}/credit-rejected`;

  try {
    await client.put(url, {
      rejected_reason: 'Posiblefraude',
    });
  } catch (e) {
    console.log(e);
  }
};

export const blockUserAccount = (customerID) => async (dispatch, getState) => {

};

export const getPhotoCategories = () => async (dispatch, getState) => {
  const url = `admin/inventory/photo-category`;
  try {
    const response = await client.get(url);
    const { data: categories } = response;
    dispatch({
      type: ACTION.SET_STATE,
      label: 'photoCategories',
      value: categories,
    });
  } catch (e) {
    console.log(e);
    return Promise.reject(e);
  }
};

export const getInventoryPhotos = (phoneNumber) => async (dispatch, getState) => {
  const url = `admin/inventory/photos/${encodeURIComponent(phoneNumber)}`;
  try {
    const response = await client.get(url);
    const { data: inventoryPhotos } = response;
    dispatch({
      type: ACTION.SET_STATE,
      label: 'inventoryPhotos',
      value: inventoryPhotos,
    });
  } catch (e) {
    console.log(e);
    return Promise.reject(e);
  }
};
