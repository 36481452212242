import axios from 'axios';

import client from '../client';
import { appService } from './app.service';

class ShopsService {
  getResourceList(queries = {}) {
    const url = `admin/shopkeepers/settlements${queries && Object.keys(queries).length > 0
      ? Object.keys(queries).reduce(
        (queryString, queryName, index) => `${queryString}${index !== 0 ? '&' : ''}${queryName}=${queries[queryName]}`, '?',
      )
      : ''}`;

    return client.get(url).then(({ data }) => data);
  }

  getSingleResource(resourceId, queries = {}) {
    const url = `admin/shopkeepers/settlements/${resourceId}${queries && Object.keys(queries).length > 0
      ? Object.keys(queries).reduce(
        (queryString, queryName, index) => `${queryString}${index !== 0 ? '&' : ''}${queryName}=${queries[queryName]}`, '?',
      )
      : ''}`;

    return client.get(url).then(({ data }) => data);
  }

  getTransactions(resourceId, queries = {}) {
    const url = `admin/shopkeepers/settlements/${resourceId}/transactions${queries && Object.keys(queries).length > 0
      ? Object.keys(queries).reduce(
        (queryString, queryName, index) => `${queryString}${index !== 0 ? '&' : ''}${queryName}=${queries[queryName]}`, '?',
      )
      : ''}`;
    return client.get(url).then(({ data }) => data);
  }

  async postOneTransaction(resourceId, transactions) {
    const url = `${appService.config.paths.api.basePath}/admin/shopkeepers/settlements/${resourceId}/transactions`;
    const token = localStorage.getItem('firebaseToken');

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token || null
      },
      body: JSON.stringify(transactions)
    };

    fetch(url, requestOptions)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error
    });
  }

  getSearchShopsList(queries = {}) {
    const url = `admin/shops${queries && Object.keys(queries).length > 0
      ? Object.keys(queries).reduce(
        (queryString, queryName, index) => `${queryString}${index !== 0 ? '&' : ''}${queryName}=${queries[queryName]}`, '?',
      )
      : ''}`;

    return client.get(url).then(({ data }) => data);
  }

  getTransactionTypes(queries = {}) {
    const url = `admin/transaction-types${queries && Object.keys(queries).length > 0
      ? Object.keys(queries).reduce(
        (queryString, queryName, index) => `${queryString}${index !== 0 ? '&' : ''}${queryName}=${queries[queryName]}`, '?',
      )
      : ''}`;

    return client.get(url).then(({ data }) => data.filter(d => d.transaction_type_id !== 'Buy' && d.transaction_type_id !== 'Pay'));
  }

  postTransaction(transaction) {
    const url = 'admin/transactions';

    return client.post(url, transaction).then((response) => response);
  }

  getCreditLineShop(queries = {}) {
    const url = `admin/shop-loan/credit-line${queries && Object.keys(queries).length > 0
      ? Object.keys(queries).reduce(
        (queryString, queryName, index) => `${queryString}${index !== 0 ? '&' : ''}${queryName}=${queries[queryName]}`, '?',
      )
      : ''}`;

    return client.get(url).then(({ data }) => data);
  }

  getCreditLineShopById(creditLineId, queries = {}) {
    const url = `admin/shop-loan/credit-line/${creditLineId}${queries && Object.keys(queries).length > 0
      ? Object.keys(queries).reduce(
        (queryString, queryName, index) => `${queryString}${index !== 0 ? '&' : ''}${queryName}=${queries[queryName]}`, '?',
      )
      : ''}`;

    return client.get(url).then(({ data }) => data);
  }

  getTransactionsShopById(shopkeeper_id, date) {
    const url = `admin/shopkeeper/${shopkeeper_id}/home?date=${date}`;
    return client.get(url).then(({ data }) => data);
  }

  getLegacyTransactions(account_id) {
    const url = `admin/shopkeeper/${account_id}/legacy-transactions`;
    return client.get(url).then(({ data }) => data);
  }

  getAllShopsDetail(queries = {}) {
    const url = `admin/shops${queries && Object.keys(queries).length > 0
      ? Object.keys(queries).reduce(
        (queryString, queryName, index) => `${queryString}${index !== 0 ? '&' : ''}${queryName}=${queries[queryName]}`, '?',
      )
      : ''}`;
    return client.get(url).then(({ data }) => data);
  }

  getShokeeperById(shopkeeper_id) {
    const url = `admin/shopkeeper/${shopkeeper_id}`;
    return client.get(url).then(({ data }) => data);
  }

  getReferencesById(shopkeeper_id) {
    const url = `admin/inventory/references?account_type_id=SK&account_id=${shopkeeper_id}`;
    return client.get(url).then(({ data }) => data);
  }

  setAllowDisbursement(creditLineId, body) {
    const url = `admin/shop-loan/credit-line/${creditLineId}/allow-disbursements`;
    return client.put(url, body).then((response) => response);
  }

  setAllowExtension(creditLineId, value) {
    const url = `admin/shop-loan/credit-line/${creditLineId}/allow-extensions`;
    const body = {
      "allow": value
    }
    return client.put(url, body).then((response) => response);
  }

  getShopBlockedById(creditLineId, queries = {}) {
    const url = `admin/shop-loan/credit-line/${creditLineId}/allow-disbursements`;
    return client.get(url).then(({ data }) => data);
  }

  getShopMovementsById(creditLineId) {
    const url = `admin/shop-loan/credit-line/${creditLineId}/movements`;
    return client.get(url).then(({ data }) => data);
  }

  getConektaReference(accountId, accountTypeId) {
    const url = `admin/conekta-reference/${accountTypeId}/${accountId}`;
    return client.get(url).then(({ data }) => data);
  }

  getArcusReference(accountId, accountTypeId) {
    const url = `admin/arcus-reference/${accountTypeId}/${accountId}`;
    return client.get(url).then(({ data }) => data);
  }

  postLightExtension(creditLineId, body) {
    const url = `admin/shop-loan/credit-line/${creditLineId}/extension`;
    return client.post(url, body).then((response) => response);
  }

  getStatus(user_type, user_id) {
    const url = `admin/shop-loan/credit-line-request/${user_type}/${user_id}/requirements`;
    return client.get(url).then(({ data }) => data);
  }
}

export default new ShopsService();
