export const FETCH_SHOPS_INIT = 'SHOPS::FETCH_SHOPS_INIT';
export const FETCH_SHOPS_SUCCESS = 'SHOPS::FETCH_SHOPS_SUCCESS';
export const FETCH_SHOPS_ERROR = 'SHOPS::FETCH_SHOPS_ERROR';
export const SET_STATE = 'SHOPS::SET_STATE';

export const ADD_SELECTED_SHOP = 'SHOP::ADD_SELECTED_SHOP';
export const DELETE_SELECTED_SHOP = 'SHOP::DELETE_SELECTED_SHOP';
export const CLEAR_SELECTED_SHOPS = 'SHOP::CLEAR_SELECTED_SHOPS';

export const ADD_SETTLEMENT_TRANSACTION = 'SHOP::ADD_SETTLEMENT_TRANSACTION';

export const FETCH_SHOPS_COLLECTION_INIT = 'COLLECTION::FETCH_SHOPS_COLLECTION_INIT';
export const FETCH_SHOPS_COLLECTION_SUCCESS = 'COLLECTION::FETCH_SHOPS_COLLECTION_SUCCESS';
export const FETCH_SHOPS_COLLECTION_ERROR = 'COLLECTION::FETCH_SHOPS_COLLECTION_ERROR';
export const SET_STATE_COLLECTION = 'COLLECTION::SET_STATE_COLLECTION';
