import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useRouteMatch } from 'react-router';
import {
  TableContainer, Table, TableBody, TableRow, TableCell, TableFooter,
  TextField,
} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from './PaginationTable.style';
import Text from '../../../components/Text';
import ArrowedText from '../../../components/ArrowedText';
import Button from '../../../components/Button';
import { Box } from '../../../components/Layout';
import { OrderedTableHead } from './SettlementsTableHead';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import { moneyFunction } from '../moneyFunction';
import { statusType } from '../../../constants/constants';
import { toggleSelectedShop } from '../ShopsPage.actions';

export const SettlementsTable = (props) => {
  const { resources } = props;
  const classes = useStyles();
  const { url } = useRouteMatch();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const { selectedShops } = useSelector((state) => state.shopsState);

  const [selectedSettlement, setSelectedSettlement] = useState({});

  const [displayedSettlementsInfo, setDisplayedSettlementsInfo] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (resources.length > 0) {
      setDisplayedSettlementsInfo(resources.map((resource) => ({ key: `${resource.shopkeeper_id}-${resource.settlement_id}` })));
    }
  }, [resources]);

  const handleInput = (e, settlementKey) => {
    const key = e.target.name;

    const currentSettlementIndex = displayedSettlementsInfo.findIndex((displayedSettlement) => displayedSettlement.key === settlementKey);

    const currentSettlement = { ...displayedSettlementsInfo[currentSettlementIndex], [key]: e.target.value };

    const newDisplayedSettlements = [...displayedSettlementsInfo];

    newDisplayedSettlements[currentSettlementIndex] = currentSettlement;

    setDisplayedSettlementsInfo(newDisplayedSettlements);
  };

  const updateSettlement = (settlement, settlementKey) => {
    const settlementDetails = displayedSettlementsInfo.find((settlement) => settlement.key === settlementKey);

    const numAmount = parseFloat(settlementDetails.amount);

    const newSelectedSettlement = {
      ...settlementDetails,
      amount: numAmount,
      settlement_id: settlement.settlement_id,
      shopkeeper_id: settlement.shopkeeper_id,
      shopkeeper_id_simple: settlement.shopkeeper_id_simple,
      shop_name: settlement.shop_name,
      transaction_type: 'WITHDRAW',
      amount_text: moneyFunction(numAmount),
      bank_reference: settlement.shopkeeper_id_simple,
    };
    const dobleAccountId = String(newSelectedSettlement.verification_account_id).toLowerCase();
    const accountId = String(newSelectedSettlement.shopkeeper_id_simple).toLowerCase();

    if (numAmount > 0 && accountId === dobleAccountId) {
      setSelectedSettlement(newSelectedSettlement);
      setIsModalVisible(true);
    } else {
      alert('La validación no es correcta. Los números de cuenta no coinciden o el monto no es mayor a 0.');
    }
  };

  return (
    <Box>
      <TableContainer className={classes.tableContainer}>
        <Table aria-label="custom pagination table">
          <OrderedTableHead />
          <TableBody>
            {
              resources.map((settlement, index) => (
                <TableRow key={`${settlement.shopkeeper_id}-${settlement.settlement_id}`} style={{ maxHeight: 43 }}>
                  <TableCell>
                    <Text className={classes.textBoldStyle}>{settlement.shopkeeper_id_simple}</Text>
                  </TableCell>
                  <TableCell>
                    <Text className={classes.textStyle}>{settlement.shop_name ? settlement.shop_name : '---' }</Text>
                  </TableCell>
                  <TableCell>
                    <Text className={classes.textStyle}>{settlement.email ? settlement.email : '---' }</Text>
                  </TableCell>
                  <TableCell>
                    <Text className={classes.textStyle}>{settlement.shopkeeper_account_bank ? settlement.shopkeeper_account_bank : '---'}</Text>
                  </TableCell>
                  <TableCell>
                    <Text className={classes.textStyle}>{settlement.total_payments}</Text>
                  </TableCell>
                  <TableCell>
                    <Text className={classes.textStyle}>{settlement.total_collections}</Text>
                  </TableCell>
                  <TableCell>
                    <Text className={classes.textBoldStyle} style={{ color: 'green' }}>{settlement.balance}</Text>
                  </TableCell>
                  <TableCell>
                    { settlement.settled_amount !== '$0.00'
                      ? (<Text className={classes.textStyle} style={{ textAlign: 'center' }}>{settlement.settled_amount}</Text>)
                      : (
                        <TextField
                          type="text"
                          name="amount"
                          onBlur={(event) => handleInput(event, `${settlement.shopkeeper_id}-${settlement.settlement_id}`)}
                        />
                      )}
                  </TableCell>
                  <TableCell>
                    { settlement.verification_account_id !== ''
                      ? (<Text className={classes.textStyle} style={{ textAlign: 'center' }}>{settlement.verification_account_id}</Text>)
                      : (
                        <TextField
                          type="text"
                          name="verification_account_id"
                          onBlur={(event) => handleInput(event, `${settlement.shopkeeper_id}-${settlement.settlement_id}`)}
                        />
                      )}
                  </TableCell>
                  <TableCell>
                    { settlement.status !== 'PENDING'
                      ? (<Text className={classes.textStyle} style={{ textAlign: 'center' }}>{statusType[settlement.status]}</Text>)
                      : (
                        <Button
                          onClick={() => {
                            updateSettlement(settlement, `${settlement.shopkeeper_id}-${settlement.settlement_id}`);
                          }}
                          style={{ backgroundColor: '#0253cc', color: 'white' }}
                        >
                          Liquidar
                        </Button>
                      )}
                  </TableCell>
                  <TableCell>
                    <Text className={classes.textStyle} style={{ textAlign: 'center' }}>{statusType[settlement.status]}</Text>
                  </TableCell>
                  <TableCell>
                    <Link to={`${url}/transactions/${settlement.shopkeeper_id}`}>
                      <Button>
                        <ArrowedText>
                          Ver detalle
                        </ArrowedText>
                      </Button>
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      checked={!!selectedShops.find((selectedShop) => selectedShop.key === `${settlement.shopkeeper_id}-${settlement.settlement_id}`)}
                      onChange={(event) => dispatch(toggleSelectedShop(settlement, event.target.checked))}
                    />
                  </TableCell>
                </TableRow>
              ))
            }
          </TableBody>
          <TableFooter />
        </Table>
      </TableContainer>
      <ConfirmationModal
        title="Confirmación de Liquidación"
        open={isModalVisible}
        setOpen={setIsModalVisible}
        selectedSettlement={selectedSettlement}
        type={'postOneTransaction'}
      />
    </Box>
  );
};

export default SettlementsTable;
