import * as ACTIONS from './loan.actionTypes';

const initialState = {
  isFetchingShops: true,
  treasuryList: [],
  selectedTreasuryElements: [],
  searchTreasuryElement: [],
  updateSuccessfullyTreasury: true,
  updateTreasuryErrorMsg: '',
};

const loanReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.FETCH_TREASURY_INIT:
      return { ...state, isFetchingShops: true, updateTreasuryErrorMsg: '' };
    case ACTIONS.FETCH_TREASURY_SUCCESS:
      return {
        ...state, isFetchingShops: false, error: null, updateTreasuryErrorMsg: '',
      };
    case ACTIONS.FETCH_TREASURY_ERROR:
      return { ...state, isFetchingShops: false, error: action.error };
    case ACTIONS.SET_STATE:
      return {
        ...state, [action.payload.label]: action.payload.value,
      };
    case ACTIONS.ADD_SELECTED_TREASURY: {
      const { selectedTreasuryElements } = state;
      const containsSelectedTreasuryElement = selectedTreasuryElements.find((selectedTreasuryElement) => `${selectedTreasuryElement.account_id}-${selectedTreasuryElement.credit_line_id}` === `${action.payload.account_id}-${action.payload.credit_line_id}`);

      if (!containsSelectedTreasuryElement) {
        return {
          ...state,
          selectedTreasuryElements: [...state.selectedTreasuryElements, action.payload],
        };
      }
      return state;
    }
    case ACTIONS.DELETE_SELECTED_TREASURY: {
      const { selectedTreasuryElements } = state;

      const filteredSelectedTreasuryElements = selectedTreasuryElements.filter((selectedTreasuryElement) => `${selectedTreasuryElement.account_id}-${selectedTreasuryElement.credit_line_id}` !== `${action.payload.account_id}-${action.payload.credit_line_id}`);

      return {
        ...state,
        selectedTreasuryElements: [...filteredSelectedTreasuryElements],
      };
    }
    case ACTIONS.CLEAR_SELECTED_TREASURY:
      return {
        ...state,
        selectedTreasuryElements: [],
      };
    case ACTIONS.FETCH_TREASURY_UPDATE_SUCCESS:
      return {
        ...state,
        updateTreasuryErrorMsg: '',
        updateSuccessfullyTreasury: true,
      };
    case ACTIONS.FETCH_TREASURY_UPDATE_CLEAR:
      return {
        ...state,
        updateTreasuryErrorMsg: '',
        updateSuccessfullyTreasury: false,
      };
    case ACTIONS.FETCH_TREASURY_UPDATE_ERROR:
      return {
        ...state,
        updateTreasuryErrorMsg: 'Hubo un error procesando el pago',
        updateSuccessfullyTreasury: false,
      };
    default:
      return { ...state };
  }
};

export default loanReducer;
