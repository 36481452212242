import React from 'react';
import ErrorIcon from '@material-ui/icons/Error';
import PropTypes from 'prop-types';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import useStyles from './MissingDataAlert.styles';
import alertStatus from './alertStatus';
import { Box } from '../Layout';
import Text from '../Text';

const MissingDataAlert = ({ status, text }) => {
  const classes = useStyles();

  let icon;


  switch (status) {
    case alertStatus.GOOD:
      icon = <CheckCircleIcon className={classes.iconClass} htmlColor="#14b497" />;
      break;
    case alertStatus.BAD:
      icon = <ErrorIcon className={classes.iconClass} htmlColor="#e23b3b" />;
      break;
    case alertStatus.ALERT:
      break;
    default:
      icon = <ErrorIcon className={classes.iconClass} htmlColor="#e23b3b" />;
      break;
  }


  return (
    <Box className={classes.Notice}>
      {icon}
      <Text className={classes.textClass}>
        {text}
      </Text>
    </Box>
  );
};

MissingDataAlert.propTypes = {
  status: PropTypes.oneOf(Object.keys(alertStatus)).isRequired,
};

export default MissingDataAlert;
