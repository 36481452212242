import React from "react";

import PageContainer from '../../components/PageContainer';
import Box from '../../components/Layout/Box';
import SectionTitle from '../../components/SectionTitle';
import TableTreasuryDealer from "../../components/TableTreasuryDealer";

function DisbursementDealer({
    classes,
}) {
    return (
        <PageContainer>
            <Box className={classes.rootContainer}>
                <SectionTitle>Tesorería-distribuidor</SectionTitle>
                <Box className={classes.tableContainer}>
                    <TableTreasuryDealer />
                </Box>
            </Box>
        </PageContainer>
    )
}

export default DisbursementDealer;