export const FETCH_SHOPS_INIT = 'SHOP::FETCH_SHOPS_INIT';
export const FETCH_SHOPS_SUCCESS = 'SHOP::FETCH_SHOPS_SUCCESS';
export const FETCH_SHOPS_ERROR = 'SHOP::FETCH_SHOPS_ERROR';
export const SET_STATE = 'SHOP::SET_STATE';

export const FETCH_ALL_SHOPS_INIT = 'SHOP::FETCH_ALL_SHOPS_INIT';
export const FETCH_ALL_SHOPS_SUCCESS = 'SHOP::FETCH_ALL_SHOPS_SUCCESS';
export const FETCH_ALL_SHOPS_ERROR = 'SHOP::FETCH_ALL_SHOPS_ERROR';
export const SET_STATE_ALL_SHOPS = 'SHOP::SET_STATE_ALL_SHOPS';

export const ADD_SELECTED_SHOP = 'SHOP_CREDIT::ADD_SELECTED_SHOP';
export const DELETE_SELECTED_SHOP = 'SHOP_CREDIT::DELETE_SELECTED_SHOP';
export const CLEAR_SELECTED_SHOPS = 'SHOP_CREDIT::CLEAR_SELECTED_SHOPS';

export const FETCH_SHOKEEPER_BY_ID_INIT = 'SHOP::FETCH_SHOKEEPER_BY_ID_INIT';
export const FETCH_SHOKEEPER_BY_ID_SUCCESS = 'SHOP::FETCH_SHOKEEPER_BY_ID_SUCCESS';
export const FETCH_SHOKEEPER_BY_ID_ERROR = 'SHOP::FETCH_SHOKEEPER_BY_ID_ERROR';
export const SET_STATE_SHOKEEPER_BY_ID = 'SHOP::SET_STATE_SHOKEEPER_BY_ID';

export const FETCH_REFERENCES_BY_ID_INIT = 'SHOP::FETCH_REFERENCES_BY_ID_INIT';
export const FETCH_REFERENCES_BY_ID_SUCCESS = 'SHOP::FETCH_REFERENCES_BY_ID_SUCCESS';
export const FETCH_REFERENCES_BY_ID_ERROR = 'SHOP::FETCH_REFERENCES_BY_ID_ERROR';
export const SET_STATE_REFERENCES_BY_ID = 'SHOP::SET_STATE_REFERENCES_BY_ID';