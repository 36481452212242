import React, { useEffect, useState } from 'react';
import { Divider, IconButton, InputBase, Snackbar } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import PageContainer from '../../components/PageContainer/PageContainer';
import useStyles from './CreditExtensions.styles';
import { Box } from '../../components/Layout';
import SectionTitle from '../../components/SectionTitle';
import CreditTable from './CreditTable';
import { useDispatch, useSelector } from 'react-redux';
import { getCreditLineExtensionRequests } from '../../reducers/creditline.action';
import { Alert } from '@material-ui/lab';
import { useLocation, useHistory } from 'react-router-dom';
import { Link } from "react-router-dom";
import Button from '../../components/Button';
import { useRouteMatch } from 'react-router';
const CreditExtensions = () => {
  const location = useLocation();
  const history = useHistory();
  let shopName = location.state && location.state.shopName;
  const dispatch = useDispatch();
  const { extensionRequestData, error: updateExtensionRequestDataError } = useSelector(
    (state) => state.creditLineReducer
  );
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState(extensionRequestData?.data);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isError, setIsError] = useState(false);
  const { url } = useRouteMatch();
  const classes = useStyles();

  const filterData = () => {
    const filtered = extensionRequestData?.data?.filter((item) => {
      const lowerCaseSearch = searchTerm.toLowerCase();
      return (
        item.shop_name.toLowerCase().includes(lowerCaseSearch) ||
        item.shop_phone.includes(searchTerm) ||
        item.shop_id.toString().includes(searchTerm)
      );
    });
    setFilteredData(filtered);
  };

  useEffect(() => {
    dispatch(getCreditLineExtensionRequests());
  }, [dispatch]);

  useEffect(() => {
    filterData();
  }, [searchTerm, extensionRequestData?.data]);

  useEffect(() => {
    if (shopName !== undefined) {
      setSnackbarOpen(true);
      setTimeout(() => {
        setSnackbarOpen(false); // Cierra la Snackbar
        shopName = undefined;
        setTimeout(() => {
          window.location.href = '/extensions';
        }, 250);
      }, 2500); 
    }
  }, [shopName]);


  useEffect(() => {
    if (updateExtensionRequestDataError?.PUT_EXTENSION_REQUEST) {
      if (updateExtensionRequestDataError?.PUT_EXTENSION_REQUEST != null) {
        setIsError(true);
      }
    }
  }, [updateExtensionRequestDataError?.PUT_EXTENSION_REQUEST]);

  return (
    <PageContainer>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={3500}
        open={snackbarOpen}
        onClose={() => {
          setSnackbarOpen(false);
          history.push('/extensions');
        }}
        className={classes.snackbar}
      >
        <Alert severity={isError ? 'error' : 'success'}>
          {isError
            ? updateExtensionRequestDataError?.PUT_EXTENSION_REQUEST?.response?.data?.readable_message
            : `Se actualizó la ampliación para ${shopName}`}
        </Alert>
      </Snackbar>
      <Box className={classes.rootContainer}>
        <Box
          className={classes.topContainer}
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <SectionTitle>Ampliaciones</SectionTitle>
          <Box className={classes.actionsContainer}>
            <Box component="form" className={classes.searchContainer}>
              <InputBase
                onChange={(event) => {
                  setSearchTerm(event.target.value);
                }}
                className={classes.input}
                placeholder="Buscar por nombre de tienda, telefono o ID"
                inputProps={{ 'aria-label': 'search user' }}
                onKeyUp={(event) => {
                  if (event.key === 'Enter') {
                    filterData();
                  }
                }}
              />
              <Divider className={classes.divider} orientation="vertical" />
              <IconButton
                onClick={() => {
                  filterData();
                }}
                className={classes.iconButton}
                aria-label="search"
              >
                <SearchIcon />
              </IconButton>
            </Box>
            <Box className={classes.buttonTop}>
            <Link to={'/extensions/create'}>
              <Button style={{ backgroundColor: '#0253cc', color: 'white' }}>
                Crear solicitud de ampliación
              </Button>
            </Link>
          </Box>
          </Box>
        </Box>
        <CreditTable resources={filteredData} />
      </Box>
    </PageContainer>
  );
};

export default CreditExtensions;
