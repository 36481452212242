import React, { useState } from 'react';
import {
  TableContainer, Table, TableBody, TableRow, TableCell, TableFooter,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import useStyles from './PaginationTable.style';
import Text from '../../components/Text';
import ArrowedText from '../../components/ArrowedText';
import Button from '../../components/Button';
import { Box } from '../../components/Layout';
import { OrderedTableHead } from './ShopTableHead';
import {utcSimpleDateFunction} from './dateFunction';
import { statusType } from '../../constants/constants';

export const ShopsTable = (props) => {
  const { resourceType, resources } = props;
  const classes = useStyles();
  return (
    <Box>
      <TableContainer className={classes.tableContainer}>
        <Table aria-label="custom pagination table">
          <OrderedTableHead resourceType={resourceType} />
          <TableBody>
            {
              resources.map((settlement, index) => (
                <TableRow key={index} style={{ maxHeight: 43 }}>
                  <TableCell>
                    <Text className={classes.textStyle} style={{ textAlign: 'center' }}>
                    {utcSimpleDateFunction(settlement.date)}
                    </Text>
                  </TableCell>
                  <TableCell>
                    <Text className={classes.textStyle} style={{ textAlign: 'center' }}>
                      {settlement.settlement_id_simple}
                    </Text>
                  </TableCell>
                  <TableCell>
                    <Text className={classes.textStyle} style={{ textAlign: 'center' }}>
                      {settlement.number_of_shops}
                    </Text>
                  </TableCell>
                  <TableCell>
                    <Text className={classes.textStyle} style={{ textAlign: 'center' }}>
                      {settlement.total_payments}
                    </Text>
                  </TableCell>
                  <TableCell>
                    <Text className={classes.textStyle} style={{ textAlign: 'center' }}>
                      {settlement.total_collections}
                    </Text>
                  </TableCell>
                  <TableCell>
                    <Text className={classes.textStyle} style={{ textAlign: 'center' }}>
                      {settlement.balance}
                    </Text>
                  </TableCell>
                  <TableCell>
                    <Text className={classes.textStyle} style={{ textAlign: 'center' }}>{statusType[settlement.status]}</Text>
                  </TableCell>
                  <TableCell>
                    <Button>
                      X
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Link to={`/shops/${resourceType}/${settlement.settlement_id}`}>
                      <Button>
                        <ArrowedText>
                          Ver detalle
                        </ArrowedText>
                      </Button>
                    </Link>
                  </TableCell>
                </TableRow>
              ))
            }
          </TableBody>
          <TableFooter />
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ShopsTable;
