import * as ACTIONS from './transactions.actionTypes';

const initialState = {
  isFetchingTransaction: false,
  transactionsDetail: [],
  error: null,
};

const transactionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.FETCH_TRANSACTIONS_INIT:
      return { ...state, isFetchingTransaction: true };
    case ACTIONS.FETCH_TRANSACTIONS_SUCCESS:
      return {
        ...state, isFetchingTransaction: false, error: null,
      };
    case ACTIONS.FETCH_TRANSACTIONS_ERROR:
      return { ...state, isFetchingTransaction: false, error: action.error };
    case ACTIONS.SET_STATE:
      return {
        ...state, [action.payload.label]: action.payload.value, isFetchingTransaction: false, error: null
      };
    default:
      return { ...state };
  }
};

export default transactionsReducer;