import * as ACTIONS from '../../reducers/warehouse.actionTypes';
import warehouseService from '../../services/warehouse.service';

export const searchWareHouse = (searchParam) => async (dispatch, getState) => {
  dispatch({
    type: ACTIONS.FETCH_SEARCHWAREHOUSE_INIT,
  });

  try {
    const warehouseListResponse = await warehouseService.getWareHouse(searchParam);
    if (warehouseListResponse) {
      dispatch({
        type: ACTIONS.FETCH_WAREHOUSE_SUCCESS,
        response: warehouseListResponse
      });
    } else {
      dispatch({
        type: ACTIONS.FETCH_WAREHOUSE_ERROR,
        error: 'No hay bodegas'
      });
    }
  } catch (e) {
    dispatch({
      type: ACTIONS.FETCH_WAREHOUSE_ERROR,
      error: e.toString()
    });
  }
};

export const clearWareHoseState = () => async (dispatch, getState) => {
  dispatch({
    type: ACTIONS.CLEAR_WAREHOUSE_STATE,
  });
};