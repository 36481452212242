import * as ACTION from './insurance.actionTypes';

const dataInsurance = {
    loading: {
        GET_POLICY_STATEMENT_CLOSING: false,
        GET_POLICY: false,
    },
    error: {
        GET_POLICY_STATEMENT_CLOSING: null,
        GET_POLICY: null,
    },

    dataPolicyStatement: {},
    dataPolicy: {},
};  

const insuranceReducer = (state = dataInsurance, action) => {
    switch (action.type) {
        case ACTION.INIT_GET_POLICY_STATEMENT_CLOSING:
            return { ...state, loading: { ...state.loading, GET_POLICY_STATEMENT_CLOSING: true }, error: { ...state.error, GET_POLICY_STATEMENT_CLOSING: null } };
        case ACTION.RESPONSE_GET_POLICY_STATEMENT_CLOSING:
            return {
                ...state, loading: { ...state.loading, GET_POLICY_STATEMENT_CLOSING: false }, error: { ...state.error, GET_POLICY_STATEMENT_CLOSING: null }, dataPolicyStatement: action.result,
            };
        case ACTION.ERROR_GET_POLICY_STATEMENT_CLOSING:
            return { ...state, loading: { ...state.loading, GET_POLICY_STATEMENT_CLOSING: false }, error: { ...state.error, GET_POLICY_STATEMENT_CLOSING: action.error } };
        case ACTION.CLEAR_GET_POLICY_STATEMENT_CLOSING:
            return { ...state, dataPolicyStatement: {} };
        
        case ACTION.INIT_GET_POLICY:
            return { ...state, loading: { ...state.loading, GET_POLICY: true }, error: { ...state.error, GET_POLICY: null } };
        case ACTION.RESPONSE_GET_POLICY:
            return {
                ...state, loading: { ...state.loading, GET_POLICY: false }, error: { ...state.error, GET_POLICY: null }, dataPolicy: action.result,
            };
        case ACTION.ERROR_GET_POLICY:
            return { ...state, loading: { ...state.loading, GET_POLICY: false }, error: { ...state.error, GET_POLICY: action.error } };
        case ACTION.CLEAR_GET_POLICY:
            return { ...state, dataPolicy: {} };
            
        default:
            return state;
    }
};

export default insuranceReducer;