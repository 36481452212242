import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    rootContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '95%',
        paddingTop: 15,
        paddingLeft: 30,
        paddingRight: 30,
    },
    tableContainer: {
        marginTop: 20,
    },
}));

export default useStyles;
