import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '../../components/Button';
import { Box } from '../../components/Layout';
import { getWeater as fetchWeater } from './dashboardActions';
import Text from '../../components/Text';
import useStyles from './DashboardPage.styles';

export const DashboardPage = (props) => {
  const getInfo = () => {
    const { getWeater } = props;
    getWeater('06500');
  };

  const handeClick = (where) => () => {
    const { history } = props;
    history.push(`/${where}`);
  };

  const renderWeatherInfo = (isLoading, info, error) => {
    if (isLoading) {
      return (
        <Box display='flex' flexDirection='row' justifyContent='center'>
          <Text variant='body2' color='secondary'>Loading</Text>
        </Box>
      );
    }

    if (info && info.main) {
      return (
        <Box>
          <Text variant='h3' align='center'>Weather</Text>
          <Text variant='body2' align='center'>{` Where: ${info.name}`}</Text>
          <Text variant='body2' align='center'>{` Temp: ${info.main.temp} c`}</Text>
          <Text variant='body2' align='center'>{` Temp Min: ${info.main.temp_min} c`}</Text>
          <Text variant='body2' align='center'>{` Temp Max: ${info.main.temp_min} c`}</Text>
        </Box>
      );
    }
    if (error) {
      return (
        <Box display='flex' flexDirection='row' justifyContent='center'>
          <Text variant='body2' color='secondary'>Error</Text>
        </Box>
      );
    }
    return null;
  };

  const { weather, loadingWeather, errorWeather } = props;
  const classes = useStyles();
  useEffect(getInfo, []);

  return (
    <Box className={classes.page}>
      <Box className={classes.container}>
        <Text variant='h1' color='primary' align='center'>Dashboard Page</Text>
        {renderWeatherInfo(loadingWeather, weather, errorWeather)}
        <Box className={classes.actionsSection} display='flex' flexDirection='row' justifyContent='center'>
          <Button variant='contained' color='primary' onClick={handeClick('about')}>Go to About Page</Button>
          <div style={{ width: '15px' }} />
          <Button variant='contained' color='primary' onClick={handeClick('home')}>Go to Home Page</Button>
          <div style={{ width: '15px' }} />
          <Button variant='contained' color='primary' onClick={handeClick('milesaway')}>Go another place</Button>
        </Box>
      </Box>
    </Box>
  );
};


DashboardPage.propTypes = {
  weather: PropTypes.shape({}).isRequired,
  loadingWeather: PropTypes.bool,
  errorWeather: PropTypes.shape({}),
  getWeater: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired
};

DashboardPage.defaultProps = {
  loadingWeather: false,
  errorWeather: null,
};

const mapStateToProps = (state) => ({
  weather: state.get('dashboardState').weather,
  loadingWeather: state.get('dashboardState').loadingWeather,
  errorWeather: state.get('dashboardState').errorWeather
});

const mapDispatchToProps = (dispatch) => ({
  getWeater: (zc) => dispatch(fetchWeater(zc))
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage);
