import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  rootContainer: {},
  controls: {
    display: 'flex',
    position: 'relative',
    top: 0,
    width: '100%',
    marginTop: '22px',
    color: '#858d9c',
    alignItems: 'center',
  },
  customerInfo: {
    backgroundColor: '#f4f8ff',
  },
  mainPanel: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-start',
  },
  StepsInfoContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    width: '100%',
    margin: '2%',
  },
  stepArrowContainer: {
    marginLeft: -15,
  },
  stepArrowLabel: {
    color: 'white',
    display: 'flex',
    width: 150,
    marginLeft: 20,
    '& svg': {
      paddingLeft: 5,
      paddingRight: 5,
    },
    '& p': {
      fontWeight: 'bold',
      fontSize: 12,
      textAlign: 'center',
    },
  },
  stepArrowChild: {},
  validationContainer: {
    fontFamily: 'Poppins',
    display: 'block',
    fontSize: '.7rem',
    '& p': {
      marginTop: 5,
      marginBottom: 5,
    },
  },
  copyBtn: {
    cursor: 'pointer',
    marginLeft: '10px',
    height: '18px',
    '&:active': { backgroundColor: '#2252C5', borderRadius: 5 },
  },
  lostLeadBtn: {
    maxHeight: 38,
    maxWidth: 150,
    width: '100%',
    height: '20%',
    paddingLeft: '5%',
    paddingRight: '5%',
    position: 'absolute',
    top: '14%',
    right: '29%',
  },
  controlButtons: {
    marginRight: 8,
    color: '#505f79',
    maxHeight: 50,
    maxWidth: 150,
    borderColor: 'rgb(2, 83, 204,0.5)',
    paddingLeft: '5%',
    paddingRight: '5%',
  },
}));

export default useStyles;
