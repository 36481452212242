import React, { useEffect } from 'react';
import {
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from './OrderedTableHead.style';
import Text from '../../components/Text';

export const OrderedTableHead = (props) => {
  const { resourceType } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { page, orderBy, sort } = useSelector((state) => state.customersState);

  useEffect(() => {
  }, [orderBy, sort]);
  
  const headCells = [
    {
      id: 'date', numeric: false, disablePadding: false, label: 'Fecha',
    },
    {
      id: 'settlement_number', numeric: false, disablePadding: false, label: 'No. Corte',
    },
    {
      id: 'total_shops', numeric: true, disablePadding: false, label: 'Total de tiendas',
    },
    {
      id: 'total_sales', numeric: true, disablePadding: false, label: 'Total de ventas',
    },
    {
      id: 'total_charges', numeric: true, disablePadding: false, label: 'Total de cobros',
    },
    {
      id: 'balance', numeric: true, disablePadding: false, label: 'Balance del corte',
    },
    {
      id: 'status', numeric: false, disablePadding: false, label: 'Estatus',
    },
    {
      id: 'settle_all', numeric: true, disablePadding: false, label: resourceType === 'settlements' ? 'Liquidar Todas' : 'Enviar nota de cobro',
    },
    {
      id: 'show_shops', label: 'Ver tiendas',
    },
  ];

  return (
    <TableHead className={classes.tableHead}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            align="center"
            scope="col"
            sort={orderBy === headCell.id ? sort : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? sort : 'asc'}

              onClick={() => {
                if (orderBy === headCell.id) {
                  /*
                   dispatch({
                    type: ACTIONS.SET_STATE,
                    label: 'sort',
                    value: (sort === 'asc' ? 'desc' : 'asc'),
                  });
                  dispatch({
                    type: ACTIONS.SET_STATE,
                    label: 'page',
                    value: 0,
                  });
                   */

                } else {
                  /*
                     dispatch({
                    type: ACTIONS.SET_STATE,
                    label: 'orderBy',
                    value: headCell.id,
                  });
                  dispatch({
                    type: ACTIONS.SET_STATE,
                    label: 'page',
                    value: 0,
                  });
                   */
                }
              }}
            >
              <Text className={classes.titleLabel}>{headCell.label}</Text>
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default OrderedTableHead;
