import * as ACTION from "./appointment.actionTypes";

const dataAppointment = {
  loading: {
    GET_APPOINTMENT: false,
    PUT_APPOINTMENT: false,
  },
  error: {
    GET_APPOINTMENT: null,
    PUT_APPOINTMENT: null,
  },

  appointmentData: {},
  appointmentPutData: {},
};

const appointmentReducer = (state = dataAppointment, action) => {
  switch (action.type) {
    case ACTION.INIT_GET_APPOINTMENT:
      return {
        ...state,
        loading: { ...state.loading, GET_APPOINTMENT: true },
        error: { ...state.error, GET_APPOINTMENT: null },
      };
    case ACTION.RESPONSE_GET_APPOINTMENT:
      return {
        ...state,
        loading: { ...state.loading, GET_APPOINTMENT: false },
        error: { ...state.error, GET_APPOINTMENT: null },
        appointmentData: action.result,
      };
    case ACTION.ERROR_GET_APPOINTMENT:
      return {
        ...state,
        loading: { ...state.loading, GET_APPOINTMENT: false },
        error: { ...state.error, GET_APPOINTMENT: action.error },
      };
    case ACTION.CLEAN_GET_APPOINTMENT:
      return { ...state, appointmentData: {} };
    case ACTION.INIT_GET_APPOINTMENT:
      return {
        ...state,
        loading: { ...state.loading, GET_APPOINTMENT: true },
        error: { ...state.error, GET_APPOINTMENT: null },
      };
    case ACTION.RESPONSE_PUT_APPOINTMENT:
      return {
        ...state,
        loading: { ...state.loading, PUT_APPOINTMENT: false },
        error: { ...state.error, PUT_APPOINTMENT: null },
        appointmentPutData: action.result,
      };
    case ACTION.ERROR_PUT_APPOINTMENT:
      return {
        ...state,
        loading: { ...state.loading, PUT_APPOINTMENT: false },
        error: { ...state.error, PUT_APPOINTMENT: action.error },
      };
    case ACTION.CLEAN_PUT_APPOINTMENT:
      return { ...state, appointmentPutData: {} };

    default:
      return state;
  }
};

export default appointmentReducer;
