import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({

  CustomerStepContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    minWidth: 160,
  },
  editProcessPage: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    marginTop: '4%',
    padding: '3%',
  },
  formControl: {
    minWidth: 160,
    display: 'flex',
    marginTop: '3%',
    height: 'auto',
  },
  smsButton: {
    color: '#0253cc',
  },
}));

export default useStyles;
