import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  contentBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    margin: '10px 0px',
  },
  buttonBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginTop: '10px',
  },
  labelText:  {
    width: '80%',
    textAlign: 'center',
    marginBottom: '30px',
    marginLeft: '20px'
  },

}));

export default useStyles;
