import client from '../../client';
import { appService } from '../../services/app.service';
import * as ACTION from './actionTypes';

export const getZipCodeDetails = (zipCode) => async (dispatch) => {
  const url = appService.config.paths.zip_code_detail.route;
  dispatch({ type: ACTION.FETCH_ZIP_CODE_INIT });
  try {
    const response = await client.get(`${url}/${zipCode}`);
    const { data } = response;
    if (data && data.data.length > 0) {
      dispatch({ type: ACTION.FETCH_ZIP_CODE_SUCCESS, value: data.data });
    } else {
      dispatch({ type: ACTION.FETCH_ZIP_CODE_ERROR, error: 'No se encontraron colonias para el código postal ingresado' });
    }
  } catch (e) {
    dispatch({ type: ACTION.FETCH_ZIP_CODE_ERROR, error: 'Hubo un error consultando las colonias por el código postal' });
  }
};

export const createHeadquarter = (headquarterValues) => async (dispatch) => {
  dispatch({ type: ACTION.CREATE_HEADQUARTER_INIT });
  const url = appService.config.paths.headquarter.route;
  let payload = {
    business_name: headquarterValues.business_name,
    trade_name: headquarterValues.trade_name,
    phone_number: headquarterValues.lada + headquarterValues.phone_number,
    owner: {
      name: headquarterValues.name,
      lastname: headquarterValues.lastname,
      maternal_lastname: headquarterValues.maternal_lastname,
      email: headquarterValues.email,
      phone_number: headquarterValues.owner_phone_number,
      curp: headquarterValues.curp,
      rfc: headquarterValues.rfc,
    },
    is_legal_representative_same_owner: headquarterValues.is_legal_representative_same_owner,
    rfc: headquarterValues.business_rfc,
    address: {
      zip_code: headquarterValues.zip_code,
      street_name: headquarterValues.street_name,
      street_number: headquarterValues.street_number,
      apartment_number: headquarterValues.apartment_number,
      neighborhood: headquarterValues.neighborhood,
      state: headquarterValues.state,
      city: headquarterValues.city,
    },
  };
  if (!headquarterValues.is_legal_representative_same_owner) {
    payload['legal_representative'] = {
      name: headquarterValues.lr_name,
      lastname: headquarterValues.lr_lastname,
      maternal_lastname: headquarterValues.lr_maternal_lastname,
    };
  }
  try {
    await client.post(url, payload).then(() =>
      dispatch({ type: ACTION.CREATE_HEADQUARTER_SUCCESS }),
    ).catch((error) => {
      dispatch({ type: ACTION.CREATE_HEADQUARTER_ERROR, error: `Error creando la bodega: ${error.response.data.readable_message}` });
    });
  } catch (e) {
    console.log(e);
    dispatch({ type: ACTION.CREATE_HEADQUARTER_ERROR, value: 'Error desconocido guardando los datos de la bodega' });
  }
};

export const findHeadquarters = (searchParam) => async (dispatch) => {
  dispatch({ type: ACTION.FIND_HEADQUARTER_INIT });
  const url = `${appService.config.paths.headquarter.route}?search-param=${searchParam}`;
  try {
    const response = await client.get(url)
    const { data } = response;
    if (data && data.length > 0) {
      dispatch({ type: ACTION.FIND_HEADQUARTER_SUCCESS, value: data });
    } else {
      dispatch({ type: ACTION.FIND_HEADQUARTER_ERROR, error: 'No se encontraron bodegas para los parámetros de la búsqueda' });
    }
  } catch (e) {
    console.log(e);
    dispatch({ type: ACTION.FIND_HEADQUARTER_ERROR, value: 'Error desconocido buscando los datos de la bodega' });
  }
};

export const createWholesaler = (headquarterId, wholesalerValues) => async (dispatch) => {
  dispatch({ type: ACTION.CREATE_WHOLESALER_INIT });
  const url = `${appService.config.paths.headquarter.route}/${headquarterId}/branch-office`;
  try {
    await client.post(url, {
      trade_name: wholesalerValues.trade_name,
      email: wholesalerValues.email,
      additional_phone_numbers: wholesalerValues.additional_phone_numbers,
      bank_account: {
        bank_id: wholesalerValues.bank_id,
        bank_account_number: wholesalerValues.bank_account_number,
      },
      address: {
        zip_code: wholesalerValues.zip_code,
        street_name: wholesalerValues.street_name,
        street_number: wholesalerValues.street_number,
        apartment_number: wholesalerValues.apartment_number,
        neighborhood: wholesalerValues.neighborhood,
        state: wholesalerValues.state,
        city: wholesalerValues.city,
      },
    }).then(() =>
      dispatch({ type: ACTION.CREATE_WHOLESALER_SUCCESS }),
    ).catch((error) => {
      dispatch({ type: ACTION.CREATE_WHOLESALER_ERROR, error: `Error creando la sucursal: ${error.response.data.readable_message}` });
    });
  } catch (e) {
    console.log(e);
    dispatch({ type: ACTION.CREATE_WHOLESALER_ERROR, value: 'Error desconocido guardando los datos de la sucursal' });
  }
};

export const clearState = () => async (dispatch) => {
  dispatch({ type: ACTION.CLEAR_WHOLESALER_STATE });
};
