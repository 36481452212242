export const FETCH_CUSTOMERS_INIT = 'REGISTERS::FETCH_CUSTOMERS_INIT';
export const FETCH_CUSTOMERS_SUCCESS = 'REGISTERS::FETCH_CUSTOMERS_SUCCESS';
export const FETCH_CUSTOMERS_ERROR = 'REGISTERS::FETCH_CUSTOMERS_ERROR';
export const UPDATE_CUSTOMERS_INIT = 'REGISTERS::UPDATE_CUSTOMERS_INIT';
export const UPDATE_CUSTOMERS_SUCCESS = 'REGISTERS::UPDATE_CUSTOMERS_SUCCESS';
export const UPDATE_CUSTOMERS_ERROR = 'REGISTERS::UPDATE_CUSTOMERS_ERROR';
export const SET_STATE = 'REGISTERS::SET_STATE';
export const UPDATE_ALERT_SUCCESS = 'REGISTERS::UPDATE_ALERT_SUCCESS';
export const UPDATE_CLOSE_ALERT = 'REGISTERS::UPDATE_CLOSE_ALERT';
export const UPDATE_ALERT_ERROR = 'REGISTERS::UPDATE_ALERT_ERROR';
export const FETCH_FIND_CUSTOMERS_INIT = 'REGISTERS::FETCH_FIND_CUSTOMERS_INIT';
export const FETCH_FIND_CUSTOMERS_SUCCESS = 'REGISTERS::FETCH_FIND_CUSTOMERS_SUCCESS';
export const FETCH_FIND_CUSTOMERS_ERROR = 'REGISTERS::FETCH_FIND_CUSTOMERS_ERROR';
